import {Component,OnInit} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {PartnerService} from "../../service/partner.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router.paths.model";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html'
})
export class PartnerComponent implements OnInit {

    constructor(private loaderService: LoaderService,
                private partnerService: PartnerService,
                private auth: AuthenticationService,
                private router: Router) {
    }

    ngOnInit() {
        this.loaderService.show();

        this.detectPartner();
    }

    detectPartner() {
        this.auth.clearStorage();

        this.partnerService.getPartner().subscribe(data => {
            if (data.status) {
                this.partnerService.savePartner(data);

                if(this.partnerService.isMascotSingle()){
                    this.router.navigate([RouterPaths.mascot.main], {queryParams: {notLogged: true}});
                }else{
                    this.router.navigate([RouterPaths.login]);
                }
            }else{
                this.router.navigate(['/']);
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }


}
