import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {StylingService} from "../../../service/styling.service";
import {ScrollToService} from "../../../service/scrollTo.service";
import {KioskAccessorService} from "../../../service/kiosk/kiosk-accessor.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";

@Component({
    selector: 'app-prizes-venmo',
    templateUrl: './prizes-venmo.component.html',
    styleUrls: ['./prizes-venmo.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PrizesVenmoComponent implements OnInit {
    cashappForm: FormGroup;
    availableBalance = 0;
    moreThenAvailable = false;
    phoneMask = this.phoneMaskService.setPhoneMask();

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private router: Router,
                private stylingService: StylingService,
                private scrollToService: ScrollToService,
                private kioskAccessorService: KioskAccessorService,
                private phoneMaskService: PhoneMaskService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getBalance();
            }
        });
    }

    ngOnInit() {
        this.getBalance();

        this.cashappForm = this.formBuilder.group({
            amount: [null, Validators.required],
            handle: [null, Validators.required],
        });
    }

    getBalance(){
        this.prizesService.venmoBalance().subscribe(data => {
            this.setRequestResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setRequestResult(data) {
        try {
            const availableBalance = Number.parseFloat(data.availableBalance);

            if (data && (availableBalance || availableBalance === 0)) {
                this.setBalance(availableBalance);
            }

            this.setVenmoHandle(data);

            if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setVenmoHandle(data) {
        if (data && data.venmoHandle) {
            const value = data.venmoHandle && String(data.venmoHandle).trim() ? data.venmoHandle : null;
            this.cashappForm.controls.handle.setValue(value);
        } else {
            this.cashappForm.controls.handle.setValue(null);
        }
    }

    setBalance(balance: number) {
        const balanceNumber = balance;
        if (balanceNumber || balanceNumber === 0) {
            this.availableBalance = balanceNumber;
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    onSubmit() {
        const amount = this.getInputValue('amount');
        const handle = this.getInputValue('handle');

        if (amount && handle) {
            this.prizesService.redeemViaVenmo(amount, handle, 'venmo').subscribe(data => {
                this.submitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.cashappForm.reset();
                this.moreThenAvailable = false;
                this.getBalance();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    getInputValue(field) {
        if (this.cashappForm.controls[field]) {
            return this.cashappForm.controls[field].value;
        }
    }

    onAmountInput() {
        const entered = +this.cashappForm.controls.amount.value;

        if (entered === 0) {
            this.cashappForm.controls.amount.setValue(String(null));
        }

        if ((this.availableBalance || this.availableBalance === 0) && (this.availableBalance < entered)) {
            if (this.availableBalance === 0) {
                this.cashappForm.controls.amount.setValue(String(null));
            } else {
                this.cashappForm.controls.amount.setValue(String(this.availableBalance));
            }
            this.moreThenAvailable = true;
        } else {
            this.moreThenAvailable = false;
        }
    }
}

