<div class="account-popup account-popup--terminal" [class.is-hidden]="!showPopup">
    <button *ngIf="!isApp" (click)="goBack()" class="login__back">
        <span class="visually-hidden">Back</span>
    </button>
    <div class="account-popup__title login__title">Please enter terminal information</div>
    <form [formGroup]="terminalForm" (ngSubmit)="onSubmitTerminalForm()" class="login-page__form">
        <input formControlName="user_name"
               placeholder="Username"
               [type]="'text'"
               class="login-input"
               autocomplete="off">
        <input formControlName="password"
               placeholder="Password"
               [type]="'password'"
               class="login-input"
               autocomplete="off">

        <button class="button snake-btn is-notouched">
            Send Request
            <svg><rect></rect></svg>
        </button>
    </form>
</div>

<button class="reload-btn" *ngIf="isApp" (click)="reloadPage()">
    <svg class="reload-btn__icon" viewBox="0 0 489.645 489.645">
        <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#reload'"></use>
    </svg>
</button>
