import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var CashAppService = /** @class */ (function () {
    function CashAppService(router) {
        this.router = router;
    }
    CashAppService.prototype.checkIfCashApp = function () {
        return this.router.url.includes('cashapp');
    };
    CashAppService.prototype.isPrepaid = function () {
        return this.router.url.includes('prepaid');
    };
    CashAppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashAppService_Factory() { return new CashAppService(i0.ɵɵinject(i1.Router)); }, token: CashAppService, providedIn: "root" });
    return CashAppService;
}());
export { CashAppService };
