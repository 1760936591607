import { ChangeDetectorRef, OnDestroy } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { APP_DATE_FORMATS } from "../shared.module";
// import {APP_DATE_FORMATS} from "../../pages/pages.module";
/** @title Datepicker with custom calendar header */
var DatepickerCustomHeaderExample = /** @class */ (function () {
    function DatepickerCustomHeaderExample() {
        this.exampleHeader = ExampleHeader;
    }
    return DatepickerCustomHeaderExample;
}());
export { DatepickerCustomHeaderExample };
/** Custom header component for datepicker. */
var ExampleHeader = /** @class */ (function () {
    function ExampleHeader(_calendar, _dateAdapter, cdr) {
        this._calendar = _calendar;
        this._dateAdapter = _dateAdapter;
        this._destroyed = new Subject();
        this.events = [];
        this._dateFormats = APP_DATE_FORMATS;
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(function () { return cdr.markForCheck(); });
    }
    ExampleHeader.prototype.ngOnDestroy = function () {
        this._destroyed.next();
        this._destroyed.complete();
    };
    Object.defineProperty(ExampleHeader.prototype, "selectedMonth", {
        get: function () {
            return this._dateAdapter
                .format(this._calendar.activeDate, this._dateFormats.display.monthLabel)
                .toLocaleUpperCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExampleHeader.prototype, "selectedYear", {
        get: function () {
            return this._dateAdapter
                .format(this._calendar.activeDate, this._dateFormats.display.yearLabel)
                .toLocaleUpperCase();
        },
        enumerable: true,
        configurable: true
    });
    ExampleHeader.prototype.previousClicked = function (mode) {
        this._calendar.activeDate =
            mode === "month"
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    };
    ExampleHeader.prototype.nextClicked = function (mode) {
        this._calendar.activeDate =
            mode === "month"
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    };
    ExampleHeader.prototype.addEvent = function (type, event) {
        this.events.push(type + ": " + event.value);
    };
    return ExampleHeader;
}());
export { ExampleHeader };
