import {Component, OnInit, HostListener, ElementRef, ViewChild, HostBinding,} from '@angular/core';
import {slideToLeft, slideToRight} from "../../../animations/router.animations";
import {RouterPaths} from "../../../config/router.paths.model";
import {PartnerService} from "../../../service/partner.service";
import {GamesProviderService} from "../../../service/games/gamesProvider.service";

@Component({
    selector: 'app-help-settings',
    templateUrl: './help-settings.component.html',
    styleUrls: ['./help-settings.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class HelpSettingsComponent implements OnInit {
    cashappTag: string;
    gameVendorShortName = this.gamesProviderService.getActiveProviderShortName();

    constructor(private gamesProviderService: GamesProviderService,
                private partnerService: PartnerService) {
        this.cashappTag = this.partnerService.storageGetCashapTag();
    }

    ngOnInit() {}

    getAddLink() {
        return RouterPaths.settings.home;
    }
}
