import {Injectable} from '@angular/core';
import {YETI_GAME_IMAGES} from "../yeti.config";
declare const PIXI: typeof import('pixi.js');

@Injectable({
  providedIn: 'root'
})
export class YetiLoaderService {
  loader;
  resources;
  gameImages = YETI_GAME_IMAGES;

  constructor() {}

  initLoader() {
    this.loader = PIXI.loader;
    this.resources = this.loader.resources;

    this.gameImages.forEach(img => {
      this.loader.add(img.title, img.url)
    });
  }
}
