import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {KioskService} from "../../service/kiosk.service";
import {PartnerService} from "../../service/partner.service";
import {HERO_TEXT} from "../../config/project";
import {HeroService} from "../../service/hero.service";

@Component({
    selector: 'app-hero-v2',
    templateUrl: './hero-v2.component.html',
    styleUrls: ['./hero-v2.component.scss']
})
export class HeroV2Component implements OnInit {
    showCoins = false;
    isRiverPay: boolean;
    isCryptoVersion = this.heroService.isCryptoVersion();
    isWatchVersion = this.heroService.isWatchVersion();
    isViewDefined = false;

    constructor(private kioskService: KioskService,
                private partnerService: PartnerService,
                private heroService: HeroService,
                private router: Router) {

        this.isRiverPay = this.partnerService.isRiverPayTerminal();

    }

    ngOnInit() {}

    isKiosk() {
        return this.kioskService.isKiosk();
    }

    isCoins(value) {
        this.showCoins = value;
        if (this.isKiosk()) this.showCoins = false;
        this.isViewDefined = true;
    }

    isKioskInnerPages() {
        return this.router.url.indexOf('/kiosk') > -1;
    }

    getKioskCoinsImage() {
        if (this.isKioskInnerPages()) {
            return '../../../assets/images/bitcoins-sm.png'
        }
        return '../../../assets/images/bitcoins_new.png'
    }

    getHeroText() {
        if (this.isRiverPay) {
            return HERO_TEXT.riverpay;
        } else {
            return HERO_TEXT.default;
        }
    }
}
