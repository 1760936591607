import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SUPPORT_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";
import {SettingsLoaderService} from "../settingsLoader.service";


@Injectable({
  providedIn: 'root'
})
export class SupportService extends SettingsLoaderService {
  private config = SUPPORT_CONFIG;

  constructor(private http: HttpClient, public loaderService: LoaderService) {
    super(loaderService);
  }

  sendSupportRequest(system, game, date, time, description) {
    this.showLoader();
    return this.http.post<any>(this.config.request, {system, game, date, time, description});
  }
}
