import { YETI_GAME_IMAGES } from "../yeti.config";
import * as i0 from "@angular/core";
var YetiLoaderService = /** @class */ (function () {
    function YetiLoaderService() {
        this.gameImages = YETI_GAME_IMAGES;
    }
    YetiLoaderService.prototype.initLoader = function () {
        var _this = this;
        this.loader = PIXI.loader;
        this.resources = this.loader.resources;
        this.gameImages.forEach(function (img) {
            _this.loader.add(img.title, img.url);
        });
    };
    YetiLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YetiLoaderService_Factory() { return new YetiLoaderService(); }, token: YetiLoaderService, providedIn: "root" });
    return YetiLoaderService;
}());
export { YetiLoaderService };
