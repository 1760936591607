export class PaginationData {
  name: string;
  selector: string;
  selectorParent: string;
  itemsListIfNoResult: string;
  private _prevPagination: number = 0;
  private _previewPagination: number = 1;
  private _lastScrollTop: number = 0;
  private _dataRichEnd: boolean = false;
  private _items: any = [];
  private _limit: number = 0;
  private _loading: boolean = false;
  private _countLastAddedItems: number = 0;
  private _canScroll: boolean = true;

  private _countTotalItems: number = 0;
  private _lastAddedItem: number = 0;



  constructor(name: string, selector: string, selectorParent: string, itemsListIfNoResult?: string) {
    this.name = name;
    this.selector = selector;
    this.selectorParent = selectorParent;
    this.itemsListIfNoResult = itemsListIfNoResult ? itemsListIfNoResult : 'result';
  }

  get prevPagination(): number {
    return this._prevPagination;
  }

  set prevPagination(prevPagination: number) {
    this._prevPagination = prevPagination;
  }

  get previewPagination(): number {
    return this._previewPagination;
  }

  set previewPagination(previewPagination: number) {
    this._previewPagination = previewPagination;
  }

  get lastScrollTop(): number {
    return this._lastScrollTop;
  }

  set lastScrollTop(lastScrollTop: number) {
    this._lastScrollTop = lastScrollTop;
  }

  get dataRichEnd(): boolean {
    return this._dataRichEnd;
  }

  set dataRichEnd(dataRichEnd: boolean) {
    this._dataRichEnd = dataRichEnd;
  }

  get items(): any {
    return this._items;
  }

  set items(items: any) {
    this._items = items;
  }

  get limit(): number {
    return this._limit;
  }

  set limit(limit: number) {
    this._limit = limit;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
  }

  get countLastAddedItems(): number {
    return this._countLastAddedItems;
  }

  set countLastAddedItems(countLastAddedItems: number) {
    this._countLastAddedItems = countLastAddedItems;
  }

  get canScroll(): boolean {
    return this._canScroll;
  }

  set canScroll(canScroll: boolean) {
    this._canScroll = canScroll;
  }

  get countTotalItems(): number {
    return this._countTotalItems;
  }

  set countTotalItems(countTotalItems: number) {
    this._countTotalItems = countTotalItems;
  }

  get lastAddedItem(): number {
    return this._lastAddedItem;
  }

  set lastAddedItem(lastAddedItem: number) {
    this._lastAddedItem = lastAddedItem;
  }
}
