const yetiImagesFolder = './assets/images/yeti';
const yetiImagesBackgroundsFolder = yetiImagesFolder + '/backgrounds';
const yetiImagesWordsFolder = yetiImagesFolder + '/words';
const yetiImagesButtonsFolder = yetiImagesFolder + '/buttons';
const yetiImagesFindingElementsFolder = yetiImagesFolder + '/finding-elements';
const yetiImagesNumbersFolder = yetiImagesFolder + '/numbers';
const yetiImagesPopupsFolder = yetiImagesFolder + '/popups';

export const YETI_GAME_IMAGES = [
  {
    title: 'gameBg',
    url: yetiImagesBackgroundsFolder + '/bg.png'
  },
  {
    title: 'askContinue',
    url: yetiImagesBackgroundsFolder + '/askContinue.png'
  },
  {
    title: 'yetiImg',
    url: yetiImagesFolder + '/yeti.png'
  },
  {
    title: 'snowdrift',
    url: yetiImagesFolder + '/snowdrift.png'
  },
  {
    title: 'snowdriftReverse',
    url: yetiImagesFolder + '/snowdrift_reverse.png'
  },
  {
    title: 'snowdriftBroken',
    url: yetiImagesFolder + '/snowdrift-broken.png'
  },
  {
    title: 'scoreBg',
    url: yetiImagesFolder + '/scoreBg.png'
  },
  {
    title: 'scoreWinsBg',
    url: yetiImagesFolder + '/scoreWinsBg.png'
  },
  {
    title: 'titleYeti',
    url: yetiImagesWordsFolder + '/title_yeti.png'
  },
  {
    title: 'titleSnowdrift',
    url: yetiImagesWordsFolder + '/title_snowdrift.png'
  },
  {
    title: 'frameImg',
    url: yetiImagesBackgroundsFolder + '/frame.png'
  },
  {
    title: 'poisonBg',
    url: yetiImagesBackgroundsFolder + '/poisonBg.png'
  },
  {
    title: 'nextBtn',
    url: yetiImagesButtonsFolder + '/next.png'
  },
  {
    title: 'use',
    url: yetiImagesButtonsFolder + '/use.png'
  },
  {
    title: 'info',
    url: yetiImagesButtonsFolder + '/info.png'
  },
  {
    title: 'closeRules',
    url: yetiImagesButtonsFolder + '/closeRules.png'
  },
  {
    title: 'playBtn',
    url: yetiImagesButtonsFolder + '/play.png'
  },
  {
    title: 'redeemBtn',
    url: yetiImagesButtonsFolder + '/redeem.png'
  },
  {
    title: 'prize1',
    url: yetiImagesFindingElementsFolder + '/El1.png'
  },
  {
    title: 'prize2',
    url: yetiImagesFindingElementsFolder + '/El2.png'
  },
  {
    title: 'prize3',
    url: yetiImagesFindingElementsFolder + '/El3.png'
  },
  {
    title: 'prize4',
    url: yetiImagesFindingElementsFolder + '/El4.png'
  },
  {
    title: 'prize5',
    url: yetiImagesFindingElementsFolder + '/El5.png'
  },
  {
    title: 'poison',
    url: yetiImagesFindingElementsFolder + '/El6.png'
  },
  {
    title: 'poisonWrapper',
    url: yetiImagesFindingElementsFolder + '/poisonWrapper.png'
  },
  {
    title: '0',
    url: yetiImagesNumbersFolder + '/0.png'
  },
  {
    title: '1',
    url: yetiImagesNumbersFolder + '/1.png'
  },
  {
    title: '2',
    url: yetiImagesNumbersFolder + '/2.png'
  },
  {
    title: '3',
    url: yetiImagesNumbersFolder + '/3.png'
  },
  {
    title: '4',
    url: yetiImagesNumbersFolder + '/4.png'
  },
  {
    title: '5',
    url: yetiImagesNumbersFolder + '/5.png'
  },
  {
    title: '6',
    url: yetiImagesNumbersFolder + '/6.png'
  },
  {
    title: '7',
    url: yetiImagesNumbersFolder + '/7.png'
  },
  {
    title: '8',
    url: yetiImagesNumbersFolder + '/8.png'
  },
  {
    title: '9',
    url: yetiImagesNumbersFolder + '/9.png'
  },
  {
    title: 'comma',
    url: yetiImagesNumbersFolder + '/comma.png'
  },
  {
    title: 'dot',
    url: yetiImagesNumbersFolder + '/dot.png'
  },
  {
    title: 'x',
    url: yetiImagesNumbersFolder + '/X.png'
  },
  {
    title: 'dollar',
    url: yetiImagesNumbersFolder + '/S.png'
  },
  {
    title: 'popupNoLuck',
    url: yetiImagesPopupsFolder + '/popup_no-luck.png'
  },
  {
    title: 'popupLuck',
    url: yetiImagesPopupsFolder + '/popup_luck.png'
  },
  {
    title: 'numbersAtlas',
    url: yetiImagesNumbersFolder + '/numbers.json'
  }
];

export const YETI_RULES = [
  'You have three shorts',
  '3 snowdrifts are empty with no win',
  '3 snowdrifts have winning symbols hidden behind',
  'The first symbol found is the winner',
  'If poison is found, all wins are lost',
  'Game never ends as long as you find a winning symbol',
  'Wins accumulate',
  'You can stop the game anytime and claim your prize'
];

export const YETI_SNOWDRIFTS_POSITIONS = [
  {
    x: 1030,
    y: 540,
    scale: .8
  },
  {
    x: 1640,
    y: 630,
    scale: 1
  },
  {
    x: 940,
    y: 1060,
    scale: 1.5
  },
  {
    x: 1250,
    y: 780,
    scale: 1.2
  },
  {
    x: 1570,
    y: 1080,
    scale: 1.6
  }
];

export const YETI_FINDING_ELEMENTS_NAMES = {
  poison: 'poison',
  bomb: 'bomb',
  coins: 'coins',
  box: 'box',
  ice: 'ice',
  egg: 'egg',
  empty: 'empty'
}
