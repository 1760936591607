<div *ngIf="data"
     class="investments-popup"
     [class.is-active]="showPopupForm !== 'hide'"
     [class.is-blurred]="showPopupForm === 'blur'">

    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()">
        </button>

        <ng-template [ngIf]="isCryptoPopup() || isBuyPopup() || isSellPopup()">
            <header class="investments-popup__head" [class.is-buy-popup]="isBuyPopup()" [class.is-sell-from-popup]="isSellPopup()" [class.is-bordered]="isBuyPopup() || isSellPopup()">
                <div class="investments-popup__icon-wrapper">
<!--                    <svg class="investments-popup__icon">-->
<!--                        <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#'+getCryptoIcon(data.icon)"></use>-->
<!--                    </svg>-->

                    <img class="investments-popup__icon"
                         [src]="'../../../../../assets/images/currencies/' + getCryptoIcon(data.icon) + '.png'"
                         (error)="loadDefaultCurrencyIcon($event)">
                </div>
                <div class="investments-popup__title">{{data.name}}</div>
            </header>
            <header *ngIf="isSellPopup()" class="investments-popup__head is-bordered is-sell-popup" [class.is-sell-to-popup]="isSellPopup()">
                <div class="investments-popup__icon-wrapper">
                        <svg class="investments-popup__icon">
                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#dollar'"></use>
                        </svg>

<!--                    <img class="investments-popup__icon"-->
<!--                         src="../../../../../assets/images/currencies/{{getCryptoIcon(data.icon)}}.png"-->
<!--                         (error)="loadDefaultCurrencyIcon($event)">-->
                </div>
                <div class="investments-popup__title">USD</div>
            </header>

            <div *ngIf="isCryptoPopup()" class="investments-popup__balance">
                <span>AVAILABLE</span>
                <span>
                {{data.size | number : CRYPTO_AMOUNT_MASK}} {{data.name}}
            </span>
            </div>

            <div *ngIf="isBuyPopup()" class="investments-popup__balance investments-popup__balance--row">
                <span>USD RATE</span>
                <span>{{data.size | number : CRYPTO_AMOUNT_MASK}} {{data.name}}</span>
            </div>

            <div *ngIf="isSellPopup()" class="investments-popup__balance investments-popup__balance--row">
                <span>AVAILABLE TO SELL</span>
                <span>{{data.availableAmount | number : CRYPTO_AMOUNT_MASK}} {{data.name}}</span>
            </div>

            <form [formGroup]="sendCryptoForm" (ngSubmit)="isBuyPopup() || isSellPopup() ? isBuyPopup() ? checkSubmitBuyPrizes() : checkSubmitSellPrizes() : onSubmit()" class="investments-form investments-popup__form form-simple form-simple--shrink form-simple--primary">

                <div class="form-simple__item">
                    <input mask="separator"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="AMOUNT"
                           [type]="'tel'"
                           (input)="onAmountInput()"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div *ngIf="(isSellPopup() || isCryptoPopup()) && moreThenAvailable" class="investments-popup__note">
                    Available {{ isSellPopup() ? data.availableAmount : data.size | number : CRYPTO_AMOUNT_MASK}} {{data.name}}
                </div>

                <div *ngIf="isBuyPopup() && moreThenAvailable" class="investments-popup__note">
                    Available {{data.availableAmount | currency : 'USD' : 'symbol'}}
                </div>

                <div class="form-simple__item" [hidden]="isBuyPopup() || isSellPopup()">
                    <input formControlName="address"
                           placeholder="ADDRESS"
                           [type]="'text'"
                           (change)="onInput($event)"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item" [hidden]="isBuyPopup() || isSellPopup()">
                    <input formControlName="note"
                           placeholder="OPTIONAL NOTE"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div *ngIf="isBuyPopup()" class="investments-popup__balance investments-popup__balance--row">
                    <span>Fee: {{getFee() | currency : 'USD' : 'symbol'}}</span>
                    <span>Total: {{getTotal() | number : CRYPTO_AMOUNT_MASK }} {{data.name}}</span>
                </div>

                <div *ngIf="isSellPopup()" class="investments-popup__balance investments-popup__balance--row">
                    <span>Fee: {{getFee() | number : CRYPTO_AMOUNT_MASK}} {{data.name}}</span>
                    <span>Total: {{getTotal() | currency : 'USD' : 'symbol'}}</span>
                </div>

                <div class="form-simple__btns">
                    <button *ngIf="isCryptoPopup()" class="button" [disabled]="!+sendCryptoForm.controls.amount.value || !sendCryptoForm.controls.address.value">
                        continue
                    </button>

                    <button *ngIf="isBuyPopup()" class="button" [disabled]="!+sendCryptoForm.controls.amount.value || +sendCryptoForm.controls.amount.value > data.availableAmount">
                        continue
                    </button>

                    <button *ngIf="isSellPopup()" class="button" [disabled]="!+sendCryptoForm.controls.amount.value || +sendCryptoForm.controls.amount.value > data.availableAmount">
                        continue
                    </button>
                </div>
            </form>
        </ng-template>

        <ng-template [ngIf]="formType === 'trade'">
            <form [formGroup]="tradeForm" (ngSubmit)="onSubmitTrade()" class="investments-form investments-popup__form form-simple form-simple--shrink form-simple--primary">

                <mat-form-field>
                    <mat-label>FROM</mat-label>
                    <mat-select #from formControlName="from" [(value)]="selectedFrom" (selectionChange)="onCurrencyFromChange($event)">
                        <mat-select-trigger>
<!--                            <svg class="investments-form__icon">-->
<!--                                <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#'+from?.value?.iconFrom"></use>-->
<!--                            </svg>-->
                            <img *ngIf="from?.value" class="investments-form__icon js-currency-icon"
                                 src="../../../../assets/images/currencies/{{from?.value?.iconFrom}}.png"
                                 alt="{{from?.value?.nameFrom}}"
                                 (error)="loadDefaultCurrencyIcon($event)"
                            >

                            {{from?.value?.nameFrom}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let option of getFromOptions()" [value]="option">
<!--                            <svg class="investments-form__icon" width="21" height="12">-->
<!--                                <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#'+option.iconFrom"></use>-->
<!--                            </svg>-->
                            <img *ngIf="option" class="investments-form__icon"
                                 src="../../../../assets/images/currencies/{{option?.iconFrom}}.png"
                                 alt="{{option.nameFrom}}"
                                 (error)="loadDefaultCurrencyIcon($event)">

                            {{option.nameFrom}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>TO</mat-label>
                    <mat-select #to formControlName="to" [(value)]="selectedTo" (selectionChange)="onChangeToCurrency($event)">
                        <mat-select-trigger>
<!--                            <svg class="investments-form__icon" width="21" height="12">-->
<!--                                <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#'+to?.value?.iconTo"></use>-->
<!--                            </svg>-->
                            <img *ngIf="to?.value" class="investments-form__icon"
                                 src="../../../../assets/images/currencies/{{to?.value?.iconTo}}.png"
                                 alt="{{to?.value?.nameTo}}"
                                 (error)="loadDefaultCurrencyIcon($event)">
                            {{to?.value?.nameTo}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let option of getToOptions()" [value]="option">
<!--                            <svg class="investments-form__icon" width="21" height="12">-->
<!--                                <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#'+option.iconTo"></use>-->
<!--                            </svg>-->
                            <img *ngIf="option" class="investments-form__icon"
                                 src="../../../../assets/images/currencies/{{option.iconTo}}.png"
                                 alt="{{option.nameTo}}"
                                 (error)="loadDefaultCurrencyIcon($event)">
                            {{option.nameTo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="investments-popup__group">
                    <div class="investments-popup__text">Last Trade price</div>
                    <div class="investments-popup__text">{{getLastTradePrice() | number}} {{getLastTradePriceName()}}</div>
                </div>

                <div class="investments-popup__group">
                    <div class="investments-popup__text">AVAILABLE TO TRADE</div>
                    <div class="investments-popup__text">{{data.availableAmount | number}} {{data.checkedCurrency.fromName}}</div>
                </div>

                <div class="form-simple__item">
                    <input mask="separator"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="AMOUNT"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button" [disabled]="!+tradeForm.controls.amount.value">
                        continue
                    </button>
                </div>
            </form>
        </ng-template>

    </div>
</div>

<app-investments-popup-confirm
        [popupData]="popupConfirmData"
        [confirmType]="confirmPopupType"
        (close)="onConfirmPopupClose($event)"></app-investments-popup-confirm>
