import * as tslib_1 from "tslib";
import { USER_CONFIG } from "../config/api";
import { SettingsLoaderService } from "./settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var UserStatusService = /** @class */ (function (_super) {
    tslib_1.__extends(UserStatusService, _super);
    function UserStatusService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = USER_CONFIG;
        return _this;
    }
    UserStatusService.prototype.getUserStatus = function () {
        this.showLoader();
        return this.http.post(this.config.status, {});
    };
    UserStatusService.prototype.getSettingsData = function () {
        this.showLoader();
        return this.http.post(this.config.settings, {});
    };
    UserStatusService.prototype.freeze = function () {
        this.showLoader();
        return this.http.post(this.config.freeze, {});
    };
    UserStatusService.prototype.activate = function () {
        this.showLoader();
        return this.http.post(this.config.activate, {});
    };
    UserStatusService.prototype.delete = function () {
        this.showLoader();
        return this.http.post(this.config.delete, {});
    };
    UserStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserStatusService_Factory() { return new UserStatusService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: UserStatusService, providedIn: "root" });
    return UserStatusService;
}(SettingsLoaderService));
export { UserStatusService };
