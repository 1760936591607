import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
    selector: 'app-slider-test4',
    templateUrl: './slider-test4.component.html',
    styleUrls: ['./slider-test4.component.scss']
})
export class SliderTest4Component implements OnInit {
    slides = [
        {img: "https://picsum.photos/700/250/?image=27"},
        {img: "https://picsum.photos/700/250/?image=22"},
        {img: "https://picsum.photos/700/250/?image=61"},
        {img: "https://picsum.photos/700/250/?image=23"},
        {img: "https://picsum.photos/700/250/?image=24"},
        {img: "https://picsum.photos/700/250/?image=26"},
        {img: "https://picsum.photos/700/250/?image=41"}
    ];

    @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;

    play(): void {


        this.ngxGlide.play();
    }

    constructor() {

    }

    ngOnInit() {

    }



}
