<div class="terms-and-conditions">
    <div class="terms-and-conditions__inner">
        <h1 class="terms-and-conditions__title terms-and-conditions__align-center">{{content.title}}</h1>
        <h2 class="terms-and-conditions__subtitle terms-and-conditions__align-center">{{content.subtitle}}</h2>

        <div class="terms-and-conditions__block" *ngFor="let part of content.parts">
            <h1 class="terms-and-conditions__title">{{part.title}}</h1>

            <ng-template ngFor let-paragraph [ngForOf]="part.paragraphs">

                <div *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock" class="terms-and-conditions__inner-block">
                    <h3 [innerHTML]="paragraph.title"></h3>

                    <p *ngFor="let item of paragraph.text" [innerHTML]="item"></p>
                </div>

                <p *ngIf="!paragraph.type" [innerHTML]="paragraph"></p>

                <ul *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.unorderedList">
                    <li *ngFor="let item of paragraph.items">{{item}}</li>
                </ul>

                <ol *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.orderedList">
                    <li *ngFor="let item of paragraph.items">{{item}}</li>
                </ol>

                <div *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.footer" class="terms-and-conditions__footer">
                    <p *ngFor="let item of paragraph.items" [innerHTML]="item"></p>
                </div>
            </ng-template>

        </div>
    </div>
</div>



