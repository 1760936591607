import { GLOBAL_CLASSES } from "../../config/globalClasses";
import * as i0 from "@angular/core";
import * as i1 from "../kiosk.service";
var DOMManipulationsService = /** @class */ (function () {
    function DOMManipulationsService(kioskService) {
        this.kioskService = kioskService;
        this.GLOBAL_CLASSES = GLOBAL_CLASSES;
    }
    DOMManipulationsService.prototype.setPageLongContent = function () {
        if (this.kioskService.isKiosk()) {
            document.body.querySelector('.page').classList.add(this.GLOBAL_CLASSES.pageLongContent);
        }
    };
    DOMManipulationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DOMManipulationsService_Factory() { return new DOMManipulationsService(i0.ɵɵinject(i1.KioskService)); }, token: DOMManipulationsService, providedIn: "root" });
    return DOMManipulationsService;
}());
export { DOMManipulationsService };
