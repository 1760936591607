<div class="page page--primary rewards" [class.is-blurred]="showSupportForm">
    <app-logo-bg></app-logo-bg>

    <div class="page__inner">

        <div class="page__row rewards__head">
            <h2 *ngIf="showPointName()" class="page__point">{{pointName}}</h2>
            <h1 class="page__title">REWARDS</h1>
        </div>

        <div class="rewards__ad rewards__ad--no-mt">
            <div class="ad-simple">
                <h2 class="ad-simple__title gl-title">LIMITED SWEEPS OFFER</h2>
                <p class="ad-simple__text gl-subtitle">For a limited time, we offer dollar-per-dollar sweepstakes entries on all purchases.</p>
            </div>
        </div>

        <div class="games-selection" *ngIf="providers.length > 1">
            <form [formGroup]="providersForm" class="form-simple form-simple--shrink form-simple--primary">
                <div class="form-simple__item">
                    <mat-form-field>
<!--                        <mat-label>GET</mat-label>-->
                        <mat-select #providersSelect
                                    formControlName="providers"
                                    (valueChange)="onProviderChange($event)"
                                    [(value)]="selectedProvider" panelClass="my-select-panel-border-yellow">
                            <mat-select-trigger>
                                {{providersSelect?.value?.name}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let provider of providers" [value]="provider">
                                {{provider.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>

        <div *ngIf="riverView" class="rewards__info">
            <div *ngIf="userPin" class="rewards__pin">
                <div id="rewards_code" class="rewards__code">{{userPin}}</div>
            </div>
        </div>


        <div *ngIf="goldenDragonView" class="rewards__info rewards__info--mb">
            <div class="rewards__row">
                <div class="rewards__col">Mobile ID:
                </div>
                <div class="rewards__col">
                    {{gd_mobile_id}}
                </div>
            </div>

            <div class="rewards__row">
                <div class="rewards__col">
                    Password:
                </div>
                <div class="rewards__col">
                    {{gd_password}}
                </div>
            </div>
        </div>

        <div class="page__body">
            <div class="game" *ngIf="activeProvider">
                <div class="game__item">
                    <img [src]="activeProvider.image_link"
                         alt=""
                         class="game__img"
                         (click)="isApp ? onSweepsKiosk() : onSweeps(true)">

                    <div class="game__content">
                        <div class="game__description" [innerHTML]="activeProvider.description"></div>
                        <button (click)="isApp ? onSweepsKiosk() : onSweeps(true)" class="game__btn">PLAY</button>
                        <button (click)="onShowSupportForm()" class="game__btn">Support</button>
                    </div>
                </div>

                <div class="rewards__footer">
                    <div class="rewards__btns">
                        <a [routerLink]="getLinkToNFT()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">MINT <br>NFT</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke-width="2"/>
                            </svg>
                        </a>
                        <a [routerLink]="getLinkToFunds()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">ADD <br>FUNDS</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke-width="2"/>
                            </svg>
                        </a>
                        <a *ngIf="isPointPlayersEarnIncome()" [routerLink]="getLinkToEarnIncome()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">EARN <br>INCOME</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke-width="2"/>
                            </svg>
                        </a>
                    </div>
                </div>

<!--                <div class="rewards__ad">-->
<!--                    <div class="ad-simple">-->
<!--                        <h2 class="ad-simple__title">ACE REVEAL AVAILABLE NOW!</h2>-->
<!--                        <p class="ad-simple__text">Go to AceReveal.com and use Promo Code: NFT</p>-->

<!--                        <div class="ad-simple__row">-->
<!--                            <div class="ad-simple__col"></div>-->
<!--                            <a [href]="advertiseLink" target="_blank" class="ad-simple__btn">PLAY ACE REVEAL</a>-->
<!--                        </div>-->
<!--                        <a [href]="advertiseLink" target="_blank">-->
<!--                            <img class="ad-simple__img" src="../../../assets/images/advertise/acereveal.png" alt="AceReveal">-->

<!--                        </a>-->

<!--&lt;!&ndash;                        <h2 class="ad-simple__title">COMING COON</h2>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p class="ad-simple__text">We are excited to share with you that soon you will be able to mint your own NFTs’ by simply uploading any image of your choice! We are also adding for you the unique ability to create your own Ethereum Wallet at no cost to you with a single click.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p class="ad-simple__text">According to a new estimate from blockchain analytics firm Chainalysis Inc., nearly $41 billion worth of cryptocurrency was sent to two types of Ethereum smart contracts associated with NFT marketplaces and collections from the beginning of 2021 through mid-December.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p class="ad-simple__text">We are thrilled for you to be part of that exploding market!</p>&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>

        <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
            <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
            <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
            <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
            <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
        </svg>

    </div>
</div>

<app-support [showSupport]="showSupportForm" (close)="onSupportClose($event)"></app-support>
