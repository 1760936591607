import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {KIOSK_CONFIG} from "../config/api";
import {SettingsLoaderService} from "./settingsLoader.service";
import {LoaderService} from "./loader.service";
import {BehaviorSubject} from "rxjs";
import {FrontendTestSettingsService} from "./testing/frontendTestSettings.service";

@Injectable({
    providedIn: 'root'
})
export class KioskService extends SettingsLoaderService{
    private config = KIOSK_CONFIG;
    billingPage = new BehaviorSubject(false);
    paymentType = new BehaviorSubject(null);

    constructor(private http: HttpClient,
                private frontendTestSettingsService: FrontendTestSettingsService,
                public loaderService: LoaderService) {
        super(loaderService);

        this.isAppDevice();
    }

    isAppDevice() {
        var userAgent = window.navigator.userAgent;
        var userAgent = userAgent.toLowerCase();
        if (userAgent.indexOf('myapp/kiosk') !== -1) {
            localStorage.setItem('isApp', '1');
        }else{
            localStorage.setItem('isApp', '0');
        }

        if (this.frontendTestSettingsService.isTerminal()) {
            localStorage.setItem('isApp', '1');
        }
    }

    getSweepsLink() {
        this.showLoader();
        let post = this.http.post<any>(this.config.sweepsSendLink, {});
        return post;
    }

    addPaymentType(type) {
        this.paymentType.next(type);
    }

    isKiosk() {
        return +localStorage.getItem('isApp');
    }

    activateBilling() {
        this.billingPage.next(true);
    }

    deactivateBilling() {
        this.billingPage.next(false);
    }
}
