<div class="page page--quaternary login-page">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <a [routerLink]="getBackLink()" class="page__back page__back--mobile page__back--mobile-text">
            <span class="visually-hidden">cancel</span>
        </a>
        <h1 class="page__title">VERIFY CARD</h1>
            <div class="login-content">
                <p class="text t-light login__text">Please check your online statement for two small deposits ranging from $0.50 to $0.99.</p>
            </div>

<!--        <div class="login-content">-->
<!--            <p class="text t-light login__text">Enter the four digit code found in your online statement for the card ending in -{{card?.last_four}}</p>-->
<!--        </div>-->

<!--        <div #infoPopup class="info-popup">-->
<!--            <div class="info-popup__wrapper" [class.is-active]="infoPopupShown">-->
<!--                <div class="info-popup__inner">-->
<!--                    <div class="info-popup__head">-->
<!--                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M1.26316 5.57143V9.90476H3.15789V5.57143H1.26316ZM5.05263 5.57143V9.90476H6.94737V5.57143H5.05263ZM0 13H12V11.1429H0V13ZM8.8421 5.57143V9.90476H10.7368V5.57143H8.8421ZM6 0L0 3.09524V4.33333H12V3.09524L6 0Z" fill="white"/>-->
<!--                        </svg>-->
<!--                        <span>EARTHBANK....1234 STATEMENT</span>-->
<!--                    </div>-->
<!--                    <div class="info-popup__body">-->
<!--                        <div class="transaction">-->
<!--                            <div class="transaction__head transaction__row">-->
<!--                                <div class="transaction__col">Amount</div>-->
<!--                                <div class="transaction__col">Transaction</div>-->
<!--                            </div>-->
<!--                            <div class="transaction__body transaction__row">-->
<!--                                <div class="transaction__col">$0.50</div>-->
<!--                                <div class="transaction__col">EBOX* CODE 5678</div>-->
<!--                            </div>-->
<!--                            <div class="transaction__footer transaction__row">-->
<!--                                <div class="transaction__col">$13.00</div>-->
<!--                                <div class="transaction__col">GROCERIES</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="info-popup__footer">-->
<!--                        The statement will include a four digit code. Some bank portals will require you to expand the view and the four digit code will be in the description.-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <button (click)="infoPopupShown = !infoPopupShown" class="info-popup__button">i</button>-->
<!--        </div>-->

        <div class="page__form">

<!--            <form [formGroup]="pinForm" (ngSubmit)="onSubmit()" class="login-page__form">-->
<!--                <div *ngIf="!isApp" class="login__items" formArrayName="digits">-->
<!--                    <input (keyup.enter)="onSubmit()" required *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i" (keydown)="check(i, field, $event)"-->
<!--                           mask="0"-->
<!--                           class="login-input-small" type="number">-->
<!--                </div>-->

<!--                <div *ngIf="isApp" class="login__items" formArrayName="digits">-->
<!--                    <input (keyup.enter)="onSubmit()"-->
<!--                           required-->
<!--                           *ngFor="let field of pinForm.get('digits')['controls']; let i = index"-->
<!--                           #inputs [maxLength]="1" [formControlName]="i"-->
<!--                           [attr.data-order]="i"-->
<!--                           mask="0"-->
<!--                           class="login-input-small" type="tel">-->
<!--                </div>-->

<!--                <div class="login__items text-center form-btn-submit">-->
<!--                    <button id="credit_card_resend_btn" (click)="resendCode()" type="button" class="text t-light login-text login__resend">Resend</button>-->
<!--                </div>-->

<!--                <div class="text-center form-btn-submit">-->
<!--                    <button id="credit_card_verify_btn" class="button snake-btn is-notouched" [disabled]="!pinForm.valid">-->
<!--                        VERIFY-->
<!--                        <svg><rect></rect></svg>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </form>-->

                <form [formGroup]="amountsForm" (ngSubmit)="onSubmitAmountsForm()" class="login-page__form verify-form">
                    <div class="verify-form__item">
                        <label class="verify-form__label login__text">Amount</label>
                        <input formControlName="amount1"
                               [prefix]="amount1Prefix"
                               mask="00"
                               *ngIf="!isCardVerified"
                               (input)="onInputAmount($event, 1)"
                               placeholder=""
                               [type]="'text'"
                               class="verify-form__input login__text"
                               autocomplete="off">
                        <input formControlName="amount3"
                               *ngIf="isCardVerified"
                               placeholder=""
                               [type]="'text'"
                               disabled
                               class="verify-form__input login__text"
                               autocomplete="off">
                    </div>

                    <div class="verify-form__item">
                        <label class="verify-form__label login__text">Amount</label>
                        <input formControlName="amount2"
                               mask="00"
                               [prefix]="amount2Prefix"
                               *ngIf="!isCardVerified"
                               (input)="onInputAmount($event, 2)"
                               placeholder=""
                               [type]="'text'"
                               class="verify-form__input login__text"
                               autocomplete="off">
                        <input formControlName="amount4"
                               *ngIf="isCardVerified"
                               placeholder=""
                               [type]="'text'"
                               disabled
                               class="verify-form__input login__text"
                               autocomplete="off">
                    </div>

<!--                    <div *ngIf="!isApp" class="login__items" formArrayName="digits">-->
<!--                        <input (keyup.enter)="onSubmitAmountsForm()" required *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i" (keydown)="check(i, field, $event)"-->
<!--                               mask="0"-->
<!--                               class="login-input-small" type="number">-->
<!--                    </div>-->

<!--                    <div *ngIf="isApp" class="login__items" formArrayName="digits">-->
<!--                        <input (keyup.enter)="onSubmitAmountsForm()"-->
<!--                               required-->
<!--                               *ngFor="let field of pinForm.get('digits')['controls']; let i = index"-->
<!--                               #inputs [maxLength]="1" [formControlName]="i"-->
<!--                               [attr.data-order]="i"-->
<!--                               mask="0"-->
<!--                               class="login-input-small" type="tel">-->
<!--                    </div>-->

<!--                    <div class="login__items text-center form-btn-submit">-->
<!--                        <button id="credit_card_resend_btn" (click)="resendCode()" type="button" class="text t-light login-text login__resend">Resend</button>-->
<!--                    </div>-->

                    <div class="text-center form-btn-submit page__form-btns">
                        <p *ngIf="isCardVerified" class="text-center">Verified on {{verifyDate}} UTC</p>
                        <button *ngIf="!isCardVerified" id="credit_card_verify_btn" class="button snake-btn is-notouched" [disabled]="!+amountsForm.controls.amount1.value || !+amountsForm.controls.amount2.value">
                            VERIFY
                            <svg><rect></rect></svg>
                        </button>

                        <a *ngIf="isCardVerified" [href]="RouterPaths.settings.funds" class="button snake-btn is-notouched">
                            START SHOPPING
                            <svg><rect></rect></svg>
                        </a>
                    </div>
                </form>
        </div>

        <a [routerLink]="getBackLink()" class="button page__back page__back--desktop" >
            Back
            <svg><rect></rect></svg>
        </a>

    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>
