import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PAYMENT_TYPES, PAYMENT_TYPES_ICONS} from "../../config/payment_types";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PrizesService} from "../../service/prizes/prizes.service";
import {PrizesModel} from "../../models/services/prizes/prizes.model";
import {StylingService} from "../../service/styling.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BREAKPOINTS} from "../../config/breakpoints";
import {DOMManipulationsService} from "../../service/dom/DOMManipulations.service";
import {KioskAccessorService} from "../../service/kiosk/kiosk-accessor.service";
import {KioskScrollService} from "../../service/kiosk-scroll.service";
import {RouterPaths} from "../../config/router.paths.model";
import {PRIZES_METHODS} from "./prizes.config";
import {PROJECT_MESSAGES} from "../../config/project";
import {formatCurrency, formatNumber} from "@angular/common";
import {CRYPTO_AMOUNT_MASK} from "../../config/crypto_settings";
import {PrizesMethodModel} from "../../models/prizes/prizesMethod.model";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {POPUP_TYPES, showPopupFormType} from "../investments/investments-popup-form/investments-popup-form.config";
import {SettingsService} from "../../service/settings/settings.service";

@Component({
    selector: 'app-prizes',
    templateUrl: './prizes.component.html',
    styleUrls: ['./prizes.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class PrizesComponent implements OnInit {
    dropdownClosed = true;

    prizesInfo: PrizesModel;

    PAYMENT_TYPES = PAYMENT_TYPES;
    PAYMENT_TYPES_ICONS = PAYMENT_TYPES_ICONS;
    ROUTER_PATHS = RouterPaths;

    menu = false;
    availableBalance = 0;

    prevChildTopOffset = 0;
    methodUnavailableMsg;
    availableMethods: Array<PrizesMethodModel> = [];
    prizesData: PaginationDataModel = new PaginationData('prizes', '.js-prizes-item', '.js-prizes-parent', 'credit');

    PRIZES_METHODS = PRIZES_METHODS;
    workingTimeMessage = 'Redemption Operating hours are Monday thru Friday from 9am to 5pm EST.';

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    @ViewChild('dropBody', {static: false}) dropBody: ElementRef;
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    // FORM
    form: FormGroup;
    venmoForm: FormGroup;
    cashappForm: FormGroup;
    cryptoForm: FormGroup;
    mailCheckForm: FormGroup;

    selectedMethod;
    selectedVenmoMethod;
    selectedCryptoMethod;
    selectedMailCheckOptionsMethod;

    isNoMailCheckChosen = true;
    games = [];
    venmoOptions = [];
    mailCheckOptions = [{name: 'MAILCHECK', shortName: 'MAILCHECK'}];
    cashappVerified = false;
    moreThenAvailablePayoutForm = new Set();

    gameVendorName = this.gamesProviderService.getActiveProviderName();

    cryptoData = {
        items: [],
        loading: false
    };

    showPopup: showPopupFormType = 'hide';
    popupFormType;
    popupFormData;
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private stylingService: StylingService,
                private paginationScrollService: PaginationScrollService,
                private DOMManipulationsService: DOMManipulationsService,
                private kioskAccessorService: KioskAccessorService,
                private kioskScrollService: KioskScrollService,
                private gamesProviderService: GamesProviderService,
                private settingsService: SettingsService,
                private prizesService: PrizesService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getPrizesInfo();
            }
        });
    }


    ngOnInit() {
        this.setForms();
        this.getPrizesInfo();
        this.getCryptoList();
    }

    setForms() {
        this.form = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
            address: [null]
        });
        this.venmoForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
            handle: [null, Validators.required],
        });
        this.cashappForm = this.formBuilder.group({
            amount: [null, Validators.required],
            handle: [null, Validators.required],
        });
        this.cryptoForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required]
        });
        this.mailCheckForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
            address: [null, Validators.required],
        });
    }

    getPrizesInfo(){
        this.prizesService.getPrizesInfo(1).subscribe(data => {
            this.getServerResponse(data, this.setPrizesInfo.bind(this));
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setPrizesInfo(data: PrizesModel) {
        // data = {"status":true,"totalDebit":556.8000000000000682121026329696178436279296875,"totalCredit":150,"credit":[{"amount":"10.0000000000","created":"2022-05-14 08:01:29","type":6},{"amount":"10.0000000000","created":"2022-05-14 08:01:18","type":7},{"amount":"10.0000000000","created":"2022-05-02 07:58:36","type":5},{"amount":"10.0000000000","created":"2022-05-02 07:58:23","type":6},{"amount":"10.0000000000","created":"2022-05-02 07:58:06","type":3},{"amount":"10.0000000000","created":"2022-05-02 07:52:59","type":7},{"amount":"20.0000000000","created":"2022-03-22 10:10:34","type":7},{"amount":"20.0000000000","created":"2022-03-22 10:08:18","type":7},{"amount":"10.0000000000","created":"2022-02-18 14:15:22","type":5},{"amount":"10.0000000000","created":"2022-02-17 15:17:07","type":3},{"amount":"10.0000000000","created":"2022-01-20 15:05:40","type":5},{"amount":"10.0000000000","created":"2022-01-20 14:17:52","type":5},{"amount":"10.0000000000","created":"2022-01-20 14:17:06","type":5}],"limit":15,"total":13,"methods":[{"shortName":"mailCheck","display":1,"disable":0},{"shortName":"venmo","display":1,"disable":0},{"shortName":"cashapp","display":1,"disable":0}],"methodUnavailableMsg":"This method is temporarily unavailable","games":[{"name":"RIVER","shortName":"river"},{"name":"KENO","shortName":"keno"},{"name":"GOLDEN DRAGON","shortName":"gd"},{"name":"EBOX","shortName":"ebox"},{"name":"BACCARAT SWEEPS","shortName":"baccarat"}],"cashappTag":"$eboxjen","cashappTagStatus":true}
        this.prizesInfo = data;
        this.availableBalance = this.prizesInfo.totalDebit;

        if (data.games.length) {
            this.games = data.games;
            this.selectedMethod = this.games[0];
            this.form.controls.method.setValue(this.selectedMethod);
        }

        this.selectedMailCheckOptionsMethod = this.mailCheckOptions[0];
        this.mailCheckForm.controls.method.setValue(this.selectedMailCheckOptionsMethod);

        this.availableMethods = data.methods;
        // this.availableMethods = [{shortName: 'crypto', display: 1, disable: 1}, {shortName: 'mailCheck', display: 0, disable: 1}, {shortName: 'paypal', display: 1, disable: 0}, {shortName: 'venmo', display: 1, disable: 0}, {shortName: 'cashapp', display: 1, disable: 0}];
        this.methodUnavailableMsg = data.methodUnavailableMsg;

        this.venmoOptions = [];
        const isPaypal = this.availableMethods.find(method => method.shortName === PRIZES_METHODS.paypal);
        const isVenmo = this.availableMethods.find(method => method.shortName === PRIZES_METHODS.venmo);
        if (isVenmo && isVenmo.display) {
            this.venmoOptions.push(isVenmo);
        }
        if (isPaypal && isPaypal.display) {
            this.venmoOptions.push(isPaypal);
        }
        this.selectedVenmoMethod = this.venmoOptions[0];
        this.venmoForm.controls.method.setValue(this.selectedVenmoMethod);

        this.setCashAppTagStatus(data);
        const prizesDataSettings: SetPaginationDataModel = {
            itemsList: data.credit,
            scrollLimit: data.limit,
            total: data.total
        };

        this.initScrolledData(this.prizesData, prizesDataSettings);
    }

    setCashAppTagStatus(data) {
        // data.cashappTag = '$eboxjen';
        // data.cashappTagStatus = true;

        this.cashappVerified = data.cashappTagStatus;
        if (this.cashappVerified) {
            this.cashappForm.controls.handle.setValue(data.cashappTag);
        }
    }

    getCryptoList() {
        this.cryptoData.loading = true;
        this.prizesService.getCryptoList().subscribe(data => {
            this.getServerResponse(data, this.setCryptoList.bind(this));
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.cryptoData.loading = false;
        });
    }

    setCryptoList(data)  {
        this.cryptoData.items = data.result;
        this.selectedCryptoMethod = this.cryptoData.items[0];
        this.cryptoForm.controls.method.setValue(this.selectedCryptoMethod);
    }

    getServerResponse(data, successHandler, additionalParams?) {
        try {
            if (data.status === true) {
                successHandler(data, additionalParams);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }


    getPaymentIcon(id){
        const type = Object.keys(this.PAYMENT_TYPES).find(key => this.PAYMENT_TYPES[key] === id);
        let icon;

        if (type) {
            icon = this.PAYMENT_TYPES_ICONS[type];
        }
        return icon;
    }

    getDate(date) {
        if(date == null){
           return '';
        }

        return date.replace(/\s/g, 'T');
    }

    toggleDropdown() {
        this.dropdownClosed = !this.dropdownClosed;

        // SCROLL TO END OF PAGE
        if (!this.dropdownClosed && window.innerWidth < BREAKPOINTS.mobile_max) {

            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page__inner')[0]).offsetHeight;

                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scroll({
                    top: pageScroll,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)
        }
    }

    isWorkingTime() {
        const today = new Date().toLocaleTimeString("en-US", {timeZone: "America/New_York", weekday: 'short', hour12: false, hour: 'numeric'});
        const todayArray = today.split(', ');
        const weekday = todayArray[0];
        const hours = +todayArray[1];

        if ((weekday !== 'Sun' && weekday !== 'Sat') &&
          (9 <= hours && hours < 17)) {
            return true;
        }
        return false;
    }

    showWorkingTimeMessage(ev, method) {
        // if(method == PRIZES_METHODS.games){
        //     return true;
        // }

        if (this.isUnavailableMethod(method)) {
            this.popupComponent.showPopupError({text: this.methodUnavailableMsg,confirmButtonText:'OK'});
            ev.preventDefault();
            return true;
        }

        // if (this.isWorkingTime()) {
        //     return;
        // } else {
        //     this.popupComponent.showPopupErrorReturnEvent({text: this.workingTimeMessage}, () => {
        //         setTimeout(() => {
        //             document.querySelector('.page__scrolled').scrollTo({
        //                 top: 0,
        //                 behavior: 'smooth'
        //             });
        //         }, 100);
        //     });
        //     ev.preventDefault();
        //     return true;
        // }
    }

    isUnavailableMethod(method) {
        return this.checkMethodProperty(method, 'disable');
    }

    isDisplayMethod(method) {
        return this.checkMethodProperty(method, 'display');
    }

    checkMethodProperty(method, property) {
        if (this.availableMethods && this.availableMethods.length) {
            const checkMethod = this.availableMethods.find(methodItem => methodItem.shortName === method);
            if (checkMethod) {
                return checkMethod[property];
            }
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this));

        // const direction = this.prevChildTopOffset > e.target.scrollTop ? 'up' : 'down';
        // if (direction === 'up' && e.target.scrollTop === 0 && (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY === 'hidden') {
        //     (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY = 'auto'
        // }
        // this.prevChildTopOffset = e.target.scrollTop;
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        if (settings.name ===  'prizes') {
            this.prizesService.getPrizesInfo(page).subscribe(data => {
                this.paginationScrollService.dataAddingLogic(page, step, data, settings)
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    // isLinkAvailable(method) {
    //     return this.isWorkingTime() && !this.isUnavailableMethod(method);
    // }

    onSubmitPayout() {
        const amount = this.form.controls.amount.value;

        const method = this.form.controls.method.value.shortName;
        const methodName = this.form.controls.method.value.name;

        if (!amount || !method) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.sendPayout(method, amount);
                }
            });
        }
    }

    sendPayout(method, amount,) {
        this.prizesService.redeemViaGames(method, amount).subscribe(data => {
            this.displaySubmitPayoutResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitPayoutResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetForms();
                this.getPrizesInfo();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetForms() {
        this.form.controls.amount.setValue(null);
        this.form.controls.address.setValue(null);

        this.venmoForm.controls.amount.setValue(null);
        this.venmoForm.controls.handle.setValue(null);

        this.cryptoForm.controls.amount.setValue(null);

        this.cashappForm.controls.amount.setValue(null);
        this.cashappForm.controls.handle.setValue(null);

        this.mailCheckForm.controls.amount.setValue(null);
        this.mailCheckForm.controls.address.setValue(null);
        this.moreThenAvailablePayoutForm.clear();
    }

    onInputAmount(form) {
        const avaliable = typeof this.prizesInfo.totalDebit === 'number' ? this.prizesInfo.totalDebit : Number.parseFloat(this.prizesInfo.totalDebit);
        const entered = Number.parseFloat(form.controls.amount.value);

        if (avaliable < entered) {
            form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayoutForm.add(form);
        } else {
            this.moreThenAvailablePayoutForm.delete(form);
        }
    }

    // onMethodChange(method) {
    //     if (method.shortName === 'mailCheck') {
    //         this.isNoMailCheckChosen = false;
    //     } else {
    //         this.isNoMailCheckChosen = true;
    //     }
    // }

    noSubmitPayoutForm() {
        if (this.form) {
            const amount = this.form.controls.amount.valid;
            const address = this.form.controls.address.value;

            if (amount && +this.form.controls.amount.value > 0) {
                if (!this.isNoMailCheckChosen && !address) {
                    return true;
                }
                return false;
            }
        }
        return true;
    }

    onInputAddress($event) {
        this.form.get('address').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    onSubmitVenmoForm() {
        const amount = this.getInputValue(this.venmoForm,'amount');
        const handle = this.getInputValue(this.venmoForm, 'handle');
        const shortName = this.getInputValue(this.venmoForm, 'method').shortName;

        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to send ${formatCurrency(amount, 'en','$')} to ${this.gameVendorName} address ${handle}. Please double check the spelling and click confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaVenmo(amount, handle, shortName).subscribe(data => {
                        this.submitVenmoFormResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });

        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitVenmoFormResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetForms();
                this.getPrizesInfo();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    onSubmitCashappForm() {
        const amount = this.getInputValue(this.cashappForm, 'amount');
        const handle = this.getInputValue(this.cashappForm,'handle');

        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to send ${formatCurrency(amount, 'en','$')} to ${this.gameVendorName} address ${handle}. Please double check the spelling and click confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaCashApp(amount, handle).subscribe(data => {
                        this.submitCashappResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitCashappResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetForms();
                this.getPrizesInfo();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    onSubmitMailCheckForm() {
        const amount = this.getInputValue(this.mailCheckForm,'amount');
        const address = this.getInputValue(this.mailCheckForm, 'address');

        if (amount && address) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to send ${formatCurrency(amount, 'en','$')} to ${this.gameVendorName} address ${address}. Please double check the spelling and click confirm`}, (status) => {
                if (status) {
                    this.prizesService.mailCheckCreatePayout(0, address, amount).subscribe(data => {
                        this.submitVenmoFormResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });

        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    getInputValue(form, field) {
        if (form.controls[field]) {
            return form.controls[field].value;
        }
    }

    openTradePopup() {
        this.showPopup = 'show';
        this.popupFormType = POPUP_TYPES.buy;
        this.popupFormData = this.cryptoForm.controls.method.value;
        this.popupFormData.availableAmount = this.availableBalance;
        this.popupFormData.amount = this.cryptoForm.controls.amount.value;
        // this.popupFormData.availableAmount = 7;
    }

    onPopupFormClose(response) {
        this.showPopup = 'hide';

        if (response && response.status) {
            this.resetForms();
            this.getPrizesInfo();
        }
    }

    setCashappTagVerified($event) {
        this.cashappVerified = true;
        this.cashappForm.controls.handle.setValue($event);
    }
}

