/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./buy-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/kiosk-table/kiosk-table.component.ngfactory";
import * as i3 from "../../../../shared/kiosk-table/kiosk-table.component";
import * as i4 from "../../games-selection/games-selection.component.ngfactory";
import * as i5 from "../../games-selection/games-selection.component";
import * as i6 from "../../../../shared/popup/popup.component";
import * as i7 from "../../../../service/loader.service";
import * as i8 from "../../../../service/games/gamesProvider.service";
import * as i9 from "../../../../service/users/users-point.service";
import * as i10 from "../../../../service/kiosk-scroll.service";
import * as i11 from "../../../../modules/auth/_services/authentication.service";
import * as i12 from "../../../../service/terminal/terminal.service";
import * as i13 from "../../../../service/kiosk.service";
import * as i14 from "../kiosk-buy-crypto/kiosk-buy-crypto.component.ngfactory";
import * as i15 from "../kiosk-buy-crypto/kiosk-buy-crypto.component";
import * as i16 from "../../../../service/kiosk/kiosk-payment.service";
import * as i17 from "../kiosk-payment/kiosk-payment.component.ngfactory";
import * as i18 from "../kiosk-payment/kiosk-payment.component";
import * as i19 from "../../../../service/kiosk/kiosk-accessor.service";
import * as i20 from "../../../../service/partner.service";
import * as i21 from "./buy-menu.component";
var styles_BuyMenuComponent = [i0.styles];
var RenderType_BuyMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BuyMenuComponent, data: {} });
export { RenderType_BuyMenuComponent as RenderType_BuyMenuComponent };
export function View_BuyMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scrolledEl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "buy-menu"]], [[2, "is-hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "buy-menu__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-kiosk-table", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.onBuy($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_KioskTableComponent_0, i2.RenderType_KioskTableComponent)), i1.ɵdid(4, 114688, null, 0, i3.KioskTableComponent, [], { items: [0, "items"] }, { onSelect: "onSelect" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-games-selection", [], [[2, "is-kiosk-app", null]], [[null, "back"], [null, "gameSelected"], [null, "isBackBtn"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.hideGamesSelection() !== false);
        ad = (pd_0 && ad);
    } if (("gameSelected" === en)) {
        var pd_1 = (_co.onSweepsPayment($event) !== false);
        ad = (pd_1 && ad);
    } if (("isBackBtn" === en)) {
        var pd_2 = (_co.setGamesBackBtn($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_GamesSelectionComponent_0, i4.RenderType_GamesSelectionComponent)), i1.ɵdid(6, 114688, null, 0, i5.GamesSelectionComponent, [i6.PopupComponent, i7.LoaderService, i8.GamesProviderService, i9.UsersPointService, i10.KioskScrollService, i11.AuthenticationService, i12.TerminalService, i13.KioskService], { showPopup: [0, "showPopup"], pointId: [1, "pointId"] }, { back: "back", gameSelected: "gameSelected", isBackBtn: "isBackBtn" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-kiosk-buy-crypto", [], null, [[null, "cryptoSelected"], [null, "isBackBtn"], [null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cryptoSelected" === en)) {
        var pd_0 = (_co.onCryptoPayment($event) !== false);
        ad = (pd_0 && ad);
    } if (("isBackBtn" === en)) {
        var pd_1 = (_co.setCryptoBackBtn($event) !== false);
        ad = (pd_1 && ad);
    } if (("back" === en)) {
        var pd_2 = (_co.hideBuyCrypto() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i14.View_KioskBuyCryptoComponent_0, i14.RenderType_KioskBuyCryptoComponent)), i1.ɵdid(8, 114688, null, 0, i15.KioskBuyCryptoComponent, [i13.KioskService, i6.PopupComponent, i7.LoaderService, i12.TerminalService, i10.KioskScrollService, i16.KioskPaymentService], { showPopup: [0, "showPopup"] }, { back: "back", cryptoSelected: "cryptoSelected", isBackBtn: "isBackBtn" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-kiosk-payment", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onClosePayment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_KioskPaymentComponent_0, i17.RenderType_KioskPaymentComponent)), i1.ɵdid(10, 114688, null, 0, i18.KioskPaymentComponent, [i13.KioskService, i6.PopupComponent, i7.LoaderService, i19.KioskAccessorService, i20.PartnerService, i8.GamesProviderService, i9.UsersPointService, i12.TerminalService, i16.KioskPaymentService], { showPopup: [0, "showPopup"], cryptoToBuy: [1, "cryptoToBuy"], type: [2, "type"], data: [3, "data"], isBackBtnToTypeList: [4, "isBackBtnToTypeList"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.nav; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.openGamesPopup; var currVal_4 = _co.pointId; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.openBuyCryptoPopup; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.openPaymentPopup; var currVal_7 = _co.cryptoToBy; var currVal_8 = _co.paymentPopupType; var currVal_9 = _co.paymentInfo; var currVal_10 = _co.paymentsBackBtn; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isHiddenClass() || (_co.showGames && !_co.showCrypto)) || (!_co.showGames && _co.showCrypto)); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).someField; _ck(_v, 5, 0, currVal_2); }); }
export function View_BuyMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-buy-menu", [], [[2, "is-hidden", null]], null, null, View_BuyMenuComponent_0, RenderType_BuyMenuComponent)), i1.ɵdid(1, 4308992, null, 0, i21.BuyMenuComponent, [i13.KioskService, i7.LoaderService, i8.GamesProviderService, i6.PopupComponent, i12.TerminalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).t; _ck(_v, 0, 0, currVal_0); }); }
var BuyMenuComponentNgFactory = i1.ɵccf("app-buy-menu", i21.BuyMenuComponent, View_BuyMenuComponent_Host_0, { showPopup: "showPopup", pointId: "pointId" }, { close: "close", gameSelected: "gameSelected" }, []);
export { BuyMenuComponentNgFactory as BuyMenuComponentNgFactory };
