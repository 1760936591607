export interface InvestmentsPopupFormTypes {
    buy: string;
    sell: string;
    crypto: string;
}

export const POPUP_TYPES: InvestmentsPopupFormTypes = {
    buy: 'buy',
    sell: 'sell',
    crypto: 'crypto'
}

export interface ConfirmRequest {
    investmentBuy: string;
    investmentSell: string;
    tradeBuy: string;
    tradeSell: string;
}

export const CONFIRM_REQUEST: ConfirmRequest = {
    investmentBuy: 'investmentBuy',
    investmentSell: 'investmentSell',
    tradeBuy: 'tradeBuy',
    tradeSell: 'tradeSell'
}

export type showPopupFormType = "show" | "hide" | "blur";
