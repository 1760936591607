import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {USER_CONTACT_CONFIG} from "../config/api";
import {Observable} from "rxjs/internal/Observable";
import {SettingsLoaderService} from "./settingsLoader.service";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class ContactInfoService extends SettingsLoaderService{
    private config = USER_CONTACT_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getUserContacts() {
        this.showLoader();

        let post = this.http.post<any>(this.config.info, {});

        this.hideLoader();

        return post;
    }

    saveUserContacts(first_name, last_name, email) {
        this.showLoader();
        let post = this.http.post<any>(this.config.save, {first_name, last_name, email});
        this.hideLoader();

        return post;
    }

    createUser(first_name, last_name, email, promo_code) {
        this.showLoader();
        let post = this.http.post<any>(this.config.create, {first_name, last_name, email, promo_code});
        this.hideLoader();

        return post;
    }
}
