import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "./../settingsLoader.service";
import { COINS_TRADE_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var CoinsTradeService = /** @class */ (function (_super) {
    tslib_1.__extends(CoinsTradeService, _super);
    function CoinsTradeService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = COINS_TRADE_CONFIG;
        return _this;
    }
    CoinsTradeService.prototype.getData = function () {
        //this.showLoader();
        return this.http.post(this.config.info, {});
    };
    CoinsTradeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoinsTradeService_Factory() { return new CoinsTradeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CoinsTradeService, providedIn: "root" });
    return CoinsTradeService;
}(SettingsLoaderService));
export { CoinsTradeService };
