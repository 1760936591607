<div class="spinner">
    <div class="segments" [class.is-game-finish]="spinningFinish" [class.is-game-started]="spinning" [ngStyle]="{transform: 'rotate(' + transformValue + 'deg) translate(-50%, -50%)' }">
        <div #segment [attr.data-segment-position]="1" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="2" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="3" class="segment is-winning">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="4" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="5" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="6" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>

        <div #segment [attr.data-segment-position]="7" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="8" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="9" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="10" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="11" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
        <div #segment [attr.data-segment-position]="12" class="segment">
            <div class="segment__inner"></div>
            <div class="segment__middle"></div>
            <div class="segment__end"></div>
        </div>
    </div>


    <img [class.is-game-finish]="spinningFinish" src="../../../../assets/images/spinner/wheel_4.png" [ngStyle]="{transform: 'rotate(' + transformValue + 'deg)'}" class="spinner__wheel" alt="">
    <div class="spinner__arrow">
        <img src="../../../../assets/images/spinner/game-arrow.png" class="spinner__arrow-img" alt="">
        <div class="spinner__arrow-inner"></div>
    </div>

    <button [disabled]="!data" class="spinner-btn" [class.is-disabled]="spinning" (click)="getSpinResult()"></button>

</div>

<audio #audio style="visibility: hidden; position: absolute"></audio>
