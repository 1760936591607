import {Component, HostListener, OnInit} from '@angular/core';
// import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
// import {Router} from "@angular/router";
// import {PartnerService} from "../../service/partner.service";
// import {AppVersionrService} from "../../service/app-version/appVersionr.service";
// import {AppPromptService} from "../../service/app/appPrompt.service";
// import {PopupComponent} from "../../shared/popup/popup.component";
// import {StylingService} from "../../service/styling.service";
// import {RouterPaths} from "../../config/router.paths.model";

@Component({
    selector: 'app-mascot',
    templateUrl: './mascot.component.html',
    styleUrls: ['./mascot.component.scss']
})
export class MascotComponent implements OnInit {
    // isApp = +localStorage.getItem('isApp');
    // menu = false;
    // appInstalled = false;
    //
    // deferredPrompt: any;
    // ROUTER_PATHS = RouterPaths;

    jackpot = 425;

    constructor(
        // private auth:AuthenticationService,
        // private router: Router,
        // private appVersionrService: AppVersionrService,
        // private appPromptService: AppPromptService,
        // private partnerService: PartnerService,
        // private stylingService: StylingService,
        // private popupComponent: PopupComponent
    ) {


    }


    ngOnInit() {
        // window.addEventListener('appinstalled', (evt) => {
        //     this.appInstalled = true;
        //     this.popupComponent.showPopupSuccess({text: 'The App was installed successful.'})
        // });
    }


    // disableMenu() {
    //     return this.router.url === '/home/rewards/form';
    // }

}
