import { TERMINAL_RESPONSE_KEYS } from "../../config/terminal/response_keys.config";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var TerminalService = /** @class */ (function () {
    function TerminalService() {
        this.storage = sessionStorage;
        this.terminalInfoStorageKey = "terminalInfo";
        this.terminalInfoUpdating = new BehaviorSubject(null);
        this.info = {};
        //   console.log("terminalInfoUpdating subscribe in TerminalService");
        //
        // this.terminalInfoUpdating.subscribe(val => {
        //   if (val) {
        //     const info = this.storage.getItem(this.terminalInfoStorageKey);
        //     this.info = JSON.parse(info);
        //   }
        // });
    }
    TerminalService.prototype.getTitle = function () {
        return this.getKey('title');
    };
    TerminalService.prototype.getDescription = function () {
        return this.getKey('description');
    };
    TerminalService.prototype.getShortName = function () {
        return this.getKey('shortName');
    };
    TerminalService.prototype.getLogo = function () {
        return this.getKey('logo');
    };
    TerminalService.prototype.isCrypto = function () {
        return this.getKey('isCrypto');
    };
    TerminalService.prototype.isGames = function () {
        return this.getKey('isGames');
    };
    TerminalService.prototype.isInfo = function () {
        if (this.info.shortName) {
            return true;
        }
        return false;
    };
    TerminalService.prototype.isRegistered = function () {
        if (this.storage.getItem(this.terminalInfoStorageKey)) {
            if (this.getShortName() !== '') {
                return true;
            }
        }
        return false;
    };
    TerminalService.prototype.getKey = function (key) {
        if (this.storage.getItem(this.terminalInfoStorageKey)) {
            var info = this.storage.getItem(this.terminalInfoStorageKey);
            this.info = JSON.parse(info);
        }
        return this.info && this.info[TERMINAL_RESPONSE_KEYS[key]] ? this.info[TERMINAL_RESPONSE_KEYS[key]] : '';
    };
    TerminalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TerminalService_Factory() { return new TerminalService(); }, token: TerminalService, providedIn: "root" });
    return TerminalService;
}());
export { TerminalService };
