<div class="page page--secondary long-content prizes">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>
        <h1 class="page__title">PRIZES</h1>

        <div class="page__scrolled">
            <div class="prizes__col">
                <div class="page__head">
                    <div class="balance">
                        <h2 class="balance__title gl-title">{{gameVendorName}} AVAILABLE PRIZES</h2>
                        <div id="balance__amount" class="balance__amount">{{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}</div>
                        <!--<p class="balance__text">{{workingTimeMessage}}</p>-->
                    </div>
                </div>
                <div class="page__form">
                    <div class="navigation">
                        <app-dropdown-item [title]="'Instant transfers'"
                                           [titleRight]="'24/7'"
                                           [noBodyLeftPadding]="true"
                                           [headTwoColumns]="true"
                                           [dropdownClosed]="true"
                                           [hasPagination]="true">
                            <div class="dropdown__body"  #dropBody>
                                <app-group-bordered>
                                        <h2>TRANSFER PORTION TO SWEEPS</h2>
                                        <h4>Available 24/7. Instant transfer. Allows you Transfering Prizes between the systems.</h4>
                                        <div class="page__form group-bordered-inner">
                                            <form [formGroup]="form" (ngSubmit)="onSubmitPayout()" class="form-simple form-simple--primary">
                                                <div class="form-simple__item">
                                                    <mat-form-field>
                                                        <!-- <mat-label>GET</mat-label> -->
                                                        <mat-select #methodsSelect
                                                                    formControlName="method"
                                                                    [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                                                            <mat-select-trigger>
                                                                {{methodsSelect?.value?.name}}
                                                            </mat-select-trigger>
                                                            <mat-option *ngFor="let method of games" [value]="method">
                                                                {{method.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-simple__item form-simple__item--amount">
                                                    <input mask="separator.0"
                                                           thousandSeparator=","
                                                           formControlName="amount"
                                                           placeholder="Amount"
                                                           [type]="'tel'"
                                                           (input)="onInputAmount(form)"
                                                           class="form-simple__input"
                                                           autocomplete="off">
                                                    <span>.00</span>
                                                    <div class="page__form-note" [class.is-active]="moreThenAvailablePayoutForm.has(form)">
                                                        Available {{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}
                                                    </div>
                                                </div>

                                                <div class="form-simple__item" [class.is-hidden]="isNoMailCheckChosen">
                                                    <input formControlName="address"
                                                           placeholder="Address"
                                                           [type]="'text'"
                                                           (change)="onInputAddress($event)"
                                                           class="form-simple__input"
                                                           autocomplete="off">
                                                </div>

                                                <div class="form-simple__btns">
                                                    <button
                                                            class="button snake-btn is-notouched"
                                                            [disabled]="noSubmitPayoutForm()">
                                                        Transfer
                                                        <svg><rect></rect></svg>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </app-group-bordered>
                            </div>
                        </app-dropdown-item>

                        <app-dropdown-item [title]="'Daily transfers'"
                                           [titleRight]="'24/7'"
                                           [noBodyLeftPadding]="true"
                                           [headTwoColumns]="true"
                                           [dropdownClosed]="true"
                                           *ngIf="isDisplayMethod(PRIZES_METHODS.venmo) || isDisplayMethod(PRIZES_METHODS.crypto)"
                                           [hasPagination]="true">
                            <div class="dropdown__body"  #dropBody>
                                <app-group-bordered *ngIf="isDisplayMethod(PRIZES_METHODS.venmo)">
                                    <h2>VENMO/PAYPAL TRANSFERS</h2>
                                    <h4>Available 24/7. Batched & sent out at 5pm daily. Allows you claiming prizes at any time. Prizes are aggregated and sent out once a day at 5pm. Everyday.</h4>
                                    <div class="page__form group-bordered-inner">
                                        <form [formGroup]="venmoForm" (ngSubmit)="onSubmitVenmoForm()" class="form-simple form-simple--primary">
                                            <div class="form-simple__item">
                                                <mat-form-field [class.no-drop]="venmoOptions.length < 2">
                                                    <mat-select #methodsVenmoSelect
                                                                formControlName="method"
                                                                [disabled]="venmoOptions.length < 2"
                                                                [(value)]="selectedVenmoMethod" panelClass="my-select-panel-border-yellow">
                                                        <mat-select-trigger>
                                                            {{methodsVenmoSelect?.value?.shortName}}
                                                        </mat-select-trigger>
                                                        <mat-option *ngFor="let method of venmoOptions" [value]="method">
                                                            {{method.shortName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="form-simple__item form-simple__item--amount">
                                                <input mask="separator.0"
                                                       thousandSeparator=","
                                                       (input)="onInputAmount(venmoForm)"
                                                       formControlName="amount"
                                                       placeholder="Amount"
                                                       [type]="'tel'"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                                <span>.00</span>
                                                <div class="page__form-note" [class.is-active]="moreThenAvailablePayoutForm.has(venmoForm)">
                                                    Available {{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}
                                                </div>
                                            </div>

                                            <div class="form-simple__item">
                                                <!--                        <input formControlName="handle"-->
                                                <!--                               [mask]="phoneMask"-->
                                                <!--                               placeholder="@Name"-->
                                                <!--                               [type]="'tel'"-->
                                                <!--                               class="form-simple__input"-->
                                                <!--                               autocomplete="off">-->
                                                <input formControlName="handle"
                                                       placeholder="@Name"
                                                       [type]="'text'"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                            </div>

                                            <div class="form-simple__btns">
                                                <button class="button snake-btn is-notouched"
                                                        (click)="showWorkingTimeMessage($event, venmoForm.controls.method.value.shortName)"
                                                        [disabled]="venmoForm.invalid || +venmoForm.controls.amount.value <= 0">
                                                    Transfer
                                                    <svg><rect></rect></svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </app-group-bordered>
                                <app-group-bordered *ngIf="isDisplayMethod(PRIZES_METHODS.mailCheck)">
                                    <h2>MAILCHECK TRANSFERS</h2>
                                    <h4>Available 24/7. Batched & sent out at 5pm daily. Allows you claiming prizes at any time. Prizes are aggregated and sent out once a day at 5pm. Everyday.</h4>
                                    <div class="page__form group-bordered-inner">
                                        <form [formGroup]="mailCheckForm" (ngSubmit)="onSubmitMailCheckForm()" class="form-simple form-simple--shrink form-simple--primary">
                                            <div class="form-simple__item">
                                                <mat-form-field [class.no-drop]="true">
                                                    <mat-select #methodsMailCheckSelect
                                                                formControlName="method"
                                                                disabled="disabled"
                                                                [(value)]="selectedMailCheckOptionsMethod" panelClass="my-select-panel-border-yellow">
                                                        <mat-select-trigger>
                                                            {{methodsMailCheckSelect?.value?.name}}
                                                        </mat-select-trigger>
                                                        <mat-option *ngFor="let method of mailCheckOptions" [value]="method">
                                                            {{method.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="form-simple__item form-simple__item--amount">
                                                <input mask="separator.0"
                                                       thousandSeparator=","
                                                       (input)="onInputAmount(mailCheckForm)"
                                                       formControlName="amount"
                                                       placeholder="Amount"
                                                       [type]="'tel'"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                                <span>.00</span>
                                                <div class="page__form-note" [class.is-active]="moreThenAvailablePayoutForm.has(mailCheckForm)">
                                                    Available {{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}
                                                </div>
                                            </div>

                                            <div class="form-simple__item">
                                                <!--                        <input formControlName="handle"-->
                                                <!--                               [mask]="phoneMask"-->
                                                <!--                               placeholder="@Name"-->
                                                <!--                               [type]="'tel'"-->
                                                <!--                               class="form-simple__input"-->
                                                <!--                               autocomplete="off">-->
                                                <input formControlName="address"
                                                       placeholder="Address"
                                                       [type]="'text'"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                            </div>

                                            <div class="form-simple__btns">
                                                <button class="button snake-btn is-notouched"
                                                        (click)="showWorkingTimeMessage($event, PRIZES_METHODS.mailCheck)"
                                                        [disabled]="mailCheckForm.invalid || +mailCheckForm.controls.amount.value <= 0">
                                                    Transfer
                                                    <svg><rect></rect></svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </app-group-bordered>
                                <app-group-bordered *ngIf="isDisplayMethod(PRIZES_METHODS.crypto)">
                                    <h2>CRYPTOCURRENCY TRANSFERS</h2>
                                    <div class="page__form group-bordered-inner">
                                        <form [formGroup]="cryptoForm" (ngSubmit)="openTradePopup()" class="form-simple form-simple--primary">
                                            <div class="form-simple__item">
                                                <mat-form-field>
                                                    <!-- <mat-label>GET</mat-label> -->
                                                    <mat-select #methodsCryptoSelect
                                                                formControlName="method"
                                                                [(value)]="selectedCryptoMethod" panelClass="my-select-panel-border-yellow">
                                                        <mat-select-trigger>
                                                            {{methodsCryptoSelect?.value?.name}}
                                                        </mat-select-trigger>
                                                        <mat-option *ngFor="let method of cryptoData.items" [value]="method">
                                                            {{method.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-simple__item form-simple__item--amount">
                                                <input mask="separator.0"
                                                       thousandSeparator=","
                                                       formControlName="amount"
                                                       placeholder="Amount"
                                                       [type]="'tel'"
                                                       (input)="onInputAmount(cryptoForm)"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                                <span>.00</span>
                                                <div class="page__form-note" [class.is-active]="moreThenAvailablePayoutForm.has(cryptoForm)">
                                                    Available {{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}
                                                </div>
                                            </div>

                                            <div class="form-simple__btns">
                                                <button
                                                        class="button snake-btn is-notouched"
                                                        (click)="showWorkingTimeMessage($event, PRIZES_METHODS.crypto)"
                                                        [disabled]="cryptoForm.invalid || cryptoForm.controls.amount.value <= 0">
                                                    Transfer
                                                    <svg><rect></rect></svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </app-group-bordered>
                            </div>
                        </app-dropdown-item>

                        <app-dropdown-item [title]="'Banking hours transfers'"
                                           [titleRight]="'8/5'"
                                           [noBodyLeftPadding]="true"
                                           *ngIf="isDisplayMethod(PRIZES_METHODS.cashapp)"
                                           [headTwoColumns]="true"
                                           [dropdownClosed]="true"
                                           [hasPagination]="true">
                            <div class="dropdown__body" *ngIf="isDisplayMethod(PRIZES_METHODS.cashapp)"  #dropBody>
                                <app-group-bordered>
                                    <h2>CASHAPP TRANSFERS</h2>
                                    <h4>Available monday thru friday 9am to 5pm. </h4>
                                    <div class="page__form group-bordered-inner" *ngIf="!cashappVerified">
                                        <app-verify-cashapp-tag [verifyStatus]="cashappVerified" (onVerify)="setCashappTagVerified($event)"></app-verify-cashapp-tag>
                                    </div>

                                    <div class="page__form group-bordered-inner" *ngIf="cashappVerified">
                                        <form [formGroup]="cashappForm" (ngSubmit)="onSubmitCashappForm()" class="form-simple form-simple--shrink form-simple--primary">
                                            <div class="form-simple__item">
                                                <input formControlName="handle"
                                                       placeholder="$CashTag"
                                                       disabled="true"
                                                       [type]="'text'"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                            </div>

                                            <div class="form-simple__item form-simple__item--amount">
                                                <input mask="separator.0"
                                                       thousandSeparator=","
                                                       (input)="onInputAmount(cashappForm)"
                                                       formControlName="amount"
                                                       placeholder="Amount"
                                                       [type]="'tel'"
                                                       class="form-simple__input"
                                                       autocomplete="off">
                                                <span>.00</span>
                                                <div class="page__form-note" [class.is-active]="moreThenAvailablePayoutForm.has(cashappForm)">
                                                    Available {{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}
                                                </div>
                                            </div>

                                            <div class="form-simple__btns">
                                                <button class="button snake-btn is-notouched"
                                                        (click)="showWorkingTimeMessage($event, PRIZES_METHODS.cashapp)"
                                                        [disabled]="cashappForm.invalid || +cashappForm.controls.amount.value <= 0">
                                                    Transfer
                                                    <svg><rect></rect></svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                            </app-group-bordered>
                            </div>
                        </app-dropdown-item>
                    </div>
                </div>
            </div>

            <div class="page__body">

                <div class="dropdown js-prizes-parent" [class.is-hidden]="dropdownClosed">
                    <div class="dropdown__head dropdown__head--two-columns"
                         (click)="toggleDropdown()">
                        <div class="dropdown__title">
                            Prizes Sent
                        </div>
                        <div>
                            <div id="prizes_total" class="dropdown__title">{{prizesInfo?.totalCredit | currency : 'USD' : 'symbol'}}</div>
                            <svg class="dropdown__arrow" width="21" height="12">
                                <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="dropdown__body dropdown__body--prizes" #scrolledEl (scroll)="scroll($event, prizesData)" #dropBody>
                        <table id="prizes_transactions_table" class="transactions">
                            <tr class="transactions__head">
                                <th class="transactions__type">TYPE</th>
                                <th class="transactions__date">DATE</th>
                                <th class="transactions__amount">AMOUNT</th>
                            </tr>
                            <tr *ngFor="let action of prizesInfo?.credit" class="transactions__item js-prizes-item">
                                <td class="transactions__type">
                                    <div class="transactions__icon-wrapper">
                                        <svg *ngIf="getPaymentIcon(action.type) !== 'usd_coin' && getPaymentIcon(action.type) !== 'referrals'" class="transactions__icon">
                                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPaymentIcon(action.type)"></use>
                                        </svg>

                                        <svg *ngIf="getPaymentIcon(action.type) === 'usd_coin' && getPaymentIcon(action.type) !== 'referrals'" class="transactions__icon transactions__icon--usdc">
                                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPaymentIcon(action.type)"></use>
                                        </svg>

                                        <img src="../../../assets/images/referrals_program.png" *ngIf="getPaymentIcon(action.type) === 'referrals'" class="transactions__icon">

                                    </div>
                                </td>
                                <td class="transactions__date">{{getDate(action.created) | date: 'MM/dd/yyyy'}}</td>
                                <td class="transactions__amount">{{action.amount | currency : 'USD' : 'symbol'}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

<app-investments-popup-form [showPopupForm]="showPopup"
                            [data]="popupFormData"
                            [formType]="popupFormType"
                            [fromPage]="'prizes'"
                            (close)="onPopupFormClose($event)"></app-investments-popup-form>
