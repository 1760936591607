import * as tslib_1 from "tslib";
import { ADDRESSES_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var AddressesService = /** @class */ (function (_super) {
    tslib_1.__extends(AddressesService, _super);
    function AddressesService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = ADDRESSES_CONFIG;
        return _this;
    }
    AddressesService.prototype.getAddresses = function () {
        this.showLoader();
        var post = this.http.post(this.config.addresses, {});
        this.hideLoader();
        return post;
    };
    AddressesService.prototype.getList = function () {
        this.showLoader();
        var post = this.http.post(this.config.list, {});
        this.hideLoader();
        return post;
    };
    AddressesService.prototype.find = function (id) {
        this.showLoader();
        var post = this.http.post(this.config.find, { id: id });
        this.hideLoader();
        return post;
    };
    AddressesService.prototype.deleteAddress = function (id) {
        this.showLoader();
        var post = this.http.post(this.config.delete, { id: id });
        this.hideLoader();
        return post;
    };
    AddressesService.prototype.addAddress = function (address) {
        this.showLoader();
        var post = this.http.post(this.config.add, { address: address });
        this.hideLoader();
        return post;
    };
    AddressesService.prototype.editAddress = function (id, address) {
        this.showLoader();
        var post = this.http.post(this.config.edit, { id: id, address: address });
        this.hideLoader();
        return post;
    };
    AddressesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddressesService_Factory() { return new AddressesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: AddressesService, providedIn: "root" });
    return AddressesService;
}(SettingsLoaderService));
export { AddressesService };
