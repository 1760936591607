import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BREAKPOINTS} from "../../config/breakpoints";

@Component({
    selector: 'app-dropdown-item',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
    @Input() title: string;
    @Input() titleRight: string;
    @Input() dropdownClosed = false;
    @Input() noBodyLeftPadding = false;
    @Input() hasPagination = false;
    @Input() dropdownClass = '';
    @Input() headTwoColumns = false;

    constructor() {}

    ngOnInit() {}

    toggleDropdown() {
        this.dropdownClosed = !this.dropdownClosed;

        // SCROLL TO END OF PAGE
        if (!this.dropdownClosed && window.innerWidth < BREAKPOINTS.mobile_max) {
            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page__inner')[0]).offsetHeight;

                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scroll({
                    top: pageScroll,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)
        }
    }

}
