import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "../settingsLoader.service";
import { NFT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var NFTService = /** @class */ (function (_super) {
    tslib_1.__extends(NFTService, _super);
    function NFTService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = NFT_CONFIG;
        return _this;
    }
    NFTService.prototype.generateWallet = function () {
        this.showLoader();
        return this.http.post(this.config.generateWallet, {});
    };
    NFTService.prototype.mintNFT = function (image) {
        var formData = new FormData();
        formData.append('image', image);
        // formData.append('groupId', groupId);
        this.showLoader();
        return this.http.post(this.config.mintNFT, formData, {
            reportProgress: true,
            observe: 'events'
        });
    };
    NFTService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NFTService_Factory() { return new NFTService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: NFTService, providedIn: "root" });
    return NFTService;
}(SettingsLoaderService));
export { NFTService };
