import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HomeArticle} from "../../models/home-articles";
import {ActivityItem} from "../../models/activityItem";
import {PROJECT_CONFIG} from "../../config/project";

@Component({
    selector: 'app-kiosk-table',
    templateUrl: './kiosk-table.component.html',
    styleUrls: ['./kiosk-table.component.scss']
})
export class KioskTableComponent implements OnInit {

    @Input() items;
    @Output() onSelect: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {}

    onBuy(game) {
        if (game.type === 'menu') {
            this.onSelect.emit(game.id);
        } else {
            this.onSelect.emit(game);
        }
    }

}
