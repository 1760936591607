import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "./settingsLoader.service";
import { CRYPTO_TRANSACTIONS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var CryptoTransactionsService = /** @class */ (function (_super) {
    tslib_1.__extends(CryptoTransactionsService, _super);
    function CryptoTransactionsService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = CRYPTO_TRANSACTIONS_CONFIG;
        return _this;
    }
    CryptoTransactionsService.prototype.buyCryptoFromFounds = function (id, method, amount, cryptoId) {
        this.showLoader();
        return this.http.post(this.config.founds, { id: id, method: method, amount: amount, cryptoId: cryptoId });
    };
    CryptoTransactionsService.prototype.sellCrypto = function (amount, id) {
        this.showLoader();
        return this.http.post(this.config.sellCrypto, { amount: amount, id: id });
    };
    CryptoTransactionsService.prototype.buyCrypto = function (amount, id) {
        this.showLoader();
        return this.http.post(this.config.buyCrypto, { amount: amount, id: id });
    };
    CryptoTransactionsService.prototype.confirmRedeemPayout = function (amount, id) {
        this.showLoader();
        return this.http.post(this.config.redeem, { amount: amount, id: id });
    };
    CryptoTransactionsService.prototype.confirmInvestmentSellCrypto = function (amount, id) {
        this.showLoader();
        return this.http.post(this.config.investmentSellCrypto, { amount: amount, id: id });
    };
    CryptoTransactionsService.prototype.confirmInvestmentBuyCrypto = function (amount, id) {
        this.showLoader();
        return this.http.post(this.config.investmentBuyCrypto, { amount: amount, id: id });
    };
    CryptoTransactionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CryptoTransactionsService_Factory() { return new CryptoTransactionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CryptoTransactionsService, providedIn: "root" });
    return CryptoTransactionsService;
}(SettingsLoaderService));
export { CryptoTransactionsService };
