import {Component, OnInit} from '@angular/core';
import {slideToLeft, slideToRight} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {StylingService} from "../../../service/styling.service";
import {WeeklyReportService} from "../../../service/reports/weekly-report.service";
import {REPORT_DATA_COLS} from "./report-weekly.config";
import {ChartOptions, ChartType} from "chart.js";
import {formatCurrency} from "@angular/common";

@Component({
  selector: 'app-report-weekly',
  templateUrl: './report-weekly.component.html',
  styleUrls: ['./report-weekly.component.scss'],
  animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
  host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class ReportWeeklyComponent implements OnInit {
  reportWeeksList = [];
  reportData;
  reportTotal;

  REPORT_DATA_COLS = REPORT_DATA_COLS;

  barChartData;
  barChartLabels: string[];
  barChartType: ChartType = 'bar';
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        type: 'time',
        distribution: 'series',
        // bounds: 'ticks',
        time: {
          displayFormats: {
            quarter: 'MM YYYY'
          },
        },
        ticks: {
          // autoSkip: true,
          maxTicksLimit: 5
        },
        offset: true
      }],
    },
    elements: {
      line: {
        fill: false
      }
    },
    tooltips: {
      callbacks: {
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatCurrency(+tooltipItem.value, 'en', '$');
        }
      }
    }
  };

  constructor(private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private weeklyReportService: WeeklyReportService,
              private stylingService: StylingService) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.weeklyReportService.getReport().subscribe(data => {
      this.setData(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  setData(data) {
    if (data.status === true) {
      this.reportData = data;
      this.reportWeeksList = this.reportData.result;
      this.reportTotal = this.reportData.total;

      this.barChartData = this.getBarChartData();
      this.barChartLabels = this.getChartLabels();
    } else if (data.status === false) {
      this.popupComponent.showPopupError({text: data.message});
    } else if (data.status === undefined) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  getDueUS(week) {
    const total = this.getTotal(week, this.REPORT_DATA_COLS.net);
    return total / 100 * week.due;
  }

  getTotal(week, colName) {
    if (week[colName]) {
      return week[colName];
    }
    return '';
  }

  getActiveColor() {
    return this.stylingService.getPageColor();
  }

  getChartLabels() {
    let labels = [];
    this.reportWeeksList.forEach(week => {
      labels.push(week.date);
    });
    return labels;
  }

  getBarChartData() {
    let barChartData = [
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.net), label: 'NET', type: 'line'},
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.inflows), label: this.REPORT_DATA_COLS.inflows},
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.freePlay), label: this.REPORT_DATA_COLS.freePlay},
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.outflows), label: this.REPORT_DATA_COLS.outflows},
    ];

    return barChartData;
  }

  getLineChartData() {
    let barChartData = [
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.net), label: this.REPORT_DATA_COLS.net},
    ];

    return barChartData;
  }

  getArrayOfWeeksTotal(colName) {
    let totalData = [];
    this.reportWeeksList.forEach(week => {
      const total = this.getTotal(week, colName);
      totalData.push(total);
    });
    return totalData;
  }
}
