<div class="buy-menu" [class.is-hidden]="isHiddenClass() || (showGames && !showCrypto) || (!showGames && showCrypto) ">
    <div class="buy-menu__inner">
        <app-kiosk-table [items]="nav"
                         (onSelect)="onBuy($event)"></app-kiosk-table>
    </div>
</div>



<app-games-selection [pointId]="pointId"
                     (back)="hideGamesSelection()"
                     (gameSelected)="onSweepsPayment($event)"
                     (isBackBtn)="setGamesBackBtn($event)"
                     [showPopup]="openGamesPopup"></app-games-selection>

<app-kiosk-buy-crypto [showPopup]="openBuyCryptoPopup"
                      (cryptoSelected)="onCryptoPayment($event)"
                      (isBackBtn)="setCryptoBackBtn($event)"
                      (back)="hideBuyCrypto()"></app-kiosk-buy-crypto>
<app-kiosk-payment
        (close)="onClosePayment($event)"
        [isBackBtnToTypeList]="paymentsBackBtn"
        [data]="paymentInfo"
        [type]="paymentPopupType"
        [showPopup]="openPaymentPopup"
        [cryptoToBuy]="cryptoToBy"></app-kiosk-payment>

