import { YETI_RESPONSES_CONFIG } from "../../../../config/api";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var YetiResponsesService = /** @class */ (function () {
    function YetiResponsesService(http) {
        this.http = http;
        this.config = YETI_RESPONSES_CONFIG;
    }
    YetiResponsesService.prototype.initGame = function () {
        var _this = this;
        // return this.http.post<any>(this.config.initGame, {});
        return timer(500).pipe(mergeMap(function () { return _this.http.post(_this.config.initGame, {}); }));
    };
    YetiResponsesService.prototype.play = function (sessionUuid, uuid) {
        // return this.http.post<any>(this.config.play, {roundId, elementId});
        var _this = this;
        return timer(2000).pipe(mergeMap(function () { return _this.http.post(_this.config.play, { sessionUuid: sessionUuid, uuid: uuid }); }));
    };
    YetiResponsesService.prototype.redeem = function () {
        // return this.http.post<any>(this.config.play, {roundId, elementId});
        var _this = this;
        return timer(500).pipe(mergeMap(function () { return _this.http.post(_this.config.redeem, {}); }));
    };
    YetiResponsesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YetiResponsesService_Factory() { return new YetiResponsesService(i0.ɵɵinject(i1.HttpClient)); }, token: YetiResponsesService, providedIn: "root" });
    return YetiResponsesService;
}());
export { YetiResponsesService };
