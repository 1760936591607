import { PROJECT_CONFIG } from "../config/project";
import { HERO_VERSIONS } from "../config/hero_versions.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var HeroService = /** @class */ (function () {
    function HeroService(router) {
        this.router = router;
    }
    HeroService.prototype.isCryptoVersion = function () {
        return PROJECT_CONFIG.heroVersion === HERO_VERSIONS.crypto;
    };
    HeroService.prototype.isWatchVersion = function () {
        return PROJECT_CONFIG.heroVersion === HERO_VERSIONS.watch;
    };
    HeroService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeroService_Factory() { return new HeroService(i0.ɵɵinject(i1.Router)); }, token: HeroService, providedIn: "root" });
    return HeroService;
}());
export { HeroService };
