import { SLOTS_RESPONSES_CONFIG } from "../../../../config/api";
import { timer } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SlotsResponsesService = /** @class */ (function () {
    function SlotsResponsesService(http) {
        this.http = http;
        this.config = SLOTS_RESPONSES_CONFIG;
    }
    SlotsResponsesService.prototype.initGame = function () {
        // return this.http.post<any>(this.config.initGame, {});
        // return timer(500).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.initGame, {}))
        // );
        return timer(500).pipe();
    };
    SlotsResponsesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SlotsResponsesService_Factory() { return new SlotsResponsesService(i0.ɵɵinject(i1.HttpClient)); }, token: SlotsResponsesService, providedIn: "root" });
    return SlotsResponsesService;
}());
export { SlotsResponsesService };
