<div class="page page--primary commission">
    <div class="page__inner">

        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">Commission</h1>

        <div class="commission__header commission__text-block">
            <h2 class="gl-title">Available Now</h2>
            <p *ngIf="isAvailableBalance(); else isNegativeBalance" class="commission__text commission__text--accent">{{availableBalance  | currency : 'USD' : 'symbol'}}</p>
            <ng-template #isNegativeBalance>
                <!--<p class="commission__text commission__text&#45;&#45;accent">({{-availableBalance  | currency : 'USD' : 'symbol'}})</p>-->
                <p class="commission__text commission__text--accent">-{{-availableBalance  | currency : 'USD' : 'symbol'}}</p>
            </ng-template>
        </div>
        <p class="t-note commission__note" *ngIf="availableBalance < 0">Since your last commission, there were more rewards sent than money spent. No worries. You are never responsible for it. We cover it all for you. The next commission will be sent as soon as your balance become positive.</p>

        <div class="page__form">
            <form [formGroup]="form" (ngSubmit)="onSubmitPayout()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <mat-form-field>
                        <!--                        <mat-label>GET</mat-label>-->
                        <mat-select #methodsSelect
                                    formControlName="method"
                                    (valueChange)="onMethodChange($event)"
                                    [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                            <mat-select-trigger>
                                {{methodsSelect?.value?.name}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let method of methods" [value]="method">
                                {{method.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           (input)="onInputAmount()"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>


                <div class="form-simple__item">
                    <div *ngIf="moreThenAvailablePayout" class="form-simple__note">
                        <p *ngIf="isAvailableBalance(); else isNegativeBalance">Available {{availableBalance | currency : 'USD' : 'symbol'}}</p>
                        <ng-template #isNegativeBalance>
                            <p>Available {{0 | currency : 'USD' : 'symbol'}}</p>
                        </ng-template>
                    </div>
                </div>

                <div class="form-simple__item" [class.is-hidden]="isNoMailCheckChosen">
                    <input formControlName="address"
                           placeholder="Address"
                           [type]="'text'"
                           (change)="onInputAddress($event)"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button
                            class="button snake-btn is-notouched"
                            [disabled]="noSubmitPayoutForm()">
                        SETTLE
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

        <div class="commission__header commission__text-block">
            <h2 class="commission__title">Running balance may Fluctuate</h2>
            <p *ngIf="balance >= 0" class="commission__text commission__text--accent">{{balance  | currency : 'USD' : 'symbol'}}</p>
            <p *ngIf="balance < 0" class="commission__text commission__text--accent">-{{-balance  | currency : 'USD' : 'symbol'}}</p>
        </div>

        <p class="t-note commission__text commission__text-block">
            Thank you and welcome to our Reseller Program where you can earn up
            to {{commission}}% commission by sharing our link with your friends and family.
            When making a referral, please mention the website {{partnerDomain}}
            together with your personal PROMO Code. The table below shows your
            commission inflows and outflows. The commission of {{commission}}% is paid out
            weekly on the Net of all earned income.
        </p>

        <div class="commission__text-block">
            <div class="commission__row">
                <div class="commission__col">
                    <h2 class="commission__title">SITE</h2>
                </div>
                <div class="commission__col">
                    <span class="commission__text commission__text--accent commission__link">{{site}}</span>
                </div>
            </div>

            <div class="commission__row">
                <div class="commission__col">
                    <h2 class="commission__title">PROMO CODE</h2>
                </div>
                <div class="commission__col">
                    <span class="commission__text commission__text--accent">{{promoCode}}</span>
                </div>
            </div>
        </div>

        <p class="t-note commission__text commission__text-block">
            You can easily refer anybody by simply entering their phone below and clicking the Refer button. A Text message will be sent on your behalf with the link and your personal Promo Code.
        </p>

        <div class="page__form">
            <form [formGroup]="formRefer" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input [mask]="phoneMask"
                           formControlName="phone"
                           placeholder="Phone"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button
                            class="button snake-btn is-notouched"
                            [disabled]="formRefer.controls.phone.invalid">
                        REFER
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

<!--        <app-dropdown-item [dropdownClass]="'js-disputes-parent'"-->
<!--                           [title]="'DISPUTES'"-->
<!--                           [noBodyLeftPadding]="true"-->
<!--                           [hasPagination]="true">-->
<!--            <div class="dropdown__body" #scrolledEl (scroll)="scroll($event, disputesData)" #dropBody>-->
<!--                <table class="commission-history commission-history&#45;&#45;disputes">-->
<!--                    <tr class="commission-history__head">-->
<!--                        <th>DATE</th>-->
<!--                        <th>PHONE</th>-->
<!--                        <th>AMOUNT</th>-->
<!--                        <th>BAL</th>-->
<!--                    </tr>-->
<!--                    <tr *ngFor="let action of disputesData.items" class="commission-history__item js-disputes-item">-->
<!--                        <td>{{getDate(action.created) | date: 'MM/dd/yy'}}</td>-->
<!--                        <td>{{action.phone | mask: phoneMask}}</td>-->
<!--                        <td><app-financial-info-item [negativeIsNotDashed]="true" [value]="action.amount"></app-financial-info-item></td>-->
<!--                        <td><app-financial-info-item [negativeIsNotDashed]="true" [value]="action.balance"></app-financial-info-item></td>-->
<!--                    </tr>-->
<!--                </table>-->
<!--            </div>-->
<!--        </app-dropdown-item>-->

        <app-dropdown-item [dropdownClass]="'js-commission-parent'"
                           [title]="'Transaction History'"
                           [noBodyLeftPadding]="true"
                           [hasPagination]="true">
            <div class="dropdown__body" #scrolledEl (scroll)="scroll($event, commissionData)" #dropBody>
                <table class="commission-history">
                    <tr class="commission-history__head">
                        <th>DATE</th>
                        <th>CREDIT</th>
                        <th>DEBIT</th>
                        <th>BAL</th>
                        <th>COM {{commission}}%</th>
                    </tr>
                    <tr *ngFor="let action of commissionData.items" class="commission-history__item js-commission-item">
                        <td>{{getDate(action.created) | date: 'MM/dd/yy'}}</td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.credit)"></app-financial-info-item></td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.debit)"></app-financial-info-item></td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="action.balance"></app-financial-info-item></td>
                        <td class="accent"><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.settlement)"></app-financial-info-item></td>
                    </tr>
                </table>
            </div>
        </app-dropdown-item>
    </div>
</div>

