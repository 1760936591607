import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { PROJECT_CONFIG } from "../../../../config/project";
var BuyMenuComponent = /** @class */ (function () {
    function BuyMenuComponent(kioskService, loaderService, gamesProviderService, popupComponent, terminalService) {
        this.kioskService = kioskService;
        this.loaderService = loaderService;
        this.gamesProviderService = gamesProviderService;
        this.popupComponent = popupComponent;
        this.terminalService = terminalService;
        this.close = new EventEmitter();
        this.gameSelected = new EventEmitter();
        this.paymentTypes = PROJECT_CONFIG.terminalPaymentType;
        this.nav = [];
        this.isApp = this.kioskService.isKiosk();
        this.openGamesPopup = false;
        this.openBuyCryptoPopup = false;
        this.openPaymentPopup = false;
        this.showGames = false;
        this.showCrypto = false;
        this.gamesBackBtn = false;
        this.cryptoBackBtn = false;
        this.paymentsBackBtn = false;
    }
    Object.defineProperty(BuyMenuComponent.prototype, "t", {
        get: function () {
            return this.isHiddenClass();
        },
        enumerable: true,
        configurable: true
    });
    BuyMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showGames = this.terminalService.isGames();
        this.showCrypto = this.terminalService.isCrypto();
        setTimeout(function () {
            console.log("test_test: 22");
            //this.loaderService.show();
            if (_this.showGames && !_this.showCrypto) {
                // this.gamesProviderService.getGamesList(this.pointId).subscribe(data => {
                //     this.getGameListResult(data);
                // }, () => {
                //     this.loaderService.hideAndShowTryAgain();
                // }, () => {
                //     this.loaderService.hide();
                // });
                _this.onBuy(_this.paymentTypes.sweeps);
            }
            else if (!_this.showGames && _this.showCrypto) {
                _this.onBuy(_this.paymentTypes.crypto);
            }
            else if (_this.showGames && _this.showCrypto) {
                _this.nav = [
                    {
                        title: 'buy crypto currency',
                        img: '../../../assets/images/kiosk/buy-crypto.png',
                        description: 'Buying your favorite cryptocurrencies has never been more exciting. Invest and watch your money grow!',
                        type: 'menu',
                        id: _this.paymentTypes.crypto
                    },
                    {
                        title: 'buy member ship',
                        img: '../../../assets/images/kiosk/buy-sweeps.png',
                        description: 'You can pay cash right here, right now to fund your membership and earn points.',
                        type: 'menu',
                        id: _this.paymentTypes.sweeps
                    }
                ];
                _this.loaderService.hide();
            }
        });
    };
    BuyMenuComponent.prototype.ngAfterViewInit = function () {
        // if (this.showGames && !this.showCrypto) {
        //     // this.gamesProviderService.getGamesList(this.pointId).subscribe(data => {
        //     //     this.getGameListResult(data);
        //     // }, () => {
        //     //     this.loaderService.hideAndShowTryAgain();
        //     // }, () => {
        //     //     this.loaderService.hide();
        //     // });
        //
        //     this.onBuy(this.paymentTypes.sweeps);
        // } else if (!this.showGames && this.showCrypto) {
        //     this.onBuy(this.paymentTypes.crypto);
        // } else if (this.showGames && this.showCrypto) {
        //     this.nav = [
        //         {
        //             title: 'buy crypto currency',
        //             img: '../../../assets/images/kiosk/buy-crypto.png',
        //             description: 'Buying your favorite cryptocurrencies has never been more exciting. Invest and watch your money grow!',
        //             type: 'menu',
        //             id: this.paymentTypes.crypto
        //         },
        //         {
        //             title: 'buy member ship',
        //             img: '../../../assets/images/kiosk/buy-sweeps.png',
        //             description: 'You can pay cash right here, right now to fund your membership and earn points.',
        //             type: 'menu',
        //             id: this.paymentTypes.sweeps
        //         }
        //     ];
        //     this.loaderService.hide();
        // }
    };
    // getGameListResult(data) {
    //     try {
    //         if(data.status === true) {
    //             this.game = data.result[0];
    //
    //             this.onSweepsPayment(this.game);
    //         } else if (data.status === false) {
    //             this.popupComponent.showPopupError({text:data.message});
    //         } else {
    //             this.loaderService.hideAndShowTryAgain();
    //         }
    //     } catch (e) {
    //         this.loaderService.hideAndShowTryAgain();
    //     }
    // }
    BuyMenuComponent.prototype.closePopup = function () {
        this.close.emit();
    };
    BuyMenuComponent.prototype.onBuy = function (id) {
        if (id === this.paymentTypes.crypto) {
            this.openBuyCryptoPopup = true;
        }
        else if (id === this.paymentTypes.sweeps && this.pointId) {
            this.openGamesPopup = true;
        }
    };
    BuyMenuComponent.prototype.onCryptoPayment = function (crypto) {
        this.paymentPopupType = this.paymentTypes.crypto;
        // this.cryptoToBy = crypto;
        this.paymentInfo = crypto;
        this.openPaymentPopup = true;
        this.openBuyCryptoPopup = false;
        this.paymentsBackBtn = this.cryptoBackBtn;
    };
    BuyMenuComponent.prototype.onSweepsPayment = function (game) {
        this.paymentPopupType = this.paymentTypes.sweeps;
        this.paymentInfo = game;
        this.openPaymentPopup = true;
        this.openGamesPopup = false;
        this.paymentsBackBtn = this.gamesBackBtn;
    };
    BuyMenuComponent.prototype.hideGamesSelection = function () {
        this.openGamesPopup = false;
    };
    BuyMenuComponent.prototype.hideBuyCrypto = function () {
        this.openBuyCryptoPopup = false;
    };
    BuyMenuComponent.prototype.onClosePayment = function (type) {
        this.paymentPopupType = null;
        this.openPaymentPopup = false;
        this.paymentsBackBtn = false;
        if ((type === this.paymentTypes.crypto && this.cryptoBackBtn) || (type === this.paymentTypes.sweeps && this.gamesBackBtn)) {
            this.onBuy(type);
        }
    };
    BuyMenuComponent.prototype.isHiddenClass = function () {
        return !this.showPopup || this.openGamesPopup || this.openBuyCryptoPopup || this.openPaymentPopup;
    };
    BuyMenuComponent.prototype.setGamesBackBtn = function (state) {
        this.gamesBackBtn = state;
    };
    BuyMenuComponent.prototype.setCryptoBackBtn = function (state) {
        this.cryptoBackBtn = state;
    };
    return BuyMenuComponent;
}());
export { BuyMenuComponent };
