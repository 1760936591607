import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var MenuService = /** @class */ (function () {
    function MenuService(route, router) {
        this.route = route;
        this.router = router;
        this.menuChanged = new BehaviorSubject(false);
        this.totalNotificationsAmount = new BehaviorSubject(null);
    }
    MenuService.prototype.show = function () {
        this.menuChanged.next(true);
        //this.searchService.hide();
        document.body.classList.add('is-hidden');
    };
    MenuService.prototype.hide = function () {
        this.menuChanged.next(false);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
        });
        document.body.classList.remove('is-hidden');
    };
    MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
