import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class FrontendTestSettingsService {
    isTestMode = environment.test_front;

    isResetAllSettings = false;

    terminalOn = false;
    mascotSinglePageOn = false;
    clientOn = true;
    partnerAdditionInfo = true;

    terminalSettings = {
        noConnectedTerminal: false,
        isStationMascot: true,
        displayGames: true,
        displayCrypto: true,
    }

    constructor() {
        if (this.isTestModeOn()) {
            console.log('Frontend Test mode is on'.toLocaleUpperCase());
        } else {
            console.log('Frontend Test mode is off'.toLocaleUpperCase());
        }
    }

    isTestModeOn() {
        if (this.isTestMode && !this.isResetAllSettings) {
            return true;
        }

        return false;
    }

    isHideForTesting() {
        if (this.isTestModeOn()) {
            return false;
        }
        return true;
    }

    isMascotSinglePage() {
        if (this.isTestModeOn()) {
            return this.mascotSinglePageOn;
        }

        return false;
    }

    isClient() {
        if (this.isTestModeOn()) {
            return this.isTestModeOn() && this.clientOn;
        }

        return false;
    }

    isTerminal() {
        if (this.isTestModeOn()) {
            return this.terminalOn;
        }

        return false;
    }

    isTerminalDisplayGames() {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.displayGames;
        }

        return false;
    }

    isTerminalDisplayCrypto() {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.displayCrypto;
        }

        return false;
    }

    isNoTerminal() {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.noConnectedTerminal;
        }

        return false;
    }

    isPartnerAdditionInfo() {
        if (this.isTestModeOn()) {
            return this.partnerAdditionInfo;
        }

        return false;
    }

    isTerminalStationMascot() {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.isStationMascot;
        }

        return false;
    }

    isTerminalExist() {
        if (this.isTestModeOn()) {
            return this.isTerminal() && !this.terminalSettings.noConnectedTerminal;
        }

        return false;
    }
}
