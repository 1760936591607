import * as tslib_1 from "tslib";
import { PAYPAL_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var PaypalService = /** @class */ (function (_super) {
    tslib_1.__extends(PaypalService, _super);
    function PaypalService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = PAYPAL_CONFIG;
        return _this;
    }
    PaypalService.prototype.getAccounts = function () {
        this.showLoader();
        return this.http.post(this.config.accounts, {});
    };
    PaypalService.prototype.find = function (id) {
        this.showLoader();
        return this.http.post(this.config.find, { id: id });
    };
    PaypalService.prototype.deleteAccount = function (id) {
        this.showLoader();
        return this.http.post(this.config.delete, { id: id });
    };
    PaypalService.prototype.addAccount = function (email) {
        this.showLoader();
        return this.http.post(this.config.add, { email: email });
    };
    PaypalService.prototype.editAccount = function (id, email) {
        this.showLoader();
        return this.http.post(this.config.edit, { id: id, email: email });
    };
    PaypalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaypalService_Factory() { return new PaypalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PaypalService, providedIn: "root" });
    return PaypalService;
}(SettingsLoaderService));
export { PaypalService };
