<ngx-slick-carousel class="carousel"
                    #slickModal2="slick-carousel"
                    [config]="slideConfig"
                    (init)="slickInit($event)"
                    (breakpoint)="breakpoint($event)"
                    (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <img src="{{ slide.img }}" alt="" width="100%">
    </div>
</ngx-slick-carousel>

<ngx-slick-carousel class="carousel"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                    (init)="slickInit($event)"
                    (breakpoint)="breakpoint($event)"
                    (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of slidesDynamic" class="slide">
        <img src="{{ slide.image360 }}" alt="" width="100%">
    </div>
</ngx-slick-carousel>

<!--<button (click)="addSlide()">Add</button>-->
<!--<button (click)="removeSlide()">Remove</button>-->
<!--<button (click)="slickModal.slickGoTo(2)">slickGoto 2</button>-->
<!--<button (click)="slickModal.unslick()">unslick</button>-->
