<div class="page page--quaternary" [class.page--blured]="menu || showSupportForm">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">SETTINGS</h1>

        <div class="page__scrolled">
            <div class="page__head">
                <div class="page__left">
                    <div *ngIf="userStatus === USER_STATUS.active" class="page__status gl-title">ACCOUNT ACTIVE</div>
                    <div *ngIf="userStatus !== USER_STATUS.active" class="page__status gl-title">ACCOUNT FROZEN</div>
                </div>
                <div class="page__right">
                    <button (click)="menu = true" class="page__options js-open-menu">
                        <svg viewBox="0 0 29 7" xmlns="http://www.w3.org/2000/svg">
                            <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
                        </svg>
                    </button>
                </div>
                <div *ngIf="userStatus === USER_STATUS.frozen" class="page__status-info">User Frozen</div>
                <div *ngIf="userStatus === USER_STATUS.blocked" class="page__status-info">Admin Frozen</div>

<!--                <div class="page__status-info page__status-info&#45;&#45;left">Limit three (3) CC purchases per 24 hours</div>-->
                <div class="page__status-info page__status-info--left gl-subtitle">You are depositing for {{gameVendorName}}</div>
            </div>

            <div class="page__body">

                <app-dropdown-item [title]="'No limits'"
                                   [noBodyLeftPadding]="true"
                                   [headTwoColumns]="true"
                                   [dropdownClosed]="true"
                                   [hasPagination]="true">
                    <div class="dropdown__body"  #dropBody>
                        <app-group-bordered>
                            <h2>NO LIMITS</h2>
                            <h4>Avaliable 24/7. Instant deposits. No max deposits. No max limit.</h4>
                            <div class="page__form group-bordered-inner">
                                <ng-container *ngIf="!cashappVerified">
                                    <app-verify-cashapp-tag [verifyStatus]="cashappVerified" (onVerify)="setCashappVerified($event)"></app-verify-cashapp-tag>
                                </ng-container>

                                <div class="info-block" *ngIf="cashappVerified">
                                    <h2>SEND FROM YOUR CASH APP
                                        <span class="color-secondary">{{userCashappTag}}</span>
                                    </h2>
                                    <h2>SEND TO CASH APP
                                        <span class="color-secondary">{{cashappTag}}
                                            <app-copy-to-buffer-button [value]="cashappTag"></app-copy-to-buffer-button>
                                        </span>
                                    </h2>
                                    <ul>
                                        <li>enter the amount you wish</li>
                                        <li>add comment <span class="color-secondary">{{gameVendorShortName}}</span>
                                        </li>
                                        <li>send to <span class="color-secondary">{{cashappTag}}</span>
                                            <app-copy-to-buffer-button [value]="cashappTag"></app-copy-to-buffer-button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="navigation">
                                    <a id="{{item.id}}"
                                       *ngFor="let item of navigationNoLimits"
                                       [hidden]="item.hidden"
                                       (click)="item.action()"
                                       class="navigation__button button snake-btn is-notouched {{background(item.id)}}"
                                       style="cursor: pointer;">
                                        {{item.title}}
                                        <svg><rect></rect></svg>
                                    </a>
                                </div>
                            </div>
                        </app-group-bordered>
                    </div>
                </app-dropdown-item>

                <app-dropdown-item [title]="'Subject to limitations'"
                                   [noBodyLeftPadding]="true"
                                   [headTwoColumns]="true"
                                   [dropdownClosed]="true"
                                   [hasPagination]="true">
                    <div class="dropdown__body" #dropBody>
                        <app-group-bordered>
                            <h2>SUBJECT TO LIMITATIONS</h2>
                            <h4>{{subjectLimitationTxt}}</h4>
                            <div class="page__form group-bordered-inner">
                                <div class="info-block">
                                    <h2></h2>
                                </div>
                                <div class="navigation">
                                    <a id="{{item.id}}"
                                       *ngFor="let item of navigation"
                                       [hidden]="item.hidden"
                                       (click)="isAvailble(item.id,item.link)"
                                       class="navigation__button button snake-btn is-notouched {{background(item.id)}}"
                                       style="cursor: pointer;">
                                        {{item.title}}
                                        <svg><rect></rect></svg>
                                    </a>
                                </div>
                            </div>
                        </app-group-bordered>
                    </div>
                </app-dropdown-item>

                <div class="navigation">
                    <div class="navigation__col" [hidden]="isRiverPay">
                        <a id="funds_btn" [routerLink]="getLinkToFunds()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">ADD <br>FUNDS</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke="#DCBCFF" stroke-width="2"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

<div class="addition-menu addition-menu--top" [class.is-active]="menu">
    <ul class="addition-menu__list">
<!--        <li class="addition-menu__item">-->
<!--            <button class="js-freeze-account" *ngIf="userStatus === USER_STATUS.active" (click)="askFreezeUser()">Freeze Account</button>-->
<!--            <button class="js-unfreeze-account" *ngIf="userStatus !== USER_STATUS.active" (click)="askUnFreezeUser()">Unfreeze Account</button>-->
<!--        </li>-->
        <li class="addition-menu__item">
            <button class="js-delete-account" (click)="askDeleteUser()">Delete</button>
        </li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn js-close-menu">Cancel</button>
</div>

<app-support [showSupport]="showSupportForm"
             (close)="onSupportClose($event)"></app-support>
