import * as tslib_1 from "tslib";
import { TERMINAL_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var KioskPaymentService = /** @class */ (function (_super) {
    tslib_1.__extends(KioskPaymentService, _super);
    function KioskPaymentService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = TERMINAL_CONFIG;
        return _this;
    }
    KioskPaymentService.prototype.create = function (amount, typeShortName) {
        return this.http.post(this.config.createPayment, { amount: amount, typeShortName: typeShortName });
    };
    KioskPaymentService.prototype.complete = function () {
        return this.http.post(this.config.complete, {});
    };
    KioskPaymentService.prototype.getBalance = function () {
        this.showLoader();
        return this.http.post(this.config.balance, {});
    };
    KioskPaymentService.prototype.getCrypto = function () {
        return this.http.post(this.config.getCrypto, {});
    };
    KioskPaymentService.prototype.buyCrypto = function (currencyIdTo) {
        this.showLoader();
        return this.http.post(this.config.buyCrypto, { currencyIdTo: currencyIdTo });
    };
    KioskPaymentService.prototype.buySweeps = function () {
        this.showLoader();
        return this.http.post(this.config.buySweeps, {});
    };
    KioskPaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KioskPaymentService_Factory() { return new KioskPaymentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: KioskPaymentService, providedIn: "root" });
    return KioskPaymentService;
}(SettingsLoaderService));
export { KioskPaymentService };
