import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../shared/popup/popup.component";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {UsersPointService} from "../../service/users/users-point.service";
import {KioskService} from "../../service/kiosk.service";
import {LoaderService} from "../../service/loader.service";
import {ServerResponse} from "../../models/responses/response.model";
import {UserBalanceService} from "../../service/users/userBalance.service";
import {PartnerService} from "../../service/partner.service";
import {PARTNERS} from "../../config/partners_settings";
import {StylingService} from "../../service/styling.service";
import {
    GAME_VENDOR_LINK, GAME_VENDORS_EBOX, GAME_VENDORS_GOLDENDRAGON,
    GAME_VENDORS_MASCOT, GAME_VENDORS_MASCOT_CARD, GAME_VENDORS_MASCOT_KENO,
    GAME_VENDORS_MASCOTCASINO,
    GAME_VENDORS_RIVER
} from "../../config/gameVendors";
import {environment} from "../../../environments/environment";
import {KioskAccessorService} from "../../service/kiosk/kiosk-accessor.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router.paths.model";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GAME_PROVIDER_ERRORS_CODE} from "../../service/games/gameProviderErrorsCode.config";
import {GameService} from "../../service/games/gameService.service";
import {TIME_SPANS} from "../../config/timeSpans.config";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {SupportService} from "../../service/support/support.service";

@Component({
    selector: 'app-rewards',
    templateUrl: './rewards.component.html',
    styleUrls: ['./rewards.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class RewardsComponent implements OnInit {
    userPin;
    userGameVendor;
    isApp = +localStorage.getItem('isApp');
    // reward = 0;
    pointName;

    advertiseLink = 'https://www.acereveal.com/signup.aspx?promocode=NFT';

    mascotView = false;
    riverView = false;
    goldenDragonView = false;

    gd_mobile_id;
    gd_password;

    GAME_VENDOR_LINK = GAME_VENDOR_LINK;
    GAME_VENDORS_RIVER = GAME_VENDORS_RIVER;
    GAME_VENDORS_MASCOT = GAME_VENDORS_MASCOT;
    GAME_VENDORS_MASCOTCASINO = GAME_VENDORS_MASCOTCASINO;
    GAME_VENDORS_GOLDENDRAGON = GAME_VENDORS_GOLDENDRAGON;
    GAME_VENDORS_EBOX = GAME_VENDORS_EBOX;
    GAME_VENDORS_MASCOT_CARD = GAME_VENDORS_MASCOT_CARD;
    GAME_VENDORS_MASCOT_KENO = GAME_VENDORS_MASCOT_KENO;

    // games;
    providers = [];
    activeProvider;
    selectedProvider;

    providersForm: FormGroup;
    showSupportForm = false;
    constructor(private popupComponent: PopupComponent,
                private auth:AuthenticationService,
                private kioskService: KioskService,
                private loaderService: LoaderService,
                private userBalanceService: UserBalanceService,
                private stylingService: StylingService,
                private partnerService: PartnerService,
                private router: Router,
                private formBuilder: FormBuilder,
                private gameService: GameService,
                private gamesProviderService: GamesProviderService,
                private kioskAccessorService: KioskAccessorService,
                private supportService: SupportService,
                private usersPointService: UsersPointService) {
        // this.kioskAccessorService.paymentComplete.subscribe((val) => {
        //     if (val !== null) {
        //         this.updateBalance();
        //     }
        // });
    }


    ngOnInit() {
        //this.userPin = this.usersPointService.getRiverPayNumber();

        this.providersForm = this.formBuilder.group({
            providers: [null, Validators.required]
        });

        this.getBalanceRequest();

        this.setPageView();

        this.pointName = this.usersPointService.getPointName();
    }

    getBalanceRequest(noGameChange?) {

        this.loaderService.show();
        this.userBalanceService.getBalance().subscribe(data => {
            // this.loaderService.hide();
            if (data.status) {
                // this.games = data.games;
                // this.reward = Math.round(data.result * 100);

                if (data.providers) {
                    this.setGamesProviders(data.providers, noGameChange);
                }

                this.userPin = data.riverpayId;

                this.gd_mobile_id = data.goldenDragonMobileId;
                this.gd_password = data.goldenDragonPassword;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setGamesProviders(providers, noGameChange) {
        this.providers = providers;
        this.gamesProviderService.setProviders(this.providers);

        if (!noGameChange) {
            this.activeProvider = this.gamesProviderService.getActiveProvider(this.providers);

            if(this.activeProvider === undefined){
                if(providers[0]){
                    this.onProviderChange(providers[0]);
                }
            }else{
                this.selectedProvider = this.activeProvider;
                this.providersForm.controls.providers.setValue(this.activeProvider);
            }
        }
    }

    // updateBalance() {
    //     this.userBalanceService.getBalance().subscribe(data => {
    //         this.loaderService.hide();
    //         if (data.status) {
    //             // this.reward = Math.round(data.result * 100);
    //         } else {
    //             this.popupComponent.showPopupError({text: data.message});
    //         }
    //     }, () => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }

    setPageView() {
        this.mascotView = false;
        this.goldenDragonView = false;
        this.riverView = false;

        this.userGameVendor = this.usersPointService.getGameVendor();

        switch (this.userGameVendor) {
            case this.GAME_VENDORS_MASCOT: {
                this.mascotView = true;
                break;
            }
            case this.GAME_VENDORS_GOLDENDRAGON: {
                this.goldenDragonView = true;
                break;
            }
            case this.GAME_VENDORS_RIVER: {
                this.riverView = true;
                break;
            }
        }
    }

    onSweeps(needCopyId?) {
        const partnerBusinessName = this.partnerService.getBusinessName();
        const riverText = `Your account number has been copied! Please paste it on the next screen to continue.<br/><br/>You are being redirected to a third-party provider not associated with ${partnerBusinessName}. Please confirm to proceed:`
        const goldenDragonText = `Your mobile ID has been copied! Please paste it on the next screen to continue.<br/><br/>You are being redirected to a third-party provider not associated with ${partnerBusinessName}. Please confirm to proceed:`
        const mascotKenoText = `You are being redirected to a third-party provider not associated with ${partnerBusinessName}. Please confirm to proceed:`;

        if (this.mascotView) {
            this.router.navigate([RouterPaths.mascot.main]);
        } else if(this.usersPointService.getGameVendor() == this.GAME_VENDORS_EBOX){
            this.router.navigate([RouterPaths.games.yeti.main]);
        } else if(this.usersPointService.getGameVendor() == this.GAME_VENDORS_MASCOT_CARD){
            this.router.navigate([RouterPaths.mascotCard.main]);
        } else if(this.usersPointService.getGameVendor() == this.GAME_VENDORS_MASCOT_KENO){
            this.popupComponent.showPopupAgreement({text: mascotKenoText,
                confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
                style: 'swal-agreement--success'}, (status) => {
                if (status) {
                    const gameId = this.activeProvider.keno_game_id;
                    if (gameId) {
                        this.gameService.getGameUrl(gameId).subscribe(data => {
                            this.openMascotKeno(data);
                        }, () => {
                            this.loaderService.hideAndShowTryAgain();
                        }, () => {
                            this.loaderService.hide();
                        });
                    } else {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }
            });

        } else {
            this.popupComponent.showPopupAgreement({text: this.isRiverView() ? riverText : goldenDragonText,
                confirmButtonText: 'SWEEP REWARDS<svg><rect></rect></svg>',
                style: 'swal-agreement--success'}, (status) => {
                if (status) {
                    if (needCopyId) {
                        this.copyToBuffer();
                    }
                    this.goToSweep();

                }
            });
        }

    }

    openMascotKeno(data) {
        try {
            if (data.status) {
                const link = data.result;
                //window.open(link, '_blank');
                //window.open(link);

                window.location.href = link;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    isPointPlayersEarnIncome(){
        return this.usersPointService.isPointPlayersEarnIncome();
    }

    isRiverView() {
        return this.riverView;
    }

    goToSweep() {
        const link = this.isRiverView() ? this.GAME_VENDOR_LINK.river : this.GAME_VENDOR_LINK.goldenDragon;

        var win = window.open(link, '_blank');
        win.focus();
    }

    copyToBuffer(needMessage?) {
        let tempInput = document.createElement('input');
        tempInput.value = this.isRiverView() ? this.userPin : this.gd_mobile_id;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        if (needMessage) {
            this.popupComponent.showPopupSuccess({text: 'Code has been successfully copied!'});
        }
    }

    onSweepsKiosk() {
        this.kioskService.getSweepsLink().subscribe(data => {
            if (data) {
                this.checkSubmitResult(data);
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitResult(responce) {
        if(responce.status === true){
            this.popupComponent.showPopupSuccess({text: responce.message});
        }else if (responce.status === false) {
            this.popupComponent.showPopupError({text: responce.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    showPointName() {
        return this.pointName && this.partnerService.getShortName() !== PARTNERS.snekapek.short_name;
    }

    onProviderChange(game) {
        this.loaderService.show();
        this.gamesProviderService.onGameChosen(game, this.checkSubmitGame.bind(this));
    }

    checkSubmitGame(data, gameId) {
        try {
            if(data.status === true){
                if (data.code) {
                    if (data.code === GAME_PROVIDER_ERRORS_CODE.gameDisabled) {
                        setTimeout(() => {
                            this.setPageView();
                            this.getBalanceRequest(true);
                        });
                    }
                } else {
                    setTimeout(() => {
                        this.setPageView();
                        this.getBalanceRequest();
                    });
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getLinkToFunds() {
        return RouterPaths.settings.funds;
    }

    getLinkToEarnIncome() {
        return RouterPaths.rewards.commission;
    }

    getLinkToNFT() {
        return RouterPaths.investments.main;
    }

    onShowSupportForm() {
        this.showSupportForm = true;
        document.body.classList.add('hide-menu');
    }

    onSupportClose($event) {
        this.showSupportForm = false;
    }
}
