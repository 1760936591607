export interface TERMINAL_RESPONSE_KEYS_MODEL {
  title?: string;
  logo?: string;
  description?: string;
  shortName?: string;
  isCrypto?: string;
  isGames?: string;
}

export const TERMINAL_RESPONSE_KEYS: TERMINAL_RESPONSE_KEYS_MODEL = {
  title: 'terminalTitle',
  logo: 'terminalLogo',
  description: 'terminalDescription',
  shortName: 'terminalShortName',
  isCrypto: 'terminalCryptoStatus',
  isGames: 'terminalGamesStatus'
};
