import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthConfig} from '../_services/auth-config';
import {AuthenticationService} from "../_services/authentication.service";
import {MenuService} from "../../../service/menu.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {AppTestService} from "../../../service/app/appTest.service";
import {UsersPointService} from "../../../service/users/users-point.service";
import {ERROR_CODE} from "../../../config/error_code";
import {PartnerService} from "../../../service/partner.service";
import {PROVIDERS_TEMPLATES, TIMERS_CONFIG} from "../../../config/project";
import {RouterPaths} from "../../../config/router.paths.model";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    hasError = false;
    refreshingToken = false;
    mascotSingle = false;
    ERROR_CODE = ERROR_CODE;



    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private authenticationService: AuthenticationService,
                private menuService: MenuService,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private appTestService: AppTestService,
                private usersPointService: UsersPointService,
                private partnerService: PartnerService,
                private router: Router) {
        this.partnerService.currentPartnerTemplate.subscribe(val => {
            if (val === PROVIDERS_TEMPLATES.mascotSingle) {
                this.mascotSingle = true;
            }
        });

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // ////console.log(request);
        // ////console.log(next);
        if (this.authConfig.login === request.url) {
            this.hasError = false;
            this.refreshingToken = false;
        }

        while (this.refreshingToken) {

        }
        if (this.hasError && this.authConfig.login !== request.url) {
            return null;
        }
        let storage = sessionStorage;
        // add authorization header with jwt token if available
        const currentUser = JSON.parse(storage.getItem('currentUser'));
        // ////console.log(request.url);

        request = request.clone({
            setHeaders: {
                Partner: `${window.location.hostname}`
            }
        });

        if (this.appTestService.isWeb()) {
            request = request.clone({
                setHeaders: {
                    Iswebtest: `true`
                }
            });
        }

        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }



        try {
            const userPointId = this.usersPointService.getUserPointId();
            if (userPointId != undefined) {
                request = request.clone({
                    setHeaders: {
                        Userpointid: `${userPointId}`
                    }
                });
            }
        }catch (e) {

        }

        try {
            const subdomain = this.usersPointService.getSubdomain();

            request = request.clone({
                setHeaders: {
                    Subdomain: subdomain
                }
            });
        }catch (e) {

        }

        if (this.authConfig.refresh === request.url) {
            this.refreshingToken = true;
        }
        return next.handle(request).pipe(
            tap( // Log the result or error
                (data: any) => {
                    try {
                        if (data != undefined) {
                            if (data.body) {
                                if (data.body.code) {
                                    if (data.body.code == 401 && !this.menuService.menuChanged.value) {
                                        this.logout();
                                    }

                                    if (data.body.code == this.ERROR_CODE.blocked) {
                                        this.popupComponent.showPopupErrorReturnEvent({text: data.body.message, closePopupAfter: TIMERS_CONFIG.popupHideAfter}, () => {
                                            this.logout();
                                        })
                                    }

                                    if (data.body.code == this.ERROR_CODE.authOver) {
                                        this.popupComponent.showPopupErrorReturnEvent({text: data.body.message, closePopupAfter: TIMERS_CONFIG.popupHideAfter}, () => {
                                            this.logout();
                                        })
                                    }
                                }
                            }
                        }
                    } catch (e) {

                    }

                    if (this.authConfig.refresh === request.url) {
                        this.refreshingToken = false;
                    }
                },
                error => {
                    this.loaderService.hide();
                    if (this.authConfig.refresh === request.url) {
                        this.refreshingToken = false;
                        this.hasError = true;
                    } else {
                        this.logout();
                        //this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
                    }
                }
            )
        );
    }

    logout() {
        this.authenticationService.logout();

        this.router.navigate(['/'], {queryParams: {notLogged: true}});

        // if (this.mascotSingle) {
        //     console.log("RouterPaths.mascot.main 4");
        //     this.router.navigate([RouterPaths.mascot.main], {queryParams: {notLogged: true}});
        // } else {
        //     this.router.navigate(['/'], {queryParams: {notLogged: true}});
        //     //this.router.navigate([this.authConfig.login], {queryParams: {returnUrl: ""}});
        // }
    }
}
