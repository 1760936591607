import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Promo777Service} from "../../../service/promo/promo777.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";

@Component({
  selector: 'app-promo777',
  templateUrl: './promo777.component.html',
  styleUrls: ['./promo777.component.scss']
})
export class Promo777Component implements OnInit {
  form: FormGroup;
  pinForm: FormGroup;
  resendForm: FormGroup;

  phoneMask = '(000) 000-0000';
  benefits = {
    register: 7,
    cashapp: 7,
    deposit: 7
  };
  popupIsOpen = false;
  popupType = '';
  numOfDigits = 4;
  promoStep = 1;

  // popupIsOpen = true;
  // popupType = 'address';

  @ViewChildren('inputs') inputs: QueryList<ElementRef>;

  constructor(private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private promo777Service: Promo777Service) { }

  ngOnInit() {
    document.body.classList.add('promo');
    //this.loaderService.show();
    // (document.querySelector('.promo__bg') as HTMLImageElement).onload = () => {
      this.setFontSize();
      window.onresize = () => {
        this.setFontSize();
      // }
      //this.loaderService.hide();
    }
    this.form = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, Validators.required],
      cashappTag: [null, Validators.required],
      deposit: ['1.00', Validators.required],
      skip: [null, Validators.required]
    });
    this.resendForm = this.formBuilder.group({
      cashappTag: [null, Validators.required],
      email: [null, Validators.required],
      cashappTagRetype: [null, Validators.required],
    });

    this.pinForm = this.formBuilder.group({
      digits: this.formBuilder.array([])
    });
    for (let i = 0; i < this.numOfDigits; i++) {
      (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('promo');
  }

  setFontSize() {
    this.loaderService.show();
    const doc = document.documentElement;
    document.querySelector('.promo__bg').removeAttribute('style');
    doc.style.setProperty('--promo-height', `${document.querySelector('.promo__inner').clientHeight}px`);
    // doc.style.setProperty('--promo-width', `${document.querySelector('.promo__inner').clientWidth}px`);
    doc.style.setProperty('--promo-width', `${window.innerWidth / 1.87 < window.innerHeight ? window.innerWidth : window.innerHeight / 0.5344}px`);
    setTimeout(() => {
      this.loaderService.hide();
    }, 200);

  }

  onPhoneSubmit() {
    const firstName = this.form.controls.firstName.value;
    const lastName = this.form.controls.lastName.value;
    const email = this.form.controls.email.value;
    const phone = this.form.controls.phone.value;

    this.promo777Service.register(firstName, lastName, email, phone).subscribe(data => {
      try {
        if (data.status) {
          console.log(data);
          if (!this.popupIsOpen) {
            this.showPinPopup();
          }
        } else {
          // this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  onPopupClose() {
    this.popupIsOpen = false;
  }

  showPinPopup() {
    this.resetPinInput();
    this.popupIsOpen = true;
    this.popupType = 'pin';
  }

  resendSms() {
    this.onPhoneSubmit();
  }

  check(index, field, event) {
    if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
      event.preventDefault();
    }

    if (field.value && event.key !== 'Backspace') {
      if (index < this.inputs.toArray().length - 1) {
        this.inputs.toArray()[index + 1].nativeElement.focus();
      }
    } else if (event.key === 'Backspace') {
      if (index > 0) {
        field.setValue(null)
        this.inputs.toArray()[index - 1].nativeElement.focus();
      } else {
        console.log('first field');
      }
    }
  }

  resetPinInput() {
    (this.pinForm.get('digits') as FormArray).clear();
    for (let i = 0; i < this.numOfDigits; i++) {
      (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
    }
  }

  onPinSubmit() {
    if (this.pinForm.invalid) {
      return;
    }

    const phone = this.form.controls.phone.value;
    let pin = this.pinForm.controls.digits.value;
    pin = pin.join('');

    if (pin.length !== 4) {
      this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
      return
    }

    if (phone && pin && (pin.length === 4)) {
      this.promo777Service.verification(phone, pin).subscribe(data => {
        this.checkPinSubmit(data);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    } else {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  checkPinSubmit(data) {
    try {
      if (data.status) {
        this.popupIsOpen = false;
        this.promoStep = 2;
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onEmailResendSubmit() {

  }
  onTagResendSubmit() {

  }

  onVerifyCashappSubmit() {
    this.popupIsOpen = true;
    this.popupType = 'tagRetype';
  }

  onTagRetypeSubmit() {
    const cashapp = this.form.controls.cashappTag.value;
    const cashappRetype = this.resendForm.controls.cashappTagRetype.value;

    if (cashapp !== cashappRetype) {
      return;
    }

    this.promo777Service.sendCashapp(cashapp).subscribe(data => {
      this.checkCashappSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkCashappSubmit(data) {
    try {
      if (data.status) {
        this.popupIsOpen = false;
        this.promoStep = 3;
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onTextCashTag() {
    this.promo777Service.textCashTag().subscribe(data => {
      this.checkTextCashTagSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkTextCashTagSubmit(data) {
    try {
      if (data.status) {
        this.popupIsOpen = true;
        this.popupType = 'textCashTag';
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onLogin() {
    this.popupIsOpen = false;
  }

  onPlayNow() {
    const deposit = this.form.controls.deposit.value;
    const skip = this.form.controls.skip.value;

    this.promo777Service.playNow(deposit, skip).subscribe(data => {
      this.checkPlayNowSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkPlayNowSubmit(data) {
    try {
      if (data.status) {

      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onSkipChange($event) {
    this.form.controls.skip.setValue($event.target.checked);
  }
}
