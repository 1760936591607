<div class="login page-wrapper" [class.is-blurred]="showGamesSelection">

    <particles *ngIf="isApp && isScreenSaverLinesAnimation()" [style]="particlesStyle" [width]="100" [height]="100" [params]="particlesParams"></particles>
    <app-screen-saver *ngIf="isApp && isScreenSaverSliderAnimation()"></app-screen-saver>


    <div class="hero-wrapper">
        <app-hero></app-hero>
    </div>

    <div class="login__inner">
        <div *ngIf="!accountEnteringPopup && !terminalConnectingPopup" class="login__col">
            <div class="login__header">
                <button *ngIf="isPinClassActive()" (click)="goBackToLogin()" class="login__back">
                    <span class="visually-hidden">Back</span>
                </button>
                <img class="login__logo" src="{{getPartnerLogo()}}" alt="">
            </div>
            <div *ngIf="!isRegistrationClassActive()" class="login__title">{{getPartnerName()}} <span class="login__title--inner"><span class="capital">M</span>ember <span class="capital">P</span>ortal</span></div>
            <div *ngIf="isRegistrationClassActive()" class="login__title login__title--square">{{getPartnerName()}} Member Portal</div>
        </div>

        <div class="login__col">
            <div *ngIf="isRegistrationClassActive()" class="login__text login__text--mb-sm">
                Hi there! Thank you for choosing {{getPartnerName()}}. You are registering with phone number:
                <div class="accent">{{phoneForm.userForm.controls.phone.value | mask: phoneMask}}</div>
            </div>


            <div class="login__forms">
                <div *ngIf="isPhoneClassActive() && !accountEnteringPopup && !terminalConnectingPopup" class="text-center login-tab">
<!--                    <form [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="login__form">-->
<!--                        <ul class="login__countries countries">-->
<!--                            <li class="countries__item">-->
<!--                                <input formControlName="country"-->
<!--                                        id="us"-->
<!--                                        class="countries__input"-->
<!--                                        type="radio"-->
<!--                                        value="us"-->
<!--                                        name="country"-->
<!--                                        checked-->
<!--                                        (change)="onCountryChange($event)">-->

<!--                                <label for="us" class="countries__label">-->
<!--                                    <img class="countries__img" src="../../../../assets/images/flags/flag-us.jpg" alt="US">-->
<!--                                    <span class="countries__name">US</span>-->
<!--                                </label>-->
<!--                            </li>-->
<!--                            <li class="countries__item">-->
<!--                                <input  formControlName="country"-->
<!--                                        id="mx"-->
<!--                                        class="countries__input"-->
<!--                                        type="radio"-->
<!--                                        value="mx"-->
<!--                                        name="country"-->
<!--                                        (change)="onCountryChange($event)">-->

<!--                                <label for="mx" class="countries__label">-->
<!--                                    <img class="countries__img" src="../../../../assets/images/flags/flag-mx.jpg" alt="MX">-->
<!--                                    <span class="countries__name">MX</span>-->
<!--                                </label>-->
<!--                            </li>-->
<!--                            <li class="countries__item">-->
<!--                                <input  formControlName="country"-->
<!--                                        id="au"-->
<!--                                        class="countries__input"-->
<!--                                        type="radio"-->
<!--                                        value="au"-->
<!--                                        name="country"-->
<!--                                        (change)="onCountryChange($event)">-->

<!--                                <label for="au" class="countries__label">-->
<!--                                    <img class="countries__img" src="../../../../assets/images/flags/flag-au.jpg" alt="AU">-->
<!--                                    <span class="countries__name">AU</span>-->
<!--                                </label>-->
<!--                            </li>-->
<!--                        </ul>-->

<!--                        <div class="login__item">-->
<!--                            <input [mask]="phoneMask"-->
<!--                                   formControlName="phone"-->
<!--                                   placeholder="Phone Number"-->
<!--                                   [type]="'tel'"-->
<!--                                   class="login-input js-phone-input"-->
<!--                                   autocomplete="off">-->
<!--                        </div>-->

<!--                        <div *ngIf="!isApp" class="login__item">-->

<!--                            <input formControlName="remember_user"-->
<!--                                   id="remember_user"-->
<!--                                   [type]="'checkbox'"-->
<!--                                   class="login-checkbox"-->
<!--                                   (change)="onRememberUser($event)"-->
<!--                                   [checked]="!!phoneForm.controls.remember_user.value"-->
<!--                                   autocomplete="off">-->
<!--                            <label for="remember_user">Remember Username</label>-->
<!--                        </div>-->
<!--                        <div class="form-btn-submit">-->
<!--                            <button id="login_btn" class="button snake-btn is-notouched" [disabled]="!phoneForm.valid">-->
<!--                                Login-->
<!--                                <svg><rect></rect></svg>-->
<!--                            </button>-->

<!--                        </div>-->
<!--                    </form>-->

                    <div *ngIf="!isInStandaloneMode() && !isApp">
                        <!--            Android-->
                        <button *ngIf="androidInstallBtn" class="button snake-btn is-notouched" (click)="install()">
                            INSTALL
                            <svg><rect></rect></svg>
                        </button>

                        <!--            IOS-->
                        <button *ngIf="!IOSInstallBtn && isIos()" class="button snake-btn is-notouched" (click)="install()">
                            INSTALL
                            <svg><rect></rect></svg>
                        </button>

                        <div id="iosInstall" *ngIf="IOSInstallBtn" class="info">
                            <div class="info__pre">
                                <img src="../../../../assets/images/add-icon.svg" alt="Add to Home Screen icon">
                            </div>
                            <div class="info__body">
                                <p  id="infoText">{{ios_popup_text}}</p>
                                <p> tap (shared icon) <img src="../../../../assets/images/share-icon.svg" alt="Share icon"></p>
                                <p>and then Add to Home Screen.</p>

                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="isPinClassActive()" class="login-tab">
                    <div *ngIf="!isRegistrationClassActive()" class="login-content">
                        <p class="text t-light login__text">Enter the four digit pin sent to the number ending in -{{phoneLastFour}}.</p>
                    </div>
                    <div *ngIf="isRegistrationClassActive()" class="login-content">
                        <p class="text t-light login__text login__text--md">Enter the Code we just texted you.</p>
                    </div>
                    <form [formGroup]="pinForm" (ngSubmit)="onSubmit()" class="login-page__form">
                        <div *ngIf="!isApp" class="login__items" formArrayName="digits">
                            <input (keydown.enter)="onSubmit()" *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                                   mask="0"
                                   class="login-input-small" type="tel">
                        </div>

                        <div *ngIf="isApp" class="login__items" formArrayName="digits">
                            <input (keydown.enter)="onSubmit()"
                                   *ngFor="let field of pinForm.get('digits')['controls']; let i = index"
                                   #inputs [maxLength]="1" [formControlName]="i"
                                   [attr.data-order]="i"
                                   mask="0"
                                   class="login-input-small" type="tel">
                        </div>

                        <button *ngIf="!isRegistrationClassActive()" (click)="resendSms()" type="button" class="text t-light login-text login__resend">Resend</button>

                        <div *ngIf="!isRegistrationClassActive()" class="text-center form-btn-submit">
                            <button type="submit" id="verify" class="button snake-btn is-notouched" [disabled]="!pinForm.valid">
                                VERIFY
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>

                <div *ngIf="isRegistrationClassActive()" class="login__text">
                    Please click the Register button below to proceed<br/>
                    or click<br/>
                    The back arrow to correct your phone number

                </div>

                <div *ngIf="isRegistrationClassActive()" class="login-tab">
                    <div class="text-center form-btn-submit">
                        <button id="register" class="button snake-btn is-notouched"
                                (click)="onRegisterNewUser()"
                                [disabled]="!pinForm.valid">
                            Register
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="account-popup" *ngIf="accountEnteringPopup">
                <button (click)="goBackToLogin()" class="login__back">
                    <span class="visually-hidden">Back</span>
                </button>
                <div class="account-popup__title login__title">Please enter your account id</div>
                <input [mask]="'00-00-00-00-00-00'"
                       #userAccountId
                       placeholder="Account Id"
                       [type]="'tel'"
                       class="login-input"
                       (keyup.enter)="registerExistingUser(userAccountId.value)"
                       autocomplete="off">

                <button class="button snake-btn is-notouched" (click)="registerExistingUser(userAccountId.value)">
                    Confirm
                    <svg><rect></rect></svg>
                </button>
            </div>

            <app-terminal-connecting *ngIf="terminalConnectingPopup"
                                     (close)="goBackToLogin()"></app-terminal-connecting>


            <svg class="login__decor-bottom" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)" fill="#FFC549"/>
                <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)" fill="#FFC549"/>
                <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)" fill="#FFC549"/>
                <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)" fill="#FFC549"/>
                <line x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke="#FFC549" stroke-width="3"/>
            </svg>
        </div>
    </div>
</div>
<app-games-selection [showPopup]="showGamesSelection"
                     (gameSelected)="gameProviderWasChecked()"
                     [pointId]="pointId"
                     (close)="logout()"></app-games-selection>


