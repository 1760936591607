import * as i0 from "@angular/core";
var FullScreenService = /** @class */ (function () {
    function FullScreenService() {
    }
    FullScreenService.prototype.toggleFullScreen = function () {
        if (!this.isFullScreen()) {
            this.setFullScreen();
        }
        else {
            this.exitFullScreen();
        }
    };
    FullScreenService.prototype.setFullScreen = function () {
        var fsDocElem = document.documentElement;
        if (fsDocElem.requestFullscreen)
            fsDocElem.requestFullscreen();
        else if (fsDocElem.msRequestFullscreen)
            fsDocElem.msRequestFullscreen();
        else if (fsDocElem.mozRequestFullScreen)
            fsDocElem.mozRequestFullScreen();
        else if (fsDocElem.webkitRequestFullscreen)
            fsDocElem.webkitRequestFullscreen();
    };
    FullScreenService.prototype.exitFullScreen = function () {
        var fsDoc = document;
        if (fsDoc.exitFullscreen)
            fsDoc.exitFullscreen();
        else if (fsDoc.msExitFullscreen)
            fsDoc.msExitFullscreen();
        else if (fsDoc.mozCancelFullScreen)
            fsDoc.mozCancelFullScreen();
        else if (fsDoc.webkitExitFullscreen)
            fsDoc.webkitExitFullscreen();
    };
    FullScreenService.prototype.isFullScreen = function () {
        var fsDoc = document;
        return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
    };
    FullScreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FullScreenService_Factory() { return new FullScreenService(); }, token: FullScreenService, providedIn: "root" });
    return FullScreenService;
}());
export { FullScreenService };
