import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "../settingsLoader.service";
import { GAMES_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var GameService = /** @class */ (function (_super) {
    tslib_1.__extends(GameService, _super);
    function GameService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = GAMES_CONFIG;
        return _this;
    }
    GameService.prototype.getGameUrl = function (id) {
        this.showLoader();
        var post = this.http.post(this.config.getGameUrl, { id: id });
        return post;
    };
    GameService.prototype.buyGame = function (id) {
        this.showLoader();
        return this.http.post(this.config.buyGame, { id: id });
    };
    GameService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GameService_Factory() { return new GameService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: GameService, providedIn: "root" });
    return GameService;
}(SettingsLoaderService));
export { GameService };
