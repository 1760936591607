import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "./button/button.component";
import {HeaderComponent} from "./header/header.component";
import {PolicyComponent} from "./policy/policy.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {ActivityComponent} from "./activity/activity.component";
import {PopupComponent} from "./popup/popup.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {HeroComponent} from "./hero/hero.component";
import {KeyboardComponent} from "./keyboard/keyboard.component";
import {TradingComponent} from "./trading/trading.component";
import {PopupConfirmPayComponent} from "./popup-confirm-pay/popup-confirm-pay.component";
import {ScreenSaverComponent} from "./screen-saver/screen-saver.component";
import {HeroV2Component} from "./hero-v2/hero-v2.component";
import {ScreenSaverDotsComponent} from "./screen-saver-dots/screen-saver-dots.component";
import {KioskTableComponent} from "./kiosk-table/kiosk-table.component";
import {FinancialInfoItemComponent} from "./financial-info-item/financial-info-item.component";
import {DropdownComponent} from "./dropdown/dropdown.component";
import {LogoBgComponent} from "./logo-bg/logo-bg.component";
import {GroupBorderedComponent} from "./group-bordered/group-bordered.component";
import {SupportComponent} from "./support/support.component";
import {MatSelectModule} from "@angular/material";
import {DemoMaterialModule} from "../material.module";
import {CopyToBufferButtonComponent} from "./copy-to-buffer-button/copy-to-buffer-button.component";
import { PromoPopupComponent } from './promo/promo-popup/promo-popup.component';
import {VerifyCashappTagComponent} from "./verify-cashapp-tag/verify-cashapp-tag.component";
import {IframePopupComponent} from "./iframe-popup/iframe-popup.component";
import {EllipsisLoaderComponent} from "./loaders/ellipsis-loader/ellipsis-loader.component";
// import {DatepickerCustomHeaderExample, ExampleHeader} from "./datapicker/datapicker-header";
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material";
// import {DatepickerCustomHeaderExample, ExampleHeader} from "./datapicker-header/datapicker-header";
// import {DemoMaterialModule} from "../material.module";

export const APP_DATE_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: {month: 'numeric', year: 'numeric', day: 'numeric'},
        yearLabel: {year: 'numeric'},
        monthLabel: {month: 'short'}
    }
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};


@NgModule({
    declarations: [
        ButtonComponent,
        HeaderComponent,
        PolicyComponent,
        ActivityComponent,
        PopupComponent,
        LoaderComponent,
        HeroComponent,
        HeroV2Component,
        KeyboardComponent,
        TradingComponent,
        PopupConfirmPayComponent,
        ScreenSaverComponent,
        ScreenSaverDotsComponent,
        KioskTableComponent,
        FinancialInfoItemComponent,
        DropdownComponent,
        LogoBgComponent,
        GroupBorderedComponent,
        SupportComponent,
        CopyToBufferButtonComponent,
        PromoPopupComponent,
        VerifyCashappTagComponent,
        IframePopupComponent,
        EllipsisLoaderComponent

    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        DemoMaterialModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
    ],
  exports: [
    ButtonComponent,
    HeaderComponent,
    PolicyComponent,
    ActivityComponent,
    PopupComponent,
    LoaderComponent,
    HeroComponent,
    HeroV2Component,
    PopupConfirmPayComponent,
    ScreenSaverComponent,
    ScreenSaverDotsComponent,
    KioskTableComponent,
    KeyboardComponent,
    FinancialInfoItemComponent,
    DropdownComponent,
    LogoBgComponent,
    GroupBorderedComponent,
    SupportComponent,
    CopyToBufferButtonComponent,
    PromoPopupComponent,
    VerifyCashappTagComponent,
    IframePopupComponent,
    EllipsisLoaderComponent
  ]
})
export class SharedModule { }
