import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {PROJECT_MESSAGES} from "../../../config/project";
import {StylingService} from "../../../service/styling.service";
import {ScrollToService} from "../../../service/scrollTo.service";
import {KioskAccessorService} from "../../../service/kiosk/kiosk-accessor.service";

@Component({
    selector: 'app-usdc',
    templateUrl: './usdc.component.html',
    styleUrls: ['./usdc.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class USDCComponent implements OnInit {
    sellForm: FormGroup;
    addresses = [];
    checkedAddress = null;
    availableBalance = 0;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private router: Router,
                private stylingService: StylingService,
                private scrollToService: ScrollToService,
                private kioskAccessorService: KioskAccessorService,
                private existingUserService: ExistingUserService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getBalance();
            }
        });
    }

    ngOnInit() {
        this.getBalance();

        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
        });
    }

    getBalance(){
        this.prizesService.getUSDCoinsBalance().subscribe(data => {
            this.setRequestResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setRequestResult(data) {
        try {
            if (data && data.available_balance) {
                this.setBalance(data.available_balance);
            }

            if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setBalance(balance: number) {
        const balanceNumber = +balance;
        if (balanceNumber) {
            this.availableBalance = balanceNumber;
        }
    }

    getInputValue(field) {
        if (this.sellForm.controls[field]) {
            return this.sellForm.controls[field].value;
        }
    }

    onSubmit() {
        const amount = this.getInputValue('amount');

        this.popupComponent.showPopupAgreement({text: `You are requesting ${amount} USD Tokens. This is not cash. This crypto currency.`}, (status) => {
            if (status) {
                this.submit();
            }
        });
    }

    submit() {
        const amount = this.getInputValue('amount');

        if (amount) {
            this.prizesService.usdcCreatePayout(amount).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    checkSubmitResult(data) {
        if(data.status === true){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.sellForm.reset();

            this.getBalance();
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}

