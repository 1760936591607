import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_CONFIG} from "../config/api";
import {SettingsLoaderService} from "./settingsLoader.service";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class UserStatusService extends SettingsLoaderService {
    private config = USER_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getUserStatus() {
        this.showLoader();
        return this.http.post<any>(this.config.status, {});
    }

    getSettingsData() {
        this.showLoader();
        return this.http.post<any>(this.config.settings, {});
    }

    freeze() {
        this.showLoader();
        return this.http.post<any>(this.config.freeze, {});
    }

    activate() {
        this.showLoader();
        return this.http.post<any>(this.config.activate, {});
    }

    delete() {
        this.showLoader();
        return this.http.post<any>(this.config.delete, {});
    }
}
