import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {YETI_RESPONSES_CONFIG} from "../../../../config/api";
import {timer} from "rxjs";
import {mergeMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class YetiResponsesService {
  private config = YETI_RESPONSES_CONFIG;

  constructor(private http: HttpClient) {
  }

  initGame() {
    // return this.http.post<any>(this.config.initGame, {});
    return timer(500).pipe(
      mergeMap(() => this.http.post<any>(this.config.initGame, {}))
    );
  }

  play(sessionUuid, uuid) {
    // return this.http.post<any>(this.config.play, {roundId, elementId});

    return timer(2000).pipe(
      mergeMap(() => this.http.post<any>(this.config.play, {sessionUuid, uuid}))
    );
  }

  redeem() {
    // return this.http.post<any>(this.config.play, {roundId, elementId});

    return timer(500).pipe(
      mergeMap(() => this.http.post<any>(this.config.redeem, {}))
    );
  }
}
