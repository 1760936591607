import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CoinsTradeService} from "../../service/coins/coinsTrade.service";
import {LoaderService} from "../../service/loader.service";
import {CoinsInfoModel, CoinsItem} from "../../models/coins/coinsInfo.model";
import {PopupComponent} from "../popup/popup.component";
import {PROJECT_CONFIG, PROJECT_MESSAGES} from "../../config/project";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {RouterPaths} from "../../config/router.paths.model";
import {InvestmentsService} from "../../service/investments/investments.service";
import {KioskService} from "../../service/kiosk.service";
import {TRADE_ACTIONS} from "./trading.config";

@Component({
    selector: 'app-trading',
    templateUrl: './trading.component.html',
    styleUrls: ['./trading.component.scss', './trading--kiosk.component.scss']
})
export class TradingComponent implements OnInit {
    coinsData: CoinsInfoModel;
    getDataInterval;
    getDataIntervalTime = 1000 * 60 * 5;

    desktopMedia = 1280;
    mediaQueryCanGetData = window.matchMedia(`(min-width: ${this.desktopMedia}px)`);

    TRADE_ACTIONS = TRADE_ACTIONS;

    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;

    @Output() isData = new EventEmitter();
    @ViewChild('table', {static: false}) table: ElementRef;
    @HostBinding('class') isAppClass = '';

    constructor(private coinsTradeService: CoinsTradeService,
                private loaderService: LoaderService,
                private router: Router,
                private investmentsService: InvestmentsService,
                private authenticationService: AuthenticationService,
                private kioskService: KioskService,
                private popupComponent: PopupComponent) {
    }

    ngOnInit() {
        this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';

        this.checkIfNeedCoinsLoading();
        this.mediaQueryCanGetData.addListener(this.screenTest.bind(this));
    }

    getData() {
        if (!this.kioskService.isKiosk()) {
            this.coinsTradeService.getData().subscribe(data => {
                this.setData(data);
            }, (error) => {
                this.isData.emit(false);
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                // this.loaderService.hide();
            });
        } else {
            this.isData.emit(false);
        }
    }

    setData(data) {
        try {
            if (data.status === true) {
                this.coinsData = data.result;
                // this.coinsData = new CoinsInfoModel(data.result);

                if (data.result.length) {
                    this.isData.emit(true);
                    return;
                }

            } else if (data.status === false) {
                //this.popupComponent.showPopupError({text: data.message});
            } else {
                //this.loaderService.hideAndShowTryAgain();
            }
            this.isData.emit(false);
        } catch (e) {
            //this.loaderService.hideAndShowTryAgain();
            this.isData.emit(false);
        }
    }

    tradeHandler(item) {
        const currentPage = this.router.url;

        if (this.authenticationService.isUserLogged() && currentPage !== RouterPaths.rewards.form) {
            // this.router.navigate([RouterPaths.investments.main], {queryParams: {openTrade: 'update'}});
            // this.investmentsService.setCheckedItem(item);
            // setTimeout(() => {
            //     this.router.navigate([RouterPaths.investments.main], {queryParams: {openTrade: action}});
            // });
            this.router.navigate([RouterPaths.settings.funds], {queryParams: {cryptoId: item.id}});
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.askLoginForTrade,confirmButtonText:"ok"});
        }
    }

    getNameTo(el: CoinsItem): string {
        return el.getNameTo();
    }

    getShortNameTo(el: CoinsItem): string {
        return el.getShortNameTo();
    }

    getName(el: CoinsItem): string {
        return el.getShortNameTo();
    }

    getShortName(el: CoinsItem): string {
        return el.getShortName();
    }

    getIcon(el: CoinsItem): string {
        return el.getIcon();
    }

    getPrice(el: CoinsItem) {
        return el.getPrice();
    }

    getChange(el: CoinsItem) {
        let change = el.getChange();
        if (change) {
            change = Number(change).toFixed(2);
            const sign = this.checkPriceGrowing(el) ? '+' : '';
            return `${sign}${change}%`;
        } else {
            return '';
        }

    }

    getCap(el: CoinsItem) {
        return el.getCap();
    }

    checkPriceGrowing(el: CoinsItem): boolean {
        const change = el.getChange();
        return +change > 0;
    }

    ngOnDestroy() {
        clearInterval(this.getDataInterval);
        this.getDataInterval = 0;
    }

    screenTest(e) {
        if (e.matches) {
            if (!this.getDataInterval) {
                this.getData();
                this.getDataInterval = setInterval(() => {
                    this.getData();
                }, this.getDataIntervalTime);
            }
        } else {
            // if (this.getDataInterval) {
                clearInterval(this.getDataInterval);
                this.getDataInterval = 0;
            // }
        }
    }

    checkDesktop() {
        return window.innerWidth >= this.desktopMedia;
    }

    checkIfNeedCoinsLoading() {
        if (this.checkDesktop()) {
            this.getData();
            this.getDataInterval = setInterval(() => {
                this.getData();
            }, this.getDataIntervalTime);
        }
    }

    canScrollTop() {
        if (this.table && this.table.nativeElement) {
            return !!this.table.nativeElement.scrollTop;
        }
        return false;
    }

    canScrollBottom() {
        if (this.table && this.table.nativeElement) {
            const scrollHeight = this.table.nativeElement.scrollHeight;
            const scrollTop = this.table.nativeElement.scrollTop;
            const elementHeight = this.table.nativeElement.offsetHeight;

            return scrollHeight > elementHeight + scrollTop;
        }
        return false;
    }

    loadDefaultCurrencyIcon($event) {
        const el = $event.target;
        el.src = '../../../../assets/images/currencies/'+this.DEFAULT_CRYPTO_ICON;
    }

    scrollTable(top?) {
        const el = document.body.querySelector('.js-scroll');
        const elemTopScroll = el.scrollTop;
        const newTopScroll = top ? elemTopScroll - 200 : elemTopScroll + 200;

        el.scrollIntoView({behavior: 'smooth'});
        el.scroll({
            top: newTopScroll,
            behavior: 'smooth'
        });
    }
}
