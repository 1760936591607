import {Component, ElementRef, HostBinding, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {StylingService} from "../../service/styling.service";
import {InvestmentsMembershipModel} from "../../models/investments/investmentsMembership";
import {InvestmentsService} from "../../service/investments/investments.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {CRYPTO_AMOUNT_MASK, CRYPTO_SETTINGS} from "../../config/crypto_settings";
import {FitTextService} from "../../service/fitTextService.service";
import {PROJECT_CONFIG} from "../../config/project";
import {KeyboardService} from "../../service/keyboard.service";
import {ActivatedRoute, Route} from "@angular/router";
import {KioskAccessorService} from "../../service/kiosk/kiosk-accessor.service";
import {KioskService} from "../../service/kiosk.service";
import {KioskScrollService} from "../../service/kiosk-scroll.service";
import {CONFIRM_REQUEST, POPUP_TYPES, showPopupFormType} from "./investments-popup-form/investments-popup-form.config";
import {TRADE_ACTIONS} from "../../shared/trading/trading.config";
import {NFTService} from "../../service/nft/nft.service";
import {CopyToBufferService} from "../../service/dom/copyToBuffer.service";
import {NgxGlideComponent} from "ngx-glide";
import {NFT_POPUP_TYPE} from "./investments-nft-popup/investments-nft-popup.config";

@Component({
    selector: 'app-investments',
    templateUrl: './investments.component.html',
    styleUrls: ['./investments.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class InvestmentsComponent implements OnInit {
    headerData: InvestmentsMembershipModel = {
        totalAmount: 0,
        currentAmount: 0,
        step: 0,
        nftPercentToToken: 0,
        // usdcTotal: 0,
        // usdTotal: 0
    };

    CRYPTO_SETTINGS = CRYPTO_SETTINGS;
    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;

    showPopup: showPopupFormType = 'hide';
    // popupCurrency = 'usd';

    popupFormType;
    popupFormData;
    popupConfirmRequest;

    // tradingData = {
    //     items: [],
    //     loading: false
    // };
    investmentData = {
        items: [],
        loading: false
    };
    cryptoData = {
        items: [],
        loading: false
    };
    // usdcData = {
    //     items: [],
    //     loading: false
    // };

    nftData: {wallet: string, qrCode: string, contractAddress: string, library: Array<{image: string, uuid: string}>} = {
        wallet: '',
        qrCode: '',
        contractAddress: '',
        library: []
    };

    nftResponseKeys = {
        wallet: 'wallet',
        qrCode: 'walletQrcodeLink',
        contractAddress: 'contractAddress',
        library: 'library'
    };

    showNFTPopup = false;
    NFTPopupData;
    NFTLibraryMaxSlides = 3;

    investmentListResponseKeys = {
        id: 'id',
        fee: 'fee',
        size: 'size',
        icon: 'icon',
        name: 'name',
        rate: 'rate',
        available: 'available',
        interest: 'interest',
        invested: 'invested'
    };

    maxPageScrollPosition = 0;
    // openTradeOnInit = false;
    CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;

    @HostBinding('class') isAppClass = '';
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;
    @ViewChild('librarySlider', {static: false}) librarySlider: NgxGlideComponent;

    constructor(private stylingService: StylingService,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private fitTextService: FitTextService,
                private copyToBufferService: CopyToBufferService,
                private keyboardService: KeyboardService,
                private activateRoute: ActivatedRoute,
                private kioskAccessorService: KioskAccessorService,
                private kioskService: KioskService,
                private kioskScrollService: KioskScrollService,
                private investmentsService: InvestmentsService,
                private NFTService: NFTService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getData();
                // this.getUSDCList();
                this.getCrypto();
                // this.getTrading();
                // this.getInvestmentList();
            }
        });

        this.activateRoute.queryParams.subscribe(queryParams => {
            const openTradeOnInit = this.activateRoute.snapshot.queryParams['openTrade'];
            const item = this.investmentsService.checkedItem.value;
            this.onPopupFormClose();

            if (openTradeOnInit === TRADE_ACTIONS.buy && item) {
                this.openTradeBuyPopup(item);
            } else if (openTradeOnInit === TRADE_ACTIONS.sell && item) {
                this.openTradeSellPopup(item);
            }
        });
    }

    ngOnInit() {
        this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';
        this.getData();
        this.getCrypto();
        this.handleLibrarySlidesClick();
    }

    handleLibrarySlidesClick() {
        document.body.addEventListener( 'click', ( event ) => {
            if ((event.target as HTMLElement).classList.contains('js-library-slide')) {
                const id = (<HTMLElement>event.target).getAttribute('data-id');
                const slideInfo = this.nftData.library.find(el => el.uuid === id);

                if (slideInfo) {
                    this.onShowNFTPopup(slideInfo);
                }
            }
        } );
    }

    getData() {
        this.investmentsService.getInfo().subscribe(data => {
          this.setInfo(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setInfo(data) {
        try {
            this.headerData = {
                totalAmount: data.stepAmount ? data.stepAmount : 0,
                currentAmount: data.currentAmount ? data.currentAmount : 0,
                step: data.step ? data.step : 1,
                nftPercentToToken: data.nftPercentToToken ? data.nftPercentToToken : 0,
                // usdcTotal: data.usdc ? data.usdc : 0,
                // usdTotal: data.usd ? data.usd : 0
            }

            this.setNFTData(data);

        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setNFTData(data) {
        if (data.nft) {

            const nftData = data.nft;
            this.nftData.wallet = nftData[this.nftResponseKeys.wallet] ? nftData[this.nftResponseKeys.wallet] : '';
            this.nftData.qrCode = nftData[this.nftResponseKeys.qrCode] ? nftData[this.nftResponseKeys.qrCode] : '';
            this.nftData.contractAddress = nftData[this.nftResponseKeys.contractAddress] ? nftData[this.nftResponseKeys.contractAddress] : '';

            this.nftData.library = [];
            setTimeout(() => {
                this.nftData.library = nftData[this.nftResponseKeys.library] ? nftData[this.nftResponseKeys.library] : [];
            });
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    toggleDropdown(e) {
        const parent = e.target.parentElement;
        const hidden = e.target.offsetParent.classList.contains('is-hidden') ? true : false;
        document.body.querySelectorAll('.js-dropdown').forEach(el => {
            el.classList.add('is-hidden');
        });

        if (hidden) {
            e.target.offsetParent.classList.remove('is-hidden');

            if (parent.classList.contains('js-usdc-parent')) {
                //if (!this.usdcData.items.length) {
                //     this.getUSDCList();
                //}
            } else if (parent.classList.contains('js-crypto-parent')) {
                //if (!this.cryptoData.items.length) {
                    this.getCrypto();
                //}

            } else if (parent.classList.contains('js-trading-parent')) {
                //if (!this.tradingData.items.length) {
                //     this.getTrading();
               // }

            } else if (parent.classList.contains('js-investment-parent')) {
                // this.getInvestmentList();
            }
        }
    }

    // getUSDCList() {
    //     this.usdcData.loading = true;
    //     this.investmentsService.getUsd(1).subscribe(data => {
    //         this.getServerResponse(data, this.setUSDCList.bind(this));
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //         this.usdcData.loading = false;
    //     });
    // }
    //
    // setUSDCList(data) {
    //     this.usdcData.items = data.result;
    // }

    getCrypto() {
        this.cryptoData.loading = true;
        this.investmentsService.getCrypto().subscribe(data => {
            this.getServerResponse(data, this.setCrypto.bind(this));
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.cryptoData.loading = false;
        });
    }

    setCrypto(data) {
        this.cryptoData.items = data.result;
        //this.updateTextSize();
    }

    // getTrading() {
    //     this.tradingData.loading = true;
    //     this.investmentsService.getTrading().subscribe(data => {
    //         this.getServerResponse(data, this.setTrading.bind(this));
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.tradingData.loading = false;
    //     });
    // }

    // setTrading(data)  {
    //     this.tradingData.items = data.result;
    // }

    getInvestmentList() {
        this.investmentData.loading = true;
        this.investmentsService.getInvestment().subscribe(data => {
            this.getServerResponse(data, this.setInvestment.bind(this));
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.investmentData.loading = false;
        });
    }

    setInvestment(data)  {
        this.investmentData.items = data.result;
    }

    static getUSDCTotal(amount) {
        return +amount.toFixed(2);
    }

    getPayoutIcon(icon) {
        return icon;
    }

    getCryptoIcon(icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].icon;
        } else {
            return this.CRYPTO_SETTINGS.default.icon;
        }
    }

    getCryptoColor(icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].color;
        } else {
            return this.CRYPTO_SETTINGS.default.color;
        }
    }

    getDate(item) {
        return item.created;
    }

    getAmount(item) {
        return Math.abs(item.amount);
    }

    getAmountSign(item) {
        return item.amount > 0 ? '+' : '-';
    }

    openSendPopup(item) {
        this.showPopup = 'show';
        this.popupFormType = 'crypto';
        this.popupFormData = item;

        let $this = this;
        setTimeout(() => {
            (<HTMLElement>document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0]).focus();
            $this.keyboardService.setActiveInput();
        },0);
    }

    onPopupFormClose(type?) {
        this.showPopup = 'hide';
        const activeDropdown = document.querySelector('.dropdown:not(.is-hidden)');

        if (type && type.status) {
            this.getData();

            if (activeDropdown.classList.contains('js-usdc-parent')) {
                // this.getUSDCList();
            } else if (activeDropdown.classList.contains('js-crypto-parent')) {
                this.getCrypto();
            } else if (activeDropdown.classList.contains('js-trading-parent')) {
                // this.getTrading();
            } else if (activeDropdown.classList.contains('js-investment-parent')) {
                // this.getInvestmentList();
            }

        }
    }

    onPopupNFTClose(needReload) {
        this.showNFTPopup = false;
        if (needReload) {
            this.getData();
        }
    }

    // OLD with Pairs
    // openTradePopup(item) {
    //     const currencyIdFrom = item.currencyIdFrom;
    //     const currencyIdTo = item.currencyIdTo;
    //     const currencyFromName = item.nameFrom;
    //     const currencyToName = item.nameTo;
    //
    //     this.investmentsService.getTradingInfo(currencyIdFrom, currencyIdTo).subscribe(data => {
    //         this.getServerResponse(data, this.getTradeInfo.bind(this),
    //           {idFrom: currencyIdFrom, idTo: currencyIdTo, fromName: currencyFromName, toName: currencyToName});
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }

    getTradeInfo(data, additionalParams) {
        this.popupFormData = data.result;

        this.popupFormData.checkedCurrency = {
            from: additionalParams.idFrom,
            to: additionalParams.idTo,
            fromName: additionalParams.fromName,
            toName: additionalParams.toName
        };

        this.showTradePopup();
        let $this = this;
        setTimeout(() => {
            (<HTMLElement>document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0]).focus();
            $this.keyboardService.setActiveInput();
        },0);
    }

    showTradePopup() {
        this.showPopup = 'show';
        this.popupFormType = 'trade';
    }

    getServerResponse(data, successHandler, additionalParams?) {
        try {
            if (data.status === true) {
                successHandler(data, additionalParams);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    loadDefaultCurrencyIcon($event) {
        const el = $event.target;
        el.src = './assets/images/currencies/'+this.DEFAULT_CRYPTO_ICON;
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable('.page__body', top);
    }

    openTradeSellPopup(item) {
        const available = item.available;
        this.openTradePopup(item, available, POPUP_TYPES.sell, CONFIRM_REQUEST.tradeSell);
    }

    openTradeBuyPopup(item) {
        // const available = this.headerData.usdTotal;
        // // const available = 1031.98;
        //
        // this.openTradePopup(item, available, POPUP_TYPES.buy, CONFIRM_REQUEST.tradeBuy);
    }

    openInvestmentSellPopup(item) {
        const available = item.available;
        this.openTradePopup(item, available, POPUP_TYPES.sell, CONFIRM_REQUEST.investmentSell);
    }

    openInvestmentBuyPopup(item) {
        // const available = this.headerData.usdTotal;
        // this.openTradePopup(item, available, POPUP_TYPES.buy, CONFIRM_REQUEST.investmentBuy);
    }

    openTradePopup(item, available, type, confirmRequest?) {
        this.showPopup = 'show';
        this.popupFormType = type;
        this.popupFormData = item;
        this.popupConfirmRequest = confirmRequest;
        this.popupFormData.availableAmount = available;
        // this.popupFormData.availableAmount = 700000000;

        let $this = this;
        setTimeout(() => {
            (<HTMLElement>document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0]).focus();
            $this.keyboardService.setActiveInput();
        },0);
    }

    generateWallet() {
        this.NFTService.generateWallet().subscribe(data => {
            this.updateWallet(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    updateWallet(data) {
        try {
            if (data.status === true) {
                const result = {
                    nft: {
                        [this.nftResponseKeys.wallet]: data[this.nftResponseKeys.wallet],
                        [this.nftResponseKeys.qrCode]: data[this.nftResponseKeys.qrCode],
                        [this.nftResponseKeys.contractAddress]: data[this.nftResponseKeys.contractAddress]
                    }
                };
                this.setNFTData(result);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    copyToBuffer() {
        const value = this.nftData.wallet;
        const message = 'Wallet address has been successfully copied!';

        this.copyToBufferService.copyToBuffer(value, true, message);
    }

    onShowNFTPopup(slide) {
        console.log('gggg');
        console.log(slide);

        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.info,
            data: {
                element: slide
            }
        }
    }

    // openMintPopup() {
    //     this.showNFTPopup = true;
    //     this.NFTPopupData = {
    //         type: NFT_POPUP_TYPE.mint,
    //         data: {
    //             contractAddress: this.nftData.contractAddress
    //         }
    //     }
    // }
}
