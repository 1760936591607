<div id="pixi" class="pixi-container" [class.is-blur]="infoPopup.show" #pixi>
<!--    <button id="btn">Get Response</button>-->
<!--    <button id="play">Play</button>-->
</div>


<!--<app-info-popup-->
<!--        *ngIf="infoPopup.show"-->
<!--        [data]="infoPopup"-->
<!--        (close)="hideInfoPopup()"></app-info-popup>-->
