var FundsModel = /** @class */ (function () {
    function FundsModel() {
    }
    Object.defineProperty(FundsModel.prototype, "status", {
        get: function () {
            return this._status;
        },
        set: function (value) {
            this._status = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FundsModel.prototype, "result", {
        get: function () {
            return this._result;
        },
        set: function (value) {
            this._result = value;
        },
        enumerable: true,
        configurable: true
    });
    return FundsModel;
}());
export { FundsModel };
var FundsResultItem = /** @class */ (function () {
    function FundsResultItem(data) {
        this._email = data.email;
        this._created = data.created;
        this._id = data.id;
        this._user_id = data.user_id;
        this._payment_method = data.payment_method;
    }
    Object.defineProperty(FundsResultItem.prototype, "email", {
        get: function () {
            return this._email;
        },
        set: function (value) {
            this._email = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FundsResultItem.prototype, "created", {
        get: function () {
            return this._created;
        },
        set: function (value) {
            this._created = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FundsResultItem.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FundsResultItem.prototype, "user_id", {
        get: function () {
            return this._user_id;
        },
        set: function (value) {
            this._user_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FundsResultItem.prototype, "payment_method", {
        get: function () {
            return this._payment_method;
        },
        set: function (value) {
            this._payment_method = value;
        },
        enumerable: true,
        configurable: true
    });
    return FundsResultItem;
}());
export { FundsResultItem };
