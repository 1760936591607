import * as tslib_1 from "tslib";
import { MASS_SMS_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var MassSmsService = /** @class */ (function (_super) {
    tslib_1.__extends(MassSmsService, _super);
    function MassSmsService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = MASS_SMS_CONFIG;
        return _this;
    }
    MassSmsService.prototype.submitSms = function (text) {
        this.loaderService.show();
        return this.http.post(this.config.submit, { text: text });
    };
    MassSmsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MassSmsService_Factory() { return new MassSmsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: MassSmsService, providedIn: "root" });
    return MassSmsService;
}(SettingsLoaderService));
export { MassSmsService };
