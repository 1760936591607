import { BehaviorSubject } from 'rxjs';
import { PAYPAL_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaypalService = /** @class */ (function () {
    function PaypalService(http) {
        this.http = http;
        this.visible = new BehaviorSubject(null);
        this.config = PAYPAL_CONFIG;
    }
    PaypalService.prototype.paymentSuccess = function (paymentId, payerId) {
        return this.http.post(this.config.paymentSuccess, { paymentId: paymentId, payerId: payerId });
    };
    PaypalService.prototype.paymentUnsuccess = function (paymentId) {
        return this.http.post(this.config.paymentUnsuccess, { paymentId: paymentId });
    };
    PaypalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaypalService_Factory() { return new PaypalService(i0.ɵɵinject(i1.HttpClient)); }, token: PaypalService, providedIn: "root" });
    return PaypalService;
}());
export { PaypalService };
