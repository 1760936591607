import { OnInit } from '@angular/core';
var ThermometerLoaderComponent = /** @class */ (function () {
    function ThermometerLoaderComponent() {
        this.isAnimation = false;
    }
    Object.defineProperty(ThermometerLoaderComponent.prototype, "progressValue", {
        get: function () {
            return this._progressValue;
        },
        set: function (value) {
            this._progressValue = value;
            if (value !== null) {
                this.updateState(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ThermometerLoaderComponent.prototype.ngOnInit = function () {
        // document.addEventListener('click', () => {
        //     console.log('click');
        //     if (!this.isAnimation) {
        //         this.isAnimation = true;
        //         let delta = .5;
        //
        //         const progressAnimation = () => {
        //             delta *= 1.01;
        //             const top = Number.parseInt(document.getElementById('therm-body-mercury').style.top);
        //             const height = Number.parseInt(document.getElementById('therm-body-mercury').style.height);
        //
        //             document.getElementById('therm-body-mercury').style.height = String(Math.round( height + delta)) + 'px';
        //             document.getElementById('therm-body-mercury').style.top = String(Math.round( top - delta)) + 'px';
        //             document.getElementById('therm-tooltip').style.top = String(Math.round( top - 4 - delta)) + 'px';
        //             document.getElementById('therm-tooltip').querySelector('p').innerHTML = String(Math.round((176 - top + 6) / 176 * 100)) + '%';
        //
        //             if (top > 6) {
        //                 this.requestAnimationId = requestAnimationFrame(progressAnimation);
        //             } else {
        //                 cancelAnimationFrame(this.requestAnimationId);
        //                 //this.isAnimation = false;
        //             }
        //         }
        //         this.requestAnimationId = requestAnimationFrame(progressAnimation);
        //     }
        //
        // });
        this.updateState(0);
    };
    ThermometerLoaderComponent.prototype.updateState = function (value) {
        var maxHeight = 172;
        var styleValue = String(Math.round(maxHeight * value / 100)) + 'px';
        document.getElementById('therm-body-mercury').style.height = styleValue;
        document.getElementById('therm-tooltip').style.bottom = styleValue;
        document.getElementById('therm-tooltip').querySelector('p').innerHTML = String(Math.round(value)) + '%';
    };
    return ThermometerLoaderComponent;
}());
export { ThermometerLoaderComponent };
