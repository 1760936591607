import { APP_VERSION_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../partner.service";
import * as i4 from "../kiosk/kiosk-accessor.service";
import * as i5 from "../../shared/popup/popup.component";
var AppVersionrService = /** @class */ (function () {
    function AppVersionrService(http, router, activatedRoute, partnerService, kioskAccessorService, popupComponent) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.partnerService = partnerService;
        this.kioskAccessorService = kioskAccessorService;
        this.popupComponent = popupComponent;
        this.config = APP_VERSION_CONFIG;
        this.isApp = +localStorage.getItem('isApp');
        this.versionCount = 0;
        this.versionPopupShow = false;
    }
    AppVersionrService.prototype.setVersionCount = function (count) {
        localStorage.setItem('versionCount', count);
    };
    AppVersionrService.prototype.getVersionCount = function () {
        if (localStorage.getItem('versionCount')) {
            return parseInt(localStorage.getItem('versionCount'));
        }
        return 0;
    };
    AppVersionrService.prototype.checkVersion = function () {
        var _this = this;
        console.log("checkVersion");
        this.getVersion().subscribe(function (data) {
            var version = _this.getMeta('app-version');
            if (version != data.result) {
                var versionCount = _this.getVersionCount();
                ++versionCount;
                _this.setVersionCount(versionCount);
                if (_this.isApp) {
                    _this.upgradeKioskAppVersion();
                    //this.showUpgradePopup();
                }
                else {
                    _this.showUpgradePopup();
                }
            }
            else {
                _this.setVersionCount(0);
            }
        });
        var $this = this;
        setTimeout(function () {
            $this.checkVersion();
        }, 5 * 60 * 1000);
    };
    AppVersionrService.prototype.upgradeKioskAppVersion = function () {
        console.log("upgradeKioskAppVersion");
        try {
            var inputSmall = document.querySelectorAll('.login-input-small');
            var countInputSmall = inputSmall.length;
            var homeUrl = '/';
            var mascotURL = '/mascot';
            var isMascotInitialPopup = document.querySelector('.popup-login .js-phone-input');
            var currentUrl = this.router.url.split('?')[0];
            //if (currentUrl === homeUrl && !countInputSmall || currentUrl === mascotURL && isMascotInitialPopup) {
            var $this_1 = this;
            setTimeout(function () {
                $this_1.upgradeApp();
            }, 10000);
            //} else {
            //return;
            //}
        }
        catch (e) {
            this.upgradeApp();
        }
    };
    AppVersionrService.prototype.showUpgradePopup = function () {
        var _this = this;
        if (!this.versionPopupShow) {
            this.versionPopupShow = true;
            if (this.getVersionCount() > 1) {
                this.popupComponent.showPopupAgreement({
                    text: 'Please restart the application',
                    closeBtn: false,
                    showConfirmButton: false
                }, function () {
                });
            }
            else {
                this.popupComponent.showPopupAgreement({
                    text: this.partnerService.getName() + ' version on your device is old, please update it now',
                    closeBtn: false,
                    confirmButtonText: 'Upgrade<svg><rect></rect></svg>'
                }, function () {
                    _this.upgradeApp();
                });
            }
        }
    };
    AppVersionrService.prototype.getVersion = function () {
        return this.http.post(this.config.version, {});
    };
    AppVersionrService.prototype.getMeta = function (metaName) {
        var metas = document.getElementsByTagName('meta');
        for (var i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === metaName) {
                return metas[i].getAttribute('content');
            }
        }
        return '';
    };
    AppVersionrService.prototype.upgradeApp = function () {
        var mascotURL = '/mascot';
        var currentUrl = this.router.url.split('?')[0];
        var time = +new Date();
        //window.applicationCache.swapCache();
        window.location.href = window.location.protocol + '//' + this.getDomain(window.location.href, true) + '?hash=' + time;
        // if (currentUrl === mascotURL) {
        //     const currentUrlFull = window.location.href;
        //     const queryParams = currentUrlFull.split('?')[1] ? currentUrlFull.split('?')[1] : null;
        //     const queryParamsList = queryParams ? queryParams.split('&') : [];
        //     const newQueryParamsList = [];
        //
        //     queryParamsList.forEach(param => {
        //         if (param.indexOf('hash') < 0) {
        //             newQueryParamsList.push(param);
        //         }
        //     });
        //     const newQueryParams = newQueryParamsList.length ? '&' + newQueryParamsList.join('&') : '';
        //
        //     window.location.href = window.location.protocol+'//'+this.getDomain(window.location.href,true)+ '/mascot' + '?hash='+time + newQueryParams;
        // } else {
        //     window.location.href = window.location.protocol+'//'+this.getDomain(window.location.href,true)+'?hash='+time;
        // }
    };
    AppVersionrService.prototype.getDomain = function (url, subdomain) {
        subdomain = subdomain || false;
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
        if (!subdomain) {
            url = url.split('.');
            url = url.slice(url.length - 2).join('.');
        }
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
        return url;
    };
    AppVersionrService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppVersionrService_Factory() { return new AppVersionrService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.PartnerService), i0.ɵɵinject(i4.KioskAccessorService), i0.ɵɵinject(i5.PopupComponent)); }, token: AppVersionrService, providedIn: "root" });
    return AppVersionrService;
}());
export { AppVersionrService };
