import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {LoaderService} from "../../../service/loader.service";
import {PixiScaleToWindowService} from "../../../service/pixi/pixiScaleToWindow.service";
import {YetiLoaderService} from "./services/yetiLoader.service";
import {YetiRulesService} from "./services/yetiRules.service";
import {YetiCommonService} from "./services/yetiCommon.service";
import {YetiGameService} from "./services/yetiGame.service";
import {YetiResponsesService} from "./services/yetiResponses.service";
declare const PIXI: typeof import('pixi.js');

@Component({
    selector: 'app-yeti',
    templateUrl: './yeti.component.html',
    styleUrls: ['./yeti.component.scss']
})

export class YetiComponent implements OnInit {
    @ViewChild('pixi', {static: false}) pixi;

    renderer;
    canvas: HTMLCanvasElement;
    stage;
    loader;
    resources;
    canvasScaleValue;

    setInfoBtn;

    gameMainLoaderProgress = null;
    gameMainLoaderTimeoutFramesMilSec = 50;
    gameMainLoaderTimeoutOffset = 1;
    isMainGameLoaderShown = true;
    mainGameLoaderInterval;

    infoPopup = {
        show: false,
        message: '',
        callback: null
    };
    constructor(private yetiRulesService: YetiRulesService,
                private yetiGameService: YetiGameService,
                private yetiLoaderService: YetiLoaderService,
                private yetiCommonService: YetiCommonService,
                private yetiResponsesService: YetiResponsesService,
                private pixiScaleToWindowService: PixiScaleToWindowService) {

        this.yetiCommonService.showRules.subscribe(val => {
            if (val) {
                this.hideInfoBtn();

                this.yetiRulesService.rulesCommonContainer.visible = true;
                if (this.yetiCommonService.isGameStart) {
                    this.yetiRulesService.closeRulesBtn.visible = true;
                    this.yetiRulesService.activateGamePlayBtn(false);
                    this.yetiGameService.disableClickEvents();
                } else {
                    this.yetiRulesService.activateGamePlayBtn(true);
                }

            } else {
                this.yetiGameService.enableClickEvents();
                this.yetiRulesService.rulesCommonContainer.visible = false;
                this.yetiRulesService.setFirstSlideActive();
                this.showInfoBtn();
            }
            setTimeout(() => {
                this.renderer.render(this.stage);
            });
        });

        this.yetiCommonService.startGame.subscribe(val => {
            if (val) {
                this.yetiGameService.startGame();
            }
        });

        this.yetiCommonService.restartGame.subscribe(val => {
            if (val) {
                this.yetiCommonService.showMainLoader.emit({show: true, minMilliSec: 0});
                this.yetiResponsesService.redeem().subscribe(data => {
                    try {
                        if (data) {
                            if (data.status) {
                                this.yetiCommonService.showInfoPopup.emit({show: true, message: data.message, callback: this.yetiGameService.closeGame.bind(this.yetiGameService)});
                            } else {
                                this.yetiCommonService.showInfoPopup.emit({show: true, message: data.message});
                            }

                        } else {
                            this.yetiCommonService.showInfoPopup.emit({show: true});
                        }
                    } catch (e) {
                        this.yetiCommonService.showInfoPopup.emit({show: true});
                    }
                }, () => {
                    this.yetiCommonService.showInfoPopup.emit({show: true});
                }, () => {
                    this.yetiCommonService.showMainLoader.emit({show: false});
                });
            }
        });

        this.yetiCommonService.showMainLoader.subscribe(val => {
            if (val.show) {
                this.gameMainLoaderProgress = 0;
                this.isMainGameLoaderShown = true;
                const time = val.minMilliSec / 6;

                this.mainGameLoaderInterval = setInterval(() => {
                    if (this.gameMainLoaderProgress < 80) {
                        this.gameMainLoaderProgress += 80 / 6;
                    }
                }, time);
            } else {
                this.gameMainLoaderProgress = 100;
                clearInterval(this.mainGameLoaderInterval);
                setTimeout(() => {
                    this.isMainGameLoaderShown = false;
                }, 200);
            }
        });

        this.yetiCommonService.showInfoPopup.subscribe(val => {
            if (val.show) {
                const message = val.message ? val.message : '';
                const callback = val.callback ? val.callback : '';
                this.showInfoPopup(message, callback);
            } else {
                this.hideInfoPopup();
            }
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.createRenderer();
            this.scaleGameToWindow();
            this.loadGameImages();
        });
    }

    createRenderer() {
        this.renderer = PIXI.autoDetectRenderer(1920, 1080, {transparent: true});
        this.renderer.view.backgroundColor = '#005fae';
        this.canvas = this.renderer.view;
        this.stage = new PIXI.Container();

        this.pixi.nativeElement.appendChild(this.canvas);
    }

    scaleGameToWindow() {
        this.canvasScaleValue = this.pixiScaleToWindowService.scaleToWindow(this.canvas, '#005fae');

        window.onresize = () => {
            this.canvasScaleValue = this.pixiScaleToWindowService.scaleToWindow(this.canvas, '#005fae');
        }
    }

    loadGameImages() {
        this.yetiLoaderService.initLoader();
        setTimeout(() => {
            this.loader = this.yetiLoaderService.loader;
            this.resources = this.yetiLoaderService.resources;
            this.loader.on('progress', this.showGameMainLoader.bind(this));
            this.loader.load();
        });
    }

    showGameMainLoader(loader) {
       this.updateGameMainLoaderProgressWithTimeOffset(Math.round(loader.progress));
    }

    updateGameMainLoaderProgressWithTimeOffset(value) {
        setTimeout(() => {
            this.gameMainLoaderProgress = value;

            if (this.gameMainLoaderProgress === 100) {
                this.initGame();
                setTimeout(() => {
                    this.isMainGameLoaderShown = false;
                }, this.gameMainLoaderTimeoutFramesMilSec);
            }
        }, this.gameMainLoaderTimeoutFramesMilSec * this.gameMainLoaderTimeoutOffset);
        this.gameMainLoaderTimeoutOffset += 1;

    }

    initGame() {
        this.setBackground();
        this.setYeti();
        this.setTitle();
        this.setInfo();
        this.setGame();
        this.setRules();
        this.renderer.render(this.stage);
    }

    setGame() {
        this.yetiGameService.setGame(this.stage, this.resources, this.renderer);
    }

    setBackground() {
        const background = new PIXI.Sprite(this.resources.gameBg.texture);
        this.addToScene(background);
    }

    setYeti() {
        const img = new PIXI.Sprite(this.resources.yetiImg.texture);
        const scale = (this.canvas.height / img.height) * .93;
        const posX = this.canvas.width * .23;
        const posY = this.canvas.height / 2;

        this.scaleImage(img, scale);
        this.centerPicture(img);
        this.setImagePosition(img, posX, posY);
        this.addToScene(img);
    }

    setInfo() {
        this.setInfoBtn = new PIXI.Sprite(this.resources.info.texture);
        const imgMarkupHeight = 115;
        const scale = imgMarkupHeight / this.setInfoBtn.height;

        const posX = 32;
        const posY = this.canvas.height - 28;

        this.setInfoBtn.anchor.y = 1;

        this.scaleImage(this.setInfoBtn, scale);
        this.setImagePosition(this.setInfoBtn, posX, posY);
        this.yetiCommonService.addButtonBehavior(this.setInfoBtn);
        this.setInfoBtn.on('pointerdown', (event) => {
            this.yetiCommonService.showRules.emit(true);
        });

        this.addToScene(this.setInfoBtn);
        this.hideInfoBtn();
    }

    setTitle() {
        const title1 = new PIXI.Sprite(this.resources.titleYeti.texture);
        const title1MarkupHeight = 117;
        const title1Scale = title1MarkupHeight / title1.height;
        const title1PosX = 648;
        const title1PosY = 25;

        const title2 = new PIXI.Sprite(this.resources.titleSnowdrift.texture);
        const title2MarkupHeight = 110;
        const title2Scale = title2MarkupHeight / title2.height;
        const title2PosX = 1040;
        const title2PosY = 20;

        this.scaleImage(title1, title1Scale);
        this.scaleImage(title2, title2Scale);
        this.setImagePosition(title1, title1PosX, title1PosY);
        this.setImagePosition(title2, title2PosX, title2PosY);
        this.addToScene(title1);
        this.addToScene(title2);
    }

    setRules() {
        this.yetiRulesService.setRules(this.stage, this.resources, this.renderer);
    }

    centerPicture(img) {
        img.anchor.x = 0.5;
        img.anchor.y = 0.5;
    }

    scaleImage(img, scale) {
        img.scale.y = scale;
        img.scale.x = scale;
    }

    setImagePosition(img, x, y) {
        img.position.y = y;
        img.position.x = x;
    }

    addToScene(el) {
        this.stage.addChild(el);
    }

    onAssetsLoaded() {
        const style = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 32,
            fontStyle: 'italic',
            fontWeight: '',
            fill: ['#ffffff', '#2352cc'], // gradient
            stroke: '#4a1850',
            strokeThickness: 5,
            dropShadow: true,
            dropShadowColor: '#000000',
            dropShadowBlur: 4,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 4,
            wordWrap: true,
            wordWrapWidth: 440,
        });

        const basicText = new PIXI.Text('Some text here', style);
        basicText.x = 5;
        basicText.y = 5;
        this.stage.addChild(basicText);
    }

    ngOnDestroy() {
        clearInterval(this.mainGameLoaderInterval);
    }

    getSpinResult() {
        // this.spinnerService.init().subscribe(data => {
        //     this.setSpinnerResult(data);
        // }, () => {
        //     this.onSpin();
        //     this.loaderService.hideAndShowTryAgain('bordered');
        // }, () => {
        //     this.loaderService.hide();
        // });
    }

    setSpinnerResult(responce) {
        // try {
        //     if (responce.status === true) {
        //         this.onSpin(responce.result);
        //         if (responce.message != '') {
        //             this.popupComponent.showPopupError({text: responce.message}, 'btn-ok');
        //         }
        //         this.mascotService.updateBalance();
        //     } else if (responce.status === false) {
        //         this.onSpin();
        //         this.popupComponent.showPopupError({text: responce.message}, 'btn-ok');
        //     } else {
        //         this.onSpin();
        //         this.loaderService.hideAndShowTryAgain('bordered');
        //         // window.location.reload();
        //     }
        // } catch (e) {
        //     this.onSpin();
        //     this.loaderService.hideAndShowTryAgain('bordered');
        //     // window.location.reload();
        // }
    }

    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
    }

    showInfoPopup(message?, callback?) {
        this.infoPopup.show = true;
        if (message) {
            this.infoPopup.message = message;
        }

        if (callback) {
            this.infoPopup.callback = callback;
        }
    }

    hideInfoPopup() {
        this.infoPopup.show = false;
        this.infoPopup.message = '';
    }

    showInfoBtn() {
        this.setInfoBtn.visible = true;
    }

    hideInfoBtn() {
        this.setInfoBtn.visible = false;
    }
}
