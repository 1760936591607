/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider-test.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-swiper-wrapper/ngx-swiper-wrapper.ngfactory";
import * as i3 from "ngx-swiper-wrapper";
import * as i4 from "@angular/common";
import * as i5 from "./slider-test.component";
import * as i6 from "../../service/mascot/mascot.service";
import * as i7 from "../../service/loader.service";
var styles_SliderTestComponent = [i0.styles];
var RenderType_SliderTestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderTestComponent, data: {} });
export { RenderType_SliderTestComponent as RenderType_SliderTestComponent };
function View_SliderTestComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "swiper-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderTestComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "swiper-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderTestComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "swiper-slide swiper-slide--auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderTestComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "swiper-slide swiper-slide--auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image360; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderTestComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "swiper-slide swiper-slide--auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image360; _ck(_v, 2, 0, currVal_0); }); }
export function View_SliderTestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "swiper", [["class", "swiper-container"], ["fxFlex", "auto"]], null, [[null, "indexChange"], [null, "swiperTransitionStart"], [null, "swiperTransitionEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (_co.onIndexChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperTransitionStart" === en)) {
        var pd_1 = (_co.onSwiperEvent("transitionStart") !== false);
        ad = (pd_1 && ad);
    } if (("swiperTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperEvent("transitionEnd") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SwiperComponent_0, i2.RenderType_SwiperComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, { indexChange: "indexChange", S_TRANSITIONEND: "swiperTransitionEnd", S_TRANSITIONSTART: "swiperTransitionStart" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTestComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "swiper", [["class", "swiper-container"], ["fxFlex", "auto"]], null, [[null, "indexChange"], [null, "swiperTransitionStart"], [null, "swiperTransitionEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (_co.onIndexChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperTransitionStart" === en)) {
        var pd_1 = (_co.onSwiperEvent("transitionStart") !== false);
        ad = (pd_1 && ad);
    } if (("swiperTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperEvent("transitionEnd") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SwiperComponent_0, i2.RenderType_SwiperComponent)), i1.ɵdid(5, 4374528, null, 0, i3.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, { indexChange: "indexChange", S_TRANSITIONEND: "swiperTransitionEnd", S_TRANSITIONSTART: "swiperTransitionStart" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTestComponent_2)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "swiper", [["class", "swiper-container"], ["fxFlex", "auto"]], null, [[null, "indexChange"], [null, "swiperTransitionStart"], [null, "swiperTransitionEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (_co.onIndexChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperTransitionStart" === en)) {
        var pd_1 = (_co.onSwiperEvent("transitionStart") !== false);
        ad = (pd_1 && ad);
    } if (("swiperTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperEvent("transitionEnd") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SwiperComponent_0, i2.RenderType_SwiperComponent)), i1.ɵdid(9, 4374528, null, 0, i3.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, { indexChange: "indexChange", S_TRANSITIONEND: "swiperTransitionEnd", S_TRANSITIONSTART: "swiperTransitionStart" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTestComponent_3)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "swiper", [["class", "swiper-container"], ["fxFlex", "auto"]], null, [[null, "indexChange"], [null, "swiperTransitionStart"], [null, "swiperTransitionEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (_co.onIndexChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperTransitionStart" === en)) {
        var pd_1 = (_co.onSwiperEvent("transitionStart") !== false);
        ad = (pd_1 && ad);
    } if (("swiperTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperEvent("transitionEnd") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SwiperComponent_0, i2.RenderType_SwiperComponent)), i1.ɵdid(13, 4374528, null, 0, i3.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, { indexChange: "indexChange", S_TRANSITIONEND: "swiperTransitionEnd", S_TRANSITIONSTART: "swiperTransitionStart" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTestComponent_4)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "swiper", [["class", "swiper-container swiper-container--looped"], ["fxFlex", "auto"]], null, [[null, "indexChange"], [null, "swiperTransitionStart"], [null, "swiperTransitionEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (_co.onIndexChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperTransitionStart" === en)) {
        var pd_1 = (_co.onSwiperEvent("transitionStart") !== false);
        ad = (pd_1 && ad);
    } if (("swiperTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperEvent("transitionEnd") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SwiperComponent_0, i2.RenderType_SwiperComponent)), i1.ɵdid(17, 4374528, null, 0, i3.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, { indexChange: "indexChange", S_TRANSITIONEND: "swiperTransitionEnd", S_TRANSITIONSTART: "swiperTransitionStart" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTestComponent_5)), i1.ɵdid(19, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config4; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.slides; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.config; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.slides; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.config2; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.slides; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.config2; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.slidesDynamic; _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.config3; _ck(_v, 17, 0, currVal_8); var currVal_9 = _co.slidesDynamic; _ck(_v, 19, 0, currVal_9); }, null); }
export function View_SliderTestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider-test", [], null, null, null, View_SliderTestComponent_0, RenderType_SliderTestComponent)), i1.ɵdid(1, 114688, null, 0, i5.SliderTestComponent, [i6.MascotService, i7.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderTestComponentNgFactory = i1.ɵccf("app-slider-test", i5.SliderTestComponent, View_SliderTestComponent_Host_0, {}, {}, []);
export { SliderTestComponentNgFactory as SliderTestComponentNgFactory };
