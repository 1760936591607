import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "../settingsLoader.service";
import { USER_BALANCE_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var UserBalanceService = /** @class */ (function (_super) {
    tslib_1.__extends(UserBalanceService, _super);
    function UserBalanceService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = USER_BALANCE_CONFIG;
        return _this;
    }
    UserBalanceService.prototype.getBalance = function () {
        this.showLoader();
        return this.http.post(this.config.balance, {});
    };
    UserBalanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserBalanceService_Factory() { return new UserBalanceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: UserBalanceService, providedIn: "root" });
    return UserBalanceService;
}(SettingsLoaderService));
export { UserBalanceService };
