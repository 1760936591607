import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {KioskService} from "../../service/kiosk.service";
import {HERO_TEXT} from "../../config/project";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
    showCoins = false;
    isRiverPay: boolean;

    constructor(private kioskService: KioskService,
                private partnerService: PartnerService,) {

        this.isRiverPay = this.partnerService.isRiverPayTerminal();
    }

    ngOnInit() {}

    isKiosk() {
        return this.kioskService.isKiosk();
    }

    isCoins(value) {
        this.showCoins = value;
    }

    getHeroText() {
        if (this.isRiverPay) {
            return HERO_TEXT.riverpay;
        } else {
            return HERO_TEXT.default;
        }
    }
}
