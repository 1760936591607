import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {PAYPAL_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class PaypalService extends SettingsLoaderService {
    private config = PAYPAL_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getAccounts() {
        this.showLoader();
        return this.http.post<any>(this.config.accounts, {});
    }

    find(id) {
        this.showLoader();
        return this.http.post<any>(this.config.find, {id:id});
    }

    deleteAccount(id) {
        this.showLoader();
        return this.http.post<any>(this.config.delete, {id});
    }

    addAccount(email) {
        this.showLoader();
        return this.http.post<any>(this.config.add, {email});
    }

    editAccount(id, email) {
        this.showLoader();
        return this.http.post<any>(this.config.edit, {id, email});
    }
}
