/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider-test4.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-glide/ngx-glide.ngfactory";
import * as i3 from "ngx-glide";
import * as i4 from "@angular/common";
import * as i5 from "./slider-test4.component";
var styles_SliderTest4Component = [i0.styles];
var RenderType_SliderTest4Component = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderTest4Component, data: {} });
export { RenderType_SliderTest4Component as RenderType_SliderTest4Component };
function View_SliderTest4Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["width", "100%"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.img, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_SliderTest4Component_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ngxGlide: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ngx-glide", [["type", "carousel"]], null, null, null, i2.View_NgxGlideComponent_0, i2.RenderType_NgxGlideComponent)), i1.ɵdid(2, 4898816, [[1, 4]], 0, i3.NgxGlideComponent, [i1.ChangeDetectorRef, i1.PLATFORM_ID], { showArrows: [0, "showArrows"], showBullets: [1, "showBullets"], type: [2, "type"], perView: [3, "perView"], gap: [4, "gap"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTest4Component_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; var currVal_2 = "carousel"; var currVal_3 = 6; var currVal_4 = 0; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.slides; _ck(_v, 4, 0, currVal_5); }, null); }
export function View_SliderTest4Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider-test4", [], null, null, null, View_SliderTest4Component_0, RenderType_SliderTest4Component)), i1.ɵdid(1, 114688, null, 0, i5.SliderTest4Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderTest4ComponentNgFactory = i1.ɵccf("app-slider-test4", i5.SliderTest4Component, View_SliderTest4Component_Host_0, {}, {}, []);
export { SliderTest4ComponentNgFactory as SliderTest4ComponentNgFactory };
