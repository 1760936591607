import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../animations/router.animations";
import {getTermsAndConditions} from "./terms-and-conditions.text";
import {TERMS_AND_CONDITIONS_CONTENT_TYPES} from "./terms-and-conditions.config";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class TermsAndConditionsComponent implements OnInit {
    content = {
        title:'',
        subtitle:'',
        parts:[],
    };
    TERMS_AND_CONDITIONS_CONTENT_TYPES = TERMS_AND_CONDITIONS_CONTENT_TYPES;

    constructor(private partnerService: PartnerService) {}


    ngOnInit() {
        this.content = getTermsAndConditions(this.partnerService.getBusinessName(), this.partnerService.getPartnerDomain());
    }

}
