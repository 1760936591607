import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  userAgent = navigator.userAgent.toLowerCase();
  constructor() {}

  isAndroid(): boolean {
    return this.userAgent.indexOf("android") > -1;
  }

  isIphone() {
    return this.userAgent.toLowerCase().indexOf("iphone") > -1;
  }

  isIpad() {
    return this.userAgent.toLowerCase().indexOf("ipad") > -1;
  }
}
