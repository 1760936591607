import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {PartnerComponent} from "./partners/partner.component";

import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {RewardsComponent} from "./rewards/rewards.component";
import {PrizesComponent} from "./prizes/prizes.component";
import {PaypalComponent} from "./prizes/paypal/paypal.component";
import {MailCheckComponent} from "./prizes/mail-check/mail-check.component";
import {NewEmailComponent} from "./prizes/paypal/new-email/new-email.component";
import {NewAddressComponent} from "./prizes/mail-check/new-address/new-address.component";
import {SettingsComponent} from "./settings/settings.component";
import {CardComponent} from "./settings/card/card.component";
import {NewCardComponent} from "./settings/card/new-card/new-card.component";
import {CardMenuComponent} from "./settings/card/card-menu/card-menu.component";
import {EditCardComponent} from "./settings/card/edit-card/edit-card.component";
import {VerifyCardComponent} from "./settings/card/verify-card/verify-card.component";
import {PaypalSettingsComponent} from "./settings/paypal-settings/paypal-settings.component";
import {AddAccountComponent} from "./settings/paypal-settings/add-account/add-account.component";
import {AddressesSettingsComponent} from "./settings/addresses-settings/addresses-settings.component";
import {AddAddressComponent} from "./settings/addresses-settings/add-address/add-address.component";
import {FundsComponent} from "./settings/funds/funds.component";
import {RewardsFormComponent} from "./rewards/rewards-form/rewards-form.component";
import {ContactInfoComponent} from "./settings/contact-info/contact-info.component";
import {InvestmentsComponent} from "./investments/investments.component";
import {PaypalPaymentsSuccessComponent} from "./paypal/payments/success/paypal-payments-success.component";
import {PaypalPaymentsUnsuccessComponent} from "./paypal/payments/unsuccess/paypal-payments-unsuccess.component";

import {SnekapekInvestmentsComponent} from "./partners/snekapek/snekapek-investments/snekapek-investments.component";
import {EditAddressComponent} from "./settings/addresses-settings/edit-address/edit-address.component";
import {EditAccountComponent} from "./settings/paypal-settings/edit-account/edit-account.component";
import {USDCComponent} from "./prizes/usdc/usdc.component";
import {TerminalComponent} from "./terminal/terminal.component";
import {LoginNewComponent} from "./login/new/login.component";
import {KioskComponent} from "./login/kiosk/kiosk.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {MascotComponent} from "./gamesProviders/mascot/mascot.component";
import {MascotSlidersComponent} from "./gamesProviders/mascot-sliders/mascot-sliders.component";
import {SliderTestComponent} from "./slider-test/slider-test.component";
import {SliderTest2Component} from "./slider-test2/slider-test2.component";
import {SliderTest3Component} from "./slider-test3/slider-test3.component";
import {SliderTest4Component} from "./slider-test4/slider-test4.component";
import {PrizesCryptoComponent} from "./prizes/prizes-crypto/prizes-crypto.component";
import {MassSmsComponent} from "./mass-sms/mass-sms.component";
import {PrizesCashappComponent} from "./prizes/prizes-cashapp/prizes-cashapp.component";
import {YetiComponent} from "./games/yeti/yeti.component";
import {SlotsComponent} from "./games/slots/slots.component";
import {CommissionComponent} from "./rewards/commission/commission.component";
import {MascotCardComponent} from "./gamesProviders/mascot-card/mascot-card.component";
import {PrizesVenmoComponent} from "./prizes/prizes-venmo/prizes-venmo.component";
import {ReportWeeklyComponent} from "./reports/report-weekly/report-weekly.component";
import {HelpSettingsComponent} from "./settings/help-settings/help-settings.component";
import {Promo777Component} from "./promo/promo777/promo777.component";
import {TransactionProcessingComponent} from "./transactions/transaction-processing/transaction-processing.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: PartnerComponent},
            {path: 'login', component: LoginComponent},
            {path: 'login-new', component: LoginNewComponent},
            // {path: 'login-old', component: LoginOldComponent},
            {path: 'paypal/execute/success', component: PaypalPaymentsSuccessComponent},
            {path: 'paypal/execute/unsuccess', component: PaypalPaymentsUnsuccessComponent},
            {path: 'kiosk/:pointId', component: KioskComponent, canActivate: [AuthGuard]},
            {path: 'mascot-old', canActivate: [AuthGuard], component: MascotComponent},
            {path: 'slider', canActivate: [AuthGuard], component: SliderTestComponent},
            {path: 'slider2', canActivate: [AuthGuard], component: SliderTest2Component},
            {path: 'slider3', canActivate: [AuthGuard], component: SliderTest3Component},
            {path: 'slider4', canActivate: [AuthGuard], component: SliderTest4Component},
            {path: 'mascot', canActivate: [AuthGuard], component: MascotSlidersComponent},
            {path: 'promo777', component: Promo777Component},
            {path: 'mascot-card', canActivate: [AuthGuard], component: MascotCardComponent},
            {path: 'mass-sms', component: MassSmsComponent},
            {path: 'yeti', canActivate: [AuthGuard], component: YetiComponent},
            {path: 'slots', component: SlotsComponent},
            {path: 'threedsecure', component: TransactionProcessingComponent},
            {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            // {path: 'home', component: HomeComponent, children:

                    {path: 'terminal', component: TerminalComponent},
                    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
                    {path: 'rewards', component: RewardsComponent},
                    {path: 'rewards/form', component: RewardsFormComponent},
                    {path: 'rewards/commission', component: CommissionComponent},
                    {path: 'prizes', component: PrizesComponent},
                    {path: 'investments', component: InvestmentsComponent},
                    {path: 'prizes/paypal', component: PaypalComponent},
                    {path: 'prizes/paypal/new-email', component: NewEmailComponent},
                    {path: 'prizes/mail-check', component: MailCheckComponent},
                    {path: 'prizes/mail-check/new-address', component: NewAddressComponent},
                    {path: 'prizes/usdc', component: USDCComponent},
                    {path: 'prizes/crypto', component: PrizesCryptoComponent},
                    {path: 'prizes/cashapp', component: PrizesCashappComponent},
                    {path: 'prizes/venmo', component: PrizesVenmoComponent},
                    // {path: 'prizes/games', component: PrizesGamesComponent},
                    {path: 'settings', component: SettingsComponent},
                    {path: 'settings/help', component: HelpSettingsComponent},
                    {path: 'settings/card', component: CardComponent},
                    {path: 'settings/cashapp', component: CardComponent},
                    {path: 'settings/prepaid', component: CardComponent},
                    {path: 'settings/card/new-card', component: NewCardComponent},
                    {path: 'settings/cashapp/new-card', component: NewCardComponent},
                    {path: 'settings/prepaid/new-card', component: NewCardComponent},
                    {path: 'settings/card/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/cashapp/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/prepaid/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/card/edit/:id', component: EditCardComponent},
                    {path: 'settings/cashapp/edit/:id', component: EditCardComponent},
                    {path: 'settings/prepaid/edit/:id', component: EditCardComponent},
                    {path: 'settings/cashapp/verify/:id', component: VerifyCardComponent},
                    {path: 'settings/card/verify/:id', component: VerifyCardComponent},
                    {path: 'settings/paypal', component: PaypalSettingsComponent},
                    {path: 'settings/paypal/add', component: AddAccountComponent},
                    {path: 'settings/paypal/edit/:id', component: EditAccountComponent},
                    {path: 'settings/addresses', component: AddressesSettingsComponent},
                    {path: 'settings/addresses/add', component: AddAddressComponent},
                    {path: 'settings/addresses/edit/:id', component: EditAddressComponent},
                    {path: 'settings/funds', component: FundsComponent},
                    {path: 'settings/contacts', component: ContactInfoComponent},
                    {path: 'snekapek/investments', component: SnekapekInvestmentsComponent },
                    {path: 'weekly-report', component: ReportWeeklyComponent},
                ]},

            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
