import * as i0 from "@angular/core";
var AppTestService = /** @class */ (function () {
    function AppTestService() {
        this.storage = sessionStorage;
        this.storageKey = 'iswebtest';
        this._accessDomain = '192.168.56.1';
        this._accessDomain1 = 'localhost';
    }
    AppTestService.prototype.saveInStorage = function (key) {
        if (this.isAccessDomain) {
            this.storage.setItem(this.storageKey, key);
        }
    };
    AppTestService.prototype.clearStorage = function () {
        //this.storage.removeItem(this.storageKey);
    };
    AppTestService.prototype.isWeb = function () {
        if (sessionStorage.getItem(this.storageKey)) {
            if (this.isAccessDomain) {
                return true;
            }
        }
        return false;
    };
    AppTestService.prototype.isAccessDomain = function () {
        if (this.accessDomain == this.getHostname() || this.accessDomain1 == this.getHostname()) {
            return true;
        }
        return false;
    };
    AppTestService.prototype.getHostname = function () {
        return window.location.hostname;
    };
    Object.defineProperty(AppTestService.prototype, "accessDomain", {
        get: function () {
            return this._accessDomain;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppTestService.prototype, "accessDomain1", {
        get: function () {
            return this._accessDomain1;
        },
        enumerable: true,
        configurable: true
    });
    AppTestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppTestService_Factory() { return new AppTestService(); }, token: AppTestService, providedIn: "root" });
    return AppTestService;
}());
export { AppTestService };
