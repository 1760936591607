import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {PaypalModel, PaypalResultItem} from "../../../models/services/prizes/paypal.model";
import {PROJECT_MESSAGES} from "../../../config/project";
import {StylingService} from "../../../service/styling.service";
import {ScrollToService} from "../../../service/scrollTo.service";
import {KioskAccessorService} from "../../../service/kiosk/kiosk-accessor.service";
import {KioskScrollService} from "../../../service/kiosk-scroll.service";

@Component({
    selector: 'app-paypal',
    templateUrl: './paypal.component.html',
    styleUrls: ['./paypal.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PaypalComponent implements OnInit {
    sellForm: FormGroup;
    emails = [];
    checkedEmail = null;
    availableBalance = 0;

    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private stylingService: StylingService,
                private scrollToService: ScrollToService,
                private kioskAccessorService: KioskAccessorService,
                private kioskScrollService: KioskScrollService,
                private existingUserService: ExistingUserService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getEmails();
            }
        });
    }

    ngOnInit() {
        this.getEmails();

        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
            email: [null, Validators.required],
        });
    }

    getEmails(){
        this.prizesService.getPaypalEmails().subscribe(data => {
            this.setEmails(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setEmails(data: PaypalModel) {
        this.setBalance(data.available_balance);

        if (data.status === true) {
            this.emails = data.result;
            this.addTemporaryEmail();
            this.setCheckedAddress();
        } else if (data.status === undefined) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setBalance(balance: number) {
        const balanceNumber = +balance;
        if (balanceNumber || balanceNumber === 0) {
            this.availableBalance = balanceNumber;
        }
    }

    addTemporaryEmail() {
        if (this.prizesService.getTemporaryEmail()) {
            const temporaryEmail = this.prizesService.getTemporaryEmail();
            this.emails.push({email: temporaryEmail, id: 0});
            this.setCheckedAddress(temporaryEmail);
        }
    }

    getInputValue(field) {
        if (this.sellForm.controls[field]) {
            return this.sellForm.controls[field].value;
        }
    }

    setCheckedAddress(email?) {
        if (!this.emails.length) {
            this.sellForm.controls.email.setValue(null);
            this.checkedEmail = null;
        } else if (email && this.emails.find(el => el.email === email)) {
            this.sellForm.controls.email.setValue(email);
            this.checkedEmail = email;
        } else {
            let email = this.emails[this.emails.length-1].email;
            this.sellForm.controls.email.setValue(email);
            this.checkedEmail = email;
        }
        this.scrollToLastMethod();
    }

    onSubmit() {
        const amount = this.getInputValue('amount');
        let email = this.getInputValue('email');

        if (!this.emails.length) {
            this.popupComponent.showPopupError({text: 'Please enter your PayPal email'});
        } else {
            if (email === null && this.emails.length === 1) {
                email = this.emails[0].email;
                this.setCheckedAddress(email);
            }

            if (email !== null) {
                this.popupComponent.showPopupAgreement({text: `${email} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
                    if (status) {
                        this.submit();
                    }
                });
            } else {
                this.popupComponent.showPopupError({text: 'Please select the email to use'});
            }
        }
    }

    submit() {
        const email = this.getInputValue('email');
        const checkedEmail = this.emails.find((emailItem) => emailItem.email === email);
        const amount = this.getInputValue('amount');

        if (email && checkedEmail && amount) {
            this.prizesService.paypalCreatePayout(checkedEmail.id, email, amount).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }

    }

    checkSubmitResult(data) {
        if(data.status === true){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.sellForm.reset();
            this.getEmails();
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    scrollToLastMethod() {
        this.scrollToService.scrollToEnd('.saved__list');
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable('.saved__list', top);
    }
}

