import { YETI_SNOWDRIFTS_POSITIONS } from "../yeti.config";
var YetiSnowdriftModel = /** @class */ (function () {
    function YetiSnowdriftModel(id, index, opened) {
        this._id = id;
        this._position = YETI_SNOWDRIFTS_POSITIONS[index];
        this._scale = YETI_SNOWDRIFTS_POSITIONS[index].scale;
        this._index = index;
        this._opened = opened;
    }
    YetiSnowdriftModel.prototype.getPosition = function () {
        return this.position;
    };
    YetiSnowdriftModel.prototype.getId = function () {
        return this.id;
    };
    YetiSnowdriftModel.prototype.getIndex = function () {
        return this.index;
    };
    YetiSnowdriftModel.prototype.getScale = function () {
        return this.scale;
    };
    YetiSnowdriftModel.prototype.isOpened = function () {
        return !!this.opened;
    };
    YetiSnowdriftModel.prototype.getOpenedElementInfo = function () {
        return this.opened;
    };
    YetiSnowdriftModel.prototype.setOpenedElementInfo = function (value) {
        this.opened = value;
    };
    Object.defineProperty(YetiSnowdriftModel.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(YetiSnowdriftModel.prototype, "index", {
        get: function () {
            return this._index;
        },
        set: function (value) {
            this._index = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(YetiSnowdriftModel.prototype, "scale", {
        get: function () {
            return this._scale;
        },
        set: function (value) {
            this._scale = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(YetiSnowdriftModel.prototype, "position", {
        get: function () {
            return this._position;
        },
        set: function (value) {
            this._position = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(YetiSnowdriftModel.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        set: function (value) {
            this._opened = value;
        },
        enumerable: true,
        configurable: true
    });
    return YetiSnowdriftModel;
}());
export { YetiSnowdriftModel };
