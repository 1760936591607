import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {PROJECT_MESSAGES} from "../../../config/project";
import {StylingService} from "../../../service/styling.service";
import {ScrollToService} from "../../../service/scrollTo.service";
import {KioskAccessorService} from "../../../service/kiosk/kiosk-accessor.service";
import {KioskScrollService} from "../../../service/kiosk-scroll.service";

@Component({
    selector: 'app-mail-check',
    templateUrl: './mail-check.component.html',
    styleUrls: ['./mail-check.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class MailCheckComponent implements OnInit {
    sellForm: FormGroup;
    addresses = [];
    checkedAddress = null;
    availableBalance = 0;

    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private router: Router,
                private stylingService: StylingService,
                private scrollToService: ScrollToService,
                private kioskScrollService: KioskScrollService,
                private kioskAccessorService: KioskAccessorService,
                private existingUserService: ExistingUserService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getAddresses();
            }
        });
    }

    ngOnInit() {
        this.getAddresses();

        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
            address: [null, Validators.required],
        });
    }

    getAddresses(){
        this.prizesService.getCheckAddresses().subscribe(data => {
            this.setAddresses(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setAddresses(data) {
        this.setBalance(data.available_balance);

        if (data.status === true) {
            this.addresses = data.result;
            this.addTemporaryAddress();
            this.setCheckedAddress();
        } else if (data.status === undefined) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setBalance(balance: number) {
        const balanceNumber = +balance;
        if (balanceNumber || balanceNumber === 0) {
            this.availableBalance = balanceNumber;
        }
    }

    addTemporaryAddress() {
        if (this.prizesService.getTemporaryAddress()) {
            const temporaryAddress = this.prizesService.getTemporaryAddress();
            this.addresses.push({address: temporaryAddress, id: 0});
            this.setCheckedAddress(temporaryAddress);
        }
    }

    getInputValue(field) {
        if (this.sellForm.controls[field]) {
            return this.sellForm.controls[field].value;
        }
    }

    setCheckedAddress(address?) {
        if (!this.addresses.length) {
            this.sellForm.controls.address.setValue(null);
            this.checkedAddress = null;
        } else if (address && this.addresses.find(el => el.address === address)) {
            this.sellForm.controls.address.setValue(address);
            this.checkedAddress = address;
        } else {
            let address = this.addresses[this.addresses.length-1].address;
            this.sellForm.controls.address.setValue(address);
            this.checkedAddress = address;
        }
        this.scrollToLastMethod();
    }

    onSubmit() {
        const amount = this.getInputValue('amount');
        let address = this.getInputValue('address');

        if (!this.addresses.length) {
            this.popupComponent.showPopupError({text: 'Please enter your mailing address'});
        } else {
            if(this.addresses.length == 1 && address === null){
                address = this.addresses[0].address;
                this.setCheckedAddress(address);
            }

            if (address !== null) {
                this.popupComponent.showPopupAgreement({text: `${address} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
                    if (status) {
                        this.submit();
                    }
                });
            } else {
                this.popupComponent.showPopupError({text: 'Please select the address to use'});
            }
        }
    }

    submit() {
        const address = this.getInputValue('address');
        const checkedAddress = this.addresses.find((addressItem) => addressItem.address === address);
        const amount = this.getInputValue('amount');

        if (address && checkedAddress && amount) {
            this.prizesService.mailCheckCreatePayout(checkedAddress.id, address, amount).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    checkSubmitResult(data) {
        if(data.status === true){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.sellForm.reset();
            this.getAddresses();
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    scrollToLastMethod() {
        this.scrollToService.scrollToEnd('.saved__list');
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable('.saved__list', top);
    }
}

