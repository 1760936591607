import * as tslib_1 from "tslib";
import { KIOSK_CONFIG } from "../config/api";
import { SettingsLoaderService } from "./settingsLoader.service";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./testing/frontendTestSettings.service";
import * as i3 from "./loader.service";
var KioskService = /** @class */ (function (_super) {
    tslib_1.__extends(KioskService, _super);
    function KioskService(http, frontendTestSettingsService, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.frontendTestSettingsService = frontendTestSettingsService;
        _this.loaderService = loaderService;
        _this.config = KIOSK_CONFIG;
        _this.billingPage = new BehaviorSubject(false);
        _this.paymentType = new BehaviorSubject(null);
        _this.isAppDevice();
        return _this;
    }
    KioskService.prototype.isAppDevice = function () {
        var userAgent = window.navigator.userAgent;
        var userAgent = userAgent.toLowerCase();
        if (userAgent.indexOf('myapp/kiosk') !== -1) {
            localStorage.setItem('isApp', '1');
        }
        else {
            localStorage.setItem('isApp', '0');
        }
        if (this.frontendTestSettingsService.isTerminal()) {
            localStorage.setItem('isApp', '1');
        }
    };
    KioskService.prototype.getSweepsLink = function () {
        this.showLoader();
        var post = this.http.post(this.config.sweepsSendLink, {});
        return post;
    };
    KioskService.prototype.addPaymentType = function (type) {
        this.paymentType.next(type);
    };
    KioskService.prototype.isKiosk = function () {
        return +localStorage.getItem('isApp');
    };
    KioskService.prototype.activateBilling = function () {
        this.billingPage.next(true);
    };
    KioskService.prototype.deactivateBilling = function () {
        this.billingPage.next(false);
    };
    KioskService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KioskService_Factory() { return new KioskService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FrontendTestSettingsService), i0.ɵɵinject(i3.LoaderService)); }, token: KioskService, providedIn: "root" });
    return KioskService;
}(SettingsLoaderService));
export { KioskService };
