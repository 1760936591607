<div class="kiosk-payment" [class.is-hidden]="!showPopup"
                           [class.kiosk-payment--secondary]="setSecondaryStyle()"
                           [class.kiosk-payment--primary]="setPrimaryStyle()">
    <div class="kiosk-payment__inner">
        <div class="kiosk-payment-header" [class.kiosk-payment-header--mb]="isSweepsType()" [class.kiosk-payment-header--mb-sm]="isRiverPay">
            <div *ngIf="isCryptoType()" class="kiosk-payment-header__title kiosk-payment-header__title--yellow">Buy Crypto Currency</div>
            <div *ngIf="isSweepsType()" class="kiosk-payment-header__title">{{data?.name}}</div>

            <img *ngIf="data && isSweepsType()" [src]="data?.image_link" alt="" class="kiosk-payment-header__head-img">
            <img *ngIf="data && isCryptoType()" src="../../../../../assets/images/kiosk/buy-crypto.png" alt="" class="kiosk-payment-header__head-img">

            <div class="kiosk-payment-header__wrapper">
                <button class="kiosk-payment-header__back" *ngIf="isBackBtn" (click)="closePopup()">Back</button>
                <div class="kiosk-payment-header__description" [innerHTML]="data?.description"></div>
            </div>
        </div>

        <div class="kiosk-payment__content">
            <div class="kiosk-payment-crypto" *ngIf="data && isCryptoType()">
                <img class="kiosk-payment-crypto__img"
                     src="../../../../assets/images/currencies/{{data.iconTo}}.png"
                     (error)="loadDefaultCurrencyIcon($event)">

                <div class="kiosk-payment-crypto__content">
                    <div class="kiosk-payment-crypto__rate">
                        <span>1 {{data.nameTo}} = </span>
                        {{data.amount | number: '1.2-12'}} {{data.nameFrom}}
                    </div>
<!--                    <div>You are buying {{data.nameFrom}}. No Sweepstakes will be issued</div>-->
                    <div>You are buying {{data.nameTo}}. No Sweepstakes will be used. The minimum accepted bill is $20 USD.</div>
                </div>
            </div>

            <div *ngIf="isRiverPay" class="kiosk-payment__progress">
                <div class="payment-progress">
                    <div class="payment-progress__max">{{progressRiverExecsMax | number}} Execs</div>
                    <div class="payment-progress__wrapper">
                        <div class="payment-progress__progress" [ngStyle]="{width: getProgressWidth(progressRiverExecs, progressRiverExecsMax)}">
                            <div class="payment-progress__parcent">{{getProgressWidth(progressRiverExecs, progressRiverExecsMax)}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="kiosk-payment__subtitle">
                Start Inserting Bills
            </div>

            <div class="kiosk-payment__amount">
                {{amount | currency : 'USD' : 'symbol'}}
            </div>
        </div>

        <button [disabled]="!amount" class="kiosk-payment__btn" (click)="isCryptoType() ? onBuyCrypto() : onBuySweeps()">
            Buy
        </button>
    </div>
</div>
