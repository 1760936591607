import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList } from '@angular/core';
import { POPUP_TYPE } from "./mascot-sliders.config";
import { Validators } from "@angular/forms";
import { PHONE_MASK } from "../../../config/country_phone_mask";
import { BehaviorSubject } from "rxjs";
import { TIMERS_CONFIG } from "../../../config/project";
import { AUTH_CODES } from "../../../config/auth_codes";
import { ERROR_CODE } from "../../../config/error_code";
import { SCRATCH_OFF_POPUP_TYPE } from "../../games/scratch-off/scratch-off-rules/scratch-off-rules.config";
var MascotSlidersComponent = /** @class */ (function () {
    function MascotSlidersComponent(mascotService, scratchOffService, loaderService, gameService, popupComponent, spinnerService, sanitizer, dateService, router, activatedRoute, formBuilder, fb, kioskService, kioskAccessorService, authenticationService, keyboardService, partnerService, fullScreenService, deviceDetectionService, usersPointService, frontendTestSettingsService) {
        var _this = this;
        this.mascotService = mascotService;
        this.scratchOffService = scratchOffService;
        this.loaderService = loaderService;
        this.gameService = gameService;
        this.popupComponent = popupComponent;
        this.spinnerService = spinnerService;
        this.sanitizer = sanitizer;
        this.dateService = dateService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.fb = fb;
        this.kioskService = kioskService;
        this.kioskAccessorService = kioskAccessorService;
        this.authenticationService = authenticationService;
        this.keyboardService = keyboardService;
        this.partnerService = partnerService;
        this.fullScreenService = fullScreenService;
        this.deviceDetectionService = deviceDetectionService;
        this.usersPointService = usersPointService;
        this.frontendTestSettingsService = frontendTestSettingsService;
        this.showPopup = false;
        this.isApp = +localStorage.getItem('isApp');
        this.bodyAdditionalClass = 'is-mascot';
        this.singlePage = false;
        this.showPhonePopup = false;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        // showContactsPopup = false;
        this.country = 'us';
        this.phoneMask = PHONE_MASK[this.country];
        this.logoutAfterMin = 5;
        this.logoutAfterSec = this.logoutAfterMin * 60;
        this.logoutAutoConfirmAfterSec = 20;
        this.screenSaver = false;
        this.screenSaverAfterMin = 5;
        this.screenSaverAfterSec = this.screenSaverAfterMin * 60;
        this.popupLoading = false;
        this.POPUP_TYPE = POPUP_TYPE;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.games = [];
        this.slider1 = [];
        this.slider2 = [];
        this.slidesPerView = 2;
        this.slideWidth = 285;
        this.sliderAnimationDuration = 2000;
        this.sliderAnimationFunction = 'cubic-bezier(0.65, 0, 0.35, 1)';
        this.sliderAnimationDurationOnClick = 400;
        this.slideResolution = 1.458;
        this.slideHeightRate = .423;
        this.numOfDigits = 4;
        this.balance = {
            freeRounds: 0,
            win: 0,
            jackpot: 0,
            balance: 0
        };
        this.jackpotDynamicText = new BehaviorSubject(null);
        this.isTerminal = true;
        this.mascotService.balanceUpdated.subscribe(function (val) {
            if (val) {
                _this.getBalance();
            }
        });
        this.kioskAccessorService.accessorStatusChanged.subscribe(function (value) {
            if (value) {
                if (_this.isPopupForLogoutOpen()) {
                    _this.closePopupAndUpdateTimer();
                }
                else {
                    _this.setLogoutTimer();
                    _this.setScreenSaver();
                }
            }
        });
        this.isStationMascot = this.partnerService.isMascotSingle();
        this.isTerminal = this.isStationMascot;
        if (this.partnerService.isMascotSingle()) {
            this.singlePage = true;
            this.checkSinglePage();
            this.detectActivity();
        }
        if (this.partnerService.isMascotSingleTerminal()) {
            this.logoutAfterMin = 2;
            this.logoutAfterSec = this.logoutAfterMin * 60;
            this.setLogoutTimer();
            this.setScreenSaver();
            this.kioskAccessorService.paymentAmountChanged.subscribe(function (val) {
                //if (val > 0) {
                _this.getBalance();
                //}
            });
        }
        this.jackpotDynamicText.subscribe(function (val) {
            if (_this.text !== undefined && _this.textData !== undefined) {
                setTimeout(function () {
                    _this.text.nativeElement.innerHTML = _this.textData.nativeElement.innerHTML;
                });
            }
        });
        // this.activatedRoute.queryParams.subscribe((queryParams:any) => {
        //     if (queryParams && queryParams.notLogged === 'true') {
        //         console.log(queryParams.notLogged);
        //
        //         this.logoutSinglePage();
        //     }
        // });
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }
    // @HostListener('window:resize', ['$event'])
    MascotSlidersComponent.prototype.unloadHandler = function (event) {
        // Your logic on beforeunload
        if ((this.isApp && this.isStationMascot) || this.singlePage) {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { notLogged: true },
                queryParamsHandling: 'merge'
            });
        }
    };
    MascotSlidersComponent.prototype.onResize = function (event) {
        var _this = this;
        this.setSlideWidth();
        setTimeout(function () {
            if (_this.newSwiper) {
                _this.newSwiper.update({ perView: _this.slidesPerView, autoplay: false });
            }
            if (_this.newSwiper2) {
                _this.newSwiper2.update({ perView: _this.slidesPerView, autoplay: false });
            }
        });
    };
    MascotSlidersComponent.prototype.onMouseMove = function (event) {
        if (!this.isApp) {
            this.newSwiper.update({ autoplay: false, animationDuration: this.sliderAnimationDurationOnClick });
            this.newSwiper2.update({ autoplay: false, animationDuration: this.sliderAnimationDurationOnClick });
            this.onMouseMove = function () { };
        }
        else {
            this.onMouseMove = function () { };
        }
    };
    MascotSlidersComponent.prototype.onOrientationChange = function (event) {
        var _this = this;
        this.setSlideWidth();
        setTimeout(function () {
            if (_this.newSwiper) {
                _this.newSwiper.update({ perView: _this.slidesPerView, autoplay: false });
            }
            if (_this.newSwiper2) {
                _this.newSwiper2.update({ perView: _this.slidesPerView, autoplay: false });
            }
        });
    };
    MascotSlidersComponent.prototype.ngOnInit = function () {
        // if (this.isIpad()) {
        //     const doc = document.documentElement;
        //     doc.style.setProperty('--ipad-height', `${window.innerHeight * .99}px`);
        //     doc.style.setProperty('--ipad-width', `${window.innerHeight * 1.4}px`);
        // }
        this.setBodyMascotClass();
        this.initGame();
        this.checkSinglePage();
        this.setAndroidSettings();
    };
    MascotSlidersComponent.prototype.ngOnDestroy = function () {
        this.removeBodyMascotClass();
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
        if (this.screenSaverTimeout) {
            clearTimeout(this.screenSaverTimeout);
        }
    };
    MascotSlidersComponent.prototype.setBodyMascotClass = function () {
        var _this = this;
        document.body.classList.add(this.bodyAdditionalClass);
        setTimeout(function () {
            _this.popupComponent.loadPopupImages();
        });
    };
    MascotSlidersComponent.prototype.removeBodyMascotClass = function () {
        document.body.classList.remove(this.bodyAdditionalClass);
    };
    MascotSlidersComponent.prototype.checkSinglePage = function () {
        var _this = this;
        if (this.partnerService.isMascotSingle()) {
            this.showLoginPopup();
            document.body.classList.add('is-mascot-app');
            setTimeout(function () {
                if (_this.jackpotDynamicText.value === null) {
                    _this.setJackpot(0);
                }
            });
        }
        else {
            this.getBalance();
        }
    };
    MascotSlidersComponent.prototype.setJackpot = function (value) {
        this.balance.jackpot = value;
        this.jackpotDynamicText.next(value);
    };
    // handleEventsForLogout() {
    //     if (this.singlePage) {
    //         setTimeout(() => {
    //             document.addEventListener('click', () => {
    //                 this.setLogoutTimer();
    //             });
    //             document.addEventListener('keydown', () => {
    //                 this.setLogoutTimer();
    //             });
    //         });
    //     }
    // }
    MascotSlidersComponent.prototype.detectActivity = function () {
        var _this = this;
        setTimeout(function () {
            document.addEventListener('click', _this.onUserActivity.bind(_this));
            document.addEventListener('keydown', _this.onUserActivity.bind(_this));
        });
    };
    MascotSlidersComponent.prototype.onUserActivity = function () {
        if (this.singlePage) {
            this.setLogoutTimer();
        }
        if (this.partnerService.isMascotSingleTerminal()) {
            this.setScreenSaver();
        }
    };
    MascotSlidersComponent.prototype.isPopupForLogoutOpen = function () {
        return document.querySelector('.swal-agreement .swal2-confirm');
    };
    MascotSlidersComponent.prototype.closePopupAndUpdateTimer = function () {
        if (this.isPopupForLogoutOpen()) {
            var popup = this.isPopupForLogoutOpen();
            popup.click();
        }
    };
    MascotSlidersComponent.prototype.setScreenSaver = function () {
        var _this = this;
        this.screenSaver = false;
        if (this.screenSaverTimeout) {
            clearTimeout(this.screenSaverTimeout);
        }
        if (this.partnerService.isMascotSingleTerminal() && this.showPhonePopup && !this.phoneForm.controls.phone.value && !this.screenSaver) {
            this.screenSaverTimeout = setTimeout(function () {
                _this.screenSaver = true;
            }, this.screenSaverAfterSec * 1000);
        }
    };
    MascotSlidersComponent.prototype.setLogoutTimer = function () {
        var _this = this;
        if (this.singlePage) {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
            this.logoutTimeout = setTimeout(function () {
                if (_this.showPhonePopup) {
                    if (_this.phoneForm.controls.phone.value) {
                        _this.phoneForm.controls.phone.setValue('');
                        _this.setScreenSaver();
                    }
                    _this.updateLogoutTimer();
                }
                else if (_this.popupGameURL) {
                    _this.mascotService.checkLastUpdates().subscribe(function (data) {
                        var now = data.now;
                        var last = data.last;
                        if ((now - last) > _this.logoutAfterSec) {
                            _this.logout();
                        }
                    }, function () {
                        _this.loaderService.hideAndShowTryAgain('bordered');
                    }, function () {
                        _this.updateLogoutTimer();
                    });
                }
                else {
                    _this.logout();
                }
            }, this.logoutAfterSec * 1000);
        }
    };
    MascotSlidersComponent.prototype.updateLogoutTimer = function () {
        var _this = this;
        console.log('updateLogoutTimer : sliders');
        this.logoutTimeout = setTimeout(function () {
            _this.setLogoutTimer();
        }, this.logoutAfterSec * 1000);
    };
    MascotSlidersComponent.prototype.initGame = function () {
        this.getData();
        this.setSlideWidth();
    };
    MascotSlidersComponent.prototype.showLoginPopup = function () {
        var _this = this;
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
        for (var i = 0; i < this.numOfDigits; i++) {
            this.pinForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
        this.showPhonePopup = true;
        if (this.pinForm.controls.digits.controls.length) {
            this.keyboardService.smallInputValueChanged.subscribe(function (settings) {
                _this.pinForm.controls.digits.controls[settings.order].patchValue(settings.value);
            });
        }
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            _this.keyboardService.setActiveInput();
        }, 0);
    };
    MascotSlidersComponent.prototype.onPhoneSubmit = function () {
        var _this = this;
        if (this.phoneForm.invalid) {
            return;
        }
        var phone = this.phoneForm.controls.phone.value;
        var country = this.country;
        this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.phoneLastFour = phone.substr(-4);
                    _this.showPhonePopup = false;
                    _this.showPinPopup = true;
                    setTimeout(function () {
                        // this.inputs.toArray()[0].nativeElement.focus();
                        document.querySelector('input').focus();
                        _this.keyboardService.setActiveInput();
                    }, 0);
                }
                else {
                    if (data.code) {
                        if (data.code == AUTH_CODES.registerNewUser) {
                            _this.errorHandlerRegisterNewUser();
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message }, 'btn-ok');
                        }
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message }, 'btn-ok');
                    }
                    // this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain('bordered');
        }, function () {
            _this.loaderService.hide();
        });
    };
    MascotSlidersComponent.prototype.errorHandlerRegisterNewUser = function () {
        var _this = this;
        this.showRegistrationPopup = true;
        this.showPinPopup = true;
        this.showPhonePopup = false;
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            _this.keyboardService.setActiveInput();
        }, 0);
    };
    MascotSlidersComponent.prototype.onRegisterNewUser = function () {
        var _this = this;
        var phone = this.phoneForm.controls.phone.value;
        var country = this.country;
        var pin = this.pinForm.controls.digits.value;
        pin = pin.join('');
        if (pin.length !== 4) {
            this.popupComponent.showPopupError({ text: 'The Code should consist of 4 numerical characters' }, 'btn-ok');
            return;
        }
        this.loaderService.show();
        this.authenticationService.register(phone, pin, country).subscribe(function (data) {
            _this.loaderService.hide();
            try {
                if (data.status) {
                    _this.authenticationService.setData(data.result);
                    _this.onUserLogged(data);
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message }, 'btn-ok');
                }
            }
            catch (e) {
                _this.loaderService.hide();
                _this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' }, 'btn-ok');
            }
        }, function (error) {
            _this.loaderService.hide();
            _this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' }, 'btn-ok');
        });
    };
    MascotSlidersComponent.prototype.resendSms = function () {
        this.pinForm.reset();
        this.onPhoneSubmit();
    };
    MascotSlidersComponent.prototype.setSlideWidth = function () {
        var docElement = document.documentElement;
        // const docElementWidth = document.documentElement.clientWidth;
        // const docElementHeight = document.documentElement.clientHeight;
        // New
        var docElementWidth = window.innerWidth;
        var docElementHeight = window.innerHeight;
        var isPortraitOrientation = docElementWidth < docElementHeight;
        var viewportHeight = isPortraitOrientation ? docElementWidth : docElementHeight;
        var viewportWidth = isPortraitOrientation ? docElementHeight : docElementWidth;
        var slideHeight = viewportHeight * this.slideHeightRate;
        if (slideHeight) {
            this.slideWidth = slideHeight / this.slideResolution;
        }
        this.slidesPerView = viewportWidth / this.slideWidth;
    };
    MascotSlidersComponent.prototype.fullScreen = function () {
        if (!this.isAndroid()) {
            this.fullScreenService.toggleFullScreen();
        }
    };
    MascotSlidersComponent.prototype.detectClickOnGame = function () {
        var _this = this;
        document.querySelectorAll('.mascot-slider__slide').forEach(function (el) {
            el.addEventListener('click', function (e) {
                var id = e.currentTarget.getAttribute('data-id');
                _this.openGame(id);
            });
        });
    };
    MascotSlidersComponent.prototype.setDate = function () {
        this.weekDay = this.dateService.getWeekDayName();
    };
    MascotSlidersComponent.prototype.getBalance = function () {
        var _this = this;
        if (this.authenticationService.isUserLogged()) {
            this.mascotService.getBalance().subscribe(function (data) {
                _this.setBalance(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    MascotSlidersComponent.prototype.getData = function () {
        var _this = this;
        if (!this.games.length) {
            this.mascotService.getIcons().subscribe(function (data) {
                _this.setData(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    MascotSlidersComponent.prototype.setData = function (data) {
        var _a, _b;
        var _this = this;
        try {
            this.games = data.result;
            var middle = Math.round(this.games.length / 2);
            this.slider1 = this.games.slice(0, middle);
            this.slider2 = this.games.slice(middle);
            (_a = this.slider1).push.apply(_a, tslib_1.__spread(this.slider1));
            (_b = this.slider2).push.apply(_b, tslib_1.__spread(this.slider2));
            this.spinnerData = data.wheel;
            this.setJackpot(data.jackpot);
            setTimeout(function () {
                _this.detectClickOnGame();
            });
        }
        catch (e) {
        }
    };
    MascotSlidersComponent.prototype.setBalance = function (data) {
        if (data && data.status) {
            this.balance.win = data.wins;
            this.balance.balance = data.balance;
            this.balance.freeRounds = data.freerounds;
            this.setJackpot(data.jackpot);
        }
        else {
            if (this.jackpotDynamicText.value === null) {
                this.setJackpot(0);
            }
            //this.popupComponent.showPopupError({text: data.message}, 'btn-ok')
        }
    };
    MascotSlidersComponent.prototype.openGame = function (id) {
        this.popupLoading = true;
        if (id) {
            var checkedGame = this.games.find(function (el) { return +el.id === +id; });
            this.popupType = POPUP_TYPE.play;
            this.popupGameImage = checkedGame.image1600;
            this.popupGameId = checkedGame.id;
        }
        else {
            this.setPayment();
        }
        this.showPopup = true;
    };
    MascotSlidersComponent.prototype.setPayment = function () {
        this.popupType = POPUP_TYPE.buy;
        this.popupLoading = true;
        this.showGamePayment = true;
        this.rulesPopupType = SCRATCH_OFF_POPUP_TYPE.pigRules;
    };
    MascotSlidersComponent.prototype.isPaymentSuccess = function (result) {
        if (result) {
            this.popupType = POPUP_TYPE.buy;
        }
    };
    MascotSlidersComponent.prototype.isPaymentReady = function (value) {
        if (value) {
            this.popupLoading = false;
        }
        else {
            this.showGamePayment = false;
            this.showPopup = false;
        }
    };
    MascotSlidersComponent.prototype.missPayment = function () {
        this.showGame();
    };
    MascotSlidersComponent.prototype.closeGame = function () {
        this.popupGameURL = null;
        this.showPopup = false;
        this.popupGameImage = null;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.mascotService.updateBalance();
    };
    MascotSlidersComponent.prototype.playGame = function (id) {
        var _this = this;
        if (id) {
            this.popupGameImage = null;
            this.popupLoading = true;
            this.gameService.getGameUrl(id).subscribe(function (data) {
                _this.checkSubmitResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
        // this.popupGameImage = null;
        // this.popupLoading = true;
        // setTimeout(() => {
        //     this.popupGameURL = this.getLink('https://kxys05e7341auuy.mascot.games');
        //     this.popupLoading = false;
        // }, 2000);
    };
    MascotSlidersComponent.prototype.getLink = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    MascotSlidersComponent.prototype.checkSubmitResult = function (responce) {
        if (responce.status === true) {
            this.popupGameURL = this.getLink(responce.result);
            this.popupLoading = false;
        }
        else if (responce.status === false) {
            this.popupComponent.showPopupError({ text: responce.message }, 'btn-ok');
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    };
    MascotSlidersComponent.prototype.imageLoaded = function () {
        this.popupLoading = false;
    };
    MascotSlidersComponent.prototype.touchCapable = function () {
        return ('ontouchstart' in window ||
            (window.DocumentTouch &&
                document instanceof window.DocumentTouch) ||
            navigator.maxTouchPoints > 0 ||
            window.navigator.msMaxTouchPoints > 0);
    };
    MascotSlidersComponent.prototype.showGame = function () {
        this.showGamePayment = false;
        this.showGameFindPrize = true;
        // this.popupLoading = false;
        this.popupLoading = true;
    };
    MascotSlidersComponent.prototype.onRulesActionBtnClick = function () {
        if (this.isWelcomeRulesPopup()) {
            this.closeGame();
        }
        else {
            this.showGame();
        }
    };
    MascotSlidersComponent.prototype.isWelcomeRulesPopup = function () {
        return this.rulesPopupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    };
    MascotSlidersComponent.prototype.closeScratchOffGame = function () {
        this.showGamePayment = true;
        this.showGameFindPrize = false;
    };
    MascotSlidersComponent.prototype.onSubmitPin = function () {
        var _this = this;
        if (this.pinForm.invalid) {
            return;
        }
        var country = this.country;
        var phone = this.phoneForm.controls.phone.value;
        var pin = this.pinForm.controls.digits.value;
        pin = pin.join('');
        if (pin.length !== 4) {
            this.popupComponent.showPopupError({ text: 'The Code should consist of 4 numerical characters' }, 'btn-ok');
            return;
        }
        if (phone && pin && (pin.length === 4) && country) {
            this.authenticationService.login(phone, pin, country).subscribe(function (data) {
                _this.checkLoginSubmit(data);
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    };
    MascotSlidersComponent.prototype.checkLoginSubmit = function (data) {
        var _this = this;
        try {
            if (data.status) {
                this.onUserLogged(data);
            }
            else {
                if (data.code) {
                    if (data.code == ERROR_CODE.blocked || data.code == ERROR_CODE.authOver) {
                        this.popupComponent.showPopupErrorReturnEvent({ text: data.message, closePopupAfter: TIMERS_CONFIG.popupHideAfter }, function () {
                            // this.popupGoBack();
                            _this.logoutSinglePage();
                        }, 'btn-ok');
                    }
                    else {
                        this.popupComponent.showPopupError({ text: data.message }, 'btn-ok');
                        this.popupGoBack();
                    }
                }
                else {
                    this.popupComponent.showPopupError({ text: data.message }, 'btn-ok');
                    this.popupGoBack();
                }
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain('bordered');
            this.popupGoBack();
        }
    };
    MascotSlidersComponent.prototype.onUserLogged = function (data) {
        console.log('onUserLogged : sliders');
        // data.result.first_name = '';
        // data.result.last_name = '';
        // data.result.email = '';
        if (this.frontendTestSettingsService.isHideForTesting()) {
            if (data.userPointId) {
                if (data.userPointId == '') {
                    this.userPointIdEmpty();
                    return true;
                }
                else {
                    this.usersPointService.setUserPointId(data.userPointId);
                }
            }
            else {
                if (this.partnerService.isMascotSingle()) {
                    this.userPointIdEmpty();
                    return true;
                }
            }
        }
        this.showRegistrationPopup = false;
        this.showPinPopup = false;
        this.authenticationService.userLoggedStatusChanged.next(true);
        // this.showContactsFormFirst = data.result.first_name == '' && data.result.last_name == '' && data.result.email == '';
        console.log("RouterPaths.mascot.main 2");
        //this.router.navigate([RouterPaths.mascot.main], {queryParams: {notLogged: false}});
        this.getBalance();
        this.keyboardService.keyboardVisible.next(false);
        this.showWelcomePopup();
        if (this.newSwiper) {
            this.newSwiper.update({ perView: this.slidesPerView, autoplay: false });
        }
        if (this.newSwiper2) {
            this.newSwiper2.update({ perView: this.slidesPerView, autoplay: false });
        }
    };
    MascotSlidersComponent.prototype.showWelcomePopup = function () {
        if ((this.isApp && this.isStationMascot) || (!this.isTerminal && this.isStationMascot)) {
            this.popupLoading = true;
            this.popupType = POPUP_TYPE.buy;
            this.showGamePayment = true;
            this.rulesPopupType = SCRATCH_OFF_POPUP_TYPE.welcome;
            this.showPopup = true;
        }
    };
    MascotSlidersComponent.prototype.userPointIdEmpty = function () {
        this.logoutSinglePage();
        this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' }, 'btn-ok');
    };
    MascotSlidersComponent.prototype.check = function (index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                this.inputs.toArray()[index - 1].nativeElement.focus();
            }
            else {
                console.log('first field');
            }
        }
    };
    MascotSlidersComponent.prototype.popupGoBack = function () {
        var _this = this;
        this.showPhonePopup = true;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.resetPinInput();
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            _this.keyboardService.setActiveInput();
        }, 0);
    };
    MascotSlidersComponent.prototype.hideContactForm = function () {
        this.showContactsFormFirst = false;
    };
    MascotSlidersComponent.prototype.logout = function () {
        var _this = this;
        if (this.singlePage) {
            var counter_1 = this.logoutAutoConfirmAfterSec;
            var counterInterval_1 = setInterval(function () {
                if (counter_1) {
                    counter_1 -= 1;
                    document.querySelector('#logoutTimer').innerHTML = String(counter_1) + '.';
                }
                else {
                    clearInterval(counterInterval_1);
                }
            }, 1000);
            var logoutTime = this.logoutAfterMin === 5 ? 'five (5)' : this.logoutAfterMin === 2 ? 'two (2)' : '';
            this.popupComponent.showPopupAgreement({ text: "Are you still here? No activity is detected for " + logoutTime + " minutes. We are about to log you out in <span id=\"logoutTimer\">" + counter_1 + "</span>", style: 'swal-agreement no-close-btn btn-continue' }, function (result) {
                if (result === 'timerOut') {
                    _this.logoutSinglePage();
                }
                clearInterval(counterInterval_1);
            }, this.logoutAutoConfirmAfterSec * 1000);
        }
        else {
            this.router.navigate(['/home/settings']);
        }
    };
    MascotSlidersComponent.prototype.onLogout = function () {
        if (this.singlePage) {
            this.logoutSinglePage();
        }
        else {
            this.router.navigate(['/home/settings']);
        }
    };
    MascotSlidersComponent.prototype.logoutSinglePage = function () {
        var _this = this;
        this.showPopup = false;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.showContactsFormFirst = false;
        this.popupGameURL = false;
        this.popupType = null;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.popupComponent.closePopup();
        this.showPhonePopup = true;
        if (this.pinForm && this.phoneForm) {
            this.phoneForm.controls.phone.setValue('');
            this.resetPinInput();
        }
        this.authenticationService.logout();
        this.clearBalance();
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            _this.keyboardService.setActiveInput();
            _this.setScreenSaver();
        }, 0);
        // this.router.navigate(['/mascot'], {queryParams: {notLogged: true}});
        // window.location.reload();
    };
    MascotSlidersComponent.prototype.resetPinInput = function () {
        this.pinForm.get('digits').clear();
        for (var i = 0; i < this.numOfDigits; i++) {
            this.pinForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    MascotSlidersComponent.prototype.frameClick = function () {
        // console.log('frameClick')
    };
    MascotSlidersComponent.prototype.clearBalance = function () {
        this.balance.freeRounds = 0;
        this.balance.balance = 0;
        this.balance.win = 0;
    };
    MascotSlidersComponent.prototype.getPartnerName = function () {
        return this.partnerService.getName();
        //return this.currentPartner.name;
    };
    MascotSlidersComponent.prototype.isIphone = function () {
        return this.deviceDetectionService.isIphone();
    };
    MascotSlidersComponent.prototype.isIpad = function () {
        return this.deviceDetectionService.isIpad();
    };
    MascotSlidersComponent.prototype.isAndroid = function () {
        return this.deviceDetectionService.isAndroid();
    };
    MascotSlidersComponent.prototype.setAndroidSettings = function () {
        if (this.isAndroid()) {
            document.addEventListener('click', this.fullScreenService.setFullScreen);
        }
    };
    MascotSlidersComponent.prototype.goToPreviousSlide = function (slider) {
        slider.go('<');
    };
    MascotSlidersComponent.prototype.goToNextSlide = function (slider) {
        slider.go('>');
    };
    return MascotSlidersComponent;
}());
export { MascotSlidersComponent };
