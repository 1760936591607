import { YetiSnowdriftModel } from "../models/yetiSnowdrift.model";
import { YETI_FINDING_ELEMENTS_NAMES } from "../yeti.config";
import * as i0 from "@angular/core";
import * as i1 from "./yetiCommon.service";
import * as i2 from "./yetiResponses.service";
var YetiGameService = /** @class */ (function () {
    function YetiGameService(yetiCommonService, yetiResponsesService) {
        var _this = this;
        this.yetiCommonService = yetiCommonService;
        this.yetiResponsesService = yetiResponsesService;
        this.gameCommonContainer = new PIXI.Container();
        this.findingElementsContainer = new PIXI.Container();
        this.balanceContainer = new PIXI.Container();
        this.eyeLoaderContainer = new PIXI.Container();
        this.restartGameContainer = new PIXI.Container();
        this.popupIsShown = false;
        this.userBalance = 0;
        this.userWinBalance = 0;
        document.body.addEventListener('click', function () {
            if (_this.popupIsShown) {
                _this.hideResultPopup();
            }
        });
    }
    YetiGameService.prototype.setRedeemBtnBehavior = function (active) {
        if (active === void 0) { active = true; }
        if (active) {
            this.activateRedeemBtn();
        }
        else {
            this.deactivateRedeemBtn();
        }
    };
    YetiGameService.prototype.activateRedeemBtn = function () {
        this.redeemBtn.alpha = 1;
        this.yetiCommonService.addButtonBehavior(this.redeemBtn);
        this.redeemBtn.on('pointerdown', this.emitRestartGame.bind(this));
    };
    YetiGameService.prototype.deactivateRedeemBtn = function () {
        this.redeemBtn.alpha = .8;
        this.yetiCommonService.removeButtonBehavior(this.redeemBtn);
        this.redeemBtn.off('pointerdown', this.emitRestartGame.bind(this));
    };
    YetiGameService.prototype.emitRestartGame = function () {
        this.yetiCommonService.restartGame.emit(true);
    };
    YetiGameService.prototype.setGame = function (stage, resources, renderer) {
        this.stage = stage;
        this.resources = resources;
        this.renderer = renderer;
        this.stage.addChild(this.gameCommonContainer);
        this.showBalance();
    };
    YetiGameService.prototype.startGame = function () {
        var _this = this;
        this.restartGameContainer.removeChildren();
        this.findingElementsContainer.removeChildren();
        this.eyeLoaderContainer.removeChildren();
        this.eyeLoaderContainer = new PIXI.Container();
        this.yetiCommonService.showMainLoader.emit({ show: true, minMilliSec: 0 });
        this.yetiResponsesService.initGame().subscribe(function (data) {
            try {
                if (data.status) {
                    _this.yetiCommonService.isGameStart = true;
                    _this.yetiCommonService.showRules.emit(false);
                    _this.sessionInfo = data;
                    if (_this.sessionInfo.elements && _this.sessionInfo.elements.length) {
                        _this.updateBalance(data.balance, data.winBalance);
                        _this.setSnowdrifts(data.elements, data.openedElements);
                        _this.renderer.render(_this.stage);
                    }
                    else {
                        _this.yetiCommonService.showInfoPopup.emit({ show: true });
                    }
                }
                else {
                    _this.yetiCommonService.showInfoPopup.emit({ show: true, message: data.message });
                }
            }
            catch (e) {
                _this.yetiCommonService.showInfoPopup.emit({ show: true });
            }
        }, function () {
            _this.yetiCommonService.showInfoPopup.emit({ show: true });
        }, function () {
            _this.yetiCommonService.showMainLoader.emit({ show: false });
        });
    };
    YetiGameService.prototype.setSnowdrifts = function (elements, openedElements) {
        var _this = this;
        this.snowdrifts = [];
        elements.forEach(function (el, index) {
            var opened = openedElements.find(function (openedEl) { return el === openedEl.uuid; });
            _this.snowdrifts.push(new YetiSnowdriftModel(el, index, opened));
        });
        this.snowdrifts.forEach(function (el) {
            _this.createSnowdrift(el);
        });
        this.gameCommonContainer.addChild(this.findingElementsContainer);
    };
    YetiGameService.prototype.createSnowdrift = function (instance) {
        var _this = this;
        if (instance.isOpened()) {
            this.showOpenedElement(instance, instance.getOpenedElementInfo());
        }
        else {
            var reverseDrifts = [0, 3];
            var driftBgImg = reverseDrifts.includes(instance.getIndex()) ? this.resources.snowdriftReverse.texture : this.resources.snowdrift.texture;
            var el = new PIXI.Sprite(driftBgImg);
            var imgMarkupHeight = 230;
            var scale = (imgMarkupHeight / el.height) * instance.getScale();
            var posX = instance.getPosition().x;
            var posY = instance.getPosition().y;
            el.scale.set(scale, scale);
            el.position.set(posX, posY);
            el.anchor.set(.5, 1);
            this.yetiCommonService.addButtonBehavior(el);
            el.on('pointerdown', function (event) {
                _this.openElement(instance);
            });
            this.findingElementsContainer.addChild(el);
        }
    };
    YetiGameService.prototype.openElement = function (el) {
        var _this = this;
        this.setEyeLoader(el);
        this.disableClickEvents();
        var sessionUuid = this.sessionInfo.sessionUuid;
        this.yetiResponsesService.play(sessionUuid, el.getId()).subscribe(function (data) {
            try {
                if (data.status) {
                    var popupType = data.type;
                    var selectedElPosition_1 = el.getPosition();
                    if (data.element) {
                        var selectedElInfo_1 = data.element;
                        _this.findingElementsContainer.children.forEach(function (snowdrift) {
                            if (selectedElPosition_1.x === snowdrift.transform.position._x &&
                                selectedElPosition_1.y === snowdrift.transform.position._y &&
                                snowdrift.pluginName === 'sprite') {
                                _this.showOpenedElement(el, selectedElInfo_1, snowdrift);
                            }
                        });
                    }
                    if (data.elements && Number(data.attempts) === 0) {
                        _this.disableClickEvents();
                        setTimeout(function () {
                            var newElements = [];
                            data.elements.forEach(function (needToOpenEl) {
                                _this.snowdrifts.forEach(function (existSnowDrift) {
                                    if (needToOpenEl.uuid === existSnowDrift.getId()) {
                                        existSnowDrift.setOpenedElementInfo(needToOpenEl);
                                        newElements.push(existSnowDrift);
                                    }
                                });
                            });
                            _this.findingElementsContainer.children.forEach(function (snowdrift) {
                                newElements.forEach(function (newElement) {
                                    var newElementPosition = newElement.getPosition();
                                    var newElementInfo = newElement.getOpenedElementInfo();
                                    if (newElementPosition.x === snowdrift.transform.position._x &&
                                        newElementPosition.y === snowdrift.transform.position._y &&
                                        snowdrift.pluginName === 'sprite' && snowdrift.cursor === 'pointer') {
                                        _this.showOpenedElement(newElement, newElementInfo, snowdrift);
                                    }
                                });
                            });
                            setTimeout(function () {
                                _this.restartGame();
                                _this.enableClickEvents();
                            }, 1000);
                        }, 1000);
                    }
                    else {
                        _this.enableClickEvents();
                    }
                    _this.updateBalance(data.balance, data.winBalance);
                }
                else {
                    _this.yetiCommonService.showInfoPopup.emit({ show: true, message: data.message });
                }
            }
            catch (e) {
                _this.yetiCommonService.showInfoPopup.emit({ show: true });
            }
        }, function () {
            _this.yetiCommonService.showInfoPopup.emit({ show: true });
        }, function () {
            _this.hideEyeLoader();
        });
    };
    YetiGameService.prototype.showOpenedElement = function (el, selectedElInfo, snowdrift) {
        var _this = this;
        var snowBroken = new PIXI.Sprite(this.resources.snowdriftBroken.texture);
        var snowBrokenMarkupHeight = 90;
        var scaleCoefficient = el.getScale() * .75;
        var snowBrokenScale = snowBrokenMarkupHeight / snowBroken.height * scaleCoefficient;
        var snowBrokenPosX = el.getPosition().x;
        var snowBrokenPosY = el.getPosition().y;
        snowBroken.scale.set(snowBrokenScale);
        snowBroken.position.set(snowBrokenPosX, snowBrokenPosY);
        snowBroken.anchor.set(.5, 1);
        if (selectedElInfo.shortName !== YETI_FINDING_ELEMENTS_NAMES.empty) {
            var prize = new PIXI.Sprite(this.resources[this.getFindingElementImage(selectedElInfo.shortName)].texture);
            var imgMarkupHeight = 230;
            var scale = imgMarkupHeight / prize.height * scaleCoefficient;
            var posX = el.getPosition().x;
            var posY = el.getPosition().y - 25;
            prize.scale.set(scale, scale);
            prize.position.set(posX, posY);
            prize.anchor.set(.5, 1);
            this.findingElementsContainer.addChild(prize);
            if (selectedElInfo.shortName === YETI_FINDING_ELEMENTS_NAMES.poison) {
                var poisionWrapper = new PIXI.Sprite(this.resources.poisonWrapper.texture);
                var poisionWrapperMarkupHeight = 245;
                var poisionWrapperScale = poisionWrapperMarkupHeight / poisionWrapper.height * scaleCoefficient;
                // const posX = el.getPosition().x;
                // const posY = el.getPosition().y - 25;
                poisionWrapper.scale.set(poisionWrapperScale);
                poisionWrapper.position.set(posX, posY);
                poisionWrapper.anchor.set(.5, 1);
                this.findingElementsContainer.addChild(poisionWrapper);
            }
            if (selectedElInfo.shortName !== YETI_FINDING_ELEMENTS_NAMES.poison &&
                selectedElInfo.shortName !== YETI_FINDING_ELEMENTS_NAMES.empty && selectedElInfo.factor) {
                var id = this.resources.numbersAtlas.textures;
                var x = new PIXI.Sprite(id['X.png']);
                var xMarkupHeight = 60;
                var xScale = xMarkupHeight / x.height * scaleCoefficient;
                var xPosX = snowBroken.position.x + (snowBroken.width / 2) * 1.2;
                var xPosY = prize.position.y;
                x.scale.set(xScale);
                x.position.set(xPosX, xPosY);
                x.anchor.set(.5, 1);
                this.findingElementsContainer.addChild(x);
                var factorFormatted = Number(selectedElInfo.factor).toFixed(0);
                var num = new PIXI.Sprite(id[factorFormatted + ".png"]);
                var numMarkupHeight = 130;
                var numScale = numMarkupHeight / num.height * scaleCoefficient;
                var numPosX = x.position.x + x.width * 1.05;
                var numPosY = x.position.y;
                num.scale.set(numScale);
                num.position.set(numPosX, numPosY);
                num.anchor.set(.5, 1);
                this.findingElementsContainer.addChild(num);
            }
        }
        setTimeout(function () {
            if (snowdrift) {
                snowdrift.alpha = 0;
                _this.yetiCommonService.removeButtonBehavior(snowdrift);
            }
            _this.findingElementsContainer.addChild(snowBroken);
            _this.renderer.render(_this.stage);
        });
    };
    YetiGameService.prototype.showResultPopup = function (type) {
        var _this = this;
        var textureName = type ? 'popupLuck' : 'popupNoLuck';
        this.popupResult = new PIXI.Sprite(this.resources[textureName].texture);
        var popupMarkupHeight = 500;
        var popupScale = popupMarkupHeight / this.popupResult.height;
        var count = 0;
        this.popupResult.scale.set(popupScale);
        this.popupResult.position.set(this.stage.width / 2, this.stage.height / 2);
        this.popupResult.anchor.set(.5, .5);
        this.popupResult.rotation = -1;
        this.popupResult.alpha = 0;
        this.findingElementsContainer.addChild(this.popupResult);
        this.popupTicker = new PIXI.ticker.Ticker();
        this.popupTicker.stop();
        var speed = .25;
        this.popupTicker.add(function (deltaTime) {
            count += 0.02;
            if (_this.popupResult.rotation < 0) {
                _this.popupResult.scale.x = Math.sin(count);
                _this.popupResult.scale.y = Math.sin(count);
                _this.popupResult.rotation += 0.05;
                _this.popupResult.alpha += 0.05;
                _this.renderer.render(_this.stage);
            }
            else {
                _this.popupTicker.stop();
                _this.popupIsShown = true;
            }
        });
        this.popupTicker.start();
    };
    YetiGameService.prototype.hideResultPopup = function () {
        var _this = this;
        this.popupIsShown = false;
        this.popupTicker = new PIXI.ticker.Ticker();
        this.popupTicker.stop();
        var count = 0.525;
        this.popupTicker.add(function (deltaTime) {
            count -= 0.025;
            if (_this.popupResult.rotation > -1) {
                _this.popupResult.scale.x = Math.sin(count);
                _this.popupResult.scale.y = Math.sin(count);
                _this.popupResult.rotation -= 0.05;
                _this.popupResult.alpha -= 0.05;
                _this.renderer.render(_this.stage);
            }
            else {
                _this.popupTicker.stop();
            }
        });
        this.popupTicker.start();
    };
    YetiGameService.prototype.setEyeLoader = function (el) {
        var _this = this;
        var selectedElPosition = el.getPosition();
        if (this.eyeLoaderContainer.children.length) {
            this.ticker.start();
            this.eyeLoaderContainer.alpha = 1;
        }
        else {
            var circle = this.drawCircle(20, 20, 20, 0xFFFFFF);
            this.eyeLoaderContainer.addChild(circle);
            var eye_1 = this.drawCircle(28, 29, 10, 0x486a8b);
            this.eyeLoaderContainer.addChild(eye_1);
            var circle2 = this.drawCircle(80, 20, 20, 0xFFFFFF);
            this.eyeLoaderContainer.addChild(circle2);
            var eye2_1 = this.drawCircle(88, 29, 10, 0x486a8b);
            this.eyeLoaderContainer.addChild(eye2_1);
            this.findingElementsContainer.addChild(this.eyeLoaderContainer);
            this.ticker = new PIXI.ticker.Ticker();
            this.ticker.stop();
            var speed_1 = .25;
            this.ticker.add(function (deltaTime) {
                if (eye_1.x === 28 || eye_1.x === 12) {
                    speed_1 = -speed_1;
                }
                eye_1.x += speed_1;
                eye2_1.x += speed_1;
                _this.renderer.render(_this.stage);
            });
            this.ticker.start();
        }
        this.eyeLoaderContainer.pivot.set(40, 70);
        this.eyeLoaderContainer.x = selectedElPosition.x;
        this.eyeLoaderContainer.y = selectedElPosition.y;
        this.renderer.render(this.stage);
    };
    YetiGameService.prototype.drawCircle = function (x, y, radius, color) {
        var circle = new PIXI.Graphics();
        circle.beginFill(color);
        circle.drawCircle(0, 0, radius);
        circle.endFill();
        circle.x = x;
        circle.y = y;
        // let textrure = circle.generateTexture();
        // let circleSprite = new PIXI.Sprite(textrure);
        return circle;
    };
    YetiGameService.prototype.hideEyeLoader = function () {
        this.eyeLoaderContainer.alpha = 0;
        this.ticker.stop();
        this.renderer.render(this.stage);
    };
    YetiGameService.prototype.showBalance = function () {
        var _this = this;
        this.balanceContainer.removeChildren();
        this.balanceContainer = new PIXI.Container();
        var minWidth = 300;
        var padding = 10;
        var scoreWinContainer = new PIXI.Container();
        var scoreContainer = new PIXI.Container();
        scoreContainer = this.setScore(scoreContainer, this.userBalance);
        scoreWinContainer = this.setScore(scoreWinContainer, this.userWinBalance);
        var bg = new PIXI.Sprite(this.resources.scoreBg.texture);
        var bgWin = new PIXI.Sprite(this.resources.scoreWinsBg.texture);
        var bgZoom = 1.4;
        var bgMarkupWidth = minWidth > scoreContainer.width * bgZoom ? minWidth : scoreContainer.width * bgZoom > scoreWinContainer.width * bgZoom ? scoreContainer.width * bgZoom : scoreWinContainer.width * bgZoom;
        var bgScale = bgMarkupWidth / bg.width;
        bg.scale.set(bgScale);
        bgWin.scale.set(bgScale);
        this.balanceContainer.addChild(bg);
        this.balanceContainer.addChild(bgWin);
        bgWin.position.y = bg.position.y + bg.height + 10;
        this.balanceContainer.addChild(scoreWinContainer);
        this.balanceContainer.addChild(scoreContainer);
        this.balanceContainer.position.set(padding);
        scoreContainer.position.x = bg.width / 2 - scoreContainer.width / 2;
        scoreContainer.position.y = bg.height / 2 - (scoreContainer.height / 2 * .6);
        scoreWinContainer.position.x = bgWin.width / 2 - scoreWinContainer.width / 2;
        scoreWinContainer.position.y = bgWin.height / 2 - (scoreContainer.height / 2 * .6) + bg.height + 10;
        setTimeout(function () {
            _this.gameCommonContainer.addChild(_this.balanceContainer);
            _this.renderer.render(_this.stage);
        });
    };
    YetiGameService.prototype.setScore = function (scoreContainer, scorebalance) {
        var id = this.resources.numbersAtlas.textures;
        var lastAddItem = 0;
        var dollar = new PIXI.Sprite(id["S.png"]);
        var numMarkupHeight = 50;
        var dollarScale = numMarkupHeight / dollar.height;
        dollar.scale.set(dollarScale);
        lastAddItem += dollar.width * 1.1;
        scoreContainer.addChild(dollar);
        var balanceFormatted = scorebalance ? Number(scorebalance).toFixed(0) : 0;
        var balance = String(balanceFormatted);
        balance += 'd00';
        balance.split('').forEach(function (number) {
            var num, numScale, numPosY;
            if (number === 'd') {
                num = new PIXI.Sprite(id["dot.png"]);
                var dotMarkupHeight = 20;
                numScale = dotMarkupHeight / num.height;
                numPosY = dollar.height - 20;
            }
            else {
                num = new PIXI.Sprite(id[number + ".png"]);
                numScale = numMarkupHeight / num.height;
                numPosY = dollar.position.y;
            }
            var numPosX = lastAddItem;
            num.scale.set(numScale);
            num.position.set(numPosX, numPosY);
            lastAddItem += num.width * 1.15;
            scoreContainer.addChild(num);
        });
        return scoreContainer;
    };
    YetiGameService.prototype.updateBalance = function (balance, winBalance) {
        if (this.userBalance !== balance || this.userWinBalance !== winBalance) {
            this.userBalance = balance;
            this.userWinBalance = winBalance;
            this.showBalance();
        }
    };
    YetiGameService.prototype.restartGame = function () {
        var _this = this;
        this.restartGameContainer.x = 260;
        this.restartGameContainer.y = 500;
        var bg = new PIXI.Sprite(this.resources.askContinue.texture);
        var bgMarkupHeight = 180;
        var bgScale = bgMarkupHeight / bg.height;
        bg.scale.set(bgScale);
        this.restartGameContainer.addChild(bg);
        this.restartBtn = this.createRestartBtn(bg);
        this.restartGameContainer.addChild(this.restartBtn);
        this.yetiCommonService.addButtonBehavior(this.restartBtn);
        this.restartBtn.on('pointerdown', function (event) {
            _this.startGame();
        });
        this.redeemBtn = this.createRedeemBtn(bg);
        this.restartGameContainer.addChild(this.redeemBtn);
        var isRedeemActive = this.isUserBalanceMoreZero();
        this.setRedeemBtnBehavior(isRedeemActive);
        this.gameCommonContainer.addChild(this.restartGameContainer);
        this.renderer.render(this.stage);
    };
    YetiGameService.prototype.createRestartBtn = function (bg) {
        var playBtn = new PIXI.Sprite(this.resources.playBtn.texture);
        var btnMarkupHeight = 60;
        var playBtnScale = btnMarkupHeight / playBtn.height;
        playBtn.scale.set(playBtnScale);
        var playBtnPosX = bg.width / 3;
        var playBtnPosY = bg.height - playBtn.height / 1.5;
        playBtn.position.set(playBtnPosX, playBtnPosY);
        playBtn.anchor.set(.5, 0);
        return playBtn;
    };
    YetiGameService.prototype.createRedeemBtn = function (bg) {
        var redeemBtn = new PIXI.Sprite(this.resources.redeemBtn.texture);
        var redeemMarkupHeight = 60;
        var redeemBtnScale = redeemMarkupHeight / redeemBtn.height;
        redeemBtn.scale.set(redeemBtnScale);
        var redeemBtnPosX = bg.width * .666;
        var redeemBtnPosY = bg.height - redeemBtn.height / 1.5;
        redeemBtn.anchor.set(.5, 0);
        redeemBtn.position.set(redeemBtnPosX, redeemBtnPosY);
        return redeemBtn;
    };
    YetiGameService.prototype.isUserBalanceMoreZero = function () {
        return this.userBalance > 0;
    };
    YetiGameService.prototype.getFindingElementImage = function (shortName) {
        var result;
        switch (shortName) {
            case YETI_FINDING_ELEMENTS_NAMES.coins:
                result = 'prize1';
                break;
            case YETI_FINDING_ELEMENTS_NAMES.box: {
                result = 'prize2';
                break;
            }
            case YETI_FINDING_ELEMENTS_NAMES.ice: {
                result = 'prize3';
                break;
            }
            case YETI_FINDING_ELEMENTS_NAMES.bomb: {
                result = 'prize4';
                break;
            }
            case YETI_FINDING_ELEMENTS_NAMES.egg: {
                result = 'prize5';
                break;
            }
            case YETI_FINDING_ELEMENTS_NAMES.poison: {
                result = 'poison';
                break;
            }
        }
        return result;
    };
    YetiGameService.prototype.closeGame = function () {
        this.yetiCommonService.isGameStart = false;
        this.yetiCommonService.showRules.emit(true);
        this.findingElementsContainer.removeChildren();
        this.gameCommonContainer.removeChildren();
        this.eyeLoaderContainer.removeChildren();
        this.eyeLoaderContainer = new PIXI.Container();
        this.renderer.render(this.stage);
    };
    YetiGameService.prototype.disableClickEvents = function () {
        this.gameCommonContainer.interactiveChildren = false;
    };
    YetiGameService.prototype.enableClickEvents = function () {
        this.gameCommonContainer.interactiveChildren = true;
    };
    YetiGameService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YetiGameService_Factory() { return new YetiGameService(i0.ɵɵinject(i1.YetiCommonService), i0.ɵɵinject(i2.YetiResponsesService)); }, token: YetiGameService, providedIn: "root" });
    return YetiGameService;
}());
export { YetiGameService };
