import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {NFT_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class NFTService extends SettingsLoaderService {
    private config = NFT_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    generateWallet() {
        this.showLoader();
        return this.http.post<any>(this.config.generateWallet, {});
    }

    mintNFT(image) {
        const formData: FormData = new FormData();
        formData.append('image', image);
        // formData.append('groupId', groupId);

        this.showLoader();
        return this.http.post<any>(this.config.mintNFT, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }
}
