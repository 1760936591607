export const RouterPaths = {
  addresses: {
    main: '/home/settings/addresses',
    edit: '/home/settings/addresses/edit',
    add: '/home/settings/addresses/add',
  },
  prizes: {
    main: '/home/prizes',
    paypal: '/home/prizes/paypal',
    mailCheck: '/home/prizes/mail-check'
  },
  paypal: {
    main: '/home/settings/paypal',
    edit: '/home/settings/paypal/edit',
    add: '/home/settings/paypal/add',
  },
}
