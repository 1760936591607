import {Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MenuService} from "./service/menu.service";
import {LoginService} from "./service/login.service";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";

import {PhoneService} from "./service/phone.service";
import {StripeService} from "./service/stripe/stripe.service";
import {MenuBottomAddContentService} from "./service/menu/menuBottomAddContent.service";
import {InstallService} from "./service/install.service";
import {PrizesService} from "./service/prizes/prizes.service";
import {ConnectionService} from "ng-connection-service";
import {PopupComponent} from "./shared/popup/popup.component";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {PAGES_WITHOUT_LOGIN, PROJECT_CONFIG, PROVIDERS_TEMPLATES} from "./config/project";
import {PartnerService} from "./service/partner.service";
import {AppVersionrService} from "./service/app-version/appVersionr.service";
import {UsersPointService} from "./service/users/users-point.service";
import {KeyboardService} from "./service/keyboard.service";
import {environment} from "../environments/environment";
import {InstallComponent} from "./pages/install/install.component";
import {AppTestService} from "./service/app/appTest.service";
import {AppPromptService} from "./service/app/appPrompt.service";
import {KioskService} from "./service/kiosk.service";
import {GLOBAL_CLASSES} from "./config/globalClasses";
import {KioskAccessorService} from "./service/kiosk/kiosk-accessor.service";
import {KEYBOARD_SETTINGS} from "./config/keyboard_settings";
import {DOCUMENT} from "@angular/common";
import {HeroService} from "./service/hero.service";
import {NETWORK_PX} from "./config/api";
import {RouterPaths} from "./config/router.paths.model";
import {PARTNERS} from "./config/partners_settings";
import {TerminalService} from "./service/terminal/terminal.service";
import {FrontendTestSettingsService} from "./service/testing/frontendTestSettings.service";

import {SocketService} from "./service/sockets/socket.service";
import { Message } from './service/sockets/model/message';
import { Event } from './service/sockets/model/event';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    menu;
    signup = false;
    login = false;
    search = true;

    canListen = false;
    androidInstallBtn = false;
    IOSInstallBtn = false;
    deferredPrompt: any;

    userLoggedStatus = false;

    noInternetConnection = false;
    isConnected = true;
    isApp = null;

    ioConnection: any;
    messages: Message[] = [];

    PROJECT_CONFIG = PROJECT_CONFIG;

    showPopupGettingMoney = false;
    amountMoneyInTerminal: number;
    isMascotSingleTemplate: boolean;

    @HostListener('click', ['$event'])
    clickInside(event) {
        if  (this.canListen) {
            const snakeBtns = document.querySelectorAll('.is-notouched');
            snakeBtns.forEach(el => {
                el.classList.remove('is-notouched');
            });
        };
    }

    @HostListener('document:touchstart', ['$event'])
    @HostListener('document:click', ['$event'])
    handleTouch(event) {

        if (this.isApp || this.partnerService.isMascotSingle()) {
            if (this.isApp) {
                this.keyboardService.activityTimer.next(this.keyboardService.timer);
            }

            let inputs = document.querySelectorAll(KEYBOARD_SETTINGS.excludedInputs);

            if (!(typeof event.target.className === "string" && event.target.className.indexOf('keyboard') !== -1) &&
              (event.target.nodeName !== 'INPUT' || event.target.type === 'radio' || event.target.type === 'checkbox') &&
              !this.partnerService.isMascotSingle()) {
                this.keyboardService.keyboardVisible.next(false);
                inputs.forEach(el => {
                    el.scrollLeft = el.scrollWidth;
                });
            } else if (!this.showPopupGettingMoney && inputs.length) {
                this.keyboardService.showKeyboard();
            }
        }
    }

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        e.preventDefault();
        // store install avaliable event
        this.deferredPrompt = e;

        //console.log(e);
        // show button with display:block;

        this.appPromptService.deferredPrompt = this.deferredPrompt;
    }

    constructor(private socketService: SocketService,
                private menuService: MenuService,
                private router: Router,
                private phoneService:PhoneService,
                private stripeService:StripeService,
                private appVersionrService:AppVersionrService,
                private installService: InstallService,
                private prizesService: PrizesService,
                private menuBottomAddContentService: MenuBottomAddContentService,
                private connectionService: ConnectionService,
                private popupComponent: PopupComponent,
                private partnerService: PartnerService,
                private terminalService: TerminalService,
                private auth:AuthenticationService,
                private activatedRoute: ActivatedRoute,
                private usersPointService: UsersPointService,
                private appTestService: AppTestService,
                private keyboardService: KeyboardService,
                private appPromptService: AppPromptService,
                private kioskService: KioskService,
                private kioskAccessorService: KioskAccessorService,
                private heroService: HeroService,
                private loginService: LoginService,
                private frontendTestSettingsService: FrontendTestSettingsService,
                @Inject(DOCUMENT) private document: Document) {

        this.isApp = this.kioskService.isKiosk();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //console.log('Navigation Start')
                this.canListen = false;
            }

            if (event instanceof NavigationEnd) {
                //console.log('Navigation End')
                if (event.url.indexOf('/home/prizes/paypal')) {
                    this.prizesService.temporaryEmail.next('');
                }

                if (event.url.indexOf('/home/prizes/mail-check')) {
                    this.prizesService.temporaryAddress.next('');
                }

                const snakeBtns = document.querySelectorAll('.snake-btn');
                snakeBtns.forEach(el => {
                    (<HTMLElement>el).classList.add('is-notouched');
                });

                setTimeout(()  => {
                    this.canListen = true;
                }, 0);

                this.setBodyClassForLongContent();

                window.addEventListener('resize', () => {
                    this.setBodyClassForLongContent();
                });

                this.appHeight();
            }
        });

        if (this.frontendTestSettingsService.isHideForTesting()) {
            this.networkResponse();
        }

        //this.partnerService.detectPartner();

        this.onPopupGettingMoneyToggle();

        let $this = this;

        this.auth.userLoggedStatusChanged.subscribe((value: any) => this.logged(value));

        document.body.addEventListener('click', e => {
            //console.log('clicked body');
        });

        this.checkMascotSinglePage();
    }

    checkMascotSinglePage() {
        // this.partnerService.currentPartnerTemplate.subscribe(val => {
        //     if (val) {
        //         if (this.partnerService.isMascotSingle() && !this.auth.isUserLogged()) {
        //             // this.router.navigate([RouterPaths.mascot.main], {queryParams: {logged: false}});
        //             console.log("RouterPaths.mascot.main 3");
        //             this.router.navigate([RouterPaths.mascot.main], {queryParams: {notLogged: true}, queryParamsHandling: 'merge'});
        //         }
        //     }
        // });
    }

    logged(value){
       if(!value){
           this.showPopupGettingMoney = false;
           document.body.classList.remove('blur-page');
       }
    }

    networkResponse(){
        if(this.isApp){
            this.networkResponseIco();
        }
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.noInternetConnection = false;
                this.popupComponent.closePopup();
            } else {
                this.noNetwork();
            }
        });
    }

    networkStatus = true;
    networkResponseIco() {
        fetch(NETWORK_PX+'?d=' + Date.now())
            .then(response => {
                if (!response.ok) {
                    this.noNetwork();

                    return;
                }

                if(!this.networkStatus){
                    this.networkStatus = true;

                    this.noInternetConnection = false;
                    this.popupComponent.closePopup();
                }

            })
            .catch(error => {
                this.noNetwork();
            });

        let $this = this;
        setTimeout(function () {
            $this.networkResponseIco();
        },5000);
    }


    noNetwork(){
        if(this.networkStatus) {
            this.networkStatus = false;

            this.noInternetConnection = true;
            this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false});
            this.auth.logout();
        }
    }


    userLogged(){
        this.auth.userLoggedStatusChanged.subscribe(val => {
            if(this.userLoggedStatus !== val && val === false){
                this.userLoggedStatus = val;

                let isMascotSingle = this.partnerService.isMascotSingle();
                if (isMascotSingle) {

                }else{
                    this.router.navigate(['/']);
                }
            }else{
                this.userLoggedStatus = val;
            }
        });

    }

    ngOnInit() {
        if(!this.partnerService.storageGetCurrentPartner() && PAGES_WITHOUT_LOGIN.indexOf(window.location.pathname) === -1){
            console.log('app partner');
            this.router.navigate(['/']);
        }

        if(this.isApp){
            if(!this.terminalService.isInfo()){
                console.log('app terminal');
                this.router.navigate(['/']);
            }
        }

        this.userLogged();


        if (this.heroService.isCryptoVersion() && !this.isApp) {
            this.document.body.classList.add('v-crypto');
        }

        this.setCorrectThumbnailIcon();
        this.phoneService.init();

        this.setPointHash();
        this.setWebTest();

        this.loginService.loginChanged.subscribe((value: any) => this.loginChanged(value));

        setTimeout(() => {
            this.menuService.menuChanged.subscribe((value: any) => this.menuChanged(value));
        });

        this.activatedRoute.queryParams.subscribe(params => {
            this.detectBrowser();
        });

        this.refreshToken();

        this.appVersion();

        this.visibilitychange();

        window.addEventListener('resize', this.appHeight);
        window.addEventListener('orientationchange', this.appHeight);
        this.appHeight();
        this.setKioskClass();
        this.setBodyClassForLongContent();

        //this.initIoConnection();
    }

    ngOnDestroy() {

    }

    private initIoConnection(): void {
        this.socketService.initSocket();

        this.ioConnection = this.socketService.onMessage()
            .subscribe((message: Message) => {
                this.messages.push(message);
            });


        this.socketService.onEvent(Event.CONNECT)
            .subscribe(() => {
                console.log('connected');
            });

        this.socketService.onEvent(Event.DISCONNECT)
            .subscribe(() => {
                console.log('disconnected');
            });
    }

    onPopupGettingMoneyToggle() {
        this.kioskAccessorService.paymentAmountChanged.subscribe(amount => {
            if (amount > 0) {
                this.showPopupGettingMoney = true;
                this.amountMoneyInTerminal = amount;
                this.keyboardService.keyboardVisible.next(false);
                document.body.classList.add('blur-page');
            } else {
                this.showPopupGettingMoney = false;
                document.body.classList.remove('blur-page');
            }
        });
    }

    visibilitychange(){
        this.auth.setStamp();

        document.addEventListener('visibilitychange', () => {
            //alert(document.hidden)
            if (document.hidden == false){
                this.auth.checkStamp();
            } else {

            }

        });


    }

    setPointHash(){
        this.activatedRoute.queryParams.subscribe(params => {
            try {
                if(params['point']){
                    if(params['point'] != ''){
                        this.usersPointService.saveHashInStorage(params['point']);
                    }
                }
            }catch (e) {

            }
        });
    }

    setWebTest(){
        this.activatedRoute.queryParams.subscribe(params => {
            try {
                if(params['iswebtest']){
                    if(params['iswebtest'] != ''){
                        this.appTestService.saveInStorage(params['iswebtest']);
                    }
                }else{
                    this.appTestService.clearStorage();
                }
            }catch (e) {

            }
        });
    }

    appVersion(){
        this.appVersionrService.checkVersion();
    }

    refreshToken(){
        let $this = this;
        setTimeout(function(){
            $this.auth.refreshToken();
        },60*5*1000);
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    addStripeJs(key){
        this.stripeService.setPublishableKey(key);

    }

    menuChanged(status){
        this.menu = status;
    }

    signUpChanged(status){
        this.signup = status;
    }

    loginChanged(status){
        this.login = status;
    }

    searchVisibilityChanged(value) {
        this.search = value;
    }

    addToHomeScreen() {
        // wait for click install button by user

        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
                // user accept the prompt

                // lets hidden button
                this.androidInstallBtn = false;
                //overlay_button.classList.add('is-app');

            } else {
                console.log('User dismissed the prompt');
            }
            //this.deferredPrompt = null;
        });
    }

    detectBrowser() {
        // let pointSubdomain = this.usersPointService.isPointSubdomain();
        // if (pointSubdomain) {
        //     this.installService.hide();
        //     this.IOSInstallBtn = false;
        //     return;
        // }
        const isLoginPage = location.pathname === '/' || location.pathname === '';

        if (window.matchMedia('(display-mode: standalone)').matches || window.innerWidth >= 600 || !isLoginPage) {
            this.installService.appInstalled.next(true);
            this.installService.hide();
            this.IOSInstallBtn = false;
        } else {
            this.installService.show();
        }
    }

    appHeight() {
        setTimeout(() => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            doc.style.setProperty('--app-width', `${window.innerWidth}px`);
        }, 0);
    }

    setKioskClass() {
        if (this.kioskService.isKiosk()) {
            document.body.classList.add(GLOBAL_CLASSES.kioskApp);
        }
    }

    setBodyClassForLongContent() {
        setTimeout(() => {
            const pageEl = document.body.querySelector('.page');
            const body = document.body;
            const maxAspectRatio = 10/8;
            const minAspectRatio = 10/11;
            const minWidth = 1280;
            const currentRatio = window.innerWidth / window.innerHeight;
            const ifSquareRatio = currentRatio <= maxAspectRatio && currentRatio >= minAspectRatio && minWidth <= window.innerWidth;

            // if (pageEl && pageEl.classList.contains(GLOBAL_CLASSES.pageLongContent) && this.kioskService.isKiosk()) {
            //     body.classList.add(GLOBAL_CLASSES.pageLongContent);
            // } else {
            //     body.classList.remove(GLOBAL_CLASSES.pageLongContent);
            // }

            // if (!this.kioskService.isKiosk() && pageEl) {
            //     pageEl.classList.remove(GLOBAL_CLASSES.pageLongContent);
            // }

            // if (this.kioskService.isKiosk()) {
            //     body.classList.add(GLOBAL_CLASSES.kioskApp);
            // } else {
            //     body.classList.remove(GLOBAL_CLASSES.kioskApp);
            // }

            if (pageEl && pageEl.classList.contains(GLOBAL_CLASSES.pageLongContent)) {
                // body.classList.add(GLOBAL_CLASSES.pageLongContent);
            } else {
                body.classList.remove(GLOBAL_CLASSES.pageLongContent);
            }

            if (!ifSquareRatio && pageEl) {
                body.classList.remove(GLOBAL_CLASSES.pageLongContent);
                pageEl.classList.remove(GLOBAL_CLASSES.pageLongContent);
            }

            if (ifSquareRatio) {
                body.classList.add('is-square');
            } else {
                body.classList.remove('is-square');
            }

        }, 600);
    }

    setCorrectThumbnailIcon() {
        const partner = this.partnerService.getShortName();
        if (partner === PARTNERS.snekapek.short_name || partner === PARTNERS.sap.short_name) {
            this.changeThumbnailIcons(PARTNERS.snekapek.short_name);
        }
        if (partner === PARTNERS.mascot.short_name) {
            this.changeThumbnailIcons(PARTNERS.mascot.short_name);
        }
    }

    changeThumbnailIcon(selector, icon, partner) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        const newIcon = defaultIcon.split('/icons')[0] + `/partners/${partner}/icons/${icon}`;
        document.querySelector(selector).setAttribute('href', newIcon);
    }

    changeThumbnailIcons(partner) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', partner);
    }

}


