import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvestmentsMembershipModel} from "../../../models/investments/investmentsMembership";
import {CRYPTO_AMOUNT_MASK, CRYPTO_SETTINGS} from "../../../config/crypto_settings";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {InvestmentsService} from "../../../service/investments/investments.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {
    InvestmentsPopupConfirmCryptoModel, InvestmentsPopupConfirmPrizesModel,
    InvestmentsPopupConfirmTradeModel
} from "../../../models/investments/investmentsPopupConfirm.model";
import {PROJECT_CONFIG} from "../../../config/project";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {CONFIRM_REQUEST, POPUP_TYPES, showPopupFormType} from "./investments-popup-form.config";
import {formatCurrency, formatNumber} from "@angular/common";
import {templateJitUrl} from "@angular/compiler";
import {CryptoTransactionsService} from "../../../service/cryptoTransactions.service";

@Component({
    selector: 'app-investments-popup-form',
    templateUrl: './investments-popup-form.component.html',
    styleUrls: ['./investments-popup-form.component.scss']
})
export class InvestmentsPopupFormComponent implements OnInit {

    @Input() showPopupForm: showPopupFormType;
    @Input() data;
    @Input() formType;
    @Input() confirmRequest;
    @Input() fromPage;


    @Output() close = new EventEmitter();
    popupConfirmData: InvestmentsPopupConfirmCryptoModel | InvestmentsPopupConfirmTradeModel | InvestmentsPopupConfirmPrizesModel;
    confirmPopupType;

    sendCryptoForm: FormGroup;
    tradeForm: FormGroup;

    moreThenAvailable = false;
    selectedFrom;
    selectedTo = null;
    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;

    CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;

    constructor(private formBuilder: FormBuilder,
                private investmentsService: InvestmentsService,
                private prizesService: PrizesService,
                private cryptoTransactionsService: CryptoTransactionsService,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                ) {
        this.sendCryptoForm = this.formBuilder.group({
            amount: [null, Validators.required],
            address: [null, Validators.required],
            note: [null, Validators.required],
        });

        this.tradeForm = this.formBuilder.group({
            from: [null, Validators.required],
            to: [null, Validators.required],
            amount: [null, Validators.required],
        });
    }


    ngOnInit() {

    }

    ngOnChanges() {
        if (this.data && this.formType === 'trade') {
            this.setSelects();
        }

        if (this.data && this.data.amount) {
            this.sendCryptoForm.controls.amount.setValue(this.data.amount);
        }

        if (this.showPopupForm === 'show') {
            document.body.classList.add('hide-menu');
        }
    }

    setSelects() {
        this.selectedTo = null;
        this.selectedFrom = null;
        const selectedGroup = this.getFromOptions().find(el => el.currencyIdFrom === this.data.checkedCurrency.from && el.currencyIdTo === this.data.checkedCurrency.to);

        setTimeout(() => {
            this.selectedFrom = selectedGroup;
            this.tradeForm.controls.from.setValue(this.selectedFrom);

            setTimeout(() => {
                this.selectedTo = selectedGroup;
                this.tradeForm.controls.to.setValue(this.selectedTo);
            }, 100);
        }, 100);
    }

    onCurrencyFromChange(item) {
        const currencyIdFrom = item.value ? item.value.currencyIdFrom : item.currencyIdFrom;
        const currencyIdTo = item.value ? item.value.currencyIdTo : item.currencyIdTo;
        const currencyFromName = item.value ? item.value.nameFrom : item.nameFrom;
        const currencyToName = item.value ? item.value.nameTo : item.nameTo;



        this.investmentsService.getTradingInfo(currencyIdFrom, currencyIdTo).subscribe(data => {
            this.updateInfo(data, currencyFromName, currencyIdFrom, currencyToName, currencyIdTo);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    updateInfo(data, currencyFromName, currencyIdFrom, currencyToName, currencyIdTo) {
        this.data.availableAmount = data.result.availableAmount;
        this.data.checkedCurrency.fromName = currencyFromName;
        this.data.checkedCurrency.from = currencyIdFrom;
        this.data.checkedCurrency.toName = currencyToName;
        this.data.checkedCurrency.to = currencyIdTo;

        setTimeout(() => {
            if (this.selectedFrom !== this.selectedTo) {
                this.tradeForm.controls.to.setValue(null);
                this.data.checkedCurrency.toName = null;
                this.data.checkedCurrency.to = null;
            }
        }, 100);
    }

    onChangeToCurrency(value) {
        this.data.checkedCurrency.toName = value.value.currencyToName;
        this.data.checkedCurrency.to = value.value.currencyIdTo;

        this.setSelects();
    }

    getCryptoIcon(icon) {
        return icon;
        // if (CRYPTO_SETTINGS[icon]) {
        //     return CRYPTO_SETTINGS[icon].icon;
        // } else {
        //     return CRYPTO_SETTINGS.default.icon;
        // }
    }

    closePopup(result?) {
        if (result) {
            this.close.emit(result);
        } else {
            this.close.emit();
        }

        this.sendCryptoForm.reset();
        this.moreThenAvailable = false;
        document.body.classList.remove('hide-menu');
    }

    onConfirmPopupClose($event) {
        if ($event === 'all') {
            this.popupConfirmData = null;
            this.closePopup({status: true});
        } else {
            this.popupConfirmData = null;
            this.showPopupForm = 'show';
        }
    }

    onSubmit() {
        const amount = this.sendCryptoForm.controls.amount.value;
        const address = this.sendCryptoForm.controls.address.value;
        const note = this.sendCryptoForm.controls.note.value;
        const currencyId = this.data.id;

        this.investmentsService.getCryptoSpendInfo(amount, address, currencyId).subscribe(data => {
            this.checkSubmitRequest(data, note, address);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitBuyPrizes() {
        // const amount = this.sendCryptoForm.controls.amount.value;
        // const currencyAmount = amount / this.data.size;
        // this.checkSubmitPrizes(currencyAmount)

        const amount = this.sendCryptoForm.controls.amount.value;
        const crypto = this.getTotal();

        this.popupComponent.showPopupAgreement({text: `Please, confirm buying <br><br> ${formatNumber(crypto, 'en', CRYPTO_AMOUNT_MASK)}  ${this.data.name} <br> for <br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
            if (status) {
                this.confirmBuyCrypto();
            }
        });
    }

    checkSubmitSellPrizes() {
        const amount = this.sendCryptoForm.controls.amount.value;
        const usd = this.getTotal();

        this.popupComponent.showPopupAgreement({text: `Please, confirm selling <br><br> ${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}  ${this.data.name} <br> for <br> ${formatCurrency(usd, 'en','$')}`}, (status) => {
            if (status) {
                this.confirmCryptoTransitionFromRedeem();
            }
        });
    }

    confirmBuyCrypto() {
        if (this.fromPage === 'prizes' || this.fromPage === 'trade') {
            this.confirmCryptoTransitionFromRedeem();
        } else if (this.fromPage === 'founds') {
            this.confirmBuyCryptoFromFounds();
        }
    }

    confirmCryptoTransitionFromRedeem() {
        const currencyId = this.data.id;
        const amount = this.sendCryptoForm.controls.amount.value;
        if (amount && currencyId) {
            if (this.isBuyPopup()) {
                if (this.confirmRequest === CONFIRM_REQUEST.investmentBuy) {
                    this.confirmInvestmentBuyCrypto(amount, currencyId);
                } else if(this.confirmRequest === CONFIRM_REQUEST.tradeBuy){
                    this.buyCrypto(amount, currencyId);
                }else {
                    this.confirmRedeemPayout(amount, currencyId);
                }
            } else if (this.isSellPopup()) {
                if (this.confirmRequest === CONFIRM_REQUEST.investmentSell) {
                    this.confirmInvestmentSellCrypto(amount, currencyId);
                } else {
                    this.confirmSellCrypto(amount, currencyId);
                }
            }
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    confirmSellCrypto(amount, currencyId) {
        this.cryptoTransactionsService.sellCrypto(amount, currencyId).subscribe(data => {
            this.checkConfirm(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    buyCrypto(amount, currencyId){
        this.cryptoTransactionsService.buyCrypto(amount, currencyId).subscribe(data => {
            this.checkConfirm(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    confirmRedeemPayout(amount, currencyId) {
        this.cryptoTransactionsService.confirmRedeemPayout(amount, currencyId).subscribe(data => {
            this.checkConfirm(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    confirmInvestmentBuyCrypto(amount, currencyId) {
        this.cryptoTransactionsService.confirmInvestmentBuyCrypto(amount, currencyId).subscribe(data => {
            this.checkConfirm(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    confirmInvestmentSellCrypto(amount, currencyId) {
        this.cryptoTransactionsService.confirmInvestmentSellCrypto(amount, currencyId).subscribe(data => {
            this.checkConfirm(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    confirmBuyCryptoFromFounds() {
        const currencyId = this.data.id;
        const amount = this.sendCryptoForm.controls.amount.value;

        if (amount && currencyId) {
            this.cryptoTransactionsService.buyCryptoFromFounds(this.data.paymentMethodId, this.data.paymentMethod, amount, currencyId).subscribe(data => {
                this.checkConfirm(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }


    checkSubmitRequest(data, note, address) {
        try {
            if (data.status === true) {
                this.popupConfirmData = {
                    address: address,
                    note: note,
                    amount: data.result.amount,
                    fee: data.result.fee,
                    total: data.result.total,
                    currencyId: this.data.id,
                    currencyName: this.data.name
                }

                this.showPopupForm = 'blur';
                this.confirmPopupType = 'crypto';
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitTrade() {
        const fromId = this.tradeForm.controls.from.value.currencyIdFrom;
        const toId = this.tradeForm.controls.to.value.currencyIdTo;
        const amount = this.tradeForm.controls.amount.value;

        this.investmentsService.confirmTrade(fromId, toId, amount).subscribe(data => {
            this.checkSubmitTrade(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitTrade(data) {
        try {
            if (data.status === true) {

                this.popupConfirmData = {
                    from: data.result.from,
                    to: data.result.to,
                    amount: data.result.amount,
                    fee: data.result.fee,
                    total: data.result.total,
                    currencyIdFrom: this.tradeForm.controls.from.value.currencyIdFrom,
                    currencyIdTo: this.tradeForm.controls.to.value.currencyIdTo
                }

                this.showPopupForm = 'blur';
                this.confirmPopupType = 'trade';
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onInput($event) {
        this.sendCryptoForm.get('address').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getFromOptions(all?) {
        if (this.data) {
            const fromList = [];

            const fromTo = this.data.availableCurrencies.find(el => el.currencyIdFrom === this.data.checkedCurrency.from && el.currencyIdTo === this.data.checkedCurrency.to);
            const from = this.data.availableCurrencies.find(el => el.currencyIdFrom === this.data.checkedCurrency.from);

            if (fromTo) {
                fromList.push(fromTo);
            } else {
                fromList.push(from);
            }

            this.data.availableCurrencies.forEach((el, index) => {
                let needAdd = true;
                fromList.forEach(elFromList => {
                    if (elFromList.currencyIdFrom === el.currencyIdFrom) {
                        needAdd = false;
                    }
                });
                if (needAdd) {
                    fromList.push(el);
                }
            });

            return fromList;
        }
        return [];
    }

    getToOptions() {
        if (this.data) {
            const fromList = [];
            this.data.availableCurrencies.forEach((el, index) => {
                if (this.selectedFrom && el.currencyIdFrom === this.selectedFrom.currencyIdFrom) {
                    if (index === 0) {
                        fromList.push(el);
                    } else {
                        let needAdd = true;
                        fromList.forEach(elFromList => {
                            if (elFromList.currencyIdTo === el.currencyIdTo) {
                                needAdd = false;
                            }
                        });
                        if (needAdd) {
                            fromList.push(el);
                        }
                    }
                }
            });
            //console.log('toList', fromList);
            return fromList;
        }
        return [];
    }

    getLastTradePrice() {
        if (this.data && this.data.availableCurrencies.length) {
            const fromCurrency = this.data.availableCurrencies.find(el => el.currencyIdFrom === this.data.checkedCurrency.from && el.currencyIdTo === this.data.checkedCurrency.to);

            if (!fromCurrency) {
                return 0;
            } else {
                return fromCurrency.amount;
            }

        } else {
            return 0;
        }
    }

    getLastTradePriceName() {
        if (this.data && this.data.availableCurrencies.length) {
            const fromCurrency = this.data.availableCurrencies.find(el => el.currencyIdFrom === this.data.checkedCurrency.from && el.currencyIdTo === this.data.checkedCurrency.to);

            if (!fromCurrency) {
                return 0;
            } else {
                return fromCurrency.nameFrom;
            }

        } else {
            return 0;
        }
    }

    loadDefaultCurrencyIcon($event) {
        const el = $event.target;
        el.src = '../../../../assets/images/currencies/' + this.DEFAULT_CRYPTO_ICON;
    }

    onAmountInput() {
        // if (this.isBuyPopup() || this.isSellPopup() || this.isCryptoPopup()) {
            let avaliable;
            const entered = this.sendCryptoForm.controls.amount.value;

            if (this.isCryptoPopup()) {
                avaliable = this.data.size;
            } else {
                avaliable = this.data.availableAmount;
            }

            if (avaliable && (avaliable < entered)) {
                this.sendCryptoForm.controls.amount.setValue(String(avaliable));
                this.moreThenAvailable = true;
            } else {
                this.moreThenAvailable = false;
            }
        // }
    }

    isSellPopup(): boolean {
        return this.formType === POPUP_TYPES.sell;
    }

    isBuyPopup(): boolean {
        return this.formType === POPUP_TYPES.buy;
    }

    isCryptoPopup(): boolean {
        return this.formType === POPUP_TYPES.crypto;
    }

    getFee(): number {
        const amount = +this.sendCryptoForm.controls.amount.value;
        const amountWithoutFee = amount - (amount / 100 * (100 - this.data.fee));
        return +amountWithoutFee.toFixed(10) || 0;
    }

    getTotal(): number {
        const amount = +this.sendCryptoForm.controls.amount.value;
        if (this.isBuyPopup()) {
            return (amount - this.getFee()) / this.data.size;
        } else {
            return (amount - this.getFee()) * this.data.size;
        }

    }

    checkConfirm(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup({status: true});
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

}
