import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { CRYPTO_SETTINGS } from "../../../../config/crypto_settings";
import { PROJECT_CONFIG } from "../../../../config/project";
var KioskBuyCryptoComponent = /** @class */ (function () {
    function KioskBuyCryptoComponent(kioskService, popupComponent, loaderService, terminalService, kioskScrollService, kioskPaymentService) {
        this.kioskService = kioskService;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.terminalService = terminalService;
        this.kioskScrollService = kioskScrollService;
        this.kioskPaymentService = kioskPaymentService;
        this.close = new EventEmitter();
        this.back = new EventEmitter();
        this.cryptoSelected = new EventEmitter();
        this.isBackBtn = new EventEmitter();
        this.amount = 0;
        this.cryptoData = {
            items: [],
            loading: false
        };
        this.CRYPTO_SETTINGS = CRYPTO_SETTINGS;
        this.DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;
        this.showBackBtn = false;
    }
    Object.defineProperty(KioskBuyCryptoComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            this._showPopup = value;
            if (value) {
                this.getCrypto();
            }
        },
        enumerable: true,
        configurable: true
    });
    KioskBuyCryptoComponent.prototype.ngOnInit = function () {
        this.showBackBtn = this.terminalService.isGames();
    };
    KioskBuyCryptoComponent.prototype.closePopup = function () {
        this.close.emit();
    };
    KioskBuyCryptoComponent.prototype.getCrypto = function () {
        var _this = this;
        this.cryptoData.loading = true;
        this.kioskPaymentService.getCrypto().subscribe(function (data) {
            _this.getCryptoResult(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
            _this.cryptoData.loading = false;
        });
    };
    KioskBuyCryptoComponent.prototype.getCryptoResult = function (data) {
        try {
            if (data.status === true) {
                this.cryptoData.items = data.result;
                // this.cryptoData.items.length = 1;
                if (this.cryptoData.items.length == 1) {
                    this.buyCrypto(this.cryptoData.items[0]);
                }
                else {
                    this.isBackBtn.emit(true);
                }
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    KioskBuyCryptoComponent.prototype.buyCrypto = function (crypto) {
        this.cryptoSelected.emit(crypto);
    };
    KioskBuyCryptoComponent.prototype.goBack = function () {
        this.back.emit();
    };
    KioskBuyCryptoComponent.prototype.loadDefaultCurrencyIcon = function ($event) {
        var el = $event.target;
        el.src = '../../../../assets/images/currencies/' + this.DEFAULT_CRYPTO_ICON;
    };
    KioskBuyCryptoComponent.prototype.getCryptoColor = function (icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].color;
        }
        else {
            return '#fff';
        }
    };
    KioskBuyCryptoComponent.prototype.canScrollTop = function () {
        return this.kioskScrollService.canScrollTop(this.scrolledEl);
    };
    KioskBuyCryptoComponent.prototype.canScrollBottom = function () {
        return this.kioskScrollService.canScrollBottom(this.scrolledEl);
    };
    KioskBuyCryptoComponent.prototype.scrollTable = function (top) {
        this.kioskScrollService.scrollTable(this.scrolledEl.nativeElement, top);
    };
    return KioskBuyCryptoComponent;
}());
export { KioskBuyCryptoComponent };
