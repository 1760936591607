import {GAMES_SOUND_TYPE} from "../games.config";

export const SPINNER_SOUND_TYPE = {
  win: {
    name: 'win',
    src: GAMES_SOUND_TYPE.win.src
  },
  wheel: {
    name: 'wheel',
    src: './assets/audio/games/wheel.mp3'
  }
}
