/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snekapek-investments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./snekapek-investments.component";
var styles_SnekapekInvestmentsComponent = [i0.styles];
var RenderType_SnekapekInvestmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnekapekInvestmentsComponent, data: { "animation": [{ type: 7, name: "slideToRight", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_SnekapekInvestmentsComponent as RenderType_SnekapekInvestmentsComponent };
export function View_SnekapekInvestmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "page page--tertiary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CREATION"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "slider-img"], ["src", "../../../../../assets/images/partners/snekapek/slider.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["class", "publication-img"], ["src", "../../../../../assets/images/partners/snekapek/publication.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "coming-soon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["COMING SOON"]))], null, null); }
export function View_SnekapekInvestmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snekapek-investments", [], [[40, "@slideToRight", 0]], null, null, View_SnekapekInvestmentsComponent_0, RenderType_SnekapekInvestmentsComponent)), i1.ɵdid(1, 114688, null, 0, i2.SnekapekInvestmentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var SnekapekInvestmentsComponentNgFactory = i1.ɵccf("app-snekapek-investments", i2.SnekapekInvestmentsComponent, View_SnekapekInvestmentsComponent_Host_0, {}, {}, []);
export { SnekapekInvestmentsComponentNgFactory as SnekapekInvestmentsComponentNgFactory };
