/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo-bg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./logo-bg.component";
import * as i4 from "../../service/partner.service";
var styles_LogoBgComponent = [i0.styles];
var RenderType_LogoBgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoBgComponent, data: {} });
export { RenderType_LogoBgComponent as RenderType_LogoBgComponent };
function View_LogoBgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "logo-bg logo-bg--ebox"], ["height", "376"], ["viewBox", "0 0 347 376"], ["width", "347"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "../../../../assets/images/sprite.svg#ebox-logo"; _ck(_v, 1, 0, currVal_0); }); }
function View_LogoBgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "logo-bg logo-bg--ebox"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.partnerLogo; _ck(_v, 0, 0, currVal_0); }); }
export function View_LogoBgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoBgComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["partnerImageLogo", 2]], null, 0, null, View_LogoBgComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.partnerLogo; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LogoBgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo-bg", [], null, null, null, View_LogoBgComponent_0, RenderType_LogoBgComponent)), i1.ɵdid(1, 114688, null, 0, i3.LogoBgComponent, [i4.PartnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoBgComponentNgFactory = i1.ɵccf("app-logo-bg", i3.LogoBgComponent, View_LogoBgComponent_Host_0, {}, {}, []);
export { LogoBgComponentNgFactory as LogoBgComponentNgFactory };
