import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {

  hasError = false;
  refreshingToken = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const w: any = window;
    if (w && w.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${w.token}`
        }

      });
    }

    return next.handle(request);
  }
}
