import * as tslib_1 from "tslib";
import { SUPPORT_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SupportService = /** @class */ (function (_super) {
    tslib_1.__extends(SupportService, _super);
    function SupportService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = SUPPORT_CONFIG;
        return _this;
    }
    SupportService.prototype.sendSupportRequest = function (system, game, date, time, description) {
        this.showLoader();
        return this.http.post(this.config.request, { system: system, game: game, date: date, time: time, description: description });
    };
    SupportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupportService_Factory() { return new SupportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SupportService, providedIn: "root" });
    return SupportService;
}(SettingsLoaderService));
export { SupportService };
