import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "./../settingsLoader.service";
import {LoaderService} from "./../loader.service";
import {COMMISSION_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class CommissionService extends SettingsLoaderService {
    private config = COMMISSION_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getCommissionData(type, page, pageDisputs) {
        this.showLoader();
        return this.http.post<any>(this.config.data, {type, page, pageDisputs});
    }

    sendRefer(phone) {
        this.showLoader();
        return this.http.post<any>(this.config.refer, {phone});
    }

    sendPayout(methodShortName, amount, address) {
        this.showLoader();
        return this.http.post<any>(this.config.payout, {methodShortName, amount, address});
    }
}
