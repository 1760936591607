import * as tslib_1 from "tslib";
import { BehaviorSubject } from "rxjs/index";
import { KIOSK_ACCESSOR_COMMANDS, KIOSK_ACCESSOR_PIRAMID_COMMANDS, KIOSK_ACCESSORS } from "./kiosk-accessor-commands";
import { KIOSK_ACCESSOR_STATUSES } from "./kiosk-accessor-statuses";
import { PROJECT_CONFIG } from "../../config/project";
import { KIOSK_ACCESSOR_ERRORS_MESSAGES } from "./kiosk-accessor-errors-messages";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/popup/popup.component";
import * as i2 from "./kiosk-payment.service";
import * as i3 from "../kiosk.service";
import * as i4 from "../loader.service";
import * as i5 from "ng-connection-service";
import * as i6 from "../users/users-point.service";
import * as i7 from "../partner.service";
import * as i8 from "../screen-saver.service";
import * as i9 from "@angular/router";
import * as i10 from "../../modules/auth/_services/authentication.service";
var KioskAccessorService = /** @class */ (function () {
    function KioskAccessorService(popupComponent, kioskPaymentService, kioskService, loaderService, connectionService, usersPointService, partnerService, screenSaverService, router, authenticationService) {
        var _this = this;
        this.popupComponent = popupComponent;
        this.kioskPaymentService = kioskPaymentService;
        this.kioskService = kioskService;
        this.loaderService = loaderService;
        this.connectionService = connectionService;
        this.usersPointService = usersPointService;
        this.partnerService = partnerService;
        this.screenSaverService = screenSaverService;
        this.router = router;
        this.authenticationService = authenticationService;
        this._transactionStart = false;
        this._paymentType = null;
        this._billingPage = false;
        this._userLogged = false;
        this.paymentAllowed = new BehaviorSubject(false);
        this.paymentAmountChanged = new BehaviorSubject(0);
        this.accessorStatusChanged = new BehaviorSubject(null);
        this.paymentComplete = new BehaviorSubject(null);
        this.needLogout = new BehaviorSubject(false);
        this.accessorCommands = KIOSK_ACCESSOR_COMMANDS;
        this.accessorPiramideCommands = KIOSK_ACCESSOR_PIRAMID_COMMANDS;
        this.accessors = KIOSK_ACCESSORS;
        this.accessorStatuses = KIOSK_ACCESSOR_STATUSES;
        this.accessorErrorsMessages = KIOSK_ACCESSOR_ERRORS_MESSAGES;
        this.projectConfig = PROJECT_CONFIG;
        this.paymentTypes = PROJECT_CONFIG.terminalPaymentType;
        var statusError = null;
        window.getCmd = function (status, error, amount, qty, billValidator) {
            var status = parseFloat(status);
            var error = parseFloat(error);
            var amount = parseFloat(amount);
            var qty = parseFloat(qty);
            var billValidator = billValidator;
            //console.log('sssss');
            //console.log(status);
            //console.log(error);
            //console.log(billValidator);
            //console.log(amount);
            if (error != 0 && statusError == null) {
                statusError = error;
                _this.returnBill();
                _this.deactivationBill();
                return false;
            }
            if (error == 0 && statusError !== 0 && statusError != null) {
                statusError = null;
                _this.activateBill();
            }
            //this.returnBill();
            var billValidatorArr = billValidator.split('_');
            if (billValidatorArr[3]) {
                _this.billValidatorName = billValidatorArr[3];
                _this.billValidatorName = _this.billValidatorName.replace('name.', '');
            }
            if (!_this.transactionStart &&
                ((status == _this.accessorStatuses.billAccepted && _this.billValidatorName == _this.accessors.cashcode) ||
                    (status == _this.accessorStatuses.billAcceptedPiramid && _this.billValidatorName == _this.accessors.piramide)) &&
                amount > 0) {
                _this.transactionStart = true;
                _this.amountChanged(amount);
            }
            else if (_this.transactionStart && ((status != _this.accessorStatuses.billAccepted && _this.billValidatorName == _this.accessors.cashcode) ||
                (status != _this.accessorStatuses.billAcceptedPiramid && _this.billValidatorName == _this.accessors.piramide))) {
                _this.transactionStart = false;
            }
        };
        var $this = this;
        setTimeout(function () {
            $this.activateBill();
        }, 3000);
        //this.activateBill();
        // TERMINAL V1
        this.authenticationService.userLoggedStatusChanged.subscribe(function (value) { return _this.userLoggedStatusChanged(value); });
        // TERMINAL V2
        this.kioskService.billingPage.subscribe(function (value) { return _this.billingPageVisibilityChanged(value); });
        this.connectionService.monitor().subscribe(function (isConnected) {
            if (!isConnected) {
                _this.deactivationBill();
            }
            else {
                _this.activateBill();
            }
        });
        this.kioskService.paymentType.subscribe(function (value) { return _this.savePaymentType(value); });
    }
    KioskAccessorService.prototype.amountChanged = function (amount) {
        // if(this.paymentType === this.paymentTypes.crypto){
        //     if(amount < this.projectConfig.terminalMinAmount){
        //         this.popupComponent.showPopupError(
        //             {
        //                 text: "To purchase crypto, the minimum accepted bill is "+this.projectConfig.terminalMinAmount+" USD. Please insert 10, 20, 50, or 100 dollar bills.",
        //                 confirmButtonText: "Ok"
        //             }
        //         );
        //
        //         this.returnBill();
        //
        //         return true;
        //     }
        //
        // }
        var _this = this;
        var canAccept = this.checkAcceptBilling();
        if (canAccept) {
            this.newAccessorStatus();
            this.loaderService.show();
            var typeShortName = this.partnerService.storageGetTerminalShortName();
            this.kioskPaymentService.create(amount, typeShortName).subscribe(function (data) {
                _this.loaderService.hide();
                if (data.status) {
                    _this.accepteBill();
                    _this.paymentAmountChanged.next(data.result);
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message }, _this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
                    _this.returnBill();
                }
            }, function (error) {
                _this.returnBill();
            });
        }
    };
    KioskAccessorService.prototype.checkAcceptBilling = function () {
        if (this._userLogged) {
            if (this._billingPage) {
                return true;
            }
            else {
                if (this.partnerService.isMascotSingleTerminal()) {
                    var isPopupOpen = document.querySelector('.mascot-popup');
                    if (isPopupOpen) {
                        this.popupComponent.showPopupError({ text: this.accessorErrorsMessages.closeGameFirst }, this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
                    }
                    else {
                        return true;
                    }
                }
                else {
                    this.popupComponent.showPopupError({ text: this.accessorErrorsMessages.productNotSelected }, this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
                }
            }
        }
        else {
            var $this_1 = this;
            this.popupComponent.showPopupErrorReturnEvent({ text: this.accessorErrorsMessages.notLogged }, function (status) {
                $this_1.screenSaverService.hideScreenSaver();
            }, this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
            //this.popupComponent.showPopupError({text: this.accessorErrorsMessages.notLogged});
        }
        this.returnBill();
        return false;
    };
    KioskAccessorService.prototype.savePaymentType = function (type) {
        this.paymentType = type;
    };
    // TERMINAL V1
    KioskAccessorService.prototype.userLoggedStatusChanged = function (status) {
        this._userLogged = !!status;
    };
    // TERMINAL V2
    KioskAccessorService.prototype.billingPageVisibilityChanged = function (status) {
        this._billingPage = !!status;
    };
    // public complete(){
    //     this.loaderService.show();
    //     this.kioskPaymentService.complete().subscribe(data => {
    //         this.loaderService.hide();
    //
    //         this.newAccessorStatus();
    //
    //         if (data.status) {
    //             this.popupComponent.showPopupSuccess({text: data.message});
    //
    //             this.paymentAmountChanged.next(0);
    //             this.paymentCompleted();
    //         }else{
    //             this.popupComponent.showPopupError({text: data.message});
    //         }
    //     }, () => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }
    KioskAccessorService.prototype.completeCryptoBuy = function (currencyIdTo, successHandler) {
        var _this = this;
        this.kioskPaymentService.buyCrypto(currencyIdTo).subscribe(function (data) {
            if (successHandler) {
                _this.checkBuyResult(data, successHandler);
            }
            else {
                _this.checkBuyResult(data);
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    KioskAccessorService.prototype.completeSweepsBuy = function (successHandler) {
        var _this = this;
        this.kioskPaymentService.buySweeps().subscribe(function (data) {
            if (successHandler) {
                _this.checkBuyResult(data, successHandler);
            }
            else {
                _this.checkBuyResult(data);
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    KioskAccessorService.prototype.checkBuyResult = function (data, successHandler) {
        this.newAccessorStatus();
        if (data.status) {
            if (successHandler) {
                successHandler();
            }
            this.popupComponent.showPopupSuccess({ text: data.message }, this.logoutAfterPayment.bind(this));
            this.paymentAmountChanged.next(0);
            this.paymentCompleted();
        }
        else {
            this.popupComponent.showPopupError({ text: data.message });
        }
    };
    KioskAccessorService.prototype.logoutAfterPayment = function () {
        if (this.partnerService.isRiverPayTerminal()) {
            this.needLogout.next(true);
        }
    };
    KioskAccessorService.prototype.cmd = function (cmd) {
        (function () {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CefSharp.BindObjectAsync("AccessorService", "bound")];
                        case 1:
                            _a.sent();
                            AccessorService.cmd(cmd);
                            return [2 /*return*/];
                    }
                });
            });
        })();
    };
    KioskAccessorService.prototype.reload = function () {
        (function () {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CefSharp.BindObjectAsync("AccessorService", "bound")];
                        case 1:
                            _a.sent();
                            AccessorService.reload();
                            return [2 /*return*/];
                    }
                });
            });
        })();
    };
    KioskAccessorService.prototype.paymentCompleted = function () {
        this.paymentComplete.next(Math.round(+new Date() / 1000) + Math.random());
    };
    KioskAccessorService.prototype.newAccessorStatus = function () {
        this.accessorStatusChanged.next(Math.round(+new Date() / 1000) + Math.random());
    };
    KioskAccessorService.prototype.accepteBill = function () {
        if (this.billValidatorName == this.accessors.piramide) {
            this.cmd(this.accessorPiramideCommands.acceptBill);
        }
        else {
            this.cmd(this.accessorCommands.acceptBill);
        }
    };
    KioskAccessorService.prototype.returnBill = function () {
        if (this.billValidatorName == this.accessors.piramide) {
            this.cmd(this.accessorPiramideCommands.returnBill);
        }
        else {
            this.cmd(this.accessorCommands.returnBill);
        }
        this.newAccessorStatus();
    };
    KioskAccessorService.prototype.deactivationBill = function () {
        if (this.billValidatorName) {
            if (this.billValidatorName == this.accessors.piramide) {
                this.cmd(this.accessorPiramideCommands.deactivationBill);
            }
            else {
                this.cmd(this.accessorCommands.deactivationBill);
            }
        }
        else {
            var $this_2 = this;
            setTimeout(function () {
                $this_2.deactivationBill();
            }, 500);
        }
    };
    KioskAccessorService.prototype.activateBill = function () {
        if (this.billValidatorName) {
            if (this.billValidatorName == this.accessors.piramide) {
                this.cmd(this.accessorPiramideCommands.acceptanceConfirmationMode);
            }
            else {
                this.cmd(this.accessorCommands.acceptanceConfirmationMode);
            }
        }
        else {
            var $this_3 = this;
            setTimeout(function () {
                $this_3.activateBill();
            }, 500);
        }
    };
    Object.defineProperty(KioskAccessorService.prototype, "transactionStart", {
        get: function () {
            return this._transactionStart;
        },
        set: function (value) {
            this._transactionStart = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KioskAccessorService.prototype, "paymentToken", {
        get: function () {
            return this._paymentToken;
        },
        set: function (value) {
            this._paymentToken = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KioskAccessorService.prototype, "paymentType", {
        get: function () {
            return this._paymentType;
        },
        set: function (value) {
            this._paymentType = value;
        },
        enumerable: true,
        configurable: true
    });
    KioskAccessorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KioskAccessorService_Factory() { return new KioskAccessorService(i0.ɵɵinject(i1.PopupComponent), i0.ɵɵinject(i2.KioskPaymentService), i0.ɵɵinject(i3.KioskService), i0.ɵɵinject(i4.LoaderService), i0.ɵɵinject(i5.ConnectionService), i0.ɵɵinject(i6.UsersPointService), i0.ɵɵinject(i7.PartnerService), i0.ɵɵinject(i8.ScreenSaverService), i0.ɵɵinject(i9.Router), i0.ɵɵinject(i10.AuthenticationService)); }, token: KioskAccessorService, providedIn: "root" });
    return KioskAccessorService;
}());
export { KioskAccessorService };
