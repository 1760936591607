import {EventEmitter, Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {MASCOT_CONFIG} from "../../config/api";

@Injectable({
  providedIn: 'root'
})
export class MascotService extends SettingsLoaderService {
  private config = MASCOT_CONFIG;
  balanceUpdated = new BehaviorSubject(0);

  constructor(private http: HttpClient, public loaderService: LoaderService) {
    super(loaderService);
  }

  getIcons() {
    this.showLoader();
    return this.http.post<any>(this.config.getIcons, {});
  }

  getBalance() {
    this.showLoader();
    return this.http.post<any>(this.config.getBalance, {});
  }

  checkLastUpdates() {
    // this.showLoader();
    return this.http.post<any>(this.config.updatesLast, {});
  }

  updateBalance() {
    this.balanceUpdated.next(Math.random());
  }
}
