import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class YetiCommonService {
  showRules: EventEmitter<boolean> = new EventEmitter();
  isGameStart = false;
  startGame: EventEmitter<boolean> = new EventEmitter();
  restartGame: EventEmitter<boolean> = new EventEmitter();
  showMainLoader: EventEmitter<{show: boolean, minMilliSec?: number}> = new EventEmitter();

  showInfoPopup: EventEmitter<{show: boolean, message?: string, callback?: any}> = new EventEmitter();
  constructor() {
  }

  addButtonBehavior(el) {
    el.interactive = true;
    el.buttonMode = true;
    el.defaultCursor = 'pointer';
  }

  removeButtonBehavior(el) {
    el.interactive = false;
    el.buttonMode = false;
    el.defaultCursor = 'auto';
  }

  setAnchorToCenter(el) {
    el.anchor.set(0.5, 0.5);
  }

  scale(el, scale) {
    el.scale.set(scale, scale);
  }
}
