import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {LoaderService} from "../../../../service/loader.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {GamesProviderService} from "../../../../service/games/gamesProvider.service";
import {KioskService} from "../../../../service/kiosk.service";
import {PROJECT_CONFIG} from "../../../../config/project";
import {TerminalService} from "../../../../service/terminal/terminal.service";


@Component({
    selector: 'app-buy-menu',
    templateUrl: './buy-menu.component.html',
    styleUrls: ['./buy-menu.component.scss'],
})
export class BuyMenuComponent implements OnInit {
    @Input() showPopup;
    @Input() pointId;

    @Output() close = new EventEmitter();
    @Output() gameSelected = new EventEmitter();

    @HostBinding('class.is-hidden') get t() {
        return  this.isHiddenClass();
    }

    paymentTypes = PROJECT_CONFIG.terminalPaymentType;
    game;

    nav = [];

    isApp = this.kioskService.isKiosk();

    openGamesPopup = false;
    openBuyCryptoPopup = false;
    openPaymentPopup = false;

    cryptoToBy;

    paymentPopupType;
    paymentInfo;

    showGames = false;
    showCrypto = false;

    gamesBackBtn = false;
    cryptoBackBtn = false;
    paymentsBackBtn = false;

    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private kioskService: KioskService,
                private loaderService: LoaderService,
                private gamesProviderService: GamesProviderService,
                private popupComponent: PopupComponent,
                private terminalService: TerminalService) {

    }

    ngOnInit() {
        this.showGames = this.terminalService.isGames();
        this.showCrypto = this.terminalService.isCrypto();

        setTimeout(() => {
            console.log("test_test: 22");
            //this.loaderService.show();
            if (this.showGames && !this.showCrypto) {
                // this.gamesProviderService.getGamesList(this.pointId).subscribe(data => {
                //     this.getGameListResult(data);
                // }, () => {
                //     this.loaderService.hideAndShowTryAgain();
                // }, () => {
                //     this.loaderService.hide();
                // });

                this.onBuy(this.paymentTypes.sweeps);
            } else if (!this.showGames && this.showCrypto) {
                this.onBuy(this.paymentTypes.crypto);
            } else if (this.showGames && this.showCrypto) {
                this.nav = [
                    {
                        title: 'buy crypto currency',
                        img: '../../../assets/images/kiosk/buy-crypto.png',
                        description: 'Buying your favorite cryptocurrencies has never been more exciting. Invest and watch your money grow!',
                        type: 'menu',
                        id: this.paymentTypes.crypto
                    },
                    {
                        title: 'buy member ship',
                        img: '../../../assets/images/kiosk/buy-sweeps.png',
                        description: 'You can pay cash right here, right now to fund your membership and earn points.',
                        type: 'menu',
                        id: this.paymentTypes.sweeps
                    }
                ];
                this.loaderService.hide();
            }
        });
    }

    ngAfterViewInit() {
        // if (this.showGames && !this.showCrypto) {
        //     // this.gamesProviderService.getGamesList(this.pointId).subscribe(data => {
        //     //     this.getGameListResult(data);
        //     // }, () => {
        //     //     this.loaderService.hideAndShowTryAgain();
        //     // }, () => {
        //     //     this.loaderService.hide();
        //     // });
        //
        //     this.onBuy(this.paymentTypes.sweeps);
        // } else if (!this.showGames && this.showCrypto) {
        //     this.onBuy(this.paymentTypes.crypto);
        // } else if (this.showGames && this.showCrypto) {
        //     this.nav = [
        //         {
        //             title: 'buy crypto currency',
        //             img: '../../../assets/images/kiosk/buy-crypto.png',
        //             description: 'Buying your favorite cryptocurrencies has never been more exciting. Invest and watch your money grow!',
        //             type: 'menu',
        //             id: this.paymentTypes.crypto
        //         },
        //         {
        //             title: 'buy member ship',
        //             img: '../../../assets/images/kiosk/buy-sweeps.png',
        //             description: 'You can pay cash right here, right now to fund your membership and earn points.',
        //             type: 'menu',
        //             id: this.paymentTypes.sweeps
        //         }
        //     ];
        //     this.loaderService.hide();
        // }
    }

    // getGameListResult(data) {
    //     try {
    //         if(data.status === true) {
    //             this.game = data.result[0];
    //
    //             this.onSweepsPayment(this.game);
    //         } else if (data.status === false) {
    //             this.popupComponent.showPopupError({text:data.message});
    //         } else {
    //             this.loaderService.hideAndShowTryAgain();
    //         }
    //     } catch (e) {
    //         this.loaderService.hideAndShowTryAgain();
    //     }
    // }

    closePopup() {
        this.close.emit();
    }

    onBuy(id) {
        if (id === this.paymentTypes.crypto) {
            this.openBuyCryptoPopup = true;
        } else if (id === this.paymentTypes.sweeps && this.pointId) {
            this.openGamesPopup = true;
        }
    }

    onCryptoPayment(crypto) {
        this.paymentPopupType = this.paymentTypes.crypto;
        // this.cryptoToBy = crypto;
        this.paymentInfo = crypto;
        this.openPaymentPopup = true;
        this.openBuyCryptoPopup = false;
        this.paymentsBackBtn = this.cryptoBackBtn;
    }

    onSweepsPayment(game) {
        this.paymentPopupType = this.paymentTypes.sweeps;
        this.paymentInfo = game;
        this.openPaymentPopup = true;
        this.openGamesPopup = false;
        this.paymentsBackBtn = this.gamesBackBtn;
    }

    hideGamesSelection() {
        this.openGamesPopup = false;
    }

    hideBuyCrypto() {
        this.openBuyCryptoPopup = false;
    }

    onClosePayment(type) {
        this.paymentPopupType = null;
        this.openPaymentPopup = false;
        this.paymentsBackBtn = false;

        if ((type === this.paymentTypes.crypto && this.cryptoBackBtn) || (type === this.paymentTypes.sweeps && this.gamesBackBtn)) {
            this.onBuy(type);
        }
    }

    isHiddenClass() {
        return !this.showPopup || this.openGamesPopup || this.openBuyCryptoPopup || this.openPaymentPopup;
    }

    setGamesBackBtn(state) {
        this.gamesBackBtn = state;
    }

    setCryptoBackBtn(state) {
        this.cryptoBackBtn = state;
    }
}

