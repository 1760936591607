import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { ExampleHeader } from "../datapicker-header/datapicker-header";
import { TIME_SPANS } from "../../config/timeSpans.config";
var SupportComponent = /** @class */ (function () {
    function SupportComponent(formBuilder, loaderService, userBalanceService, supportService, gamesProviderService, popupComponent) {
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.userBalanceService = userBalanceService;
        this.supportService = supportService;
        this.gamesProviderService = gamesProviderService;
        this.popupComponent = popupComponent;
        this.showSupportForm = false;
        this.close = new EventEmitter();
        this.exampleHeader = ExampleHeader;
        this.times = TIME_SPANS;
        this.providers = [];
    }
    Object.defineProperty(SupportComponent.prototype, "showSupport", {
        set: function (val) {
            this.showSupportForm = val;
            if (this.supportForm) {
                if (!this.providers.length) {
                    this.getProviders();
                }
                else {
                    this.setSupportSelects();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    SupportComponent.prototype.ngOnInit = function () {
        this.supportForm = this.formBuilder.group({
            system: ['', Validators.required],
            game: [''],
            date: ['', Validators.required],
            time: ['', Validators.required],
            description: ['', Validators.required],
        });
    };
    SupportComponent.prototype.getProviders = function () {
        var _this = this;
        this.userBalanceService.getBalance().subscribe(function (data) {
            if (data.status) {
                if (data.providers) {
                    _this.providers = data.providers;
                    _this.setSupportSelects();
                }
            }
            else {
                _this.popupComponent.showPopupError({ text: data.message });
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    SupportComponent.prototype.closePopup = function () {
        document.body.classList.remove('hide-menu');
        this.supportForm.reset();
        this.setSupportSelects();
        this.close.emit(true);
    };
    SupportComponent.prototype.onSubmitSupportForm = function () {
        var _this = this;
        if (this.supportForm.invalid) {
            return;
        }
        var system = this.getSupportFormFieldValue('system').name;
        var game = this.getSupportFormFieldValue('game');
        var date = this.getSupportDate();
        var time = this.getSupportFormFieldValue('time');
        var description = this.getSupportFormFieldValue('description');
        if (system && date && time && description) {
            this.supportService.sendSupportRequest(system, game, date, time, description).subscribe(function (data) {
                try {
                    if (data.status) {
                        _this.popupComponent.showPopupSuccess({ text: data.message });
                        _this.closePopup();
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
                catch (e) {
                    _this.loaderService.hideAndShowTryAgain();
                }
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    SupportComponent.prototype.getSupportFormFieldValue = function (name) {
        if (this.supportForm && this.supportForm.controls[name]) {
            return this.supportForm.controls[name].value;
        }
        else {
            return null;
        }
    };
    SupportComponent.prototype.getSupportDate = function () {
        return document.querySelector('[formcontrolname=date]').value;
    };
    SupportComponent.prototype.setSupportSelects = function () {
        this.selectedSystem = this.gamesProviderService.getActiveProvider(this.providers);
        this.selectedTime = this.times[0];
        this.supportForm.controls.time.setValue(this.selectedTime);
        this.supportForm.controls.system.setValue(this.selectedSystem);
    };
    return SupportComponent;
}());
export { SupportComponent };
