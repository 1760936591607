<div class="transactions-wrapper">
    <div #table class="transactions-wrapper__inner js-scroll">
        <table class="coins">
            <thead class="coins__head">
            <tr>
<!--                <th>#</th>-->
                <th>Crypto</th>
                <th colspan="2">Trade</th>
            </tr>
            </thead>

            <tbody *ngIf="coinsData && coinsData" class="coins__body">
                <tr *ngFor="let coin of coinsData; let i = index" class="transactions__row">
<!--                    <td>{{i + 1}}</td>-->
                    <td>
                        <div class="coins__name-wrapper">
                            <img *ngIf="coin.icon" class="coins__icon"
                                 style="min-width: 2em"
                                 src="../../../../assets/images/currencies/{{coin.icon}}.png"
                                 (error)="loadDefaultCurrencyIcon($event)">

                            <div class="coins__name">
<!--                                <span>1 {{getShortNameTo(coin)}} / </span>-->
<!--                                {{getPrice(coin) | number: '1.2-12'}} {{getShortName(coin)}}-->

                                <span>1 {{coin.name}}</span> /
                                {{coin.size | currency : 'USD' : 'symbol'}}
                            </div>
                        </div>
                    </td>
<!--                    <td>-->
<!--                        <button (click)="tradeHandler(coin, TRADE_ACTIONS.sell)" class="coins__btn" [class.is-disabled]="">-->
<!--                            Sell-->
<!--                        </button>-->
<!--                    </td>-->
                    <td>
                        <button (click)="tradeHandler(coin)" class="coins__btn" [class.is-disabled]="">
                            Buy
                        </button>
                    </td>
                </tr>
            </tbody>
            <svg [class.is-active]="canScrollTop()" (click)="scrollTable(true)" class="coins__arrow coins__arrow--top">
                <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>
            </svg>
            <svg [class.is-active]="canScrollBottom()" (click)="scrollTable()" class="coins__arrow coins__arrow--bottom">
                <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>
            </svg>
        </table>
    </div>
</div>
