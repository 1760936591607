/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trading.component.scss.shim.ngstyle";
import * as i1 from "./trading--kiosk.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./trading.component";
import * as i5 from "../../service/coins/coinsTrade.service";
import * as i6 from "../../service/loader.service";
import * as i7 from "@angular/router";
import * as i8 from "../../service/investments/investments.service";
import * as i9 from "../../modules/auth/_services/authentication.service";
import * as i10 from "../../service/kiosk.service";
import * as i11 from "../popup/popup.component";
var styles_TradingComponent = [i0.styles, i1.styles];
var RenderType_TradingComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_TradingComponent, data: {} });
export { RenderType_TradingComponent as RenderType_TradingComponent };
function View_TradingComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "img", [["class", "coins__icon"], ["style", "min-width: 2em"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.loadDefaultCurrencyIcon($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i2.ɵinlineInterpolate(1, "../../../../assets/images/currencies/", _v.parent.context.$implicit.icon, ".png"); _ck(_v, 0, 0, currVal_0); }); }
function View_TradingComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 12, "tr", [["class", "transactions__row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 8, "td", [], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 7, "div", [["class", "coins__name-wrapper"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_TradingComponent_3)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(5, 0, null, null, 4, "div", [["class", "coins__name"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["1 ", ""])), (_l()(), i2.ɵted(8, null, [" / ", " "])), i2.ɵppd(9, 3), (_l()(), i2.ɵeld(10, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 1, "button", [["class", "coins__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tradeHandler(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, [" Buy "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = i2.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i2.ɵnov(_v.parent.parent, 0), _v.context.$implicit.size, "USD", "symbol")); _ck(_v, 8, 0, currVal_2); }); }
function View_TradingComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "tbody", [["class", "coins__body"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_TradingComponent_2)), i2.ɵdid(2, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.coinsData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TradingComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i3.CurrencyPipe, [i2.LOCALE_ID]), i2.ɵqud(671088640, 1, { table: 0 }), (_l()(), i2.ɵeld(2, 0, null, null, 14, "div", [["class", "transactions-wrapper"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, [[1, 0], ["table", 1]], null, 13, "div", [["class", "transactions-wrapper__inner js-scroll"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 12, "table", [["class", "coins"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 5, "thead", [["class", "coins__head"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Crypto"])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "th", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Trade"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_TradingComponent_1)), i2.ɵdid(12, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(13, 0, null, null, 1, ":svg:svg", [["class", "coins__arrow coins__arrow--top"]], [[2, "is-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollTable(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#arrow-up"]], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 1, ":svg:svg", [["class", "coins__arrow coins__arrow--bottom"]], [[2, "is-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollTable() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(16, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#arrow-up"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.coinsData && _co.coinsData); _ck(_v, 12, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.canScrollTop(); _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.canScrollBottom(); _ck(_v, 15, 0, currVal_2); }); }
export function View_TradingComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-trading", [], [[8, "className", 0]], null, null, View_TradingComponent_0, RenderType_TradingComponent)), i2.ɵdid(1, 245760, null, 0, i4.TradingComponent, [i5.CoinsTradeService, i6.LoaderService, i7.Router, i8.InvestmentsService, i9.AuthenticationService, i10.KioskService, i11.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 1).isAppClass; _ck(_v, 0, 0, currVal_0); }); }
var TradingComponentNgFactory = i2.ɵccf("app-trading", i4.TradingComponent, View_TradingComponent_Host_0, {}, { isData: "isData" }, []);
export { TradingComponentNgFactory as TradingComponentNgFactory };
