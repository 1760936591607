<div class="hero">
    <div class="hero__keyboard" *ngIf="isKiosk()">
        <div class="keyboard">
            <app-keyboard></app-keyboard>
        </div>
    </div>
    <img *ngIf="!showCoins" class="hero__graph hero__graph--top" src="../../../assets/images/graph_1.png" alt="">
    <img *ngIf="!showCoins" class="hero__graph hero__graph--bottom" src="../../../assets/images/graph_2.png" alt="">
    <img class="hero__girl" src="../../../assets/images/girl-with-vase.png"
         srcset="../../../assets/images/girl-with-vase-1200w.png 1200w,
             ../../../assets/images/girl-with-vase-1440w.png 1440w
            ../../../assets/images/girl-with-vase"
         sizes="(max-width: 768px) 768px,
            (max-width: 1200px) 1200px,
            1440px"  alt="">
    <img class="hero__coins" src="../../../assets/images/bitcoins.png"  alt="">
    <div class="hero__text">{{getHeroText()}}</div>

    <app-trading [ngStyle]="{visibility: showCoins ? 'visible' : 'hidden'}"
                 (isData)="isCoins($event)"></app-trading>

</div>

