import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var MenuBottomAddContentService = /** @class */ (function () {
    function MenuBottomAddContentService(ngZone) {
        this.ngZone = ngZone;
        this.menuChanged = new BehaviorSubject(false);
    }
    MenuBottomAddContentService.prototype.show = function () {
        this.menuChanged.next(true);
    };
    MenuBottomAddContentService.prototype.hide = function () {
        this.menuChanged.next(false);
    };
    MenuBottomAddContentService.prototype.toggle = function () {
        this.menuChanged.next(!this.menuChanged.value);
    };
    MenuBottomAddContentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuBottomAddContentService_Factory() { return new MenuBottomAddContentService(i0.ɵɵinject(i0.NgZone)); }, token: MenuBottomAddContentService, providedIn: "root" });
    return MenuBottomAddContentService;
}());
export { MenuBottomAddContentService };
