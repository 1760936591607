import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var YetiCommonService = /** @class */ (function () {
    function YetiCommonService() {
        this.showRules = new EventEmitter();
        this.isGameStart = false;
        this.startGame = new EventEmitter();
        this.restartGame = new EventEmitter();
        this.showMainLoader = new EventEmitter();
        this.showInfoPopup = new EventEmitter();
    }
    YetiCommonService.prototype.addButtonBehavior = function (el) {
        el.interactive = true;
        el.buttonMode = true;
        el.defaultCursor = 'pointer';
    };
    YetiCommonService.prototype.removeButtonBehavior = function (el) {
        el.interactive = false;
        el.buttonMode = false;
        el.defaultCursor = 'auto';
    };
    YetiCommonService.prototype.setAnchorToCenter = function (el) {
        el.anchor.set(0.5, 0.5);
    };
    YetiCommonService.prototype.scale = function (el, scale) {
        el.scale.set(scale, scale);
    };
    YetiCommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YetiCommonService_Factory() { return new YetiCommonService(); }, token: YetiCommonService, providedIn: "root" });
    return YetiCommonService;
}());
export { YetiCommonService };
