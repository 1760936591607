import * as tslib_1 from "tslib";
import { USER_CARD_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var UserCardService = /** @class */ (function (_super) {
    tslib_1.__extends(UserCardService, _super);
    function UserCardService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = USER_CARD_CONFIG;
        return _this;
    }
    UserCardService.prototype.getCards = function (type) {
        this.showLoader();
        return this.http.post(this.config.cards, { type: type });
    };
    UserCardService.prototype.getCardInfo = function (id) {
        this.showLoader();
        return this.http.post(this.config.card, { id: id });
    };
    UserCardService.prototype.deleteCard = function (id) {
        this.showLoader();
        return this.http.post(this.config.delete, { id: id });
    };
    UserCardService.prototype.resendCode = function (id) {
        this.showLoader();
        return this.http.post(this.config.resendCode, { id: id });
    };
    UserCardService.prototype.checkVerify = function (id) {
        this.showLoader();
        return this.http.post(this.config.checkVerify, { id: id });
    };
    // verifyCard(code, id) {
    //     this.showLoader();
    //     return this.http.post<any>(this.config.verify, {code, id});
    // }
    UserCardService.prototype.verifyCard = function (amount1, amount2, id) {
        this.showLoader();
        return this.http.post(this.config.verify, { amount1: amount1, amount2: amount2, id: id });
    };
    UserCardService.prototype.addCard = function (type, card, month, year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        this.showLoader();
        return this.http.post(this.config.add, { type: type, card: card, month: month, year: year, cvv: cvv, name: name, firstName: firstName, lastName: lastName, billingAddress: billingAddress, billingZip: billingZip, city: city, stateId: stateId });
    };
    UserCardService.prototype.saveCard = function (id, month, year, name, billingAddress, billingZip, city, stateId) {
        this.showLoader();
        return this.http.post(this.config.save, { id: id, month: month, year: year, name: name, billingAddress: billingAddress, billingZip: billingZip, city: city, stateId: stateId });
    };
    UserCardService.prototype.addCashAppCard = function (card, month, year, cvv, name) {
        this.showLoader();
        return this.http.post(this.config.addCashAppCard, { card: card, month: month, year: year, cvv: cvv, name: name });
    };
    UserCardService.prototype.addPrepaidCard = function (card, month, year, cvv, name) {
        this.showLoader();
        return this.http.post(this.config.addPrepaidCard, { card: card, month: month, year: year, cvv: cvv, name: name });
    };
    UserCardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserCardService_Factory() { return new UserCardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: UserCardService, providedIn: "root" });
    return UserCardService;
}(SettingsLoaderService));
export { UserCardService };
