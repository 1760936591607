import {Inject, Injectable, NgZone} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppTestService {
    private storage = sessionStorage;
    private storageKey = 'iswebtest';
    private _accessDomain = '192.168.56.1';
    private _accessDomain1 = 'localhost';

    constructor() {

    }

    saveInStorage(key){
        if(this.isAccessDomain){
            this.storage.setItem(this.storageKey, key);
        }
    }

    clearStorage(){
        //this.storage.removeItem(this.storageKey);
    }

    isWeb(){
        if (sessionStorage.getItem(this.storageKey)) {
            if(this.isAccessDomain){
                return true;
            }
        }

        return false;
    }

    isAccessDomain(){
        if(this.accessDomain == this.getHostname() || this.accessDomain1 == this.getHostname()){
            return true;
        }

        return false;
    }

    getHostname(){
        return window.location.hostname;
    }

    get accessDomain(): string {
        return this._accessDomain;
    }


    get accessDomain1(): string {
        return this._accessDomain1;
    }

}
