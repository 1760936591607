<div class="page page--quaternary long-content">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <a [routerLink]="['/home/settings']" class="page__back page__back--mobile">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">{{getTitle()}}</h1>
        <div class="page__centered">
            <a id="add_credit_card" [routerLink]="getAddLink()" class="button snake-btn is-notouched">
                ADD NEW CARD
                <svg><rect></rect></svg>
            </a>
        </div>
        <div class="page__body" #scrolledEl>
            <div class="cards">
                <div *ngFor="let card of cards" class="cards__item">
                    <div (click)="viewCardInfo(card)" class="cards__left">
                        <div class="cards__title">{{card.cardholder}}</div>
                        <div class="cards__subtitle">ending in {{card.lastFour}}</div>
                    </div>
                    <div class="cards__right">
                        <button (click)="viewCardInfo(card)" class="cards__btn cards__btn--edit">
                            <svg width="25" height="25">
                                <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>
                            </svg>
                        </button>

                        <button *ngIf="showDelete(card)" (click)="askDelete(card)" class="cards__btn cards__btn--del">
                            <svg width="17" height="22">
                                <use xlink:href="../../../../assets/images/sprite.svg#delete"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

<!--        <div class="scroll-btns scroll-btns&#45;&#45;small">-->
<!--            <svg [class.is-active]="canScrollTop()"-->
<!--                 (click)="scrollTable(true)"-->
<!--                 class="scroll-btns__arrow scroll-btns__arrow&#45;&#45;top">-->
<!--                <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>-->
<!--            </svg>-->
<!--            <svg [class.is-active]="canScrollBottom()"-->
<!--                 (click)="scrollTable()"-->
<!--                 class="scroll-btns__arrow scroll-btns__arrow&#45;&#45;bottom">-->
<!--                <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>-->
<!--            </svg>-->
<!--        </div>-->

        <a [routerLink]="['/home/settings']" class="button page__back page__back--desktop" >
            Back
            <svg><rect></rect></svg>
        </a>

    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

