import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "./../settingsLoader.service";
import { COMMISSION_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var CommissionService = /** @class */ (function (_super) {
    tslib_1.__extends(CommissionService, _super);
    function CommissionService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = COMMISSION_CONFIG;
        return _this;
    }
    CommissionService.prototype.getCommissionData = function (type, page, pageDisputs) {
        this.showLoader();
        return this.http.post(this.config.data, { type: type, page: page, pageDisputs: pageDisputs });
    };
    CommissionService.prototype.sendRefer = function (phone) {
        this.showLoader();
        return this.http.post(this.config.refer, { phone: phone });
    };
    CommissionService.prototype.sendPayout = function (methodShortName, amount, address) {
        this.showLoader();
        return this.http.post(this.config.payout, { methodShortName: methodShortName, amount: amount, address: address });
    };
    CommissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommissionService_Factory() { return new CommissionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CommissionService, providedIn: "root" });
    return CommissionService;
}(SettingsLoaderService));
export { CommissionService };
