import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren, HostListener, HostBinding
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {LoginService} from "../../../service/login.service";

import {PopupComponent} from "../../../shared/popup/popup.component";
import {MenuService} from "../../../service/menu.service";
import {LoaderService} from "../../../service/loader.service";
import {PHONE_MASK} from "../../../config/country_phone_mask";
import {PartnerService} from "../../../service/partner.service";
import {PARTNERS} from "../../../config/partners_settings";
import {UsersPointService} from "../../../service/users/users-point.service";
import {KeyboardService} from "../../../service/keyboard.service";
import {AppPromptService} from "../../../service/app/appPrompt.service";
import {KioskService} from "../../../service/kiosk.service";
import {KEYBOARD_SETTINGS} from "../../../config/keyboard_settings";
import {PARTICLES_SETTINGS} from "../../../config/particles_settings";
import {ScreenSaverService} from "../../../service/screen-saver.service";
import {SCREEN_SAVER_ANIMATION_TYPE} from "../../../config/screenSaver/animation_types.model";
import {LoginPhoneFormComponent} from "../login-phone-form/login-phone-form.component";

@Component({
    selector: 'app-login-new',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss',
        '../../../../../node_modules/simple-keyboard/build/css/index.css'],
})
export class LoginNewComponent implements OnInit {


    @ViewChild(LoginPhoneFormComponent, {static: true}) phoneForm: LoginPhoneFormComponent;
    // NEW CHANGES END
    pinForm: FormGroup;
    //phoneForm: FormGroup;

    showScreenSaverTimer;
    showScreenSaverAfterMin = 10;
    screenSaverAnimation;

    phoneFormActive = true;
    registrationPartActive = false;
    phoneLastFour = ' ';
    numOfDigits = 4;

    phoneMask;
    PHONE_MASK_CONFIG = PHONE_MASK;

    PARTNERS = PARTNERS;
    currentPartner;

    riverPayAccountId = '';
    accountEnteringPopup = false;
    terminalConnectingPopup = false;

    showContactsFormFirst = false;

    showGamesSelection = false;
    pointId;
    gamesForSelect = [];
    // gamesForSelect = [
    //     {
    //         id: 1,
    //         name: 'Book',
    //         description: 'Lorem ipsum',
    //         image_link: '../../../../assets/images/gamesBg/game_1.png'
    //     },
    //     {
    //         id: 2,
    //         name: 'Book',
    //         description: 'Lorem ipsum dolor <span>sit amet</span>, consec tetur adipiscing elit, sed dp adipiscing elit, sed dp Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed dp adipiscing elit, sed dp',
    //         image_link: '../../../../assets/images/gamesBg/game_2.png'
    //     },
    //     {
    //         id: 3,
    //         name: 'Book',
    //         description: 'Lorem ipsum dolor sit amet, consec',
    //         image_link: '../../../../assets/images/gamesBg/game_3.png'
    //     }
    //     ,
    //     {
    //         id: 4,
    //         name: 'Book',
    //         description: 'Lorem ipsum <span>dolor sit</span> amet, consec tetur adipiscing elit, sed dp adipiscing elit, sed dp',
    //         image_link: '../../../../assets/images/gamesBg/game_3.png'
    //     }
    //
    // ];

    // for INSTALL BUTTON
    deferredPrompt: any;
    IOSInstallBtn = false;
    ios_popup_text;
    androidInstallBtn = false;
    isApp = +localStorage.getItem('isApp');
    clearSmallInput = false;
    ifPointHash = this.usersPointService.getHash() !== '';
    ifPointSubdomain = this.usersPointService.isPointSubdomain();

    logoutTimeSec = 90;
    logoutTimeSecForPin = 120;
    //logoutTimeSecForPin = 10;

    timeoutLogout;

    particlesStyle = PARTICLES_SETTINGS.style;
    particlesParams = PARTICLES_SETTINGS.params;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        e.preventDefault();
        // store install avaliable event
        this.deferredPrompt = e;

        // show button with display:block;
        this.androidInstallBtn = true;

        this.appPromptService.deferredPrompt = this.deferredPrompt;
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('click', ['$event'])
    handleTouch(event) {
        if (this.isApp) {
            this.setSaveScreenTimer();
            this.screenSaverService.hide();
        }
    }

    @HostBinding('class') isAppClass = '';

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private usersPointService: UsersPointService,
        private loginService: LoginService,
        private menuService: MenuService,
        private loaderService: LoaderService,
        private partnerService: PartnerService,
        private router: Router,
        private auth:AuthenticationService,
        private keyboardService: KeyboardService,
        private appPromptService: AppPromptService,
        private kioskService: KioskService,
        private screenSaverService: ScreenSaverService,
        private popupComponent: PopupComponent) {

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });

        this.deferredPrompt = this.appPromptService.deferredPrompt;

        this.screenSaverService.animation.subscribe(val => {
            if (val) {
                this.screenSaverAnimation = val;
                if (this.isScreenSaverSliderAnimation()) {
                    this.setSaveScreenTimer();
                }
            }
        });
    }

    ngOnInit() {
        //this.getPartner();

        this.keyboardService.wasLogout.next(true);
        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');

        this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';

        this.auth.logout();


        // this.phoneForm = this.formBuilder.group({
        //     phone: [null, Validators.required],
        //     remember_user: [false],
        //     country: ['us'],
        // });

        for (let i = 0; i< this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }

        this.setPinValue();

        //this.setPhoneMask(this.phoneForm.controls.country.value);

        if (localStorage.remember_user !== "false" && !this.isApp) {
            this.phoneForm.userForm.controls.remember_user.setValue(true);
            if(localStorage.country){
                this.phoneForm.userForm.controls.country.setValue(localStorage.country);
            }
            this.setPhoneMask(this.phoneForm.userForm.controls.country.value);
            this.phoneForm.userForm.controls.phone.setValue(localStorage.phone);

        } else {
            this.phoneForm.userForm.controls.remember_user.setValue(false);
            this.setPhoneMask(this.phoneForm.userForm.controls.country.value);
            this.phoneForm.userForm.controls.phone.setValue('');
            //this.phoneForm.controls.country.setValue('');
        }


        //this.setPhoneMask(this.phoneForm.controls.country.value);


    }

    setPinValue() {
        this.keyboardService.smallInputValueChanged.next({order: 0, value: null});
        this.keyboardService.smallInputValueChanged.next({order: 1, value: null});
        this.keyboardService.smallInputValueChanged.next({order: 2, value: null});
        this.keyboardService.smallInputValueChanged.next({order: 3, value: null});

        this.keyboardService.smallInputValueChanged.subscribe((settings) => {
            (<FormArray>this.pinForm.controls.digits).controls[settings.order].patchValue(settings.value);
        });
    }

    resendSms(){
        // this.onPhoneSubmit();
      this.phoneForm.onPhoneSubmit();
    }

    // onPhoneSubmit(){
    //     if (this.phoneForm.invalid) {
    //         return;
    //     }
    //
    //     this.setTimeoutForLogout(this.logoutTimeSecForPin);
    //     this.resetLogoutQueryParam();
    //     this.riverPayAccountId = '';
    //
    //
    //     let phone = this.phoneForm.controls.phone.value;
    //     let country = this.phoneForm.controls.country.value;
    //
    //     this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(data => {
    //
    //         try {
    //             if(data.status){
    //                 if (this.phoneForm.controls.remember_user.value && !this.isApp) {
    //                     // save username and password
    //                     localStorage.phone = phone;
    //                     localStorage.country = country;
    //                     localStorage.remember_user = this.phoneForm.controls.remember_user.value;
    //                 } else {
    //                     localStorage.phone = '';
    //                     localStorage.country = '';
    //                     localStorage.remember_user = "false";
    //                 }
    //
    //                 this.phoneLastFour = phone.substr(-4);
    //                 this.phoneFormActive = false;
    //
    //                 let $this = this;
    //                 setTimeout(() => {
    //                     $this.inputs.toArray()[0].nativeElement.focus();
    //                     $this.keyboardService.setActiveInput();
    //                 },0);
    //             } else {
    //                 if(data.code){
    //                     if(data.code == 402){
    //                         this.errorHandlerRegisterNewUser();
    //                     } else if (data.code == 403) {
    //                         this.errorHandlerNewOrExistingUser(data);
    //                     } else if (data.code == 405) {
    //                         this.errorHandlerNeedRegisterOnlineAccount(data);
    //                     } else if (data.code == 407) {
    //                         this.errorHandlerTerminalNotConnected();
    //                     } else{
    //                         this.popupComponent.showPopupError({text:data.message});
    //                     }
    //                 } else {
    //                     this.popupComponent.showPopupError({text:data.message});
    //                 }
    //             }
    //         } catch (e) {
    //             this.loaderService.hideAndShowTryAgain();
    //         }
    //
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }

    errorHandlerRegisterNewUser() {
        this.registrationPartActive = true;
        this.phoneFormActive = false;

        let $this = this;
        setTimeout(() => {
            $this.inputs.toArray()[0].nativeElement.focus();
            $this.keyboardService.setActiveInput();
        },0);
    }

    errorHandlerNewOrExistingUser(data) {
        let pointName = '';
        if(data.pointName){
            pointName = ' '+data.pointName;
        }
        this.popupComponent.showPopupChoice({
            text: 'Are you a new'+pointName+' user or an existing user?',
            confirmButtonText: 'Yes, I am a new<svg><rect></rect></svg>',
            cancelButtonText: 'No, I am an existing'
        }, this.checkNewOrExistingUser.bind(this));
    }

    errorHandlerNeedRegisterOnlineAccount(data) {
        let pointName = '';
        if (data.pointName) {
            pointName = ' '+data.pointName;
        }
        this.popupComponent.showPopupChoice({
            text: 'Location'+pointName+` has removed your account. Would you like to signup for ${this.partnerService.getBusinessName()} online account and continue enjoying our serves?`,
            confirmButtonText: 'Yes<svg><rect></rect></svg>',
            cancelButtonText: 'No'
        }, this.registerNewVirtualUser.bind(this));
    }

    errorHandlerTerminalNotConnected() {
        this.terminalConnectingPopup = true;
        setTimeout(() => {
            (<HTMLElement>document.querySelectorAll(KEYBOARD_SETTINGS.excludedInputs)[0]).focus();
            this.keyboardService.setActiveInput();
        },0);
    }

    onSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        const country = this.phoneForm.userForm.controls.country.value;
        const phone = this.phoneForm.userForm.controls.phone.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        if (phone && pin && (pin.length === 4) && country) {
          this.authenticationService.login(phone, pin, country).subscribe(data => {
            this.checkLoginSubmit(data);
          }, (error) => {
            this.loaderService.hideAndShowTryAgain();
          }, () => {
            this.loaderService.hide();
          });
        } else {
          this.loaderService.hideAndShowTryAgain();
        }
    }

    checkLoginSubmit(data) {
      try {
        if (data.status) {
            // this.showRiverPayAccounts(data, data.riverPayAccounts);
            this.showRiverPayAccounts(data, data.accounts);
            this.gamesForSelect = data.gameProviders;
            this.showContactsFormFirst = data.result.first_name == '' && data.result.last_name == '' && data.result.email == '';
        } else {
          this.popupComponent.showPopupError({text:data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }

    showRiverPayAccounts(data,riverPayAccounts){
        if (riverPayAccounts.length > 1) {
            var options = {};

            riverPayAccounts.map(function(o) {
                options[o.point_id] = `<div class="point-name">${o.point_name}</div>`;
            });

            this.setTimeoutForLogout();

            this.popupComponent.showPopupVariants(
                {accounts: options}, (status) => {
                    //console.log(status);
                    if (status) {
                        let riverPayAccountId = status;

                        this.setUserPointsAccount(riverPayAccounts,riverPayAccountId);

                        //this.showGamesSelection = true;
                        //this.redirectToRewards(data.result);
                        clearTimeout(this.timeoutLogout);
                    }
                });



        } else {
            if(riverPayAccounts[0]){
                this.usersPointService.setAccount(riverPayAccounts[0]);
                //this.auth.userLoggedStatusChanged.next(true);

                this.showGamesSelection = true;
                //this.redirectToRewards(data.result);
            }else{
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }
        }
    }

    redirectToRewards(){
        if (this.isApp) {
            this.keyboardService.setTimeout();
            this.clearTimeoutForLogout();

            this.clearSaveScreenTimer();
        }

        // if (result.first_name == '' && result.last_name == '' && result.email == '') {
        //     this.router.navigate(['/home/rewards/form']);
        // } else {
        //     this.router.navigate(['/home/rewards']);
        // }


        if (this.showContactsFormFirst) {
            this.router.navigate(['/home/rewards/form']);
        } else {
            this.router.navigate(['/home/rewards']);
        }
    }

    isPhoneClassActive(){
        if(this.phoneFormActive){
            return true;
        }

        return false;
    }

    isRegistrationClassActive(){
        if (this.registrationPartActive) {
            return true;
        }

        return false;
    }

    isPinClassActive(){
        if(!this.phoneFormActive){
            return true;
        }

        return false;
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    // checkInput(index, field, event) {
    //     console.log(field.value, event.target, event.target.value !== '')
    //     if (field.value && event.target.value !== '') {
    //         if (index < this.inputs.toArray().length - 1) {
    //             this.inputs.toArray()[index + 1].nativeElement.focus();
    //         }
    //     } else if (event.target.value === '') {
    //         if (index > 0) {
    //             //field.setValue(null)
    //             if (!this.clearSmallInput) {
    //                 this.clearSmallInput = true;
    //             } else {
    //                 this.inputs.toArray()[index - 1].nativeElement.focus();
    //                 this.clearSmallInput = false;
    //             }
    //         } else {
    //             console.log('first field');
    //         }
    //     }
    // }

    hideLogin() {
        this.loginService.hide();
        document.body.classList.remove('is-hidden');
    }

    // onRememberUser(e) {
    //     const checked = e.target.checked;
    //     this.phoneForm.controls.remember_user.setValue(!!checked);
    // }

    onRegisterNewUser() {
        let phone = this.phoneForm.userForm.controls.phone.value;
        let country = this.phoneForm.userForm.controls.country.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        this.loaderService.show();
        this.authenticationService.register(phone, pin, country,this.riverPayAccountId).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    // let riverPayAccounts = data.riverPayAccounts;
                    let riverPayAccounts = data.accounts;

                    if (this.phoneForm.userForm.controls.remember_user.value && !this.isApp) {
                        localStorage.phone = phone;
                        localStorage.country = country;
                    } else {
                        localStorage.phone = '';
                        localStorage.country = '';
                    }
                    localStorage.remember_user = this.phoneForm.userForm.controls.remember_user.value;

                    this.authenticationService.setData(data.result);

                    if(riverPayAccounts[0]){
                        this.checkLoginSubmit(data);
                        //this.setUserPointsAccount(riverPayAccounts,customerPointId);

                        //this.redirectToRewards(data.result);
                    }else{
                        this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
                    }
                }else{
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    setUserPointsAccount(riverPayAccounts,userPointId){
        let $this = this;
        riverPayAccounts.map(function(o) {
            if(o.point_id == userPointId){
                $this.usersPointService.setAccount(o);
                //$this.auth.userLoggedStatusChanged.next(true);
                return;
            }
        });

        this.setFirstAccount(riverPayAccounts);
    }

    setFirstAccount(riverPayAccounts){
        let $this = this;
        setTimeout(function(){
            if($this.usersPointService.getId() == ''){
                $this.usersPointService.setAccount(riverPayAccounts[0]);
                //$this.auth.userLoggedStatusChanged.next(true);
            }
        },1000);
        this.showGamesSelection = true;
    }

    setPhoneMask(country) {
        this.phoneMask = this.PHONE_MASK_CONFIG[country];
    }

    onCountryChange(e) {
        const value = e.target.value;
        this.setPhoneMask(value);
    }

    getPartner() {
        this.currentPartner = this.PARTNERS[this.partnerService.currentPartner];
    }

    getPartnerName() {
        return this.partnerService.getName();
        //return this.currentPartner.name;
    }

    getPartnerLogo() {
        return this.partnerService.getLogo();

        // if (!environment.production) {
        //     return '../../../assets/images/activate_yourself.png'
        // }
        //return this.currentPartner.logo;
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);
    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    install() {
        if (this.isIos() && !this.isInStandaloneMode()) {
            let userAgent = window.navigator.userAgent.toLowerCase();
            this.IOSInstallBtn = true;
            let device = 'device';

            if  (/iphone/.test( userAgent )) {
                device = 'IPhone';
            } else if (/ipad/.test( userAgent )) {
                device = 'IPad';
            }
            this.ios_popup_text = `Install this webapp on your ${device}: `;
        } else {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === 'accepted') {
                    // user accept the prompt
                    // lets hidden button
                    //this.androidInstallBtn = false;
                    //this.appInstalled = true;
                    //document.location.reload();

                    //this.appInstalled = true;

                } else {
                    console.log('User dismissed the prompt');
                }
                //this.deferredPrompt = null;
            });
        }
    }

    checkNewOrExistingUser(result) {
        if (result) {
            let phone = this.phoneForm.userForm.controls.phone.value;
            let country = this.phoneForm.userForm.controls.country.value;

            this.loaderService.show();
            this.authenticationService.newCustomer(phone, country).subscribe(data => {
                this.loaderService.hide();
                try {
                    if(data.status){
                        this.registrationPartActive = true;
                        this.activateFields();
                    } else {
                        this.popupComponent.showPopupError({text:data.message});
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            });
        } else {
            // this.popupComponent.enterAccountId({}, this.registerExistingUser.bind(this));
            this.accountEnteringPopup = true;
            setTimeout(() => {
                this.keyboardService.setActiveInput();
            }, 500);

        }
    }

    registerExistingUser(data) {
        const accountId = data.replace(/-/g,'');

        let phone = this.phoneForm.userForm.controls.phone.value;
        let country = this.phoneForm.userForm.controls.country.value;

        if (accountId && accountId.length === 12 && phone && country) {
            this.loaderService.show();
            this.authenticationService.findByAccountId(accountId, phone, country).subscribe(data => {
                this.loaderService.hide();
                try {
                    if(data.status){
                        this.registrationPartActive = true;
                        this.accountEnteringPopup = false;

                        this.riverPayAccountId = accountId;

                        this.activateFields();
                    } else {
                        this.popupComponent.showPopupError({text:data.message});
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Entered account id is not valid'});
        }
    }

    registerNewVirtualUser(result) {
        if (!result) {
            return;
        }

        let phone = this.phoneForm.userForm.controls.phone.value;
        let country = this.phoneForm.userForm.controls.country.value;

        this.loaderService.show();
        this.authenticationService.newCustomerVirtual(phone, country).subscribe(data => {
            this.loaderService.hide();
            try {
                if(data.status){
                    if(data.code){
                        if(data.code == 405){

                        }else{
                            this.registrationPartActive = true;
                        }
                    }else{
                        this.registrationPartActive = true;
                    }

                    this.activateFields();
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        });
    }

    activateFields(){
        this.phoneFormActive = false;

        let $this = this;
        setTimeout(() => {
            $this.inputs.toArray()[0].nativeElement.focus();
            $this.keyboardService.setActiveInput();
        },0);
    }

    goBackToLogin() {
        this.terminalConnectingPopup = false;
        this.accountEnteringPopup = false;
        this.phoneFormActive = true;
        this.registrationPartActive = false;
        this.resetPinInput();


        let $this = this;
        setTimeout(() => {
            (<HTMLElement>document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0]).focus();
            this.keyboardService.setActiveInput();
        }, 500);
    }

    resetLogoutQueryParam() {
        this.keyboardService.wasLogout.next(false);
    }

    resetPinInput() {
      (this.pinForm.get('digits') as FormArray).clear();
      for (let i = 0; i< this.numOfDigits; i++) {
        (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
      }
    }

    logout(){
        console.log('new login');

        this.auth.logout();
        this.router.navigate(['/']);
        this.goBackToLogin();
        this.showGamesSelection = false;
        this.popupComponent.closePopup();
        this.phoneForm.userForm.controls.phone.setValue(null);
        this.resetPinInput();
    }

    setTimeoutForLogout(time = this.logoutTimeSec) {
        if (this.isApp) {
            this.clearTimeoutForLogout();

            this.timeoutLogout = setTimeout(() => {
                this.logout();
            }, time*1000);
        }
    }

    clearTimeoutForLogout() {
        clearTimeout(this.timeoutLogout);
    }

    setSaveScreenTimer() {
        if (this.isApp && this.isScreenSaverSliderAnimation()) {
            this.clearSaveScreenTimer();

            this.showScreenSaverTimer = setTimeout(() => {
                this.screenSaverService.show();
                this.keyboardService.wasLogout.next(true);
                document.body.click();
                this.logout();
            }, this.showScreenSaverAfterMin * 60 * 1000);
        }
    }


    clearSaveScreenTimer() {
        clearTimeout(this.showScreenSaverTimer);
    }

    gameProviderWasChecked() {
        this.showGamesSelection = false;
        this.redirectToRewards();
    }

    isScreenSaverSliderAnimation() {
        return this.screenSaverAnimation === SCREEN_SAVER_ANIMATION_TYPE.slider;
    }

    isScreenSaverLinesAnimation() {
        return this.screenSaverAnimation === SCREEN_SAVER_ANIMATION_TYPE.lines;
    }


    // NEW CHANGES START



}

