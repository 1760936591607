import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ScratchOffService} from "../../../../service/games/scratchOff.service";
import {LoaderService} from "../../../../service/loader.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FundsResultItem} from "../../../../models/services/funds/funds.model";
import {ScrollToService} from "../../../../service/scrollTo.service";
import {SCRATCH_OFF_POPUP_TYPE, SCRATCH_OFF_RULES, SCRATCH_OFF_WELCOME_RULES} from "./scratch-off-rules.config";


@Component({
    selector: 'app-scratch-off-rules',
    templateUrl: './scratch-off-rules.component.html',
    styleUrls: ['./scratch-off-rules.component.scss']
})

export class ScratchOffRulesComponent implements OnInit {
    @Output() isElementReady = new EventEmitter();
    @Output() isPaymentForbidden = new EventEmitter();
    @Output() isPaymentSuccess = new EventEmitter();

    buyForm: FormGroup;

    imageLoaded = false;

    gamePrice;
    backgroundImage;
    paymentMethods;
    activeMethod;
    rules;
    POPUP_TYPE = SCRATCH_OFF_POPUP_TYPE;

    private popupVisible = false;
    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
      this._showPopup = value;
      if (value && !this.popupVisible) {
          this.popupVisible = true;
          this.getInfo();
      }
    }

    get showPopup() : boolean  {
      return this._showPopup;
    }

    @Input() popupType: string;

    responseKeys = {
        backgroundImage: 'image_bg',
        gamePrice: 'price',
        paymentMethods: 'cards',
        isPaymentForbidden: 'hidePayment'
    };

    constructor(private scratchOffService: ScratchOffService,
                private formBuilder: FormBuilder,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {

    }

    ngOnInit() {
        this.buyForm = this.formBuilder.group({
            paymentMethod: [null, Validators.required],
        });
    }

    ngOnDestroy() {
        this.popupVisible = false;
    }

    getInfo() {
        this.backgroundImage = './assets/images/scratchOff/game-placeholder.jpeg';
        setTimeout(() => {
          this.rules = this.isWelcomePopup() ? SCRATCH_OFF_WELCOME_RULES : SCRATCH_OFF_RULES;
        });

        // this.scratchOffService.setPayment().subscribe(data => {
        //     this.setPayment(data);
        // }, () => {
        //     this.loaderService.hideAndShowTryAgain();
        //     this.isElementReady.emit(false);
        // }, () => {
        //     this.loaderService.hide();
        // });
    }

    isWelcomePopup() {
        return this.popupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    }

    isScratchOffRulesPopup() {
      return this.popupType === SCRATCH_OFF_POPUP_TYPE.pigRules;
    }

    setPayment(response) {
        if(response.status === true){
            if (response[this.responseKeys.isPaymentForbidden]) {
                this.isPaymentForbidden.emit();
                return;
            }

            this.isElementReady.emit(true);
            this.backgroundImage = response[this.responseKeys.backgroundImage];
            this.gamePrice = response[this.responseKeys.gamePrice];
            this.paymentMethods = response[this.responseKeys.paymentMethods];
        }else if (response.status === false) {
            this.isElementReady.emit(false);
            this.popupComponent.showPopupError({text: response.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
            this.isElementReady.emit(false);
        }
    }

    onActionBtnClick() {
        if (this.isScratchOffRulesPopup()) {
          this.buyGameRequest(1);
        } else if (this.isWelcomePopup()) {
          this.isPaymentSuccess.emit();
        }

        // if (this.activeMethod) {
        //     const cardId = this.getMethodId(this.activeMethod);
        //     this.popupComponent.showPopupAgreement({
        //           text: `You are purchasing ${this.gamePrice} <br><br> ${this.getPaymentName(this.activeMethod)}`},
        //       (status) => {
        //           if (status) {
        //               this.buyGameRequest(cardId);
        //           }
        //       });
        // } else {
        //     this.popupComponent.showPopupError({text: 'Please, select payment card first'});
        // }
    }

    buyGameRequest(cardId) {
        this.buyGameResult({status: true});
        // this.loaderService.show();
        // this.scratchOffService.buyGame(cardId).subscribe(data => {
        //     this.buyGameResult(data);
        // }, () => {
        //     this.loaderService.hideAndShowTryAgain();
        // }, () => {
        //     this.loaderService.hide();
        // });
    }

    buyGameResult(response) {
        if(response.status === true){
            // this.popupComponent.showPopupSuccess({text: response.message});
            this.isPaymentSuccess.emit();
        }else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getPaymentMethod(method) {
        return method.payment_method;
    }

    getMethodId(method) {
        return method.id;
    }

    getPaymentName(data){
        if (data) {
            return data.description+' - '+data.last_four;
        }
        return '';
    }

    changeMethod(method){
        if (method) {
            this.activeMethod = method;
        }
    }

    onBgLoaded() {
        this.imageLoaded = true;
        this.isElementReady.emit(true);
    }
}
