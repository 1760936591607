import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { PROVIDERS_GAME_STATUS } from "../../../config/project";
var GamesSelectionComponent = /** @class */ (function () {
    function GamesSelectionComponent(popupComponent, loaderService, gamesProviderService, userPointService, kioskScrollService, auth, terminalService, kioskService) {
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.gamesProviderService = gamesProviderService;
        this.userPointService = userPointService;
        this.kioskScrollService = kioskScrollService;
        this.auth = auth;
        this.terminalService = terminalService;
        this.kioskService = kioskService;
        this.close = new EventEmitter();
        this.back = new EventEmitter();
        this.gameSelected = new EventEmitter();
        this.isBackBtn = new EventEmitter();
        this.games = [];
        this.kioskTableGames = [];
        this.isApp = this.kioskService.isKiosk();
        this.isAppGamesAndCrypto = false;
        this.showPaymentPopup = false;
        this.someField = !!this.isApp;
    }
    Object.defineProperty(GamesSelectionComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            this._showPopup = value;
            if (value && this.isApp) {
                this.getGamesList(this.pointId);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GamesSelectionComponent.prototype, "pointId", {
        get: function () {
            return this._pointId;
        },
        set: function (value) {
            this._pointId = value;
            if (value !== null && !this.isApp) {
                this.getGamesList(this._pointId);
            }
        },
        enumerable: true,
        configurable: true
    });
    GamesSelectionComponent.prototype.ngOnInit = function () {
        this.isAppGamesAndCrypto = this.terminalService.isCrypto();
    };
    GamesSelectionComponent.prototype.onGameSelected = function (game) {
        if (this.isApp) {
            this.gameSelected.emit(game);
        }
        else {
            this.gameSelected.emit();
        }
    };
    GamesSelectionComponent.prototype.chooseGame = function (game) {
        // game.status = 2;
        // game['error_message'] = 'Errro';
        var gameStatus = game.status;
        var errorMessage = game.error_message;
        if (gameStatus === PROVIDERS_GAME_STATUS.notAvailable && this.isApp) {
            this.popupComponent.showPopupError({ text: errorMessage });
            return;
        }
        // const gameId = game.id;
        // this.gamesProviderService.chooseGame(this.userPointService.getId(), gameId).subscribe(data => {
        //     this.checkSubmitGame(data, gameId);
        // }, () => {
        //     this.loaderService.hideAndShowTryAgain();
        // }, () => {
        //     // this.loaderService.hide();
        // });
        this.gamesProviderService.onGameChosen(game, this.checkSubmitGame.bind(this));
    };
    GamesSelectionComponent.prototype.checkSubmitGame = function (data, gameId) {
        this.loaderService.hide();
        try {
            if (data.status === true) {
                if (this.isApp) {
                    this.buyingGameInfo = this.games.find(function (el) { return el.id === gameId; });
                    this.onGameSelected(this.buyingGameInfo);
                }
                else {
                    this.onGameSelected();
                    this.loaderService.hide();
                }
                // this.userPointService.setUserPointId(data.userPointId);
                // this.userPointService.setGameVendorShortName(data.gameVendorShortName);
                //
                // this.auth.userLoggedStatusChanged.next(true);
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    GamesSelectionComponent.prototype.closePopup = function () {
        this.close.emit();
    };
    GamesSelectionComponent.prototype.canScrollTop = function () {
        return this.kioskScrollService.canScrollTop(this.scrolledEl);
    };
    GamesSelectionComponent.prototype.canScrollBottom = function () {
        return this.kioskScrollService.canScrollBottom(this.scrolledEl);
    };
    GamesSelectionComponent.prototype.scrollTable = function (top) {
        this.kioskScrollService.scrollTable(this.scrolledEl.nativeElement, top);
    };
    GamesSelectionComponent.prototype.getGamesList = function (pointId) {
        var _this = this;
        if (pointId !== null) {
            this.gamesProviderService.getGamesList(pointId).subscribe(function (data) {
                _this.getGamesListResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                //this.loaderService.hide();
            });
        }
    };
    GamesSelectionComponent.prototype.getGamesListResult = function (data) {
        var _this = this;
        try {
            if (data.status === true) {
                this.games = data.result;
                // this.games.length = 1;
                this.gamesProviderService.setProviders(this.games);
                if (this.games.length === 1) {
                    this.chooseGame(this.games[0]);
                }
                else {
                    this.convertGamesList();
                    setTimeout(function () {
                        _this.updateMaxScrollHeight();
                    });
                    this.loaderService.hide();
                    this.isBackBtn.emit(true);
                }
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    GamesSelectionComponent.prototype.convertGamesList = function () {
        this.kioskTableGames = this.games.map(this.convertGamesListItem);
    };
    GamesSelectionComponent.prototype.convertGamesListItem = function (el) {
        return {
            img: el.image_link,
            title: el.name,
            description: el.description,
            id: el.id,
            status: el.status,
            error_message: el.error_message
        };
    };
    GamesSelectionComponent.prototype.goBack = function () {
        this.back.emit();
    };
    GamesSelectionComponent.prototype.updateMaxScrollHeight = function () {
        var firstChild = document.querySelector('.games-selection .kiosk-table__item:first-child');
        var secondChild = document.querySelector('.games-selection .kiosk-table__item:nth-child(2)');
        if (this.isApp && firstChild && secondChild) {
            var first = firstChild.scrollHeight;
            var second = secondChild.scrollHeight;
            var spicing = 70;
            var maxHeightInVH = this.vhToPixels(57);
            var maxHeightFirstTwoItems = first + second + spicing;
            document.querySelector('.games-selection').style.maxHeight = (maxHeightFirstTwoItems < maxHeightInVH ? maxHeightFirstTwoItems : maxHeightInVH) + "px";
        }
    };
    GamesSelectionComponent.prototype.vhToPixels = function (vh) {
        return Math.round(window.innerHeight / (100 / vh));
    };
    return GamesSelectionComponent;
}());
export { GamesSelectionComponent };
