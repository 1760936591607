export const PAGE_COLORS = {
  rewards: '#EEBF27',
  rewardsRGB: 'rgb(238, 191, 39)',
  prizes: '#76D6FF',
  prizesRGB: 'rgb(118, 214, 255)',
  settings: '#DCBCFF',
  settingsRGB: 'rgb(220, 188, 255)',
  default: '#FF5F6F',
  defaultRGB: 'rgb(255, 95, 111)'
};
