<div class="games-selection" [class.is-hidden]="!showPopup || showPaymentPopup || (isApp && kioskTableGames.length <= 1 )">
<!--    <button class="games-selection__close" (click)="closePopup()"></button>-->
    <button class="games-selection__back" *ngIf="!isApp || (isApp && isAppGamesAndCrypto)" (click)="goBack()">Back</button>
    <div class="games-selection__inner" #scrolledEl>
        <div class="games-selection__list" *ngIf="!isApp">
            <div class="games-selection__item" *ngFor="let game of games">
                <img [src]="game.image_link"
                     alt=""
                     class="games-selection__img"
                     (click)="chooseGame(game)">

                <div class="games-selection__content">
                    <div class="games-selection__description" [innerHTML]="game.description"></div>
                    <button (click)="chooseGame(game)" class="games-selection__btn">SELECT</button>
                </div>
            </div>
        </div>

        <app-kiosk-table *ngIf="isApp"
                         [items]="kioskTableGames"
                         (onSelect)="chooseGame($event)"></app-kiosk-table>
    </div>


    <div *ngIf="isApp" class="scroll-btns scroll-btns--small">
        <svg [class.is-active]="canScrollTop()"
             (click)="scrollTable(true)"
             class="scroll-btns__arrow scroll-btns__arrow--top">
            <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>
        </svg>
        <svg [class.is-active]="canScrollBottom()"
             (click)="scrollTable()"
             class="scroll-btns__arrow scroll-btns__arrow--bottom">
            <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>
        </svg>
    </div>
</div>

<app-kiosk-payment [showPopup]="showPaymentPopup"
                   [data]="buyingGameInfo"
                   [type]="2"></app-kiosk-payment>
