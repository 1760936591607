/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./promo-popup.component";
var styles_PromoPopupComponent = [i0.styles];
var RenderType_PromoPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoPopupComponent, data: {} });
export { RenderType_PromoPopupComponent as RenderType_PromoPopupComponent };
export function View_PromoPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "promo-popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "promo-popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "promo-popup__back"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 0, 0, currVal_0); }); }
export function View_PromoPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promo-popup", [], null, null, null, View_PromoPopupComponent_0, RenderType_PromoPopupComponent)), i1.ɵdid(1, 114688, null, 0, i2.PromoPopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromoPopupComponentNgFactory = i1.ɵccf("app-promo-popup", i2.PromoPopupComponent, View_PromoPopupComponent_Host_0, { active: "active" }, { close: "close" }, ["*"]);
export { PromoPopupComponentNgFactory as PromoPopupComponentNgFactory };
