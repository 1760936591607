/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-bordered.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./group-bordered.component";
var styles_GroupBorderedComponent = [i0.styles];
var RenderType_GroupBorderedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupBorderedComponent, data: {} });
export { RenderType_GroupBorderedComponent as RenderType_GroupBorderedComponent };
export function View_GroupBorderedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "group-bordered__subtitle"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "group-bordered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "group-bordered__title"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "group-bordered__subtitle"]], null, null, null, null, null)), i1.ɵncd(null, 2), i1.ɵncd(null, 3)], null, null); }
export function View_GroupBorderedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-bordered", [], null, null, null, View_GroupBorderedComponent_0, RenderType_GroupBorderedComponent)), i1.ɵdid(1, 114688, null, 0, i2.GroupBorderedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GroupBorderedComponentNgFactory = i1.ɵccf("app-group-bordered", i2.GroupBorderedComponent, View_GroupBorderedComponent_Host_0, {}, {}, ["h3", "h2", "h4", ".group-bordered-inner"]);
export { GroupBorderedComponentNgFactory as GroupBorderedComponentNgFactory };
