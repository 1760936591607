<div class="page page--quaternary">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <a id="address_back_mobile" [routerLink]="[routerPaths.addresses.main]" class="page__back page__back--mobile page__back--mobile-text">
            <span class="visually-hidden">Cancel</span>
        </a>
        <h1 class="page__title">ADD ADDRESS</h1>
        <div class="page__form">
            <form [formGroup]="newAddressForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="address"
                           (change)="onInput($event)"
                           placeholder="Address"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button id="save_address" class="button snake-btn is-notouched" [disabled]="newAddressForm.invalid">
                        SAVE
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
        <a id="address_back" [routerLink]="[routerPaths.addresses.main]" class="button page__back page__back--desktop" >
            Back
            <svg><rect></rect></svg>
        </a>
    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

