/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./transaction-processing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/loaders/ellipsis-loader/ellipsis-loader.component.ngfactory";
import * as i3 from "../../../shared/loaders/ellipsis-loader/ellipsis-loader.component";
import * as i4 from "./transaction-processing.component";
import * as i5 from "../../../shared/popup/popup.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../service/transactions/transactions.service";
import * as i8 from "../../../service/loader.service";
var styles_TransactionProcessingComponent = [i0.styles];
var RenderType_TransactionProcessingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransactionProcessingComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_TransactionProcessingComponent as RenderType_TransactionProcessingComponent };
export function View_TransactionProcessingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "transaction-processing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "transaction-processing__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "transaction-processing__loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-ellipsis-loader", [], null, null, null, i2.View_EllipsisLoaderComponent_0, i2.RenderType_EllipsisLoaderComponent)), i1.ɵdid(5, 114688, null, 0, i3.EllipsisLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
export function View_TransactionProcessingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-transaction-processing", [], [[40, "@slideToLeft", 0]], null, null, View_TransactionProcessingComponent_0, RenderType_TransactionProcessingComponent)), i1.ɵdid(1, 114688, null, 0, i4.TransactionProcessingComponent, [i5.PopupComponent, i6.ActivatedRoute, i7.TransactionsService, i8.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var TransactionProcessingComponentNgFactory = i1.ɵccf("app-transaction-processing", i4.TransactionProcessingComponent, View_TransactionProcessingComponent_Host_0, {}, {}, []);
export { TransactionProcessingComponentNgFactory as TransactionProcessingComponentNgFactory };
