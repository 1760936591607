import {Component, OnInit} from '@angular/core';
import {KioskAccessorService} from "../../service/kiosk/kiosk-accessor.service";


@Component({
    selector: 'app-terminal',
    templateUrl: './terminal.component.html'
})
export class TerminalComponent implements OnInit {
    payToken = '';
    payAmount = 0;

    constructor(
        private kioskAccessorService:KioskAccessorService
    ) {


    }

    ngOnInit() {
        this.kioskAccessorService.paymentAllowed.subscribe((value: any) => this.payment(value));
        this.kioskAccessorService.paymentAmountChanged.subscribe((value: any) => this.paymentAmountChanged(value));
    }

    paymentAmountChanged(amount:number){
        // if(!isNaN(amount)){
        //     this.payAmount += parseFloat(amount);
        // }

    }

    payment(status:boolean){
        if(status){
            this.payToken = this.kioskAccessorService.paymentToken;
        }
    }

    cmd(cmd){
        this.kioskAccessorService.cmd(cmd);
    }

    pay(){
        //this.kioskAccessorService.payment();
    }
}
