import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
var FrontendTestSettingsService = /** @class */ (function () {
    function FrontendTestSettingsService() {
        this.isTestMode = environment.test_front;
        this.isResetAllSettings = false;
        this.terminalOn = false;
        this.mascotSinglePageOn = false;
        this.clientOn = true;
        this.partnerAdditionInfo = true;
        this.terminalSettings = {
            noConnectedTerminal: false,
            isStationMascot: true,
            displayGames: true,
            displayCrypto: true,
        };
        if (this.isTestModeOn()) {
            console.log('Frontend Test mode is on'.toLocaleUpperCase());
        }
        else {
            console.log('Frontend Test mode is off'.toLocaleUpperCase());
        }
    }
    FrontendTestSettingsService.prototype.isTestModeOn = function () {
        if (this.isTestMode && !this.isResetAllSettings) {
            return true;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isHideForTesting = function () {
        if (this.isTestModeOn()) {
            return false;
        }
        return true;
    };
    FrontendTestSettingsService.prototype.isMascotSinglePage = function () {
        if (this.isTestModeOn()) {
            return this.mascotSinglePageOn;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isClient = function () {
        if (this.isTestModeOn()) {
            return this.isTestModeOn() && this.clientOn;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminal = function () {
        if (this.isTestModeOn()) {
            return this.terminalOn;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalDisplayGames = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.displayGames;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalDisplayCrypto = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.displayCrypto;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isNoTerminal = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.noConnectedTerminal;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isPartnerAdditionInfo = function () {
        if (this.isTestModeOn()) {
            return this.partnerAdditionInfo;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalStationMascot = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.isStationMascot;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalExist = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && !this.terminalSettings.noConnectedTerminal;
        }
        return false;
    };
    FrontendTestSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FrontendTestSettingsService_Factory() { return new FrontendTestSettingsService(); }, token: FrontendTestSettingsService, providedIn: "root" });
    return FrontendTestSettingsService;
}());
export { FrontendTestSettingsService };
