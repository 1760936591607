import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LENDING_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";
import {SettingsLoaderService} from "../settingsLoader.service";
import {BehaviorSubject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class InvestmentsService extends SettingsLoaderService{
  private config = LENDING_CONFIG;
  public checkedItem = new BehaviorSubject(null);

  constructor(private http: HttpClient, public loaderService: LoaderService) {
    super(loaderService);
  }

  getInfo() {
    this.showLoader();
    let post = this.http.post<any>(this.config.info, {})
    return post;
  }

  getUSDC(page) {
    // this.showLoader();
    let post = this.http.post<any>(this.config.usdcList, {page})
    return post;
  }

    getUsd(page) {
        // this.showLoader();
        let post = this.http.post<any>(this.config.usdList, {page})
        return post;
    }

  getCrypto() {
    // this.showLoader();
    let post = this.http.post<any>(this.config.cryptoList, {})
    return post;
  }

  getCryptoSpendInfo(amount, address, currencyId) {
    this.showLoader();
    let post = this.http.post<any>(this.config.cryptoCart, {amount, address, currencyId});
    return post;
  }

  spendCrypto(amount, address, currencyId, note) {
    this.showLoader();
    let post = this.http.post<any>(this.config.cryptoSpend, {amount, address, currencyId, note})
    return post;
  }

  // OLD (PAIRS)
  // getTrading() {
  //   // this.showLoader();
  //   let post = this.http.post<any>(this.config.tradingList, {})
  //   return post;
  // }

  getTrading() {
    let post = this.http.post<any>(this.config.tradingList, {})
    return post;
  }

  getInvestment() {
    let post = this.http.post<any>(this.config.investmentList, {})
    return post;
  }

  getTradingInfo(currencyIdFrom, currencyIdTo) {
    this.showLoader();
    let post = this.http.post<any>(this.config.tradingCart, {currencyIdFrom, currencyIdTo});
    return post;
  }

  confirmTrade(currencyIdFrom, currencyIdTo, amount) {
    this.showLoader();
    let post = this.http.post<any>(this.config.tradingGetInfo, {currencyIdFrom, currencyIdTo, amount});
    return post;
  }

  confirmTradeResult(currencyIdFrom, currencyIdTo, amount) {
    this.showLoader();
    let post = this.http.post<any>(this.config.tradingConfirm, {currencyIdFrom, currencyIdTo, amount});
    return post;
  }

  setCheckedItem(item) {
    this.checkedItem.next(item);
  }
}
