import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopupComponent} from "../popup/popup.component";

@Component({
    selector: 'app-copy-to-buffer-button',
    templateUrl: './copy-to-buffer-button.component.html',
    styleUrls: ['./copy-to-buffer-button.component.scss']
})
export class CopyToBufferButtonComponent implements OnInit {

    @Input() value;

    constructor(private popupComponent: PopupComponent) {}

    ngOnInit() {}

    copyToBuffer() {
        let tempInput = document.createElement('input');
        tempInput.value = this.value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.popupComponent.showPopupSuccess({text: 'Text has been successfully copied!'});
    }
}
