import {EventEmitter, Injectable} from '@angular/core';
import {YetiCommonService} from "./yetiCommon.service";
import {YetiSnowdriftModel} from "../models/yetiSnowdrift.model";
import {YetiResponsesService} from "./yetiResponses.service";
import {YetiCreateGameElementResponseModel, YetiCreateGameResponseModel} from "../models/yetiCreateGameResponse.model";
import {YETI_FINDING_ELEMENTS_NAMES} from "../yeti.config";
declare const PIXI: typeof import('pixi.js');

@Injectable({
  providedIn: 'root'
})
export class YetiGameService {
  gameCommonContainer = new PIXI.Container();
  findingElementsContainer = new PIXI.Container();
  balanceContainer = new PIXI.Container();
  eyeLoaderContainer = new PIXI.Container();
  restartGameContainer = new PIXI.Container();

  renderer;
  stage;
  resources;

  sessionInfo: YetiCreateGameResponseModel;
  snowdrifts;

  ticker;
  popupTicker;
  popupIsShown = false;
  popupResult;

  redeemBtn;
  restartBtn;

  userBalance = 0;
  userWinBalance = 0;
  constructor(private yetiCommonService: YetiCommonService,
              private yetiResponsesService: YetiResponsesService) {
    document.body.addEventListener('click', () => {
      if (this.popupIsShown) {
        this.hideResultPopup();
      }
    });
  }

  setRedeemBtnBehavior(active = true) {
    if (active) {
      this.activateRedeemBtn();
    } else {
      this.deactivateRedeemBtn();
    }
  }

  activateRedeemBtn() {
    this.redeemBtn.alpha = 1;
    this.yetiCommonService.addButtonBehavior(this.redeemBtn);
    this.redeemBtn.on('pointerdown', this.emitRestartGame.bind(this));
  }

  deactivateRedeemBtn() {
    this.redeemBtn.alpha = .8;
    this.yetiCommonService.removeButtonBehavior(this.redeemBtn);
    this.redeemBtn.off('pointerdown', this.emitRestartGame.bind(this));
  }

  emitRestartGame() {
    this.yetiCommonService.restartGame.emit(true);
  }

  setGame(stage, resources, renderer) {
    this.stage = stage;
    this.resources = resources;
    this.renderer = renderer;
    this.stage.addChild(this.gameCommonContainer);
    this.showBalance();
  }

  startGame() {
    this.restartGameContainer.removeChildren();
    this.findingElementsContainer.removeChildren();
    this.eyeLoaderContainer.removeChildren();
    this.eyeLoaderContainer = new PIXI.Container();

    this.yetiCommonService.showMainLoader.emit({show: true, minMilliSec: 0});
    this.yetiResponsesService.initGame().subscribe(data => {
      try {
        if (data.status) {
          this.yetiCommonService.isGameStart = true;
          this.yetiCommonService.showRules.emit(false);
          this.sessionInfo = data;
          if (this.sessionInfo.elements && this.sessionInfo.elements.length) {
            this.updateBalance(data.balance, data.winBalance);
            this.setSnowdrifts(data.elements, data.openedElements);
            this.renderer.render(this.stage);
          } else {
            this.yetiCommonService.showInfoPopup.emit({show: true});
          }
        } else {
          this.yetiCommonService.showInfoPopup.emit({show: true, message: data.message});
        }
      } catch (e) {
        this.yetiCommonService.showInfoPopup.emit({show: true});
      }
    }, () => {
      this.yetiCommonService.showInfoPopup.emit({show: true});
    }, () => {
      this.yetiCommonService.showMainLoader.emit({show: false});
    });
  }

  setSnowdrifts(elements: Array<string>, openedElements: Array<YetiCreateGameElementResponseModel>) {
    this.snowdrifts = [];

    elements.forEach((el, index) => {
      const opened = openedElements.find(openedEl => el === openedEl.uuid);
      this.snowdrifts.push(new YetiSnowdriftModel(el, index, opened));
    });

    this.snowdrifts.forEach((el) => {
      this.createSnowdrift(el);
    });

    this.gameCommonContainer.addChild(this.findingElementsContainer);
  }

  createSnowdrift(instance: YetiSnowdriftModel) {
    if (instance.isOpened()) {
      this.showOpenedElement(instance, instance.getOpenedElementInfo());
    } else {
      const reverseDrifts = [0, 3];
      const driftBgImg = reverseDrifts.includes(instance.getIndex()) ? this.resources.snowdriftReverse.texture : this.resources.snowdrift.texture;
      const el = new PIXI.Sprite(driftBgImg);
      const imgMarkupHeight = 230;
      const scale = (imgMarkupHeight / el.height) * instance.getScale();

      const posX = instance.getPosition().x;
      const posY = instance.getPosition().y;

      el.scale.set(scale, scale);
      el.position.set(posX, posY);
      el.anchor.set(.5, 1);

      this.yetiCommonService.addButtonBehavior(el);

      el.on('pointerdown', (event) => {
        this.openElement(instance);
      });
      this.findingElementsContainer.addChild(el);
    }
  }

  openElement(el) {
    this.setEyeLoader(el);
    this.disableClickEvents();
    let sessionUuid = this.sessionInfo.sessionUuid;

    this.yetiResponsesService.play(sessionUuid, el.getId()).subscribe(data => {
      try {
        if (data.status) {
          const popupType = data.type;
          const selectedElPosition = el.getPosition();
          if (data.element) {
            const selectedElInfo: YetiCreateGameElementResponseModel = data.element;

            this.findingElementsContainer.children.forEach(snowdrift => {

              if (selectedElPosition.x === snowdrift.transform.position._x &&
                selectedElPosition.y === snowdrift.transform.position._y &&
                snowdrift.pluginName === 'sprite') {



                this.showOpenedElement(el, selectedElInfo, snowdrift);
              }
            });
          }

          if (data.elements && Number(data.attempts) === 0) {
            this.disableClickEvents();
            setTimeout(() => {
              const newElements: Array<YetiSnowdriftModel> = [];

              data.elements.forEach(needToOpenEl => {
                this.snowdrifts.forEach(existSnowDrift => {
                  if (needToOpenEl.uuid === existSnowDrift.getId()) {
                    existSnowDrift.setOpenedElementInfo(needToOpenEl);
                    newElements.push(existSnowDrift);
                  }
                })
              });

              this.findingElementsContainer.children.forEach(snowdrift => {
                newElements.forEach(newElement => {
                  const newElementPosition = newElement.getPosition();
                  const newElementInfo = newElement.getOpenedElementInfo();

                  if (newElementPosition.x === snowdrift.transform.position._x &&
                    newElementPosition.y === snowdrift.transform.position._y &&
                    snowdrift.pluginName === 'sprite' && snowdrift.cursor === 'pointer') {

                    this.showOpenedElement(newElement, newElementInfo, snowdrift);
                  }
                });
              });

              setTimeout(() => {
                this.restartGame();
                this.enableClickEvents();
              }, 1000);

            }, 1000);
          } else {
            this.enableClickEvents();
          }

          this.updateBalance(data.balance, data.winBalance);

        } else {
          this.yetiCommonService.showInfoPopup.emit({show: true, message: data.message});
        }
      } catch (e) {
        this.yetiCommonService.showInfoPopup.emit({show: true});
      }
    }, () => {
      this.yetiCommonService.showInfoPopup.emit({show: true});
    }, () => {
      this.hideEyeLoader();
    });
  }

  showOpenedElement(el, selectedElInfo, snowdrift?) {
    const snowBroken = new PIXI.Sprite(this.resources.snowdriftBroken.texture);
    const snowBrokenMarkupHeight = 90;
    const scaleCoefficient = el.getScale() * .75;
    const snowBrokenScale = snowBrokenMarkupHeight / snowBroken.height * scaleCoefficient;
    const snowBrokenPosX = el.getPosition().x;
    const snowBrokenPosY = el.getPosition().y;
    snowBroken.scale.set(snowBrokenScale);
    snowBroken.position.set(snowBrokenPosX, snowBrokenPosY);
    snowBroken.anchor.set(.5, 1);


    if (selectedElInfo.shortName !== YETI_FINDING_ELEMENTS_NAMES.empty) {
      const prize = new PIXI.Sprite(this.resources[this.getFindingElementImage(selectedElInfo.shortName)].texture);
      const imgMarkupHeight = 230;
      const scale = imgMarkupHeight / prize.height * scaleCoefficient;
      const posX = el.getPosition().x;
      const posY = el.getPosition().y - 25;
      prize.scale.set(scale, scale);
      prize.position.set(posX, posY);
      prize.anchor.set(.5, 1);
      this.findingElementsContainer.addChild(prize);

      if (selectedElInfo.shortName === YETI_FINDING_ELEMENTS_NAMES.poison) {
        const poisionWrapper = new PIXI.Sprite(this.resources.poisonWrapper.texture);
        const poisionWrapperMarkupHeight = 245;
        const poisionWrapperScale = poisionWrapperMarkupHeight / poisionWrapper.height * scaleCoefficient;
        // const posX = el.getPosition().x;
        // const posY = el.getPosition().y - 25;
        poisionWrapper.scale.set(poisionWrapperScale);
        poisionWrapper.position.set(posX, posY);
        poisionWrapper.anchor.set(.5, 1);
        this.findingElementsContainer.addChild(poisionWrapper);
      }

      if (selectedElInfo.shortName !== YETI_FINDING_ELEMENTS_NAMES.poison &&
        selectedElInfo.shortName !== YETI_FINDING_ELEMENTS_NAMES.empty && selectedElInfo.factor) {
        const id = this.resources.numbersAtlas.textures;

        const x = new PIXI.Sprite(id['X.png']);
        const xMarkupHeight = 60;
        const xScale = xMarkupHeight / x.height * scaleCoefficient;
        const xPosX = snowBroken.position.x + (snowBroken.width / 2) * 1.2;
        const xPosY = prize.position.y;
        x.scale.set(xScale);
        x.position.set(xPosX, xPosY);
        x.anchor.set(.5, 1);
        this.findingElementsContainer.addChild(x);

        const factorFormatted = Number(selectedElInfo.factor).toFixed(0);
        const num = new PIXI.Sprite(id[`${factorFormatted}.png`]);
        const numMarkupHeight = 130;
        const numScale = numMarkupHeight / num.height * scaleCoefficient;
        const numPosX = x.position.x + x.width * 1.05;
        const numPosY = x.position.y;
        num.scale.set(numScale);
        num.position.set(numPosX, numPosY);
        num.anchor.set(.5, 1);
        this.findingElementsContainer.addChild(num);
      }
    }



    setTimeout(() => {
      if (snowdrift) {
        snowdrift.alpha = 0;
        this.yetiCommonService.removeButtonBehavior(snowdrift);
      }

      this.findingElementsContainer.addChild(snowBroken);
      this.renderer.render(this.stage);
    });
  }

  showResultPopup(type) {
    const textureName = type ? 'popupLuck' : 'popupNoLuck';
    this.popupResult = new PIXI.Sprite(this.resources[textureName].texture);
    const popupMarkupHeight = 500;
    const popupScale = popupMarkupHeight / this.popupResult.height;
    let count = 0;

    this.popupResult.scale.set(popupScale);
    this.popupResult.position.set(this.stage.width / 2, this.stage.height / 2);
    this.popupResult.anchor.set(.5, .5);
    this.popupResult.rotation = -1;
    this.popupResult.alpha = 0;

    this.findingElementsContainer.addChild(this.popupResult);

    this.popupTicker = new PIXI.ticker.Ticker();
    this.popupTicker.stop();
    let speed = .25;

    this.popupTicker.add((deltaTime) => {
      count += 0.02;

      if (this.popupResult.rotation < 0) {
        this.popupResult.scale.x = Math.sin(count);
        this.popupResult.scale.y = Math.sin(count);
        this.popupResult.rotation += 0.05;
        this.popupResult.alpha += 0.05;

        this.renderer.render(this.stage);
      } else {
        this.popupTicker.stop();
        this.popupIsShown = true;
      }
    });
    this.popupTicker.start();
  }

  hideResultPopup() {
    this.popupIsShown = false;
    this.popupTicker = new PIXI.ticker.Ticker();
    this.popupTicker.stop();
    let count = 0.525;

    this.popupTicker.add((deltaTime) => {
      count -= 0.025;

      if (this.popupResult.rotation > -1) {
        this.popupResult.scale.x = Math.sin(count);
        this.popupResult.scale.y = Math.sin(count);
        this.popupResult.rotation -= 0.05;
        this.popupResult.alpha -= 0.05;

        this.renderer.render(this.stage);
      } else {
        this.popupTicker.stop();

      }
    });
    this.popupTicker.start();
  }

  setEyeLoader(el) {
    const selectedElPosition = el.getPosition();

    if (this.eyeLoaderContainer.children.length) {
      this.ticker.start();
      this.eyeLoaderContainer.alpha = 1;
    } else {
      let circle = this.drawCircle(20, 20, 20, 0xFFFFFF);
      this.eyeLoaderContainer.addChild(circle);

      let eye = this.drawCircle(28, 29, 10, 0x486a8b);
      this.eyeLoaderContainer.addChild(eye);

      let circle2 = this.drawCircle(80, 20, 20, 0xFFFFFF);
      this.eyeLoaderContainer.addChild(circle2);

      let eye2 = this.drawCircle(88, 29, 10, 0x486a8b);
      this.eyeLoaderContainer.addChild(eye2);

      this.findingElementsContainer.addChild(this.eyeLoaderContainer);

      this.ticker = new PIXI.ticker.Ticker();
      this.ticker.stop();
      let speed = .25;
      this.ticker.add((deltaTime) => {
        if (eye.x === 28 || eye.x === 12) {
          speed = -speed
        }
        eye.x += speed;
        eye2.x += speed;
        this.renderer.render(this.stage);
      });
      this.ticker.start();
    }

    this.eyeLoaderContainer.pivot.set(40, 70);
    this.eyeLoaderContainer.x = selectedElPosition.x;
    this.eyeLoaderContainer.y = selectedElPosition.y;

    this.renderer.render(this.stage);
  }

  drawCircle(x, y, radius, color) {
    let circle = new PIXI.Graphics();
    circle.beginFill(color);
    circle.drawCircle(0, 0, radius);
    circle.endFill();
    circle.x = x;
    circle.y = y;

    // let textrure = circle.generateTexture();
    // let circleSprite = new PIXI.Sprite(textrure);
    return circle;
  }

  hideEyeLoader() {
    this.eyeLoaderContainer.alpha = 0;
    this.ticker.stop();
    this.renderer.render(this.stage);
  }

  showBalance() {
    this.balanceContainer.removeChildren();
    this.balanceContainer = new PIXI.Container();

    const minWidth = 300;
    const padding = 10;

    let scoreWinContainer = new PIXI.Container();
    let scoreContainer = new PIXI.Container();

    scoreContainer = this.setScore(scoreContainer, this.userBalance);
    scoreWinContainer = this.setScore(scoreWinContainer, this.userWinBalance);

    const bg = new PIXI.Sprite(this.resources.scoreBg.texture);
    const bgWin = new PIXI.Sprite(this.resources.scoreWinsBg.texture);

    const bgZoom = 1.4;
    const bgMarkupWidth = minWidth > scoreContainer.width * bgZoom ? minWidth : scoreContainer.width * bgZoom > scoreWinContainer.width * bgZoom ? scoreContainer.width * bgZoom : scoreWinContainer.width * bgZoom;
    const bgScale = bgMarkupWidth / bg.width;
    bg.scale.set(bgScale);
    bgWin.scale.set(bgScale);
    this.balanceContainer.addChild(bg);
    this.balanceContainer.addChild(bgWin);
    bgWin.position.y = bg.position.y + bg.height + 10;

    this.balanceContainer.addChild(scoreWinContainer);
    this.balanceContainer.addChild(scoreContainer);
    this.balanceContainer.position.set(padding);

    scoreContainer.position.x = bg.width / 2 - scoreContainer.width / 2;
    scoreContainer.position.y = bg.height / 2 - (scoreContainer.height / 2 * .6);

    scoreWinContainer.position.x = bgWin.width / 2 - scoreWinContainer.width / 2;
    scoreWinContainer.position.y = bgWin.height / 2 - (scoreContainer.height / 2 * .6) + bg.height + 10;

    setTimeout(() => {
      this.gameCommonContainer.addChild(this.balanceContainer);
      this.renderer.render(this.stage);
    });
  }

  setScore(scoreContainer, scorebalance) {
    const id = this.resources.numbersAtlas.textures;
    let lastAddItem = 0;

    const dollar = new PIXI.Sprite(id[`S.png`]);
    const numMarkupHeight = 50;
    const dollarScale = numMarkupHeight / dollar.height;
    dollar.scale.set(dollarScale);
    lastAddItem += dollar.width * 1.1;
    scoreContainer.addChild(dollar);

    const balanceFormatted = scorebalance ? Number(scorebalance).toFixed(0) : 0;
    let balance = String(balanceFormatted);
    balance += 'd00';
    balance.split('').forEach(number => {
      let num, numScale, numPosY;
      if (number === 'd') {
        num = new PIXI.Sprite(id[`dot.png`]);
        const dotMarkupHeight = 20;
        numScale = dotMarkupHeight / num.height;
        numPosY = dollar.height - 20;
      } else {
        num = new PIXI.Sprite(id[`${number}.png`]);
        numScale = numMarkupHeight / num.height;
        numPosY = dollar.position.y;
      }
      const numPosX = lastAddItem;
      num.scale.set(numScale);
      num.position.set(numPosX, numPosY);
      lastAddItem += num.width * 1.15;
      scoreContainer.addChild(num);
    });
    return scoreContainer;
  }

  updateBalance(balance, winBalance) {
    if (this.userBalance !== balance || this.userWinBalance !== winBalance) {
      this.userBalance = balance;
      this.userWinBalance = winBalance;
      this.showBalance();
    }
  }

  restartGame() {
    this.restartGameContainer.x = 260;
    this.restartGameContainer.y = 500;

    const bg = new PIXI.Sprite(this.resources.askContinue.texture);
    const bgMarkupHeight = 180;
    const bgScale = bgMarkupHeight / bg.height;
    bg.scale.set(bgScale);
    this.restartGameContainer.addChild(bg);



    this.restartBtn = this.createRestartBtn(bg);
    this.restartGameContainer.addChild(this.restartBtn);
    this.yetiCommonService.addButtonBehavior(this.restartBtn);
    this.restartBtn.on('pointerdown', (event) => {
      this.startGame();
    });

    this.redeemBtn = this.createRedeemBtn(bg);
    this.restartGameContainer.addChild(this.redeemBtn);
    const isRedeemActive = this.isUserBalanceMoreZero();
    this.setRedeemBtnBehavior(isRedeemActive);

    this.gameCommonContainer.addChild(this.restartGameContainer);
    this.renderer.render(this.stage);
  }

  createRestartBtn(bg) {
    const playBtn = new PIXI.Sprite(this.resources.playBtn.texture);
    const btnMarkupHeight = 60;
    const playBtnScale = btnMarkupHeight / playBtn.height;
    playBtn.scale.set(playBtnScale);
    const playBtnPosX = bg.width / 3;
    const playBtnPosY = bg.height - playBtn.height / 1.5;
    playBtn.position.set(playBtnPosX, playBtnPosY);
    playBtn.anchor.set(.5, 0);
    return playBtn;
  }

  createRedeemBtn(bg) {
    const redeemBtn = new PIXI.Sprite(this.resources.redeemBtn.texture);
    const redeemMarkupHeight = 60;
    const redeemBtnScale = redeemMarkupHeight / redeemBtn.height;
    redeemBtn.scale.set(redeemBtnScale);
    const redeemBtnPosX = bg.width * .666;
    const redeemBtnPosY = bg.height - redeemBtn.height / 1.5;
    redeemBtn.anchor.set(.5, 0);
    redeemBtn.position.set(redeemBtnPosX, redeemBtnPosY);
    return redeemBtn;
  }

  isUserBalanceMoreZero() {
    return this.userBalance > 0;
  }

  getFindingElementImage(shortName) {
    let result;
    switch (shortName) {
      case YETI_FINDING_ELEMENTS_NAMES.coins:
        result = 'prize1';
        break;
      case YETI_FINDING_ELEMENTS_NAMES.box: {
        result = 'prize2';
        break;
      }
      case YETI_FINDING_ELEMENTS_NAMES.ice: {
        result = 'prize3';
        break;
      }
      case YETI_FINDING_ELEMENTS_NAMES.bomb: {
        result = 'prize4';
        break;
      }
      case YETI_FINDING_ELEMENTS_NAMES.egg: {
        result = 'prize5';
        break;
      }
      case YETI_FINDING_ELEMENTS_NAMES.poison: {
        result = 'poison';
        break;
      }
    }
    return result;
  }

  closeGame() {
    this.yetiCommonService.isGameStart = false;
    this.yetiCommonService.showRules.emit(true);
    this.findingElementsContainer.removeChildren();
    this.gameCommonContainer.removeChildren();
    this.eyeLoaderContainer.removeChildren();
    this.eyeLoaderContainer = new PIXI.Container();
    this.renderer.render(this.stage);
  }

  disableClickEvents() {
    this.gameCommonContainer.interactiveChildren = false;
  }

  enableClickEvents() {
    this.gameCommonContainer.interactiveChildren = true;
  }
}
