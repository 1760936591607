import { APP_DATE_FORMATS } from '../shared/shared.module';
export var DEFAULT_SWIPER_CONFIG = {
    // a11y: true,
    direction: 'horizontal',
    slidesPerView: 'auto',
    loop: true,
    loopedSlides: 100,
    observer: true,
    observeParents: true,
    preventClicks: false,
    initialSlide: 0,
    // simulateTouch: touchCapable(),
    simulateTouch: true,
    // cssMode: {
    //     simulateTouch: touchCapable(),
    // },
    // shortSwipes: false
    resistanceRatio: 0.6,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    watchOverflow: true,
};
var ɵ0 = APP_DATE_FORMATS;
// function touchCapable() {
//     return (
//       'ontouchstart' in window ||
//       ((window as any).DocumentTouch &&
//         document instanceof (window as any).DocumentTouch) ||
//       navigator.maxTouchPoints > 0 ||
//       (window as any).navigator.msMaxTouchPoints > 0
//     );
// }
var ChatQuizModule = /** @class */ (function () {
    function ChatQuizModule() {
    }
    return ChatQuizModule;
}());
export { ChatQuizModule };
export { ɵ0 };
