import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PROMO_777_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {timer} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class Promo777Service extends SettingsLoaderService{
    private config = PROMO_777_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    register(firstName, lastName, phone, email) {
        return this.http.post<any>(this.config.register, {firstName, lastName, phone, email});
    }

    verification(phone, pin) {
         return this.http.post<any>(this.config.verify, {phone, pin});
    }

    sendCashapp(cashapp) {
        return this.http.post<any>(this.config.cashapp, {cashapp});
    }

    textCashTag() {
        return timer(500).pipe();
        // return this.http.post<any>(this.config.textCashTag, {});
    }

    playNow(deposit, skip) {
        return timer(500).pipe();
        // return this.http.post<any>(this.config.playNow, {});
    }
}
