<div class="mascot" [class.is-iphone]="isIphone()" [class.is-disabled]="showPopup" [class.is-station-mascot]="isStationMascot" [class.is-blurred]="showPhonePopup || showContactsFormFirst || showPinPopup || showPopup" (dblclick)="fullScreen()">
    <div class="mascot-slider">
        <ngx-glide data-glide-el="track" [autoplay]="true" [hoverpause]="false" [animationDuration]="sliderAnimationDuration" [animationTimingFunc]="sliderAnimationFunction" [focusAt]="5" [startAt]="5" #newSwiper *ngIf="slider1.length" type="carousel" [perView]="slidesPerView" [showArrows]="false" [showBullets]="false" [gap]="0">
            <div [attr.data-id]="slide.id" *ngFor="let slide of slider1" class="slide mascot-slider__slide">
                <img class="slide mascot-slider__img" src="{{ slide.image360 }}" alt="" width="100%">
            </div>
        </ngx-glide>

        <div class="mascot-slider__btns" *ngIf="!isApp">
            <button class="mascot-slider__btn mascot-slider__btn--left" (click)="goToPreviousSlide(newSwiper)">
                <img src="./assets/images/mascot/arrows_left.png" title="Previous Slide" alt="Previous Slide">
            </button>
            <button class="mascot-slider__btn mascot-slider__btn--right" (click)="goToNextSlide(newSwiper)">
                <img src="./assets/images/mascot/arrows_right.png" title="Next Slide" alt="Next Slide">
            </button>
        </div>
    </div>

    <div class="mascot-slider">
        <ngx-glide #newSwiper2 *ngIf="slider2.length" [hoverpause]="false" [autoplay]="true" [direction]="'rtl'" [animationDuration]="sliderAnimationDuration" [animationTimingFunc]="sliderAnimationFunction" [focusAt]="5" [startAt]="5" type="carousel" [perView]="slidesPerView" [showArrows]="false" [showBullets]="false" [gap]="0">
            <div [attr.data-id]="slide.id" *ngFor="let slide of slider2" class="slide mascot-slider__slide mascot-slider__slide--transform">
                <img class="slide mascot-slider__img" src="{{ slide.image360 }}" alt="" width="100%">
            </div>
        </ngx-glide>

        <div class="mascot-slider__btns" *ngIf="!isApp">
            <button class="mascot-slider__btn mascot-slider__btn--right" (click)="goToPreviousSlide(newSwiper2)">
                <img src="./assets/images/mascot/arrows_right.png" title="Next Slide" alt="Next Slide">
            </button>
        </div>
    </div>


<!--    <img class="mascot__aphrodite" src="./assets/images/mascot-slider/aphrodite.png" alt="">-->

    <img class="mascot__coins mascot__coins--left" src="./assets/images/mascot-slider/coins-left.png" alt="">
<!--    <img class="mascot__coins mascot__coins&#45;&#45;center" src="./assets/images/mascot-slider/coins-center.png" alt="">-->
    <img class="mascot__coins mascot__coins--right" src="./assets/images/mascot-slider/coins-right.png" alt="">
    <img class="mascot__coins-bottom" src="./assets/images/mascot-slider/cents.png" alt="">

<!--    <img (click)="openGame()" class="mascot__text-img" src="./assets/images/mascot-slider/text.png" alt="">-->
    <button (click)="openGame()" class="mascot__scratch-off-btn">
        <img src="./assets/images/mascot-slider/piggy_icon.png" alt="">
    </button>

    <div class="balance-wrapper balance-wrapper--rounds">
        {{balance.freeRounds | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--win">
        {{balance.win | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--balance">
        {{balance.balance | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--jackpot">
        <span style="visibility: hidden" #textData>{{balance.jackpot | number: '.2'}}</span>

        <svg _ngcontent-trq-c4="" viewBox="0 0 70 70" width="100%">
            <path _ngcontent-trq-c4="" d="M 15,35m-35,60a23,23 0 1,1 110,0a23,23 0 1,1 -46,0" fill="transparent" id="tophalf"></path>
            <text _ngcontent-trq-c4="" style="text-align: center; font-size: 6px;" width="100%" fill="#f7c837">
                <textPath #text _ngcontent-trq-c4="" xlink:href="#tophalf" text-anchor="middle" startOffset="35.5%"></textPath>
            </text>
        </svg>



    </div>

    <button (click)="onLogout()" class="mascot__btn-settings">
        <img class="mascot__img-settings" src="./assets/images/mascot-slider/logout.png" alt="">
    </button>


    <!--    <img class="mascot__spinner" src="./assets/images/mascot-slider/spinner-img.png" alt="">-->
    <app-spinner [data]="spinnerData"></app-spinner>
</div>

<div [class.is-visible]='showPopup'
     [class.is-iphone]="isIphone()"
     [class.is-ipad]="isIpad()"
     [class.is-ipad-iframe]="isIpad() && popupGameURL"
     *ngIf="showPopup"
     class="mascot-popup"
     [ngStyle]="{backgroundImage: popupType === POPUP_TYPE.play ? 'none' : 'url(' + popupGameImage + ')'}">
    <button (click)="closeGame()"
            class="mascot-popup__close"></button>
    <button (click)="playGame(popupGameId)"
            *ngIf="!popupGameURL && popupType === POPUP_TYPE.play"
            class="mascot-popup__btn mascot-popup__btn--play"></button>

    <img *ngIf="popupGameImage" class="mascot-popup__img" [src]="popupGameImage" (load)="imageLoaded()" alt="">
    <iframe *ngIf="popupGameURL" (mousemove)="frameClick()" width="100%" height="100%" [src]="popupGameURL" frameborder="0"></iframe>

    <app-pig [showPopup]="showGameFindPrize"
             *ngIf="showGameFindPrize"
             (isElementReady)="isPaymentReady($event)"
             (close)="closeScratchOffGame()"></app-pig>

    <app-scratch-off-rules
            (isPaymentForbidden)="missPayment()"
            (isElementReady)="isPaymentReady($event)"
            (isPaymentSuccess)="onRulesActionBtnClick()"
            [popupType]="rulesPopupType"
            [showPopup]="showGamePayment"></app-scratch-off-rules>

<!--    <app-games-payment-->
<!--            (isPaymentForbidden)="missPayment()"-->
<!--            (isElementReady)="isPaymentReady($event)"-->
<!--            (isPaymentSuccess)="showGame()"-->
<!--            [showPopup]="showGamePayment"></app-games-payment>-->


    <div class="loading" *ngIf="popupLoading">
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
        <div class="bar bar4"></div>
        <div class="bar bar5"></div>
        <div class="bar bar6"></div>
        <div class="bar bar7"></div>
        <div class="bar bar8"></div>
    </div>
</div>

<div class="rotate-popup">
    <div class="rotate-popup__text">
        Please rotate your device to landscape for better experience
    </div>
    <img class="rotate-popup__icon" src="./assets/images/rotate-icon-light.png" alt="">
</div>

<div class="popup popup-login" [class.no-terminal]="!isTerminal"  *ngIf="showPhonePopup || showPinPopup || showContactsFormFirst">
    <div class="popup-login__inner" [class.is-contact-form]="showContactsFormFirst" [class.is-register]="showRegistrationPopup">
        <div class="popup__content"></div>
        <form *ngIf="showPhonePopup" [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="popup-login__form">
            <div class="popup-login__item">
                <input [mask]="phoneMask"
                       formControlName="phone"
                       placeholder="Phone Number"
                       [type]="'tel'"
                       (keydown.enter)="onPhoneSubmit()"
                       class="login-input js-phone-input input-bordered-mascot"
                       autocomplete="off">
            </div>

            <button class="popup-login__btn popup-login__btn--login" [disabled]="!phoneForm?.valid"></button>
        </form>

        <form *ngIf="showPinPopup" [formGroup]="pinForm" class="popup-login__form">
            <div *ngIf="showRegistrationPopup" class="login__text login__text--mb-sm">
                Hi there! Thank you for choosing {{getPartnerName()}}. You are registering with phone number:
                <div class="accent">{{phoneForm.controls.phone.value | mask: phoneMask}}</div>
            </div>
            <div *ngIf="!showRegistrationPopup" class="login-content login-content--mt">
                <p class="text t-light login__text">Enter the four digit pin sent to the number ending in -{{phoneLastFour}}.</p>
            </div>
            <div *ngIf="showRegistrationPopup" class="login-content">
                <p class="text t-light login__text login__text--md">Enter the Code we just texted you.</p>
            </div>

            <div *ngIf="!isTerminal" class="login__items popup-login__items" formArrayName="digits">
                <input (keydown.enter)="showRegistrationPopup ? onRegisterNewUser() : onSubmitPin()"
                       *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                       mask="0"
                       class="login-input-small" type="tel">
            </div>

            <div *ngIf="singlePage && isTerminal" class="login__items popup-login__items" formArrayName="digits">
                <input (keydown.enter)="showRegistrationPopup ? onRegisterNewUser() : onSubmitPin()"
                       *ngFor="let field of pinForm.get('digits')['controls']; let i = index"
                       #inputs [maxLength]="1" [formControlName]="i"
                       [attr.data-order]="i"
                       mask="0"
                       class="login-input-small" type="tel">
            </div>


            <div *ngIf="showRegistrationPopup" class="login__text">
                Please click the Register button below to proceed
                <span [class.addition-text]="showRegistrationPopup"> <br/> or click<br/>
                    <span>The back button to correct your phone number</span>
                </span>
            </div>

<!--            <div *ngIf="showRegistrationPopup" class="login-tab">-->
<!--                <div class="text-center form-btn-submit">-->
<!--                    <button id="register" class="button snake-btn is-notouched"-->
<!--                            (click)="onRegisterNewUser()"-->
<!--                            [disabled]="!pinForm.valid">-->
<!--                        Register-->
<!--                        <svg><rect></rect></svg>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->

            <button *ngIf="showRegistrationPopup"
                    (click)="onRegisterNewUser()"
                    class="popup-login__btn popup-login__btn--login"
                    [disabled]="!pinForm.valid"></button>


            <button (click)="resendSms()"
                    type="button"
                    class="text t-light login-text login__resend">Resend</button>

            <button (click)="popupGoBack()" type="button" class="popup-login__back login__back"></button>

            <button *ngIf="!showRegistrationPopup"
                    (click)="onSubmitPin()"
                    class="popup-login__btn popup-login__btn--login"
                    [disabled]="!pinForm.valid"></button>
        </form>

<!--        <app-rewards-form-->
<!--                *ngIf="showContactsFormFirst"-->
<!--                [style]="'mascot'"-->
<!--                (userCreated)="hideContactForm()"-->
<!--        ></app-rewards-form>-->

    </div>
</div>

<div class="mascot__keyboard" *ngIf="(showPhonePopup || showPinPopup) && isTerminal">
    <div class="keyboard">
        <app-keyboard></app-keyboard>
    </div>
</div>

<div class="mascot-screen-saver" [class.is-active]="screenSaver">
    <img class="mascot-screen-saver__img" src="../../../../assets/images/mascot/NFT_kiosk_splash.jpeg" alt="">
</div>
