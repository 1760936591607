import { OnInit } from '@angular/core';
import Swal from "sweetalert2";
import * as i0 from "@angular/core";
import * as i1 from "ngx-mask";
var PopupComponent = /** @class */ (function () {
    function PopupComponent(maskApplierService) {
        this.maskApplierService = maskApplierService;
        this.isApp = +localStorage.getItem('isApp');
        this.closePopupAfter = this.isApp ? 5 * 60 * 1000 : null;
        this.swalStyled = Swal.mixin({
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm snake-btn is-notouched',
                cancelButton: 'popup__btn popup__btn_cancel',
                input: 'login-input',
            }
        });
    }
    PopupComponent.prototype.ngOnInit = function () {
    };
    PopupComponent.prototype.showPopupSuccess = function (message, callBack) {
        this.loadPopupImages();
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: message.text ? message.text : 'The action was successful!',
            confirmButtonText: 'OK, THANKS',
            timer: message.closePopupAfter ? message.closePopupAfter : this.closePopupAfter,
        }).then(function (result) {
            if (result.isConfirmed && callBack) {
                callBack();
            }
        });
    };
    PopupComponent.prototype.showPopupError = function (message, btn) {
        this.loadPopupImages();
        var text = message.text ? message.text : 'Oops! Something is not right.';
        var confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'BACK';
        var closeBtn = message.closeBtn === false ? message.closeBtn : true;
        var popupTypeBtn = btn ? btn : '';
        this.swalStyled.fire({
            // title: 'Error',
            // imageUrl: '../assets/images/popup/error-logo.png',
            //className: "swal-back",
            showClass: {
                popup: "swal-error " + popupTypeBtn
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
            timer: this.closePopupAfter,
        });
    };
    PopupComponent.prototype.showPopupErrorReturnEvent = function (message, handler, btn) {
        this.loadPopupImages();
        var text = message.text ? message.text : 'Oops! Something is not right.';
        var confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'OK';
        var closeBtn = message.closeBtn === false ? message.closeBtn : true;
        var closePopupAfter = message.closePopupAfter ? message.closePopupAfter : this.closePopupAfter;
        var popupTypeBtn = btn ? btn : '';
        this.swalStyled.fire({
            showClass: {
                popup: "swal-error " + popupTypeBtn
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
            timer: closePopupAfter,
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.loadPopupImages = function () {
        var wrapperId = '#hidden-images';
        var bodyImagesLoadedClass = 'popup-images-loaded';
        var bodyMascotClass = 'is-mascot';
        var isMascotPage = document.body.classList.contains(bodyMascotClass);
        var isImagesNotLoaded = !document.body.classList.contains(bodyImagesLoadedClass);
        if (isImagesNotLoaded && isMascotPage) {
            var popupImages = [
                '/assets/images/popup/popup_border--sm.png',
                '/assets/images/popup/popup_close-btn.png',
                '/assets/images/popup/popup_login-btn.png',
                '/assets/images/popup/popup_ok-btn.png',
                '/assets/images/popup/popup_play-btn.png',
                '/assets/images/popup/popup_сontinue-btn.png'
            ];
            var popupCounter_1 = popupImages.length;
            popupImages.forEach(function (src, index) {
                var image = new Image();
                image.src = src;
                image.addEventListener('load', function () {
                    popupCounter_1 -= 1;
                    if (!popupCounter_1) {
                        document.body.classList.add(bodyImagesLoadedClass);
                    }
                });
            });
        }
    };
    PopupComponent.prototype.showPopupAgreement = function (options, handler, timer) {
        if (timer === void 0) { timer = null; }
        this.loadPopupImages();
        var text = options.text ? options.text : 'Something went wrong. Please try again.';
        var style = options.style ? options.style : 'swal-agreement';
        var closeBtn = options.closeBtn === false ? options.closeBtn : true;
        var showConfirmButton = options.showConfirmButton === false ? options.showConfirmButton : true;
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
            '</svg>';
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: closeBtn ? cancelButtonText : false,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            showConfirmButton: showConfirmButton,
            timer: timer
        }).then(function (result) {
            var dismissReason = result.dismiss && result.dismiss.toString();
            if (dismissReason === 'timer') {
                return handler('timerOut');
            }
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.showPopupConfirmCancel = function (options, handler, timer) {
        if (timer === void 0) { timer = null; }
        this.loadPopupImages();
        var text = options.text ? options.text : 'Something went wrong. Please try again.';
        var style = options.style ? options.style : 'swal-confirm-cancel';
        var closeBtn = options.closeBtn === false ? options.closeBtn : true;
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'CANCEL<svg><rect></rect></svg>';
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            html: text,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            timer: timer,
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm snake-btn is-notouched',
                input: 'login-input',
                cancelButton: 'popup__btn snake-btn popup__btn_cancel is-notouched'
            }
        }).then(function (result) {
            var dismissReason = result.dismiss && result.dismiss.toString();
            if (dismissReason === 'timer') {
                return handler('timerOut');
            }
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.showPopupChoice = function (options, handler) {
        var text = options.text ? options.text : 'Something went wrong. Please try again.';
        var style = options.style ? options.style : 'swal-choice';
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'Yes<svg><rect></rect></svg>';
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'No<svg><rect></rect></svg>';
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.enterAccountId = function (options, handler) {
        var _this = this;
        var text = options.text ? options.text : 'Please enter your account id';
        var style = options.style ? options.style : 'swal-choice';
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'No<svg><rect></rect></svg>';
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            input: 'tel',
            inputValidator: function (value) {
                if (!value) {
                    return 'You need to write something!';
                }
                else if (value.length !== 17) {
                    return 'Entered account id is not valid';
                }
            },
            onBeforeOpen: function () {
                _this.swalStyled.getInput().addEventListener('input', function (event) {
                    if (!Number.isInteger(parseInt((event['data']))) && event['inputType'] === "insertText") {
                        setTimeout(function () {
                            _this.swalStyled.getInput().value = _this.swalStyled.getInput().value.slice(0, -1);
                        });
                        // return false;
                    }
                    else {
                        _this.swalStyled.getInput().maxLength = 17;
                        var maskedValue = _this.maskApplierService.applyMask(_this.swalStyled.getInput().value, '00-00-00-00-00-00');
                        _this.swalStyled.getInput().value = maskedValue;
                    }
                });
            },
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(_this.swalStyled.getInput().value);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.showPopupVariants = function (options, handler) {
        var text = options.text ? options.text : 'Select which account you would like to use:';
        var style = options.style ? options.style : 'swal-agreement';
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        var accounts = options.accounts;
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
            '</svg>';
        var $this = this;
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            input: 'radio',
            inputOptions: accounts,
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            inputValidator: function (value) {
                if (!value) {
                    return 'You need to choose something!';
                }
                else {
                    //return handler(value);
                    //return value;
                }
            }
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(result.value);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.closePopup = function () {
        this.swalStyled.close();
    };
    PopupComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupComponent_Factory() { return new PopupComponent(i0.ɵɵinject(i1.MaskApplierService)); }, token: PopupComponent, providedIn: "root" });
    return PopupComponent;
}());
export { PopupComponent };
