import {Component, HostListener, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {PartnerService} from "../../service/partner.service";
import {AppVersionrService} from "../../service/app-version/appVersionr.service";
import {AppPromptService} from "../../service/app/appPrompt.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {StylingService} from "../../service/styling.service";
import {RouterPaths} from "../../config/router.paths.model";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    isApp = +localStorage.getItem('isApp');
    menu = false;
    appInstalled = false;

    deferredPrompt: any;
    ROUTER_PATHS = RouterPaths;

    constructor(
        private auth:AuthenticationService,
        private router: Router,
        private appVersionrService: AppVersionrService,
        private appPromptService: AppPromptService,
        private partnerService: PartnerService,
        private stylingService: StylingService,
        private popupComponent: PopupComponent
    ) {


    }


    ngOnInit() {
        window.addEventListener('appinstalled', (evt) => {
            this.appInstalled = true;
            this.popupComponent.showPopupSuccess({text: 'The App was installed successful.'})
        });
    }

    upgradeApp(){
        this.appVersionrService.upgradeApp();
    }

    // upgradeApp(){
    //     let time = + new Date();
    //     window.location.href = window.location.protocol+'//'+this.getDomain(window.location.href,true)+'?hash='+time;
    // }
    //
    // getDomain(url, subdomain) {
    //     subdomain = subdomain || false;
    //
    //     url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    //
    //     if (!subdomain) {
    //         url = url.split('.');
    //
    //         url = url.slice(url.length - 2).join('.');
    //     }
    //
    //     if (url.indexOf('/') !== -1) {
    //         return url.split('/')[0];
    //     }
    //
    //     return url;
    // }

    logout(){
        this.auth.logout();

        console.log('home');

        this.router.navigate(['/'], {queryParams: {logout: true}});
    }

    showMenu(status) {
        this.menu = !this.menu;
    }

    getInvestmentURL() {
        return ['/home/investments'];

        // IF we need different pages for ebox and snekapeak
        // const partner = this.partnerService.getShortName();
        // if (partner === 'default' || partner === 'ebox' || partner === 'portal') {
        //     return ['/home/investments'];
        // }
        // return [`/home/${partner}/investments`];
    }

    getPartner() {
        const partner = this.partnerService.getShortName();
        return partner;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    installApp() {
        // this.deferredPrompt = this.appPromptService.deferredPrompt;
        // if (!this.deferredPrompt) {
        //     this.appInstalled = true;
        //     return;
        // }
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
            }
            //this.deferredPrompt = null;
            this.menu = false;
        });
    }

    isAndroid(){
        if(/Android/i.test(navigator.userAgent)){
            return true
        }
        else{
            return false
        }
    }

    isAppUse() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return true;
        }
        return false;
    }

    needInstallOnAndroid() {
        this.deferredPrompt = this.appPromptService.deferredPrompt;
        if (!this.deferredPrompt) {
            this.appInstalled = true;
        }

        if (this.isAndroid() && !this.isAppUse() && !this.isApp && !this.appInstalled) {
            return true;
        }
        return false;
    }

    disableMenu() {
        return this.router.url === '/home/rewards/form';
    }

    goToTermsAndConditions() {
        this.router.navigate([this.ROUTER_PATHS.termsAndConditions.main]);
        this.showMenu(false);
    }

}
