import { EventEmitter, OnInit } from '@angular/core';
import { GAMES_DEFAULT_ERROR_MESSAGE } from "../../pages/games/games.config";
var InfoPopupComponent = /** @class */ (function () {
    function InfoPopupComponent() {
        this.close = new EventEmitter();
        this.show = false;
        this.activeClass = false;
        this.message = '';
    }
    Object.defineProperty(InfoPopupComponent.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (value) {
            this._data = value;
            if (value !== null) {
                this.updateState(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    InfoPopupComponent.prototype.ngOnInit = function () { };
    InfoPopupComponent.prototype.closePopup = function () {
        if (this.callback) {
            try {
                this.callback();
            }
            catch (e) {
                console.log(e);
            }
        }
        this.close.emit();
    };
    InfoPopupComponent.prototype.updateState = function (value) {
        var _this = this;
        this.show = value.show;
        this.callback = value.callback;
        this.message = value.message ? value.message : GAMES_DEFAULT_ERROR_MESSAGE;
        setTimeout(function () {
            _this.activeClass = value.show;
        }, 10);
    };
    return InfoPopupComponent;
}());
export { InfoPopupComponent };
