import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../datapicker-header/datapicker-header";
import {TIME_SPANS} from "../../config/timeSpans.config";
import {LoaderService} from "../../service/loader.service";
import {UserBalanceService} from "../../service/users/userBalance.service";
import {PopupComponent} from "../popup/popup.component";
import {SupportService} from "../../service/support/support.service";
import {GamesProviderService} from "../../service/games/gamesProvider.service";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    showSupportForm = false;
    @Input() set showSupport(val) {
        this.showSupportForm = val;
        if (this.supportForm) {
            if (!this.providers.length) {
                this.getProviders();
            } else {
                this.setSupportSelects();
            }
        }
    };
    @Input() selectedProvider;

    @Output() close: EventEmitter<boolean> = new EventEmitter();

    supportForm: FormGroup;
    exampleHeader = ExampleHeader;
    times = TIME_SPANS;
    selectedTime;
    selectedSystem;
    providers = [];

    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private userBalanceService: UserBalanceService,
                private supportService: SupportService,
                private gamesProviderService: GamesProviderService,
                private popupComponent: PopupComponent) {
    }

    ngOnInit() {
        this.supportForm = this.formBuilder.group({
            system: ['', Validators.required],
            game: [''],
            date: ['', Validators.required],
            time: ['', Validators.required],
            description: ['', Validators.required],
        });
    }

    getProviders() {
        this.userBalanceService.getBalance().subscribe(data => {
            if (data.status) {
                if (data.providers) {
                    this.providers = data.providers;
                    this.setSupportSelects();
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    closePopup() {
        document.body.classList.remove('hide-menu');
        this.supportForm.reset();
        this.setSupportSelects();
        this.close.emit(true);
    }

    onSubmitSupportForm() {
        if (this.supportForm.invalid) {
            return
        }

        const system = this.getSupportFormFieldValue('system').name;
        const game = this.getSupportFormFieldValue('game');
        const date = this.getSupportDate();
        const time = this.getSupportFormFieldValue('time');
        const description = this.getSupportFormFieldValue('description');

        if (system && date && time && description) {
            this.supportService.sendSupportRequest(system, game, date, time, description).subscribe(data => {
                try {
                    if (data.status) {
                        this.popupComponent.showPopupSuccess({text: data.message});
                        this.closePopup();
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    getSupportFormFieldValue(name) {
        if (this.supportForm && this.supportForm.controls[name]) {
            return this.supportForm.controls[name].value;
        } else {
            return null;
        }

    }

    getSupportDate(){
        return (<HTMLInputElement>document.querySelector('[formcontrolname=date]')).value;
    }

    setSupportSelects() {
        this.selectedSystem = this.gamesProviderService.getActiveProvider(this.providers);
        this.selectedTime = this.times[0];
        this.supportForm.controls.time.setValue(this.selectedTime);

        this.supportForm.controls.system.setValue(this.selectedSystem);
    }
}
