/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./terminal.component";
import * as i2 from "../../service/kiosk/kiosk-accessor.service";
var styles_TerminalComponent = [];
var RenderType_TerminalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TerminalComponent, data: {} });
export { RenderType_TerminalComponent as RenderType_TerminalComponent };
export function View_TerminalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" init\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 1\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 2\n"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 3\n"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 4\n"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 5\n"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(6) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 6\n"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(7) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 7\n"])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cmd(8) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" cmd 8\n"])), (_l()(), i0.ɵeld(18, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" pay\n"])), (_l()(), i0.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(28, null, [" pay token ", "\n"])), (_l()(), i0.ɵeld(29, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(30, null, [" pay amount ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.payToken; _ck(_v, 28, 0, currVal_0); var currVal_1 = _co.payAmount; _ck(_v, 30, 0, currVal_1); }); }
export function View_TerminalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-terminal", [], null, null, null, View_TerminalComponent_0, RenderType_TerminalComponent)), i0.ɵdid(1, 114688, null, 0, i1.TerminalComponent, [i2.KioskAccessorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TerminalComponentNgFactory = i0.ɵccf("app-terminal", i1.TerminalComponent, View_TerminalComponent_Host_0, {}, {}, []);
export { TerminalComponentNgFactory as TerminalComponentNgFactory };
