/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slots.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./slots.component";
import * as i3 from "../../../service/pixi/pixiScaleToWindow.service";
import * as i4 from "./services/slotsResponses.service";
var styles_SlotsComponent = [i0.styles];
var RenderType_SlotsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlotsComponent, data: {} });
export { RenderType_SlotsComponent as RenderType_SlotsComponent };
export function View_SlotsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pixi: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["pixi", 1]], null, 0, "div", [["class", "pixi-container"], ["id", "pixi"]], [[2, "is-blur", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoPopup.show; _ck(_v, 1, 0, currVal_0); }); }
export function View_SlotsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slots", [], null, null, null, View_SlotsComponent_0, RenderType_SlotsComponent)), i1.ɵdid(1, 114688, null, 0, i2.SlotsComponent, [i3.PixiScaleToWindowService, i4.SlotsResponsesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SlotsComponentNgFactory = i1.ɵccf("app-slots", i2.SlotsComponent, View_SlotsComponent_Host_0, {}, {}, []);
export { SlotsComponentNgFactory as SlotsComponentNgFactory };
