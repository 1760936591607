import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "../settingsLoader.service";
import { SPINNER_GAME_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SpinnerService = /** @class */ (function (_super) {
    tslib_1.__extends(SpinnerService, _super);
    function SpinnerService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = SPINNER_GAME_CONFIG;
        return _this;
    }
    SpinnerService.prototype.init = function () {
        // this.showLoader();
        var post = this.http.post(this.config.init, {});
        return post;
        // return timer(1500).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.init, {}))
        // );
    };
    SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}(SettingsLoaderService));
export { SpinnerService };
