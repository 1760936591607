import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {GAMES_DEFAULT_ERROR_MESSAGE} from "../../pages/games/games.config";

@Component({
    selector: 'app-info-popup',
    templateUrl: './info-popup.component.html',
    styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent implements OnInit {
    private _data: any;

    @Input() set data(value: any) {
        this._data = value;

        if (value !== null) {
            this.updateState(value);
        }

    }

    get data() : any  {
        return this._data;
    }

    @Output() close: EventEmitter<any> = new EventEmitter();

    show = false;
    activeClass = false;
    message = '';
    callback;
    constructor() {}

    ngOnInit() {}


    closePopup() {
        if (this.callback) {
            try {
                this.callback();
            } catch (e) {
                console.log(e);
            }

        }
        this.close.emit();
    }

    updateState(value) {
        this.show = value.show;
        this.callback = value.callback;
        this.message = value.message ? value.message : GAMES_DEFAULT_ERROR_MESSAGE;
        setTimeout(() => {
            this.activeClass = value.show;
        }, 10);
    }
}
