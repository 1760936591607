/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./keyboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./keyboard.component";
import * as i4 from "../../service/keyboard.service";
import * as i5 from "../../modules/auth/_services/authentication.service";
import * as i6 from "../../service/partner.service";
import * as i7 from "@angular/router";
var styles_KeyboardComponent = [i0.styles];
var RenderType_KeyboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KeyboardComponent, data: {} });
export { RenderType_KeyboardComponent as RenderType_KeyboardComponent };
function View_KeyboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "logout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "p", [["class", "logout__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout after "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "logoutTimer"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" sec. "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [["class", "logout__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#logout"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoutTimer; _ck(_v, 6, 0, currVal_0); }); }
function View_KeyboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "keyboard"]], [[2, "keyboard--centered", null], [2, "keyboard--caps", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "7"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["7"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "8"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["8"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "9"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["9"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["4"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "5"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["5"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["6"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["1"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["2"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["3"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "keyboard__key keyboard__key--md keyboard__key--no-caps"], ["title", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, ":svg:svg", [["enable-background", "new 0 0 428.235 428.235"], ["height", "512"], ["id", "Capa_1"], ["viewBox", "0 0 428.235 428.235"], ["width", "512"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, ":svg:path", [["d", "m388.088 53.529h-241.575c-12.089 0-23.419 5.358-31.091 14.728l-112.403 137.392c-4.025 4.927-4.025 12.01 0 16.937l112.417 137.404c7.658 9.357 18.989 14.715 31.077 14.715h241.575c22.138 0 40.147-18.009 40.147-40.147v-240.881c0-22.139-18.008-40.148-40.147-40.148zm-61.37 208.577-37.847 37.847-47.988-47.988-47.988 47.988-37.847-37.847 47.988-47.988-47.988-47.988 37.847-37.847 47.988 47.988 47.988-47.988 37.847 37.847-47.988 47.988c-.001 0 47.988 47.988 47.988 47.988z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMascotSingle; var currVal_1 = _co.capitalLetters; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_KeyboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeyboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeyboardComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isUserLogout && !_co.isMascotSingle); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.keyboardVisible; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_KeyboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-keyboard", [], null, null, null, View_KeyboardComponent_0, RenderType_KeyboardComponent)), i1.ɵdid(1, 114688, null, 0, i3.KeyboardComponent, [i4.KeyboardService, i5.AuthenticationService, i6.PartnerService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KeyboardComponentNgFactory = i1.ɵccf("app-keyboard", i3.KeyboardComponent, View_KeyboardComponent_Host_0, {}, {}, []);
export { KeyboardComponentNgFactory as KeyboardComponentNgFactory };
