export const PAYMENT_TYPES = {
    cash: 0,
    card: 1,
    payPal: 2,
    check: 3,
    cashapp: 5,
    usdc: 'usdc',
    referrals: 6,
    games: 7
};

export const PAYMENT_TYPES_ICONS = {
    cash: 'cash',
    cashapp: 'cashapp',
    card: 'card',
    payPal: 'payPal',
    check: 'check',
    usdc: 'usd_coin',
    referrals: 'referrals',
    games: 'games'
};
