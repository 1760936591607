import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EXISTING_USER_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ExistingUserService {
    private config = EXISTING_USER_CONFIG;

    constructor(private http: HttpClient) {

    }

    getUsers(phone) {
        return this.http.post<any>(this.config.getUsers, {phone});
    }

    getUserInfo(id) {
        return this.http.post<any>(this.config.userInfo, {id});
    }

    freeze(id) {
        return this.http.post<any>(this.config.freeze, {id});
    }

    delete(id) {
        return this.http.post<any>(this.config.delete, {id});
    }

    sell(id, amount) {
        return this.http.post<any>(this.config.sell, {id, amount});
    }

}
