var SettingsLoaderService = /** @class */ (function () {
    function SettingsLoaderService(loaderService) {
        this.loaderService = loaderService;
        this._showLoaderBeforeRequest = true;
        this._hideLoaderAfterRequest = true;
    }
    SettingsLoaderService.prototype.showLoader = function () {
        if (this.showLoaderBeforeRequest) {
            this.loaderService.show();
        }
    };
    SettingsLoaderService.prototype.hideLoader = function () {
        if (this.hideLoaderAfterRequest) {
            this.loaderService.hide();
        }
    };
    Object.defineProperty(SettingsLoaderService.prototype, "showLoaderBeforeRequest", {
        get: function () {
            return this._showLoaderBeforeRequest;
        },
        set: function (value) {
            this._showLoaderBeforeRequest = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsLoaderService.prototype, "hideLoaderAfterRequest", {
        get: function () {
            return this._hideLoaderAfterRequest;
        },
        set: function (value) {
            this._hideLoaderAfterRequest = value;
        },
        enumerable: true,
        configurable: true
    });
    return SettingsLoaderService;
}());
export { SettingsLoaderService };
