/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yeti.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../sharedGames/thermometer-loader/thermometer-loader.component.ngfactory";
import * as i3 from "../../../sharedGames/thermometer-loader/thermometer-loader.component";
import * as i4 from "../../../sharedGames/info-popup/info-popup.component.ngfactory";
import * as i5 from "../../../sharedGames/info-popup/info-popup.component";
import * as i6 from "@angular/common";
import * as i7 from "./yeti.component";
import * as i8 from "./services/yetiRules.service";
import * as i9 from "./services/yetiGame.service";
import * as i10 from "./services/yetiLoader.service";
import * as i11 from "./services/yetiCommon.service";
import * as i12 from "./services/yetiResponses.service";
import * as i13 from "../../../service/pixi/pixiScaleToWindow.service";
var styles_YetiComponent = [i0.styles];
var RenderType_YetiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YetiComponent, data: {} });
export { RenderType_YetiComponent as RenderType_YetiComponent };
function View_YetiComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "action-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-thermometer-loader", [], null, null, null, i2.View_ThermometerLoaderComponent_0, i2.RenderType_ThermometerLoaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.ThermometerLoaderComponent, [], { progressValue: [0, "progressValue"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gameMainLoaderProgress; _ck(_v, 2, 0, currVal_0); }, null); }
function View_YetiComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-popup", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.hideInfoPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_InfoPopupComponent_0, i4.RenderType_InfoPopupComponent)), i1.ɵdid(1, 114688, null, 0, i5.InfoPopupComponent, [], { data: [0, "data"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoPopup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_YetiComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pixi: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["pixi", 1]], null, 0, "div", [["class", "pixi-container"], ["id", "pixi"]], [[2, "is-blur", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YetiComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YetiComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isMainGameLoaderShown; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.infoPopup.show; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoPopup.show; _ck(_v, 1, 0, currVal_0); }); }
export function View_YetiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yeti", [], null, null, null, View_YetiComponent_0, RenderType_YetiComponent)), i1.ɵdid(1, 245760, null, 0, i7.YetiComponent, [i8.YetiRulesService, i9.YetiGameService, i10.YetiLoaderService, i11.YetiCommonService, i12.YetiResponsesService, i13.PixiScaleToWindowService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YetiComponentNgFactory = i1.ɵccf("app-yeti", i7.YetiComponent, View_YetiComponent_Host_0, {}, {}, []);
export { YetiComponentNgFactory as YetiComponentNgFactory };
