import * as i0 from "@angular/core";
var KioskScrollService = /** @class */ (function () {
    function KioskScrollService() {
    }
    KioskScrollService.prototype.canScrollTop = function (viewChild) {
        if (viewChild && viewChild.nativeElement) {
            return !!viewChild.nativeElement.scrollTop;
        }
        return false;
    };
    KioskScrollService.prototype.canScrollBottom = function (viewChild) {
        if (viewChild && viewChild.nativeElement) {
            var scrollHeight = viewChild.nativeElement.scrollHeight;
            var scrollTop = viewChild.nativeElement.scrollTop;
            var elementHeight = viewChild.nativeElement.offsetHeight;
            return scrollHeight > elementHeight + scrollTop;
        }
        return false;
    };
    KioskScrollService.prototype.scrollTable = function (scrollableSelector, top) {
        var el;
        if (typeof scrollableSelector === 'string') {
            el = document.body.querySelector(scrollableSelector);
        }
        else {
            el = scrollableSelector;
        }
        // const el = document.body.querySelector(scrollableSelector);
        // const el  = scrollableSelector;
        var elemTopScroll = el.scrollTop;
        var newTopScroll = top ? elemTopScroll - 200 : elemTopScroll + 200;
        el.scrollIntoView({ behavior: 'smooth' });
        el.scroll({
            top: newTopScroll,
            behavior: 'smooth'
        });
    };
    KioskScrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KioskScrollService_Factory() { return new KioskScrollService(); }, token: KioskScrollService, providedIn: "root" });
    return KioskScrollService;
}());
export { KioskScrollService };
