import * as tslib_1 from "tslib";
import { REPORTS_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var WeeklyReportService = /** @class */ (function (_super) {
    tslib_1.__extends(WeeklyReportService, _super);
    function WeeklyReportService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = REPORTS_CONFIG;
        return _this;
    }
    WeeklyReportService.prototype.getReport = function () {
        this.showLoader();
        return this.http.post(this.config.weekly, {});
    };
    WeeklyReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WeeklyReportService_Factory() { return new WeeklyReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: WeeklyReportService, providedIn: "root" });
    return WeeklyReportService;
}(SettingsLoaderService));
export { WeeklyReportService };
