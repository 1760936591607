<swiper
        class="swiper-container"
        fxFlex="auto"
        [config]="config4"
        (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
>
    <div *ngFor="let slide of slides" class="swiper-slide">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            <img [src]="slide" alt="image" class="slide">
        </div>
    </div>
</swiper>

<swiper
        class="swiper-container"
        fxFlex="auto"
        [config]="config"
        (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
>
    <div *ngFor="let slide of slides" class="swiper-slide">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            <img [src]="slide" alt="image" class="slide">
        </div>
    </div>
</swiper>

<swiper
        class="swiper-container"
        fxFlex="auto"
        [config]="config2"
        (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
>
    <div *ngFor="let slide of slides" class="swiper-slide swiper-slide--auto">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            <img [src]="slide" alt="image" class="slide">
        </div>
    </div>
</swiper>

<swiper
        class="swiper-container"
        fxFlex="auto"
        [config]="config2"
        (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
>
    <div *ngFor="let slide of slidesDynamic" class="swiper-slide swiper-slide--auto">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            <img [src]="slide.image360" alt="image" class="slide">
        </div>
    </div>
</swiper>

<swiper
        class="swiper-container swiper-container--looped"
        fxFlex="auto"
        [config]="config3"
        (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
>
    <div *ngFor="let slide of slidesDynamic" class="swiper-slide swiper-slide--auto">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            <img [src]="slide.image360" alt="image" class="slide">
        </div>
    </div>
</swiper>
