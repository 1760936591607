import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { SettingsLoaderService } from "../settingsLoader.service";
import { MASCOT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var MascotService = /** @class */ (function (_super) {
    tslib_1.__extends(MascotService, _super);
    function MascotService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = MASCOT_CONFIG;
        _this.balanceUpdated = new BehaviorSubject(0);
        return _this;
    }
    MascotService.prototype.getIcons = function () {
        this.showLoader();
        return this.http.post(this.config.getIcons, {});
    };
    MascotService.prototype.getBalance = function () {
        this.showLoader();
        return this.http.post(this.config.getBalance, {});
    };
    MascotService.prototype.checkLastUpdates = function () {
        // this.showLoader();
        return this.http.post(this.config.updatesLast, {});
    };
    MascotService.prototype.updateBalance = function () {
        this.balanceUpdated.next(Math.random());
    };
    MascotService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MascotService_Factory() { return new MascotService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: MascotService, providedIn: "root" });
    return MascotService;
}(SettingsLoaderService));
export { MascotService };
