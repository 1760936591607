import { SCRATCH_OFF_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ScratchOffService = /** @class */ (function () {
    function ScratchOffService(http) {
        this.http = http;
        this.config = SCRATCH_OFF_CONFIG;
    }
    ScratchOffService.prototype.setPayment = function () {
        return this.http.post(this.config.paymentInfo, {});
    };
    ScratchOffService.prototype.buyGame = function (cardId) {
        return this.http.post(this.config.buyGame, { cardId: cardId });
    };
    ScratchOffService.prototype.createRound = function () {
        return this.http.post(this.config.createRound, {});
    };
    ScratchOffService.prototype.chooseItem = function (roundUuid, uuid) {
        // return this.http.post<any>(this.config.item, {hash});
        return this.http.post(this.config.chooseItem, { roundUuid: roundUuid, uuid: uuid });
        // return timer(2000).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.chooseItem, {roundUuid, uuid}))
        // );
    };
    ScratchOffService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScratchOffService_Factory() { return new ScratchOffService(i0.ɵɵinject(i1.HttpClient)); }, token: ScratchOffService, providedIn: "root" });
    return ScratchOffService;
}());
export { ScratchOffService };
