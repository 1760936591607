import {Inject, Injectable, NgZone} from '@angular/core';
import {PROJECT_CONFIG} from "../../config/project";

@Injectable({
    providedIn: 'root'
})
export class UsersPointService {
    private storage = sessionStorage;
    private keyName = 'userPoint';
    private keyPointHash = 'pointHash';

    constructor() {

    }

    saveHashInStorage(hash){
        this.storage.setItem(this.keyPointHash, hash);
    }

    getHash(){

        if (sessionStorage.getItem(this.keyPointHash)) {
            try {
                return sessionStorage.getItem(this.keyPointHash);
            }catch (e) {

            }
        }

        return '';
    }

    isPointSubdomain(){
        if(this.getSubdomain() != ''){
            if(PROJECT_CONFIG.pointSubdomainEbox != this.getSubdomain() && PROJECT_CONFIG.pointSubdomainEboxDemo != this.getSubdomain()){
                return true;
            }
        }

        return false;
    }

    getSubdomain(){
        var domain = window.location.hostname;
        var parts = domain.split('.');
        parts.pop();
        parts.pop();

        //alert(this.usersPointService.getSubdomain());

        return parts.join('.');
    }

    setUserPointId(id){
        this.storage.setItem('userPointId', id);
    }

    getUserPointId(){
        return this.storage.getItem('userPointId');
    }

    setPointPlayersEarnIncome(id){
        this.storage.setItem('pointPlayersEarnIncome', id);
    }

    getPointPlayersEarnIncome(){
        return this.storage.getItem('pointPlayersEarnIncome');
    }

    setAccount(data){
        this.storage.setItem(this.keyName, JSON.stringify(data));
    }

    isPointPlayersEarnIncome(){
        if (sessionStorage.getItem('pointPlayersEarnIncome')) {
            try {
                const pointPlayersEarnIncome = sessionStorage.getItem('pointPlayersEarnIncome');
                if(pointPlayersEarnIncome === '0'){
                    return false;
                }
            }catch (e) {}
        }

        return true;
    }

    getId(){
        if (sessionStorage.getItem(this.keyName)) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem(this.keyName));

                return currentUser.id;
            }catch (e) {

            }
        }

        return '';
    }

    getPointName() {
        if (sessionStorage.getItem(this.keyName)) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem(this.keyName));

                return currentUser.point_name;
            }catch (e) {

            }
        }

        return '';
    }

    getRiverPayNumber(){
        if (sessionStorage.getItem(this.keyName)) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem(this.keyName));

                return currentUser.river_pay_account_id;
            }catch (e) {

            }
        }

        return '';
    }

    setGameVendorShortName(name){
        this.storage.setItem('gameVendorShortName', name);
    }
    getGameVendor(){
        return this.storage.getItem('gameVendorShortName');
    }


}
