import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {LoaderService} from "../../../../service/loader.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../../modules/auth/_services/authentication.service";
import {GamesProviderService} from "../../../../service/games/gamesProvider.service";
import {UsersPointService} from "../../../../service/users/users-point.service";
import {KioskScrollService} from "../../../../service/kiosk-scroll.service";
import {KioskService} from "../../../../service/kiosk.service";
import {KioskPaymentService} from "../../../../service/kiosk/kiosk-payment.service";
import {CRYPTO_SETTINGS} from "../../../../config/crypto_settings";
import {PROJECT_CONFIG} from "../../../../config/project";
import {TerminalService} from "../../../../service/terminal/terminal.service";


@Component({
    selector: 'app-kiosk-buy-crypto',
    templateUrl: './kiosk-buy-crypto.component.html',
    styleUrls: ['./kiosk-buy-crypto.component.scss'],
})
export class KioskBuyCryptoComponent implements OnInit {
    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
        this._showPopup = value;
        if (value) {
            this.getCrypto();
        }
    }

    get showPopup() : boolean  {
        return this._showPopup;
    }

    @Input() type;
    @Input() data;

    @Output() close = new EventEmitter();
    @Output() back = new EventEmitter();
    @Output() cryptoSelected = new EventEmitter();
    @Output() isBackBtn = new EventEmitter();

    amount = 0;
    cryptoData = {
        items: [],
        loading: false
    };

    CRYPTO_SETTINGS = CRYPTO_SETTINGS;
    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;
    showBackBtn = false;

    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private kioskService: KioskService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private terminalService: TerminalService,
                private kioskScrollService: KioskScrollService,
                private kioskPaymentService: KioskPaymentService) {

    }

    ngOnInit() {
        this.showBackBtn = this.terminalService.isGames();
    }

    closePopup() {
        this.close.emit();
    }

    getCrypto() {
        this.cryptoData.loading = true;
        this.kioskPaymentService.getCrypto().subscribe(data => {
            this.getCryptoResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
            this.cryptoData.loading = false;
        });
    }
    getCryptoResult(data) {
        try {
            if(data.status === true){
                this.cryptoData.items = data.result;
                // this.cryptoData.items.length = 1;

                if (this.cryptoData.items.length == 1) {
                    this.buyCrypto(this.cryptoData.items[0]);
                } else {
                    this.isBackBtn.emit(true);
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    buyCrypto(crypto) {
        this.cryptoSelected.emit(crypto)
    }

    goBack() {
        this.back.emit();
    }

    loadDefaultCurrencyIcon($event) {
        const el = $event.target;
        el.src = '../../../../assets/images/currencies/'+this.DEFAULT_CRYPTO_ICON;
    }

    getCryptoColor(icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].color;
        } else {
            return '#fff'
        }
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable(this.scrolledEl.nativeElement, top);
    }
}

