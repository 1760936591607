import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactInfoService} from "../../../service/contactInfo.service";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {UserContacts,UserContactsResult} from "../../../models/services/contactInfo/userContacts";
import {ServerResponse} from "../../../models/responses/response.model";
import {StylingService} from "../../../service/styling.service";
import {KioskService} from "../../../service/kiosk.service";

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class ContactInfoComponent implements OnInit {
    contactInfo: FormGroup;
    info:UserContactsResult;

    constructor(private popupComponent: PopupComponent,
                private contactInfoService: ContactInfoService,
                private router: Router,
                private loaderService: LoaderService,
                private stylingService: StylingService,
                private formBuilder: FormBuilder) {
        this.contactInfo = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern('^[0-9A-Za-z._%+-]+@[0-9A-Za-z.-]+\\.[a-zA-Z]{2,4}$')]],
        });
    }

    ngOnInit() {

        this.getUser();
    }

    getUser(){
        this.contactInfoService.getUserContacts().subscribe(data => {
            this.setContactFormData(data);
        });
    }

    setContactFormData(responce:UserContacts): void {
        if(this.isStatus(responce.status)){
            this.setContactForm(responce.result);
        } else if (responce.status === undefined) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getFormSubmitResult(responce: ServerResponse) {
        if(responce.status === true){
            this.onSubmitSuccess(responce.message);
        }else if (responce.status === false) {
            this.popupComponent.showPopupError({text: responce.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setContactForm(user: UserContactsResult): boolean {
        if (user.first_name && user.last_name && user.email) {
            this.setContactFormValue('first_name', user.first_name);
            this.setContactFormValue('last_name', user.last_name);
            this.setContactFormValue('email', user.email);
            return true;
        } else {
            return false;
        }

    }

    setContactFormValue(field: string, value: string): boolean {
        if (this.contactInfo.controls[field]) {
            this.contactInfo.controls[field].setValue(value);
            return true;
        }
        return false;
    }

    getContactFormValue(field: string): string {
        return this.contactInfo.controls[field].value;
    }

    isStatus(status: boolean): boolean{
        if(status){
            return true;
        }
        return false;
    }

    saveUserContacts(firstName: string, lastName: string, email: string): void {
        this.contactInfoService.saveUserContacts(firstName, lastName, email).subscribe(data => {
            this.getFormSubmitResult(data);
        })
    }


    onSubmit() {
        const firstName = this.getContactFormValue('first_name');
        const lastName = this.getContactFormValue('last_name')
        const email = this.getContactFormValue('email')

        this.saveUserContacts(firstName, lastName, email);
    }

    onSubmitSuccess(message) {
        this.popupComponent.showPopupSuccess({text: message});
        this.router.navigate(['/home/settings']);
    }

    onInput(control, $event) {
        this.contactInfo.get(control).patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
