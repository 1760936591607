import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_CARD_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";
import {SettingsLoaderService} from "../settingsLoader.service";


@Injectable({
  providedIn: 'root'
})
export class StatesService extends SettingsLoaderService {
  private config = USER_CARD_CONFIG;

  constructor(private http: HttpClient, public loaderService: LoaderService) {
    super(loaderService);
  }

  getStatesList() {
    this.showLoader();
    return this.http.post<any>(this.config.states, {});
  }
}
