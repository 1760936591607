/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider-test2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./slider-test2.component";
import * as i4 from "../../service/mascot/mascot.service";
import * as i5 from "../../service/loader.service";
var styles_SliderTest2Component = [i0.styles];
var RenderType_SliderTest2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderTest2Component, data: {} });
export { RenderType_SliderTest2Component as RenderType_SliderTest2Component };
function View_SliderTest2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "swiper-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderTest2Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "swiper-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlexFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "slide"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image360; _ck(_v, 2, 0, currVal_0); }); }
export function View_SliderTest2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "block container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["data-current", "0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderTest2Component_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "block container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["data-current", "0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderTest2Component_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slides; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.slidesDynamic; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_SliderTest2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider-test2", [], null, null, null, View_SliderTest2Component_0, RenderType_SliderTest2Component)), i1.ɵdid(1, 114688, null, 0, i3.SliderTest2Component, [i4.MascotService, i5.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderTest2ComponentNgFactory = i1.ɵccf("app-slider-test2", i3.SliderTest2Component, View_SliderTest2Component_Host_0, {}, {}, []);
export { SliderTest2ComponentNgFactory as SliderTest2ComponentNgFactory };
