<div class="page page--secondary" [class.page--blured]="showPopup !== 'hide'">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <a id="prizes_usdc_mob_back_btn" [routerLink]="['/home/prizes']" class="page__back page__back--mobile page__back--mobile-text">
            <span class="visually-hidden">Back</span>
        </a>

        <div class="mail-check__col">
            <h1 class="page__title">Get Crypto</h1>

            <div class="page__scrolled">
                <div class="page__head">
                    <div class="balance">
                        <h2 class="balance__title">AVAILABLE WINNING BALANCE</h2>
                        <div class="balance__amount">{{availableBalance | currency : 'USD' : 'symbol'}}</div>
                        <p class="balance__text balance__text--secondary">Crypto will be added to your in-app Crypto Account. After that you will be able to withdrew it to any third-party wallet.</p>

                    </div>
                </div>

                <div class="page__form">
                    <table id="tradingPairs" class="transactions">
                        <tr *ngFor="let item of cryptoData.items; let i = index" class="transactions__item js-trading-item">
                            <td class="transactions__type transactions__type--fixed">
                                <div class="transactions__icon-wrapper transactions__icon-wrapper--xl transactions__icon-wrapper--left-align">
                                    <!--                                    <svg class="transactions__icon">-->
                                    <!--                                        <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#'+item.icon"></use>-->
                                    <!--                                    </svg>-->

                                    <img *ngIf="item" class="transactions__icon"
                                         src="../../../../assets/images/currencies/{{item.icon}}.png"
                                         (error)="loadDefaultCurrencyIcon($event)">
                                </div>
                            </td>
                            <!--                        <td class="transactions__middle">-->
                            <!--                            <div class="js-flex-text">-->
                            <!--                                {{item.size | number: '1.2-12'}} {{item.name}}-->
                            <!--                            </div>-->
                            <!--                        </td>-->

                            <td class="transactions__middle transactions__middle--row">
                                <span>1 {{item.name}}</span>
                                {{item.size | currency : 'USD' : 'symbol'}}
                            </td>
                            <td class="transactions__btn-wrapper">
                                <button id="tradingPairs_{{i}}"
                                        [ngStyle]="{color: getCryptoColor(item.icon), borderColor: getCryptoColor(item.icon)}"
                                        (click)="openTradePopup(item)"
                                        class="transactions__btn snake-btn btn is-notouched">
                                    Redeem
                                    <svg><rect [ngStyle]="{stroke: getCryptoColor(item.icon)}"></rect></svg>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="cryptoData.loading">
                            <td colspan="3" style="text-align: center">
                                <div class="pre-loader">
                                    <div class="box1"></div>
                                    <div class="box2"></div>
                                    <div class="box3"></div>
                                    <div class="box4"></div>
                                    <div class="box5"></div>
                                </div>
                            </td>

                        </tr>
                    </table>

                </div>
            </div>
        </div>

        <a id="prizes_usdc_back_btn" [routerLink]="['/home/prizes']" class="button page__back page__back--desktop" >
            Back
            <svg><rect></rect></svg>
        </a>
    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

<app-investments-popup-form [showPopupForm]="showPopup"
                            [data]="popupFormData"
                            [formType]="popupFormType"
                            [fromPage]="'prizes'"
                            (close)="onPopupFormClose($event)"></app-investments-popup-form>
