/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copy-to-buffer-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./copy-to-buffer-button.component";
import * as i3 from "../popup/popup.component";
var styles_CopyToBufferButtonComponent = [i0.styles];
var RenderType_CopyToBufferButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyToBufferButtonComponent, data: {} });
export { RenderType_CopyToBufferButtonComponent as RenderType_CopyToBufferButtonComponent };
export function View_CopyToBufferButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "copy"], ["data-clipboard-action", "copy"], ["title", "copy number"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyToBuffer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["viewBox", "0 0 24 24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M 6 2 L 6 4 L 20 4 L 20 18 L 22 18 L 22 4 C 22 2.895 21.105 2 20 2 L 6 2 z M 4 6 C 2.895 6 2 6.895 2 8 L 2 20 C 2 21.105 2.895 22 4 22 L 16 22 C 17.105 22 18 21.105 18 20 L 18 8 C 18 6.895 17.105 6 16 6 L 4 6 z M 4 8 L 16 8 L 16 20 L 4 20 L 4 8 z M 9 10 L 9 13 L 6 13 L 6 15 L 9 15 L 9 18 L 11 18 L 11 15 L 14 15 L 14 13 L 11 13 L 11 10 L 9 10 z"]], null, null, null, null, null))], null, null); }
export function View_CopyToBufferButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-copy-to-buffer-button", [], null, null, null, View_CopyToBufferButtonComponent_0, RenderType_CopyToBufferButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.CopyToBufferButtonComponent, [i3.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CopyToBufferButtonComponentNgFactory = i1.ɵccf("app-copy-to-buffer-button", i2.CopyToBufferButtonComponent, View_CopyToBufferButtonComponent_Host_0, { value: "value" }, {}, []);
export { CopyToBufferButtonComponentNgFactory as CopyToBufferButtonComponentNgFactory };
