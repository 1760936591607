import {Component, HostListener, Injectable, OnInit} from '@angular/core';
import {InstallService} from "../../service/install.service";
import { environment } from '../../../environments/environment';
import {Router} from "@angular/router";
import {PARTNERS} from "../../config/partners_settings";
import {PartnerService} from "../../service/partner.service";
import {UsersPointService} from "../../service/users/users-point.service";

@Component({
    selector: 'app-install',
    templateUrl: './install.component.html',
    styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {

    appInstalled = false;
    isBrowser;
    IOSInstallBtn = false;
    ios_popup_text;
    androidInstallBtn = false;
    deferredPrompt: any;

    PARTNERS = PARTNERS;
    currentPartner;

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        // console.log(e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        // prevent default event
        e.preventDefault();

        // store install avaliable event
        this.deferredPrompt = e;

        // show button with display:block;
        this.androidInstallBtn = true;
        //this.appInstalled = true;
        //this.appInstalled = false;
    }

    constructor(private installService: InstallService,
                private partnerService: PartnerService,
                private usersPointService: UsersPointService,
                private router: Router) {
        if(environment.production){
            this.installService.visible.subscribe((value: any) => this.installChanged(value));
        }

        // this.installService.appInstalled.subscribe((value: any) => this.appInstalledChanged(value));
    }


    ngOnInit() {

        //this.getPartner();

        // let $this1 = this;
        // setTimeout(function(){
        //     $this1.useBrowser();
        // },2000);

        if (this.isIos()) {
            this.appInstalled = false;
        }

        window.addEventListener('appinstalled', (evt) => {
            this.appInstalled = true;
        });

        let $this = this;
        setTimeout(function(){
            if (!$this.isIos() && !$this.androidInstallBtn) {
                $this.appInstalled = true;
            }
        },3000);

    }

    installChanged(value) {
        this.isBrowser = value;
    }

    appInstalledChanged(value) {
        this.appInstalled = value;
    }

    install() {


        if (this.isIos() && !this.isInStandaloneMode()) {
            let userAgent = window.navigator.userAgent.toLowerCase();
            this.IOSInstallBtn = true;
            let device = 'device';

            if  (/iphone/.test( userAgent )) {
                device = 'IPhone';
            } else if (/ipad/.test( userAgent )) {
                device = 'IPad';
            }
            this.ios_popup_text = `Install this webapp on your ${device}: `;
        } else {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === 'accepted') {
                    // user accept the prompt
                    // lets hidden button
                    //this.androidInstallBtn = false;
                    //this.appInstalled = true;
                    //document.location.reload();

                    this.appInstalled = true;

                } else {
                    console.log('User dismissed the prompt');
                }
                //this.deferredPrompt = null;
            });
        }
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);
    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    isMobile(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true
        }
        else{
            return false
        }
    }

    useBrowser() {
        localStorage.useBrowser = true;
        this.installService.hide();
    }

    getPartner() {
        this.currentPartner = this.PARTNERS[this.partnerService.currentPartner];
    }

    getPartnerName() {
        return this.partnerService.getName();
    }

    getText(){
        return this.partnerService.getLoginText();
    }

    isDefaultPartner() {
        // if (this.currentPartner.name === 'eBox') {
        //     return true;
        // }
        return false;
    }

    getPartnerLogo() {
        return this.partnerService.getLogo();
    }

}
