import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {PixiScaleToWindowService} from "../../../service/pixi/pixiScaleToWindow.service";
import {interval} from "rxjs";
import {SlotsResponsesService} from "./services/slotsResponses.service";
declare const PIXI: typeof import('pixi.js');

@Component({
    selector: 'app-slots',
    templateUrl: './slots.component.html',
    styleUrls: ['./slots.component.scss']
})

export class SlotsComponent implements OnInit {
    @ViewChild('pixi', {static: false}) pixi;

    app;
    canvas: HTMLCanvasElement;
    stage;
    loader;
    resources;
    canvasScaleValue;

    infoPopup = {
        show: false,
        message: ''
    }
    constructor(private pixiScaleToWindowService: PixiScaleToWindowService,
                private slotsResponsesService: SlotsResponsesService) {}

    reelsExtraOffset = [];
    winCombination = [1, 1, 1, 1, 1];
    tweening = [];
    REEL_WIDTH = 145;
    SYMBOL_SIZE = 160;
    running = false;
    reels = [];
    waitingInterval = null;
    slotTextures = [];

    ngOnInit() {
        setTimeout(() => {
            this.createRenderer();
            this.loadGameImages();
            this.scaleGameToWindow();
        });
    }

    loadGameImages() {
      PIXI.loader
        .add('el1', './assets/images/slots/elements/el1-size.png')
        .add('el2', './assets/images/slots/elements/el2-size.png')
        .add('el3', './assets/images/slots/elements/el3-size.png')
        .add('el4', './assets/images/slots/elements/el4-size.png')
        .add('el5', './assets/images/slots/elements/el5-size.png')
        .add('el6', './assets/images/slots/elements/el6-size.png')
        .add('el7', './assets/images/slots/elements/el7-size.png')
        .add('el8', './assets/images/slots/elements/el8-size.png')
        .add('drumMiddle', './assets/images/slots/drum-middle-sm.png')
        .add('drumLeft', './assets/images/slots/drum-left.png')
        .add('drumRight', './assets/images/slots/drum-right.png')
        .add('playBtn', './assets/images/slots/play-btn.png')
        .add('top', './assets/images/slots/top.png')
        .add('bg', './assets/images/slots/bg.jpg')
        .load(this.onAssetsLoaded.bind(this));
      this.resources = PIXI.loader.resources;
    }

    defineSlotTextures() {
      this.slotTextures = [
        PIXI.Texture.fromImage('el1'),
        PIXI.Texture.fromImage('el2'),
        PIXI.Texture.fromImage('el3'),
        PIXI.Texture.fromImage('el4'),
        PIXI.Texture.fromImage('el3'),
        PIXI.Texture.fromImage('el4'),
        PIXI.Texture.fromImage('el5'),
        PIXI.Texture.fromImage('el6'),
        PIXI.Texture.fromImage('el7'),
        PIXI.Texture.fromImage('el8')
      ];
    }


    addToScene(el) {
        this.stage.addChild(el);
    }


    setBackground() {
        const background = new PIXI.Sprite(this.resources.bg.texture);
        const scale = this.canvas.width / background.width;
        background.scale.set(scale);

        this.addToScene(background);
    }

    setPlayBtn() {
        const sprite = new PIXI.Sprite(this.resources.playBtn.texture);
        const scale = this.canvas.width * .075 / sprite.width;
        sprite.scale.set(scale);
        sprite.x = this.canvas.width - sprite.height * 1.2;
        sprite.y = this.canvas.height - sprite.height * 1.2;
        sprite.interactive = true;
        sprite.buttonMode = true;
        sprite.defaultCursor = 'pointer';
        sprite.addListener('pointerdown', () => {
          this.startPlay();
        });

        this.addToScene(sprite);
    }

    setTop() {
      const sprite = new PIXI.Sprite(this.resources.top.texture);
      const scale = this.canvas.width * .42 / sprite.width;
      sprite.scale.set(scale);
      sprite.x = this.canvas.width / 2 - sprite.width / 2;

      this.addToScene(sprite);
    }


    createRenderer() {
        this.app = new PIXI.Application(4000, 2500, { transparent: true });

        this.stage = this.app.stage;
        this.canvas = this.app.view;
        this.canvas.style.backgroundColor = '#0C0101';
        document.querySelector('#pixi').appendChild(this.canvas);

        this.setPlayAnimation();
    }

    setPlayAnimation() {
      this.app.ticker.add((delta) => {
          var now = Date.now();
          var remove = [];
          for (var i = 0; i < this.tweening.length; i++) {
              var t = this.tweening[i];
              var phase = Math.min(1, (now - t.start) / t.time);

              if (t.easing) {
                  t.object[t.property] = this.lerp(t.propertyBeginValue, t.target, t.easing(phase));
              } else {
                  t.object[t.property] = this.lerp(t.propertyBeginValue, t.target, phase);
              }

              if (t.change) t.change(t);
              if (phase === 1) {
                  t.object[t.property] = t.target;
                  if (t.complete) t.complete(t);
                  remove.push(t);
              }
          }
          for (var i = 0; i < remove.length; i++) {
              this.tweening.splice(this.tweening.indexOf(remove[i]), 1);
          }
      });
    }

    lerp(a1, a2, t) {
        return a1 * (1 - t) + a2 * t;
    }

    backout(amount) {
        return function(t) {
            return (--t * t * ((amount + 1) * t + amount) + 1);
        };
    }

    tweenTo(object, property, target, time, easing, onchange, oncomplete) {
        var tween = {
            object: object,
            property: property,
            propertyBeginValue: object[property],
            target: target,
            easing: easing,
            time: time,
            change: onchange,
            complete: oncomplete,
            start: Date.now()
        };

        this.tweening.push(tween);
        return tween;
    }

    onAssetsLoaded() {
        this.defineSlotTextures();
        this.setBackground();
        this.setTop();
        this.setPlayBtn();

        var drumContainer = new PIXI.Container();
        const drum = this.createDrumSprite();
        drumContainer.addChild(drum);
        drumContainer.x = this.canvas.width / 2 - drumContainer.width / 2;
        drumContainer.y = this.canvas.height / 2 - drum.height / 2;

        // Build the reels
        var reelContainer = new PIXI.Container();

        for (var i = 0; i < 5; i++) {
            var rc = new PIXI.Container();
            rc.x = i * this.REEL_WIDTH;
            reelContainer.addChild(rc);

            var reel = {
                container: rc,
                symbols: [],
                position: 0,
                previousPosition: 0,
                blur: new PIXI.filters.BlurFilter()
            };
            reel.blur.blurX = 0;
            reel.blur.blurY = 0;
            rc.filters = [reel.blur];

            // Build the symbols
            for (var j = 0; j < 4; j++) {
                var symbol = new PIXI.Sprite(this.slotTextures[Math.floor(Math.random() * 8)]);
                // Scale the symbol to fit symbol area.
                symbol.y = j * this.SYMBOL_SIZE;
                symbol.scale.x = symbol.scale.y = Math.min(this.SYMBOL_SIZE / symbol.width, this.SYMBOL_SIZE / symbol.height);
                symbol.x = Math.round((this.SYMBOL_SIZE - symbol.width) / 2);
                reel.symbols.push(symbol);
                rc.addChild(symbol);
            }
            this.reels.push(reel);
        }

        const scaleReels = drumContainer.width / reelContainer.width * .82;
        reelContainer.scale.set(scaleReels);
        reelContainer.x = drumContainer.width / 2 - reelContainer.width / 2 - 50;
        reelContainer.y = 98;


        const mask = this.createDrumMask();
        reelContainer.addChild(mask);
        reelContainer.mask = mask;

        drumContainer.addChild(reelContainer);
        this.stage.addChild(drumContainer);

        setTimeout(() => {
          this.listenAnimationUpdate();
        });
    }

    createDrumSprite() {
      const drum = new PIXI.Sprite(this.resources.drumMiddle.texture);
      const scale = (this.canvas.height / drum.height) * .7;
      drum.scale.set(scale);
      return drum;
    }

    createDrumMask() {
      const mask = new PIXI.Graphics();
      mask.beginFill(0x000000);
      mask.moveTo(38, 0);
      mask.lineTo(160, -1);
      mask.lineTo(300, -1);
      mask.lineTo(435, -1);
      mask.lineTo(575, 0);
      mask.lineTo(697, 1);
      mask.lineTo(697, 462.5);
      mask.lineTo(575, 464);
      mask.lineTo(435, 463);
      mask.lineTo(38, 466);
      mask.lineTo(38, 0);
      mask.endFill();

      mask.beginFill(0xffffff);
      mask.drawEllipse(39, 231.5, 29, 231.5);
      mask.endFill();

      mask.beginFill(0xffffff);
      mask.drawEllipse(696, 231.5, 29, 231.5);
      mask.endFill();
      return mask;
    }

    listenAnimationUpdate() {
      this.app.ticker.add((delta) => {
        // Update the slots.
        for (var i = 0; i < this.reels.length; i++) {
          var r = this.reels[i];
          // Update blur filter y amount based on speed.
          // This would be better if calculated with time in mind also. Now blur depends on frame rate.
          r.blur.blurY = (r.position - r.previousPosition) * 8;
          r.previousPosition = r.position;

          // Update symbol positions on reel.
          for (var j = 0; j < r.symbols.length; j++) {
            var s = r.symbols[j];
            var prevy = s.y;
            s.y = ((r.position + j) % r.symbols.length) * this.SYMBOL_SIZE - this.SYMBOL_SIZE;

            if (s.y < 0 && prevy > this.SYMBOL_SIZE) {
              // Detect going over and swap a texture.
              // This should in proper product be determined from some logical reel.

              if ((Math.round(r.position) === Math.round(this.reelsExtraOffset[i] - 2)) && this.winCombination) {
                s.texture = this.slotTextures[this.winCombination[i]];
              } else {
                // s.texture = slotTextures[Math.floor(1)];
                s.texture = this.slotTextures[Math.floor(Math.random() * 8)];

              }

              s.scale.x = s.scale.y = Math.min(this.SYMBOL_SIZE / s.texture.width, this.SYMBOL_SIZE / s.texture.height);
              s.x = Math.round((this.SYMBOL_SIZE - s.width) / 2);
            }
          }
        }
      });
    }

    // Function to start playing.
    startPlay() {
        for (var i = 0; i < this.reels.length; i++) {
            var r = this.reels[i];
            var extra = 1;

            let target = r.position + 10 + extra;
            this.tweenTo(r, 'position', target, 1000, null, null, i === this.reels.length - 1 ? this.reelsComplete.bind(this) : null);

        }
        this.waitingInterval = setInterval(() => {
            for (var i = 0; i < this.reels.length; i++) {
                var r = this.reels[i];
                var extra = 1;

                let target = r.position + 10 + extra;
                this.tweenTo(r, 'position', target, 1000, null, null, i === this.reels.length - 1 ? this.reelsComplete.bind(this) : null);

            }
        }, 1000);

        this.slotsResponsesService.initGame().subscribe(data => {
          // if (data && data.status) {
          //   this.winCombination = data.winCombination;
          // } else {
            this.winCombination = [1, 2, 3, 4, 5];
          // }
          setTimeout(() => {
            this.startPlay1();
          });
        }, () => {
          this.winCombination = [1, 2, 3, 4, 5];
          this.startPlay1();
        }, () => {

        })
    }

    startPlay1() {
        clearInterval(this.waitingInterval);
        this.waitingInterval = null;

        if (this.running) return;
        this.running = true;

        for (var i = 0; i < this.reels.length; i++) {
            var r = this.reels[i];
            const extra = Math.floor(Math.random() * 2);
            const target = Math.floor(r.position) + 10 + i * 5 + extra;
            const time = 2500 + i * 600 + extra * 600;
            this.reelsExtraOffset[i] = target;

            this.tweenTo(r, 'position', target, time, this.backout(0.5), null, i === this.reels.length - 1 ? this.reelsComplete.bind(this) : null);
        }
    }

    // Reels done handler.
    reelsComplete() {
        this.running = false;
    }

    scaleGameToWindow() {
        this.canvasScaleValue = this.pixiScaleToWindowService.scaleToWindow(this.canvas, '#0C0101');

        window.onresize = () => {
            this.canvasScaleValue = this.pixiScaleToWindowService.scaleToWindow(this.canvas, '#0C0101');
        }
    }
}
