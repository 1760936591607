import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-financial-info-item',
    templateUrl: './financial-info-item.component.html',
    styleUrls: ['./financial-info-item.component.scss']
})
export class FinancialInfoItemComponent implements OnInit {

    @Input() value;
    @Input() negativeIsNotDashed;
    @Input() showZeroValue = false;
    @Input() negativeWithoutBrackets = false;

    constructor() {
    }

    ngOnInit() {

    }

    isNegativeNumber(amount) {
        if(!this.negativeWithoutBrackets){
            return amount < 0;
        }else{
            return false;
        }

    }

    getAbs(amount) {
        if(!this.negativeWithoutBrackets){
            return Math.abs(amount);
        }else{
            return amount;
        }

    }

}
