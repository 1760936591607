import { PAGE_COLORS } from "../config/page_colors";
import { RouterPaths } from "../config/router.paths.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var StylingService = /** @class */ (function () {
    function StylingService(router) {
        this.router = router;
        this.pageColors = PAGE_COLORS;
    }
    StylingService.prototype.getPageColor = function () {
        if (this.router.isActive(RouterPaths.rewards.main, false)) {
            return this.pageColors.rewards;
        }
        else if (this.router.isActive(RouterPaths.prizes.main, false)) {
            return this.pageColors.prizes;
        }
        else if (this.router.isActive(RouterPaths.settings.home, false)) {
            return this.pageColors.settings;
        }
        else {
            return this.pageColors.default;
        }
    };
    StylingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StylingService_Factory() { return new StylingService(i0.ɵɵinject(i1.Router)); }, token: StylingService, providedIn: "root" });
    return StylingService;
}());
export { StylingService };
