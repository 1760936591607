import * as tslib_1 from "tslib";
import { YETI_RULES } from "../yeti.config";
import * as i0 from "@angular/core";
import * as i1 from "./yetiCommon.service";
var YetiRulesService = /** @class */ (function () {
    function YetiRulesService(yetiCommonService) {
        var _this = this;
        this.yetiCommonService = yetiCommonService;
        this.RULES = YETI_RULES;
        this.activeSlide = 1;
        this.TITLE_STYLE = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 80,
            lineHeight: 100,
            fontWeight: 'bold',
            align: 'center',
            fill: '#e0f8ff'
        });
        this.TITLE_SECONDARY_STYLE = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 60,
            lineHeight: 80,
            fontWeight: 'bold',
            align: 'center',
            fill: '#e3ffdc'
        });
        this.TEXT_STYLE = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 40,
            fill: ['#e0f7ff'],
        });
        this.CONTAINER_SETTINGS = {
            positionX: 726,
            positionY: 348,
            width: 1065,
            height: 600
        };
        this.PRIZES = [
            {
                containerPosX: 580,
                containerPosY: 370,
                imgName: 'prize1',
                imgHeight: 115,
                numImageName: '1',
                numHeight: 90,
            },
            {
                containerPosX: 50,
                containerPosY: 565,
                imgName: 'prize2',
                imgHeight: 135,
                numImageName: '2',
                numHeight: 94,
            },
            {
                containerPosX: 430,
                containerPosY: 565,
                imgName: 'prize3',
                imgHeight: 176,
                numImageName: '3',
                numHeight: 90,
            },
            {
                containerPosX: 180,
                containerPosY: 370,
                imgName: 'prize4',
                imgHeight: 155,
                numImageName: '4',
                numHeight: 98,
            },
            {
                containerPosX: 790,
                containerPosY: 565,
                imgName: 'prize5',
                imgHeight: 150,
                numImageName: '5',
                numHeight: 96,
            }
        ];
        this.rulesSlide1Container = new PIXI.Container();
        this.rulesSlide2Container = new PIXI.Container();
        this.rulesSlide3Container = new PIXI.Container();
        this.rulesCommonContainer = new PIXI.Container();
        this.isSlideChangeAnimation = false;
        this.changeSlide = function (direction, renderer, stage) {
            var animationSpeed;
            var requestAnimation;
            if (!_this.isSlideChangeAnimation) {
                var isNext_1 = direction === 'next';
                var isPrev = direction === 'prev';
                _this.isSlideChangeAnimation = true;
                animationSpeed = 20;
                if ((isNext_1 && _this.activeSlide !== 3) || (isPrev && _this.activeSlide !== 1)) {
                    var play_1 = function () {
                        var speed = direction === 'next' ? -animationSpeed : animationSpeed;
                        var allRulesSlideContainersChildren = tslib_1.__spread(_this.rulesSlide1Container.children, _this.rulesSlide2Container.children, _this.rulesSlide3Container.children);
                        allRulesSlideContainersChildren.forEach(function (child) {
                            if (!child.isMask) {
                                child.position.x += speed;
                            }
                        });
                        animationSpeed = animationSpeed * 1.02;
                        var nextActiveSlide;
                        if (isNext_1) {
                            nextActiveSlide = _this.activeSlide === 1 ? 2 : 3;
                        }
                        else {
                            nextActiveSlide = _this.activeSlide === 2 ? 1 : 2;
                        }
                        var stopPoint = isNext_1 ? _this.CONTAINER_SETTINGS.width / 2 - _this.CONTAINER_SETTINGS.width * _this.activeSlide : _this.CONTAINER_SETTINGS.width / 2 - _this.CONTAINER_SETTINGS.width * (_this.activeSlide - 2);
                        var stopCondition;
                        if (isNext_1) {
                            stopCondition = stopPoint < _this.rulesSlide1Container.children[0].position.x;
                        }
                        else {
                            stopCondition = stopPoint > _this.rulesSlide1Container.children[0].position.x + 30;
                        }
                        if (stopCondition) {
                            requestAnimation = requestAnimationFrame(play_1);
                        }
                        else {
                            _this.activeSlide = nextActiveSlide;
                            _this.isSlideChangeAnimation = false;
                            if (_this.activeSlide === 1) {
                                _this.prevSlideBtn.alpha = 0;
                            }
                            else {
                                _this.prevSlideBtn.alpha = 1;
                            }
                            if (_this.activeSlide === 3) {
                                _this.nextSlideBtn.alpha = 0;
                            }
                            else {
                                _this.nextSlideBtn.alpha = 1;
                            }
                            cancelAnimationFrame(requestAnimation);
                        }
                        renderer.render(stage);
                    };
                    requestAnimation = requestAnimationFrame(play_1);
                }
                else {
                    _this.isSlideChangeAnimation = false;
                }
            }
        };
    }
    YetiRulesService.prototype.setFirstSlideActive = function () {
        var allRulesSlideContainersChildren = tslib_1.__spread(this.rulesSlide1Container.children, this.rulesSlide2Container.children, this.rulesSlide3Container.children);
        var dist = this.CONTAINER_SETTINGS.width / 2 - this.rulesSlide1Container.children[0].position.x;
        allRulesSlideContainersChildren.forEach(function (child) {
            if (!child.isMask) {
                child.position.x += dist;
            }
        });
        this.activeSlide = 1;
        this.prevSlideBtn.alpha = 0;
        this.nextSlideBtn.alpha = 1;
    };
    YetiRulesService.prototype.setRules = function (stage, resources, renderer) {
        this.setRulesView(stage, resources, renderer);
        this.setRulesControls(stage, renderer);
    };
    YetiRulesService.prototype.setRulesView = function (stage, resources, renderer) {
        this.setRulesFrame(stage, resources);
        this.setPlayBtn(stage, resources);
        this.setCloseBtn(stage, resources);
        this.setSlidesPosition();
        this.setSlide1(stage);
        this.setSlide2(stage, resources);
        this.setSlide3(stage, resources);
        this.setRulesFrameControls(stage, resources, renderer);
        stage.addChild(this.rulesCommonContainer);
    };
    YetiRulesService.prototype.setRulesControls = function (stage, renderer) {
        this.setSliderControls(renderer, stage);
        this.onPlayBtnClick();
        this.onCloseRulesBtnClick();
    };
    YetiRulesService.prototype.setSliderControls = function (renderer, stage) {
        this.onSlideNextBtnClick(renderer, stage);
        this.onSlidePrevBtnClick(renderer, stage);
    };
    YetiRulesService.prototype.onCloseRulesBtnClick = function () {
        var _this = this;
        this.yetiCommonService.addButtonBehavior(this.closeRulesBtn);
        this.closeRulesBtn.on('pointerdown', function (event) {
            _this.yetiCommonService.showRules.emit(false);
        });
    };
    YetiRulesService.prototype.onPlayBtnClick = function () {
        var _this = this;
        this.yetiCommonService.addButtonBehavior(this.playBtn);
        this.playBtn.on('pointerdown', function (event) {
            _this.yetiCommonService.startGame.emit(true);
        });
    };
    YetiRulesService.prototype.onSlideNextBtnClick = function (renderer, stage) {
        var _this = this;
        this.nextSlideBtn.on('pointerdown', function (event) {
            _this.changeSlide('next', renderer, stage);
        });
    };
    YetiRulesService.prototype.onSlidePrevBtnClick = function (renderer, stage) {
        var _this = this;
        this.prevSlideBtn.on('pointerdown', function (event) {
            _this.changeSlide('prev', renderer, stage);
        });
    };
    YetiRulesService.prototype.setRulesFrame = function (stage, resources) {
        var img = new PIXI.Sprite(resources.frameImg.texture);
        var imgMarkupHeight = 810;
        var scale = imgMarkupHeight / img.height;
        var posX = 670;
        var posY = 184;
        img.scale.set(scale, scale);
        img.position.set(posX, posY);
        this.rulesCommonContainer.addChild(img);
    };
    YetiRulesService.prototype.setPlayBtn = function (stage, resources) {
        this.playBtn = new PIXI.Sprite(resources.use.texture);
        var imgMarkupHeight = 110;
        var scale = imgMarkupHeight / this.playBtn.height;
        this.playBtn.scale.set(scale, scale);
        var posX = this.CONTAINER_SETTINGS.positionX + this.CONTAINER_SETTINGS.width / 2;
        var posY = 965;
        this.playBtn.anchor.set(.5, .5);
        this.playBtn.position.set(posX, posY);
        this.rulesCommonContainer.addChild(this.playBtn);
    };
    YetiRulesService.prototype.setCloseBtn = function (stage, resources) {
        this.closeRulesBtn = new PIXI.Sprite(resources.closeRules.texture);
        var imgMarkupHeight = 105;
        var scale = imgMarkupHeight / this.closeRulesBtn.height;
        this.closeRulesBtn.scale.set(scale, scale);
        var posX = this.CONTAINER_SETTINGS.positionX + this.CONTAINER_SETTINGS.width;
        var posY = 200;
        this.closeRulesBtn.anchor.set(.25, .5);
        this.closeRulesBtn.visible = false;
        this.closeRulesBtn.position.set(posX, posY);
        this.rulesCommonContainer.addChild(this.closeRulesBtn);
    };
    YetiRulesService.prototype.setSlidesPosition = function () {
        var _this = this;
        [this.rulesSlide1Container, this.rulesSlide2Container, this.rulesSlide3Container].forEach(function (container) {
            container.position.set(_this.CONTAINER_SETTINGS.positionX, _this.CONTAINER_SETTINGS.positionY);
        });
    };
    YetiRulesService.prototype.setSlideMask = function (container) {
        var mask = new PIXI.Sprite(PIXI.Texture.WHITE);
        mask.width = this.CONTAINER_SETTINGS.width;
        mask.height = this.CONTAINER_SETTINGS.height;
        container.addChild(mask);
        container.mask = mask;
    };
    YetiRulesService.prototype.setSlide1 = function (stage) {
        var _this = this;
        var lastAddedElPos = 0;
        var title = new PIXI.Text('RULES:', this.TITLE_STYLE);
        title.anchor.set(0.5, 0);
        this.rulesSlide1Container.addChild(title);
        lastAddedElPos += title.height * 1.2;
        this.RULES.forEach(function (rule) {
            var item = new PIXI.Text("\u2022 " + rule, _this.TEXT_STYLE);
            item.y = lastAddedElPos;
            item.x = 30;
            lastAddedElPos += item.height * 1.2;
            _this.rulesSlide1Container.addChild(item);
        });
        title.position.x = this.CONTAINER_SETTINGS.width / 2;
        this.setSlideMask(this.rulesSlide1Container);
        this.rulesCommonContainer.addChild(this.rulesSlide1Container);
    };
    YetiRulesService.prototype.setSlide2 = function (stage, resources) {
        var hiddenOffset = this.CONTAINER_SETTINGS.width;
        var title = new PIXI.Text('Multiplier \n Winning Elements', this.TITLE_STYLE);
        title.anchor.set(0.5, 0);
        this.rulesSlide2Container.addChild(title);
        title.position.x = this.CONTAINER_SETTINGS.width / 2 + hiddenOffset;
        this.setSlide2Images(hiddenOffset, resources);
        this.setSlideMask(this.rulesSlide2Container);
        this.rulesCommonContainer.addChild(this.rulesSlide2Container);
    };
    YetiRulesService.prototype.setSlide2Images = function (hiddenOffset, resources) {
        var _this = this;
        this.PRIZES.forEach(function (prizeInfo) {
            var lastAddedElementXPosition = 0;
            var prizeContainer = new PIXI.Container();
            var prizeImage = new PIXI.Sprite(resources[prizeInfo.imgName].texture);
            var imgMarkupHeight = prizeInfo.imgHeight;
            var scale = imgMarkupHeight / prizeImage.height;
            _this.yetiCommonService.scale(prizeImage, scale);
            prizeContainer.addChild(prizeImage);
            lastAddedElementXPosition = prizeImage.width;
            var xImage = new PIXI.Sprite(resources.x.texture);
            var xMarkupHeight = 40;
            var xScale = xMarkupHeight / xImage.height;
            _this.yetiCommonService.scale(xImage, xScale);
            xImage.anchor.set(0, 1);
            xImage.position.set(lastAddedElementXPosition + 20, prizeImage.height - 20);
            prizeContainer.addChild(xImage);
            lastAddedElementXPosition += 20 + xImage.width;
            var numImage = new PIXI.Sprite(resources[prizeInfo.numImageName].texture);
            var numMarkupHeight = prizeInfo.numHeight;
            var numScale = numMarkupHeight / numImage.height;
            _this.yetiCommonService.scale(numImage, numScale);
            numImage.anchor.set(0, 1);
            numImage.position.set(lastAddedElementXPosition + 3, prizeImage.height - 20);
            prizeContainer.addChild(numImage);
            prizeContainer.position.set(prizeInfo.containerPosX + hiddenOffset, prizeInfo.containerPosY - prizeContainer.height);
            _this.rulesSlide2Container.addChild(prizeContainer);
        });
    };
    YetiRulesService.prototype.setSlide3 = function (stage, resources) {
        var hiddenOffset = this.CONTAINER_SETTINGS.width * 2;
        var title = new PIXI.Text('Lose All \n previous wins', this.TITLE_SECONDARY_STYLE);
        title.anchor.set(0.5, 0);
        this.rulesSlide3Container.addChild(title);
        title.position.x = this.CONTAINER_SETTINGS.width / 2 + 330 + hiddenOffset;
        title.position.y = 240;
        var posionBg = new PIXI.Sprite(resources.poisonBg.texture);
        var posionBgMarkupHeight = 750;
        var posionBgScale = posionBgMarkupHeight / posionBg.height;
        this.yetiCommonService.scale(posionBg, posionBgScale);
        posionBg.position.x = hiddenOffset;
        posionBg.position.y = -100;
        this.rulesSlide3Container.addChild(posionBg);
        var posionImage = new PIXI.Sprite(resources.poison.texture);
        var markupHeight = 345;
        var scale = markupHeight / posionImage.height;
        this.yetiCommonService.scale(posionImage, scale);
        posionImage.position.x = 230 + hiddenOffset;
        posionImage.position.y = 140;
        this.rulesSlide3Container.addChild(posionImage);
        this.setSlideMask(this.rulesSlide3Container);
        this.rulesCommonContainer.addChild(this.rulesSlide3Container);
    };
    YetiRulesService.prototype.setRulesFrameControls = function (stage, resources, renderer) {
        this.nextSlideBtn = new PIXI.Sprite(resources.nextBtn.texture);
        this.prevSlideBtn = new PIXI.Sprite(resources.nextBtn.texture);
        var imgMarkupHeight = 110;
        var scale = imgMarkupHeight / this.nextSlideBtn.height;
        var nextSlideBtnPosX = 1810;
        var prevSlideBtnPosX = 705;
        var posY = 524;
        this.prevSlideBtn.rotation = 3.14159;
        this.yetiCommonService.scale(this.nextSlideBtn, scale);
        this.yetiCommonService.scale(this.prevSlideBtn, scale);
        this.nextSlideBtn.position.set(nextSlideBtnPosX, posY);
        this.prevSlideBtn.position.set(prevSlideBtnPosX, posY);
        this.yetiCommonService.setAnchorToCenter(this.nextSlideBtn);
        this.yetiCommonService.setAnchorToCenter(this.prevSlideBtn);
        this.yetiCommonService.addButtonBehavior(this.nextSlideBtn);
        this.yetiCommonService.addButtonBehavior(this.prevSlideBtn);
        this.prevSlideBtn.alpha = 0;
        this.rulesCommonContainer.addChild(this.nextSlideBtn);
        this.rulesCommonContainer.addChild(this.prevSlideBtn);
    };
    YetiRulesService.prototype.activateGamePlayBtn = function (state) {
        if (state === void 0) { state = false; }
        if (state) {
            this.playBtn.alpha = 1;
            this.playBtn.interactive = true;
            this.playBtn.buttonMode = true;
            this.playBtn.defaultCursor = 'pointer';
        }
        else {
            this.playBtn.alpha = 0;
            this.playBtn.interactive = false;
            this.playBtn.buttonMode = false;
            this.playBtn.defaultCursor = 'auto';
        }
    };
    YetiRulesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YetiRulesService_Factory() { return new YetiRulesService(i0.ɵɵinject(i1.YetiCommonService)); }, token: YetiRulesService, providedIn: "root" });
    return YetiRulesService;
}());
export { YetiRulesService };
