import { EventEmitter, OnInit } from '@angular/core';
import { PROJECT_CONFIG } from "../../../../config/project";
import { KIOSK_CRYPTO } from "../../../../config/kiosk_config";
import { GAME_VENDORS } from "../../../../config/gameVendors";
var KioskPaymentComponent = /** @class */ (function () {
    function KioskPaymentComponent(kioskService, popupComponent, loaderService, kioskAccessorService, partnerService, gamesProviderService, usersPointService, terminalService, kioskPaymentService) {
        var _this = this;
        this.kioskService = kioskService;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.kioskAccessorService = kioskAccessorService;
        this.partnerService = partnerService;
        this.gamesProviderService = gamesProviderService;
        this.usersPointService = usersPointService;
        this.terminalService = terminalService;
        this.kioskPaymentService = kioskPaymentService;
        this.close = new EventEmitter();
        this.buySuccess = new EventEmitter();
        this.DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;
        this.KIOSK_CRYPTO = KIOSK_CRYPTO;
        this.GAME_VENDORS = GAME_VENDORS;
        this.amount = 0;
        this.progressRiverExecs = 0;
        this.progressRiverExecsMax = 0;
        this.paymentTypes = PROJECT_CONFIG.terminalPaymentType;
        this.isBackBtn = false;
        this.kioskAccessorService.paymentAmountChanged.subscribe(function (val) {
            if (val) {
                // this.amount = val;
                _this.getBalance();
            }
        });
        this.partnerService.isRiverPayTerminalPartner.subscribe(function (val) {
            _this.isRiverPay = val;
        });
    }
    Object.defineProperty(KioskPaymentComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            var _this = this;
            this._showPopup = value;
            if (value) {
                if (this.isRiverPay) {
                    this.gamesProviderService.connectRiver().subscribe(function (val) {
                        if (val && val.status && val.userPointId) {
                            _this.usersPointService.setUserPointId(val.userPointId);
                            _this.usersPointService.setGameVendorShortName(val.gameVendorShortName);
                            _this.getBalance();
                        }
                        else if (val.status === false) {
                            _this.popupComponent.showPopupError({ text: val.message });
                        }
                        else {
                            _this.loaderService.hideAndShowTryAgain();
                        }
                    });
                }
                else {
                    this.getBalance();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KioskPaymentComponent.prototype, "isBackBtnToTypeList", {
        // private _isBackBtnToTypeList: boolean;
        set: function (value) {
            this.isBackBtn = value || (this.terminalService.isGames() && this.terminalService.isCrypto());
            console.log('payment', 'is Back btn', this.isBackBtn, this.isBackBtnToTypeList);
        },
        enumerable: true,
        configurable: true
    });
    KioskPaymentComponent.prototype.ngOnInit = function () {
    };
    KioskPaymentComponent.prototype.closePopup = function () {
        // if (!this.isRiverPay) {
        this.close.emit(this.type);
        // }
        this.kioskService.deactivateBilling();
    };
    KioskPaymentComponent.prototype.getBalance = function () {
        var _this = this;
        this.kioskPaymentService.getBalance().subscribe(function (data) {
            _this.getBalanceResult(data);
        }, function () {
            _this.showPopup = false;
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    KioskPaymentComponent.prototype.getBalanceResult = function (data) {
        this.kioskService.addPaymentType(this.type);
        try {
            if (data.status === true) {
                this.amount = data.result;
                this.progressRiverExecs = data.river_execs_current;
                this.progressRiverExecsMax = data.river_execs_max;
            }
        }
        catch (e) { }
        this.kioskService.activateBilling();
    };
    KioskPaymentComponent.prototype.onBuyCrypto = function () {
        this.kioskAccessorService.completeCryptoBuy(this.data.currencyIdTo, this.closePopup.bind(this));
    };
    KioskPaymentComponent.prototype.onBuySweeps = function () {
        this.kioskAccessorService.completeSweepsBuy(this.closePopup.bind(this));
    };
    KioskPaymentComponent.prototype.loadDefaultCurrencyIcon = function ($event) {
        var el = $event.target;
        el.src = '../../../../assets/images/currencies/' + this.DEFAULT_CRYPTO_ICON;
    };
    KioskPaymentComponent.prototype.isSweepsType = function () {
        return this.type === this.paymentTypes.sweeps;
    };
    KioskPaymentComponent.prototype.isCryptoType = function () {
        return this.type === this.paymentTypes.crypto;
    };
    KioskPaymentComponent.prototype.setPrimaryStyle = function () {
        return this.isCryptoType() && this.data.nameTo.toLowerCase() === 'eth' ||
            this.isSweepsType() && this.data && this.data.short_name === this.GAME_VENDORS.mascotCasino;
    };
    KioskPaymentComponent.prototype.setSecondaryStyle = function () {
        return this.isCryptoType() && this.data.nameTo.toLowerCase() === 'btc';
    };
    KioskPaymentComponent.prototype.getProgressWidth = function (current, max) {
        return current / max * 100 + '%';
    };
    return KioskPaymentComponent;
}());
export { KioskPaymentComponent };
