import * as i0 from "@angular/core";
var DateService = /** @class */ (function () {
    function DateService() {
        this.days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }
    DateService.prototype.getWeekDayName = function (date) {
        var d;
        if (date) {
            d = new Date(date);
        }
        else {
            d = new Date();
        }
        return this.days[d.getDay()];
    };
    DateService.prototype.getDateSafariSupport = function (date) {
        if (date) {
            return date.replace(/\s/g, 'T');
        }
        else {
            return '';
        }
    };
    DateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateService_Factory() { return new DateService(); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
