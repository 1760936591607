import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public loginChanged = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private router: Router) {

    }

    show() {
        this.loginChanged.next(true);
        document.body.classList.add('is-hidden');

    }

    hide() {
        this.loginChanged.next(false);
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {},
                //queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
        document.body.classList.remove('is-hidden');
    }

}
