import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PRIZES_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class PrizesService extends SettingsLoaderService {
    private config = PRIZES_CONFIG;
    public temporaryEmail = new BehaviorSubject('');
    public temporaryAddress = new BehaviorSubject('');

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getPrizesInfo(page) {
        //this.showLoader();
        let post = this.http.post<any>(this.config.data, {page});

        return post;
    }

    getCryptoList() {
        //this.showLoader();
        let post = this.http.post<any>(this.config.cryptoList, {});
        return post;
    }

    // getEmails() {
    //     return this.http.post<any>(this.config.emails, {});
    // }

    getPaypalEmails() {
        this.showLoader();
        let post = this.http.post<any>(this.config.paypalEmails, {});
        //this.hideLoader();

        return post;
    }

    getCheckAddresses() {
        this.showLoader();
        return this.http.post<any>(this.config.checkAddresses, {});
    }

    getUSDCoinsBalance() {
        this.showLoader();
        return this.http.post<any>(this.config.usdcBalance, {});
    }

    setTemporaryEmail(email) {
        this.temporaryEmail.next(email);
    }

    getTemporaryEmail() {
        return this.temporaryEmail.value;
    }

    setTemporaryAddress(address) {
        this.temporaryAddress.next(address);
    }

    getTemporaryAddress() {
        return this.temporaryAddress.value;
    }

    paypalCreatePayout(id, email, amount) {
      this.showLoader();
      let post = this.http.post<any>(this.config.paypalCreatePayout, {id, email, amount});
      // this.hideLoader();

      return post;
    }

    mailCheckCreatePayout(id, address, amount) {
        this.showLoader();
        return this.http.post<any>(this.config.mailCheckCreatePayout, {id, address, amount});
    }

    usdcCreatePayout(amount) {
        this.showLoader();
        return this.http.post<any>(this.config.usdcCreatePayout, {amount});
    }

    // confirmCryptoPayout(amount, id) {
    //     this.showLoader();
    //     return this.http.post<any>(this.config.confirmCryptoPayout, {amount,id});
    // }

    // confirmCryptoSellPayout(amount, id) {
    //     this.showLoader();
    //     return this.http.post<any>(this.config.confirmCryptoSellPayout, {amount,id});
    // }

    paypalAddEmail(email) {
        this.loaderService.show();
        return this.http.post<any>(this.config.paypalAddEmail, {email});
    }

    checkAddAddress(address) {
        this.loaderService.show();
        return this.http.post<any>(this.config.checkAddAddress, {address});
    }

    redeemViaCashApp(amount, handle: string) {
        this.showLoader();
        return this.http.post<any>(this.config.redeemViaCashApp, {amount, handle});
    }

    redeemViaVenmo(amount, handle: string, shortName) {
        this.showLoader();
        return this.http.post<any>(this.config.redeemViaVenmo, {amount, handle, shortName});
    }

    cashAppBalance() {
        this.showLoader();
        return this.http.post<any>(this.config.cashAppBalance, {});
    }

    venmoBalance() {
        this.showLoader();
        return this.http.post<any>(this.config.venmoBalance, {});
    }

    getGamesBalance() {
        this.showLoader();
        return this.http.post<any>(this.config.gamesBalance, {});
    }

    redeemViaGames(methodShortName: string, amount) {
        this.showLoader();
        return this.http.post<any>(this.config.redeemViaGames, {methodShortName, amount});
    }
}
