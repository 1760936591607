<div *ngIf="!isUserLogout && !isMascotSingle" class="logout">
<!--    <button (click)="logout()" class="button snake-btn logout__btn">-->
<!--        LOGOUT-->
<!--        <svg><rect></rect></svg>-->
<!--    </button>-->

    <button (click)="logout()">
        <p class="logout__text">Logout after <br><span class="logoutTimer">{{logoutTimer}}</span> sec. </p>
        <svg class="logout__icon">
            <use xlink:href="../../../../assets/images/sprite.svg#logout"></use>
        </svg>
    </button>

</div>


<div class="keyboard" [class.keyboard--centered]="isMascotSingle" *ngIf="keyboardVisible" [class.keyboard--caps]="capitalLetters">
    <button class="keyboard__key keyboard__key--no-caps" title="7" (click)="clickHandle($event)">7</button>
    <button class="keyboard__key keyboard__key--no-caps" title="8" (click)="clickHandle($event)">8</button>
    <button class="keyboard__key keyboard__key--no-caps" title="9" (click)="clickHandle($event)">9</button>

    <button class="keyboard__key keyboard__key--no-caps" title="4" (click)="clickHandle($event)">4</button>
    <button class="keyboard__key keyboard__key--no-caps" title="5" (click)="clickHandle($event)">5</button>
    <button class="keyboard__key keyboard__key--no-caps" title="6" (click)="clickHandle($event)">6</button>

    <button class="keyboard__key keyboard__key--no-caps" title="1" (click)="clickHandle($event)">1</button>
    <button class="keyboard__key keyboard__key--no-caps" title="2" (click)="clickHandle($event)">2</button>
    <button class="keyboard__key keyboard__key--no-caps" title="3" (click)="clickHandle($event)">3</button>

    <button class="keyboard__key keyboard__key--md keyboard__key--no-caps" title="cancel" (click)="clickHandle($event)">
        <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 428.235 428.235" height="512" viewBox="0 0 428.235 428.235" width="512">
            <path d="m388.088 53.529h-241.575c-12.089 0-23.419 5.358-31.091 14.728l-112.403 137.392c-4.025 4.927-4.025 12.01 0 16.937l112.417 137.404c7.658 9.357 18.989 14.715 31.077 14.715h241.575c22.138 0 40.147-18.009 40.147-40.147v-240.881c0-22.139-18.008-40.148-40.147-40.148zm-61.37 208.577-37.847 37.847-47.988-47.988-47.988 47.988-37.847-37.847 47.988-47.988-47.988-47.988 37.847-37.847 47.988 47.988 47.988-47.988 37.847 37.847-47.988 47.988c-.001 0 47.988 47.988 47.988 47.988z"/>
        </svg>
    </button>
    <button class="keyboard__key keyboard__key--no-caps" title="0" (click)="clickHandle($event)">0</button>
</div>


