/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iframe-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loaders/ellipsis-loader/ellipsis-loader.component.ngfactory";
import * as i3 from "../loaders/ellipsis-loader/ellipsis-loader.component";
import * as i4 from "./iframe-popup.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../service/loader.service";
import * as i7 from "../popup/popup.component";
import * as i8 from "../../service/transactions/transactions.service";
var styles_IframePopupComponent = [i0.styles];
var RenderType_IframePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IframePopupComponent, data: {} });
export { RenderType_IframePopupComponent as RenderType_IframePopupComponent };
export function View_IframePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { iframeParent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "iframe-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "iframe-popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["iframeParent", 1]], null, 7, "div", [["class", "iframe-popup__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "button", [["class", "iframe-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "transaction-processing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "transaction-processing__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Transaction in processing"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "transaction-processing__loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-ellipsis-loader", [], null, null, null, i2.View_EllipsisLoaderComponent_0, i2.RenderType_EllipsisLoaderComponent)), i1.ɵdid(10, 114688, null, 0, i3.EllipsisLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 10, 0); }, null); }
export function View_IframePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iframe-popup", [], null, null, null, View_IframePopupComponent_0, RenderType_IframePopupComponent)), i1.ɵdid(1, 4308992, null, 0, i4.IframePopupComponent, [i5.DomSanitizer, i6.LoaderService, i7.PopupComponent, i8.TransactionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IframePopupComponentNgFactory = i1.ɵccf("app-iframe-popup", i4.IframePopupComponent, View_IframePopupComponent_Host_0, { url: "url" }, { emitClose: "onClose" }, []);
export { IframePopupComponentNgFactory as IframePopupComponentNgFactory };
