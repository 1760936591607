<!--<div class="screen-saver" [class.is-active]="showSlider">-->
<!--    <img *ngFor="let slide of slides; let i = index"-->
<!--            #img-->
<!--            class="screen-saver__slide"-->
<!--            [src]="slide"-->
<!--            [attr.data-order]="i+1"-->
<!--            alt="">-->
<!--</div>-->


<div class="screen-saver" [class.is-active]="showSlider && !isRiverPay">
    <div #img
         [attr.data-order]="1"
         class="screen-saver__slide kiosk-slide">
        <header class="kiosk-slide__header">
            <svg class="kiosk-slide__icon" width="334" height="754" viewBox="0 0 334 754" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path d="M122.33 169.4L115.96 167.8C109.58 177.37 98.42 194.91 77.69 214.04L33.05 258.68L2.76001 229.98C64.94 158.24 111.17 103.98 174.94 1.97998H232.34V623.76C232.34 713.04 240.34 722.6 316.83 722.6H331.18V751.3H5.95V722.6H33.05C109.58 722.6 119.15 713.04 119.15 623.76V258.68C119.18 222.01 119.18 191.72 122.33 169.4Z" stroke="#F9F18B" stroke-width="4" stroke-miterlimit="10"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="333.18" height="753.3" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <div class="kiosk-slide__content">
                <h2 class="kiosk-slide__title">install.</h2>
                <h3 class="kiosk-slide__subtitle">Register through this kiosk and you will be sent the installation link.
                    On your Android device click “install”.  If using iPhone, click install and save to your
                    homescreen. You can also access through your
                    computer browser anytime. Now your money maker is easily accessible from your applications!
                </h3>
            </div>
        </header>

        <img class="kiosk-slide__hand" src="../../../assets/images/kiosk-slider/hand-with-phone.png" alt="">
        <img class="kiosk-slide__coins" src="../../../assets/images/kiosk-slider/coins.png" alt="">
        <img class="kiosk-slide__qr-code" src="../../../assets/images/kiosk-slider/qr-code.png" alt="">

        <footer class="kiosk-slide__footer">loan and trade <span>crypto</span></footer>
    </div>

    <div #img
         [attr.data-order]="2"
         class="screen-saver__slide kiosk-slide">
        <header class="kiosk-slide__header">
            <div class="kiosk-slide__content">
                <h2 class="kiosk-slide__title">payment methods.</h2>
                <h3 class="kiosk-slide__subtitle">No matter what you’re in to - this
                    application is for you.  You can pay cash right here, right now. Or you can pay with Cash App,
                    Card, or PayPal to fund your membership and earn points.</h3>
            </div>

            <svg class="kiosk-slide__icon" width="585" height="769" viewBox="0 0 585 769" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.13 270V29.08H57.85V45.08C57.85 65.82 57.85 75.39 56.25 91.35L61.04 92.94C70.61 76.94 84.97 64.22 99.33 54.65C155.17 19.55 211.01 2 276.42 2C455.11 2 557.22 86.56 557.22 214.2C557.22 317.9 472.66 378.53 357.79 461.49L136.02 619.4C121.66 628.97 86.5601 656.1 80.1801 657.69L84.97 668.86C96.14 667.26 147.19 667.26 172.72 667.26H459.9C538.08 667.26 544.46 662.48 554.03 590.68H582.75L569.98 766.18H1.97998V675.24C16.34 673.65 37.09 644.93 127.98 571.54L301.88 416.78C357.72 365.72 443.88 298.71 443.88 214.16C443.88 112.05 376.88 43.44 276.35 43.44C140.74 43.44 75.35 147.15 57.78 254.04V270.04L29.13 270Z" stroke="#F9F18B" stroke-width="4" stroke-miterlimit="10"/>
            </svg>
        </header>

        <div class="kiosk-slide__img-wrapper">
            <img class="kiosk-slide__img-slide-2" src="../../../assets/images/kiosk-slider/slide-2.png" alt="">
            <img class="kiosk-slide__qr-code" src="../../../assets/images/kiosk-slider/qr-code.png" alt="">
        </div>


        <footer class="kiosk-slide__footer">earn & invest <span>money</span></footer>
    </div>

    <div #img
         [attr.data-order]="3"
         class="screen-saver__slide kiosk-slide is-active">
        <header class="kiosk-slide__header">
<!--            <svg class="kiosk-slide__icon" width="511" height="749" viewBox="0 0 511 749" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <g clip-path="url(#clip0)">-->
<!--                    <path d="M401.82 526.11C401.82 401.82 338.93 334.43 226.62 334.43V294C316.47 294 365.88 247.58 365.88 163.73C365.88 84.36 317.96 40.93 226.62 40.93C117.3 40.93 75.38 94.84 61.9 160.73V171.21H28.95V21.44H55.95V31.95C55.95 40.95 55.95 48.42 54.45 54.41L57.45 55.91C63.45 48.42 73.92 37.91 82.9 33.45C120.34 13.98 172.75 2 226.66 2C362.93 2 449.78 64.89 449.78 159.23C449.78 234.11 371.91 299.99 295.54 313.47V314.97C409.31 328.44 508.14 419.79 508.14 526.11C508.14 657.88 397.33 746.23 226.62 746.23C138.27 746.23 58.9 720.78 2 669.86V553.06H33.45V563.55C49.92 633.93 105.32 705.8 226.62 705.8C341.92 705.8 401.82 642.91 401.82 526.11Z" stroke="#F9F18B" stroke-width="4" stroke-miterlimit="10"/>-->
<!--                </g>-->
<!--                <defs>-->
<!--                    <clipPath id="clip0">-->
<!--                        <rect width="510" height="748" fill="white"/>-->
<!--                    </clipPath>-->
<!--                </defs>-->
<!--            </svg>-->

<!--            <svg class="kiosk-slide__icon" xmlns="http://www.w3.org/2000/svg" width="511" height="749" viewBox="0 0 511 749" fill="none" style="&#10;    width: 201px;&#10;">-->
<!--                <g clip-path="url(#clip0)">-->
<!--                    <path d="M401.82 526.11C401.82 401.82 338.93 334.43 226.62 334.43V294C316.47 294 365.88 247.58 365.88 163.73C365.88 84.36 317.96 40.93 226.62 40.93C117.3 40.93 75.38 94.84 61.9 160.73V171.21H28.95V21.44H55.95V31.95C55.95 40.95 55.95 48.42 54.45 54.41L57.45 55.91C63.45 48.42 73.92 37.91 82.9 33.45C120.34 13.98 172.75 2 226.66 2C362.93 2 449.78 64.89 449.78 159.23C449.78 234.11 371.91 299.99 295.54 313.47V314.97C409.31 328.44 508.14 419.79 508.14 526.11C508.14 657.88 397.33 746.23 226.62 746.23C138.27 746.23 58.9 720.78 2 669.86V553.06H33.45V563.55C49.92 633.93 105.32 705.8 226.62 705.8C341.92 705.8 401.82 642.91 401.82 526.11Z" stroke="#F9F18B" stroke-width="4" stroke-miterlimit="10"/>-->
<!--                </g>-->
<!--                <defs>-->
<!--                    <clipPath id="clip0">-->
<!--                        <rect width="510.14" height="748.23" fill="white"/>-->
<!--                    </clipPath>-->
<!--                </defs>-->
<!--            </svg>-->

            <img class="kiosk-slide__icon" src="../../../assets/images/kiosk-slider/third.svg" alt="">


            <div class="kiosk-slide__content">
                <h2 class="kiosk-slide__title">trade & lend crypto.</h2>
                <h3 class="kiosk-slide__subtitle">Buying, trading, and selling your favorite cryptocurrencies has
                    never been more exciting. Invest and watch your money grow! Make sure to check back regularly
                    so you trade your crypto for the best rate.</h3>
            </div>
        </header>

        <img class="kiosk-slide__img-slide-3" src="../../../assets/images/kiosk-slider/slide-3.png" alt="">
        <img class="kiosk-slide__qr-code" src="../../../assets/images/kiosk-slider/qr-code.png" alt="">

        <footer class="kiosk-slide__footer">grow your worth - <span>anywhere</span></footer>
    </div>
</div>

<div (click)="stopVideo()" class="screen-saver" [class.is-active]="showSlider && isRiverPay">
    <iframe *ngIf="isRiverPay" [src]="riverPaySliderLink">
        Your browser doesn't support iframes
    </iframe>
<!--    <div class="login__inner">-->
<!--        <div class="login__col login__top">-->
<!--            <div class="login__header">-->
<!--                <img class="login__logo" src="{{getPartnerLogo()}}" alt="">-->
<!--            </div>-->

<!--            <div class="login__title-wrapper">-->
<!--                <div class="login__title">{{getPartnerName()}} <span class="login__title&#45;&#45;inner"><span class="capital">S</span>weeps</span></div>-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->


<!--    <video id="riverPayVideo" [volume]="0" #video (load)="videoLoad()" (timeupdate)="currentTime($event)" [loop]="true" [autoplay]="true">-->
<!--        &lt;!&ndash;<source src="./../../../assets/videos/oceanDiveScreensaver.mp4?n=3" type="video/mp4">&ndash;&gt;-->
<!--        &lt;!&ndash;<source #videoSource src="./../../../assets/videos/videoplayback.webm?n={{random()}}" type="video/ogg">&ndash;&gt;-->
<!--        &lt;!&ndash;<source #videoSource src="{{videoLinkRes}}" type="video/webm">&ndash;&gt;-->
<!--        <source #videoSource type="video/webm">-->

<!--        Your browser does not support the video tag.-->
<!--    </video>-->
</div>
