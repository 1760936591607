<div class="mascot">
<!--    <img class="mascot__img" src="../../../../assets/images/gamesProviders/mascot/mascot_bg.png" alt="">-->
    <div class="mascot__menu mascot-menu">
        <a href="" class="mascot-menu__link">
            <img src="../../../../assets/images/gamesProviders/mascot/dragon_eye_1.png" alt="" class="mascot-menu__img">
            <span>All Games</span>
        </a>
        <a href="" class="mascot-menu__link">
            <img src="../../../../assets/images/gamesProviders/mascot/dragon_eye_2.png" alt="" class="mascot-menu__img">
            <span>Urban & riot</span>
        </a>
        <a href="" class="mascot-menu__link">
            <img src="../../../../assets/images/gamesProviders/mascot/dragon_eye_3.png" alt="" class="mascot-menu__img">
            <span>fruit, fruit</span>
        </a>
        <a href="" class="mascot-menu__link">
            <img src="../../../../assets/images/gamesProviders/mascot/dragon_eye_4.png" alt="" class="mascot-menu__img">
            <span>Kenoooo</span>
        </a>
    </div>
    
    <div class="mascot__jackpot">
        JackPot <br>
        {{jackpot | currency}}
    </div>
</div>

<!--<div class="mascot-rotate">-->
<!--    <h1>Please, rotate your device</h1>-->
<!--</div>-->



