import {AfterContentInit, Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthConfig} from '../../_services/auth-config';
import {UserIdleConfig} from '../user-idle-config';


@Component({
  selector: 'app-user-idle',
  templateUrl: './user-idle.component.html',
  styleUrls: ['./user-idle.component.scss']
})
export class UserIdleComponent implements OnInit, OnDestroy, AfterContentInit {
  idleHeader = '';
  idleMessage = '';

  public onCloseDialog: EventEmitter<any> = new EventEmitter<any>();

  public timeToEnd: number;

  constructor(@Inject(AuthConfig) private authConfig: AuthConfig, @Inject(UserIdleConfig) private idleConfig: UserIdleConfig) {
    this.timeToEnd = this.idleConfig.timeout;
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onCloseDialog = new EventEmitter<any>();
  }

  ngAfterContentInit(): void {

  }
}
