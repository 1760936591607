import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {KioskService} from "./kiosk.service";

@Injectable({
    providedIn: 'root'
})
export class ScreenSaverService {
    showing = new BehaviorSubject(false);
    animation = new BehaviorSubject('');
    logoutCmd = new BehaviorSubject(0);

    showScreenSaverTimer;
    showScreenSaverAfterMin = 5;
    //showScreenSaverAfterMin = 0.3;

    isApp = this.kioskService.isKiosk();

    constructor(
        private kioskService: KioskService
        ) {
    }

    logout(){
        if (this.isApp) {
            this.logoutCmd.next(Math.round(+new Date() / 1000) + Math.random());
        }
    }

    hideScreenSaver(){
        if (this.isApp) {
            this.setSaveScreenTimer();
            this.hide();
        }
    }

    setSaveScreenTimer() {
        if (this.isApp) {
            this.clearSaveScreenTimer();
            this.showScreenSaverTimer = setTimeout(() => {
                console.log("setSaveScreenTimer");

                this.show();
                document.body.click();
                this.logout();
            }, this.showScreenSaverAfterMin * 60 * 1000);
        }
    }

    clearSaveScreenTimer() {
        clearTimeout(this.showScreenSaverTimer);
    }


    show() {
        this.showing.next(true);
    }

    hide() {
        this.showing.next(false);
    }

}
