/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider-test3.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-slick-carousel";
import * as i3 from "../../../../node_modules/ngx-slick-carousel/ngx-slick-carousel.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./slider-test3.component";
import * as i7 from "../../service/mascot/mascot.service";
import * as i8 from "../../service/loader.service";
var styles_SliderTest3Component = [i0.styles];
var RenderType_SliderTest3Component = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderTest3Component, data: {} });
export { RenderType_SliderTest3Component as RenderType_SliderTest3Component };
function View_SliderTest3Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slide"], ["ngxSlickItem", ""]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.SlickItemDirective, [i1.ElementRef, i1.PLATFORM_ID, i2.SlickCarouselComponent], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["width", "100%"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.img, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_SliderTest3Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slide"], ["ngxSlickItem", ""]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.SlickItemDirective, [i1.ElementRef, i1.PLATFORM_ID, i2.SlickCarouselComponent], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["width", "100%"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.image360, ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_SliderTest3Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngx-slick-carousel", [["class", "carousel"]], null, [[null, "init"], [null, "breakpoint"], [null, "afterChange"], [null, "beforeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("init" === en)) {
        var pd_0 = (_co.slickInit($event) !== false);
        ad = (pd_0 && ad);
    } if (("breakpoint" === en)) {
        var pd_1 = (_co.breakpoint($event) !== false);
        ad = (pd_1 && ad);
    } if (("afterChange" === en)) {
        var pd_2 = (_co.afterChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("beforeChange" === en)) {
        var pd_3 = (_co.beforeChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_SlickCarouselComponent_0, i3.RenderType_SlickCarouselComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SlickCarouselComponent]), i1.ɵdid(2, 13287424, [["slickModal2", 4]], 0, i2.SlickCarouselComponent, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { config: [0, "config"] }, { afterChange: "afterChange", beforeChange: "beforeChange", breakpoint: "breakpoint", init: "init" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTest3Component_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ngx-slick-carousel", [["class", "carousel"]], null, [[null, "init"], [null, "breakpoint"], [null, "afterChange"], [null, "beforeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("init" === en)) {
        var pd_0 = (_co.slickInit($event) !== false);
        ad = (pd_0 && ad);
    } if (("breakpoint" === en)) {
        var pd_1 = (_co.breakpoint($event) !== false);
        ad = (pd_1 && ad);
    } if (("afterChange" === en)) {
        var pd_2 = (_co.afterChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("beforeChange" === en)) {
        var pd_3 = (_co.beforeChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_SlickCarouselComponent_0, i3.RenderType_SlickCarouselComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SlickCarouselComponent]), i1.ɵdid(7, 13287424, [["slickModal", 4]], 0, i2.SlickCarouselComponent, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { config: [0, "config"] }, { afterChange: "afterChange", beforeChange: "beforeChange", breakpoint: "breakpoint", init: "init" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SliderTest3Component_2)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slideConfig; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.slides; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.slideConfig; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.slidesDynamic; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_SliderTest3Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider-test3", [], null, null, null, View_SliderTest3Component_0, RenderType_SliderTest3Component)), i1.ɵdid(1, 114688, null, 0, i6.SliderTest3Component, [i7.MascotService, i8.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderTest3ComponentNgFactory = i1.ɵccf("app-slider-test3", i6.SliderTest3Component, View_SliderTest3Component_Host_0, {}, {}, []);
export { SliderTest3ComponentNgFactory as SliderTest3ComponentNgFactory };
