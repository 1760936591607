import * as tslib_1 from "tslib";
import { LENDING_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var InvestmentsService = /** @class */ (function (_super) {
    tslib_1.__extends(InvestmentsService, _super);
    function InvestmentsService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = LENDING_CONFIG;
        _this.checkedItem = new BehaviorSubject(null);
        return _this;
    }
    InvestmentsService.prototype.getInfo = function () {
        this.showLoader();
        var post = this.http.post(this.config.info, {});
        return post;
    };
    InvestmentsService.prototype.getUSDC = function (page) {
        // this.showLoader();
        var post = this.http.post(this.config.usdcList, { page: page });
        return post;
    };
    InvestmentsService.prototype.getUsd = function (page) {
        // this.showLoader();
        var post = this.http.post(this.config.usdList, { page: page });
        return post;
    };
    InvestmentsService.prototype.getCrypto = function () {
        // this.showLoader();
        var post = this.http.post(this.config.cryptoList, {});
        return post;
    };
    InvestmentsService.prototype.getCryptoSpendInfo = function (amount, address, currencyId) {
        this.showLoader();
        var post = this.http.post(this.config.cryptoCart, { amount: amount, address: address, currencyId: currencyId });
        return post;
    };
    InvestmentsService.prototype.spendCrypto = function (amount, address, currencyId, note) {
        this.showLoader();
        var post = this.http.post(this.config.cryptoSpend, { amount: amount, address: address, currencyId: currencyId, note: note });
        return post;
    };
    // OLD (PAIRS)
    // getTrading() {
    //   // this.showLoader();
    //   let post = this.http.post<any>(this.config.tradingList, {})
    //   return post;
    // }
    InvestmentsService.prototype.getTrading = function () {
        var post = this.http.post(this.config.tradingList, {});
        return post;
    };
    InvestmentsService.prototype.getInvestment = function () {
        var post = this.http.post(this.config.investmentList, {});
        return post;
    };
    InvestmentsService.prototype.getTradingInfo = function (currencyIdFrom, currencyIdTo) {
        this.showLoader();
        var post = this.http.post(this.config.tradingCart, { currencyIdFrom: currencyIdFrom, currencyIdTo: currencyIdTo });
        return post;
    };
    InvestmentsService.prototype.confirmTrade = function (currencyIdFrom, currencyIdTo, amount) {
        this.showLoader();
        var post = this.http.post(this.config.tradingGetInfo, { currencyIdFrom: currencyIdFrom, currencyIdTo: currencyIdTo, amount: amount });
        return post;
    };
    InvestmentsService.prototype.confirmTradeResult = function (currencyIdFrom, currencyIdTo, amount) {
        this.showLoader();
        var post = this.http.post(this.config.tradingConfirm, { currencyIdFrom: currencyIdFrom, currencyIdTo: currencyIdTo, amount: amount });
        return post;
    };
    InvestmentsService.prototype.setCheckedItem = function (item) {
        this.checkedItem.next(item);
    };
    InvestmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvestmentsService_Factory() { return new InvestmentsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: InvestmentsService, providedIn: "root" });
    return InvestmentsService;
}(SettingsLoaderService));
export { InvestmentsService };
