import {Component, OnInit, ElementRef} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../service/sell/existingUser.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {UserStatusService} from "../../service/userStatus.service";
import {USER_STATUS} from "../../config/user_status";
import {RouterPaths} from "../../config/router.paths.model";
import {StylingService} from "../../service/styling.service";
import {PartnerService} from "../../service/partner.service";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {SettingsService} from "../../service/settings/settings.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': '','[class.is-menu]': 'menu',} : {'[@slideToLeft]': '', '[class.is-menu]': 'menu',}
})
export class SettingsComponent implements OnInit {
    userStatus;
    menu = false;
    isRiverPay: boolean;

    navigation = [];
    navigationNoLimits = [];

    USER_STATUS = USER_STATUS;
    gameVendorName = this.gamesProviderService.getActiveProviderName();
    gameVendorShortName = this.gamesProviderService.getActiveProviderShortName();
    showSupportForm = false;
    cashappTag: string;

    cashappVerified = false;
    userCashappTag: string;
    subjectLimitationTxt: string;
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private userStatusService: UserStatusService,
                private popupComponent: PopupComponent,
                private stylingService: StylingService,
                private partnerService: PartnerService,
                private settingsService: SettingsService,
                private activateRoute: ActivatedRoute,
                private gamesProviderService: GamesProviderService,
                private router: Router) {

        this.isRiverPay = this.partnerService.isRiverPayTerminal();
        this.cashappTag = this.partnerService.storageGetCashapTag();

      this.activateRoute.queryParams.subscribe(queryParams => {
        if (queryParams.back) {
          setTimeout(() => {
            (document.querySelector('.dropdown').querySelector('.dropdown__head') as HTMLElement).click();
            setTimeout(() => {
              (<HTMLElement>document.querySelector('.page__scrolled')).scrollIntoView({behavior: 'smooth'});
              (<HTMLElement>document.querySelector('.page__scrolled')).scroll({
                top: (document.querySelector('.dropdown').querySelector('.dropdown__head') as HTMLElement).offsetHeight + (document.querySelector('.page__body') as HTMLElement).offsetTop - (document.querySelector('.page__scrolled') as HTMLElement).offsetTop,
                left: 0,
                behavior: 'smooth'
              });
            }, 500);
          }, 0)
        }
      });
    }


    ngOnInit() {
        this.getUserStatus();

        this.navigation = [
            {
                title: 'cash app card',
                link: RouterPaths.settings.cashapp.main,
                hidden: this.isRiverPay,
                id: 'cashapp',
            },
            {
                title: 'CREDIT/DEBIT CARD',
                link: RouterPaths.settings.card.main,
                hidden: this.isRiverPay,
                id: 'credit_card',
            },
            {
                title: 'PAPER CHECKS',
                link: RouterPaths.settings.addresses.main,
                id: 'mail_check',
            },
            {
                title: 'Contact info',
                link: RouterPaths.settings.contacts,
                id: 'contact_info',
            },
        ];

        this.setNavigationNoLimits();
    }

    setNavigationNoLimits() {
        this.navigationNoLimits = [
            {
                title: 'Text me address',
                action: this.onTextMe.bind(this),
                hidden: !this.cashappVerified,
                id: 'text_me',
            },
            {
                title: 'Help',
                action: this.onHelpBtnClick.bind(this),
                hidden: !this.cashappVerified,
                id: 'help',
            },
            {
                title: 'Support',
                action: this.onShowSupportForm.bind(this),
                id: 'support',
            },
        ]
    }

    onHelpBtnClick() {
        this.router.navigate([RouterPaths.settings.help]);
    }

    onTextMe() {
        this.settingsService.textMe().subscribe(data => {
            try {
                if (data.status) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onShowSupportForm() {
        this.showSupportForm = true;
        document.body.classList.add('hide-menu');
    }

    onSupportClose($event) {
        this.showSupportForm = false;
    }

    getUserStatus() {
        this.userStatusService.getSettingsData().subscribe(data => {
            this.setUserStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setUserStatus(data) {
        if (data.status === true) {
            this.userStatus = data.userStatus;
            this.subjectLimitationTxt = data.subjectLimitationTxt;
            this.cashappVerified = data.cashappTagStatus;
            this.userCashappTag = data.cashappTag;

            this.setNavigationNoLimits();
        } else if (data.status === false){
            this.popupComponent.showPopupError({text: data.message});
        } else if (data.status === undefined){
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `You are about to freeze your account. No data will be saved. Do you wish to proceed?`}, (status) => {
            if (status) {
                this.freezeUser();
            }
        });
    }

    freezeUser() {
        this.userStatusService.freeze().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    askUnFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to freeze the user?`}, (status) => {
            if (status) {
                this.unFreezeUser();
            }
        });
    }

    unFreezeUser() {
        this.userStatusService.activate().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    askDeleteUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to delete the user?`,
            confirmButtonText: 'DELETE ACCOUNT<svg><rect></rect></svg>',
            style: 'swal-agreement--error'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.userStatusService.delete().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    updateUserChangingStatus(data) {
        if(data.status === true || data.status === "true"){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.menu = false;
            this.getUserStatus();
        } else if (data.status === false || data.status === "false") {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    background(id){
        if(id == 'credit_card'){
            //return 'card_background';
        }

        return '';
    }

    isAvailble(id,link){
        // if(id == 'credit_card' || id == 'cashapp'){
        //     this.popupComponent.showPopupError({
        //         text:'Performing maintenance. Temporarily unavailable. Please check back later.',
        //         confirmButtonText:'OK'
        //     });
        //
        //     return;
        // }

        this.router.navigate([link]);
    }

    getLinkToFunds() {
        return RouterPaths.settings.funds;
    }

    getLinkToCashApp() {
        return RouterPaths.settings.cashapp.main;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    setCashappVerified($event?) {
        this.cashappVerified = true;
        this.setNavigationNoLimits();
    }
}
