import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-group-bordered',
    templateUrl: './group-bordered.component.html',
    styleUrls: ['./group-bordered.component.scss']
})
export class GroupBorderedComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
