import * as tslib_1 from "tslib";
import { PROMO_777_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import { timer } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var Promo777Service = /** @class */ (function (_super) {
    tslib_1.__extends(Promo777Service, _super);
    function Promo777Service(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = PROMO_777_CONFIG;
        return _this;
    }
    Promo777Service.prototype.register = function (firstName, lastName, phone, email) {
        return this.http.post(this.config.register, { firstName: firstName, lastName: lastName, phone: phone, email: email });
    };
    Promo777Service.prototype.verification = function (phone, pin) {
        return this.http.post(this.config.verify, { phone: phone, pin: pin });
    };
    Promo777Service.prototype.sendCashapp = function (cashapp) {
        return this.http.post(this.config.cashapp, { cashapp: cashapp });
    };
    Promo777Service.prototype.textCashTag = function () {
        return timer(500).pipe();
        // return this.http.post<any>(this.config.textCashTag, {});
    };
    Promo777Service.prototype.playNow = function (deposit, skip) {
        return timer(500).pipe();
        // return this.http.post<any>(this.config.playNow, {});
    };
    Promo777Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Promo777Service_Factory() { return new Promo777Service(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: Promo777Service, providedIn: "root" });
    return Promo777Service;
}(SettingsLoaderService));
export { Promo777Service };
