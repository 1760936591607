import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {CommissionService} from "../../../service/commission/commission.service";
import {CommissionHistoryItemModel} from "../../../models/commission/commissionHistoryItemModel";
import {COMMISSION_PAYOUT_METHODS, COMMISSION_TYPE} from "./commission.config";
import {DateService} from "../../../service/date.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../../service/pageScroll/paginationScroll.service";
import {PROJECT_MESSAGES} from "../../../config/project";
import {formatNumber} from "@angular/common";
import {CRYPTO_AMOUNT_MASK} from "../../../config/crypto_settings";
import {PartnerService} from "../../../service/partner.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";

@Component({
    selector: 'app-commission',
    templateUrl: './commission.component.html',
    styleUrls: ['./commission.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CommissionComponent implements OnInit {
    formRefer: FormGroup;
    form: FormGroup;

    phoneMask = this.phoneMaskService.setPhoneMask();

    balance = 0;
    availableBalance = 0;
    commission = '';
    site: string;
    promoCode: string;

    commissionHistory: Array<CommissionHistoryItemModel> = [];

    commissionDataResponseKeys = {
        site: 'site',
        balance: 'balance',
        promoCode: 'promoCode',
        history: 'history',
        commission: 'commission',
        availableBalance: 'availableBalance',
        methods: 'methods',
    };

    COMMISSION_TYPE = COMMISSION_TYPE;

    selectedMethod;
    isNoMailCheckChosen = true;
    methods = [];
    moreThenAvailablePayout = false;

    partnerDomain = '';
    commissionData: PaginationDataModel = new PaginationData('commission', '.js-commission-item', '.js-commission-parent', 'history');
    disputesData: PaginationDataModel = new PaginationData('disputes', '.js-disputes-item', '.js-disputes-parent', 'disputes');

    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private commissionService: CommissionService,
                private dateService: DateService,
                private partnerService: PartnerService,
                private phoneMaskService: PhoneMaskService,
                private paginationScrollService: PaginationScrollService,
                private formBuilder: FormBuilder) {
    }


    ngOnInit() {
        this.partnerDomain = this.partnerService.getPartnerDomain();
        this.formRefer = this.formBuilder.group({
            phone: [null, Validators.required],
        });

        this.form = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
            address: [null],
        });

        this.getData(true);
    }

    getData(update = false) {
        this.commissionService.getCommissionData('', 1, 1).subscribe(data => {
            this.setData(data, update);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setData(data, update) {
        try {
            if(data.status){
                this.promoCode = data[this.commissionDataResponseKeys.promoCode];
                this.site = data[this.commissionDataResponseKeys.site];
                this.balance = data[this.commissionDataResponseKeys.balance];
                this.availableBalance = data[this.commissionDataResponseKeys.availableBalance];
                this.commission = data[this.commissionDataResponseKeys.commission];
                this.commissionHistory = data[this.commissionDataResponseKeys.history];

                if (data[this.commissionDataResponseKeys.methods].length && update) {
                    this.methods = data[this.commissionDataResponseKeys.methods];
                    this.selectedMethod = this.methods[0];
                    this.form.controls.method.setValue(this.selectedMethod);
                }

                if (!update) {
                    this.paginationScrollService.resetPagination(this.commissionData);
                }

                setTimeout(() => {
                    const commissionDataSettings: SetPaginationDataModel = {
                        itemsList: data.history,
                        scrollLimit: data.historyLimit,
                        total: data.historyTotal
                    };

                    this.initScrolledData(this.commissionData, commissionDataSettings);

                    const disputesDataSettings: SetPaginationDataModel = {
                        itemsList: data.disputes,
                        scrollLimit: data.disputesLimit,
                        total: data.disputesTotal
                    };

                    this.initScrolledData(this.disputesData, disputesDataSettings);
                });
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        const phone = this.formRefer.controls.phone.value;

        if (!phone) {
            return;
        }

        this.commissionService.sendRefer(phone).subscribe(data => {
            this.displaySubmitResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.formRefer.controls.phone.setValue(null);
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }


    onSubmitPayout() {
        const amount = this.form.controls.amount.value;

        const method = this.form.controls.method.value.shortName;
        const methodName = this.form.controls.method.value.name;
        const isMailCheckMethod = method === COMMISSION_PAYOUT_METHODS.mailCheck;
        const address = isMailCheckMethod ? this.form.controls.address.value : null;

        if (!amount || !method || (isMailCheckMethod && !address)) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = isMailCheckMethod ? `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00 to ${methodName} address: ${address}?` : `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.sendPayout(method, amount, address);
                }
            });
        }
    }

    sendPayout(method, amount, address) {
        this.commissionService.sendPayout(method, amount, address).subscribe(data => {
            this.displaySubmitPayoutResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitPayoutResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetFormPayout();
                this.getData();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetFormPayout() {
        this.form.controls.amount.setValue(null);
        this.form.controls.address.setValue(null);
        this.moreThenAvailablePayout = false;
    }

    getDate(date) {
        return this.dateService.getDateSafariSupport(date);
    }

    getAmount(amount, type, baseType) {
        return type === baseType ? amount : 0;
    }


    onInputAmount() {
        let avaliable = typeof this.availableBalance === 'number' ? this.availableBalance : Number.parseFloat(this.availableBalance);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            if (avaliable < 0) {
                avaliable = 0;
            }


            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onInputAddress($event) {
        this.form.get('address').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    onMethodChange(method) {
        if (method.shortName === 'mailCheck') {
            this.isNoMailCheckChosen = false;
        } else {
            this.isNoMailCheckChosen = true;
        }
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this));
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const type = settings.name ===  'commission' ? 'ledger' : 'disputs';
        const pageLedger = settings.name ===  'commission' ? page : '';
        const pageDisputs = settings.name ===  'commission' ? '' : page;

        this.commissionService.getCommissionData(type, pageLedger, pageDisputs).subscribe(data => {
            this.paginationScrollService.dataAddingLogic(page, step, data, settings);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });

    }

    noSubmitPayoutForm() {
        if (this.form) {
            const amount = this.form.controls.amount.valid;
            const address = this.form.controls.address.value;
            if (amount) {
                if (!this.isNoMailCheckChosen && !address) {
                    return true;
                }
                return false;
            }
        }
        return true;
    }

    isAvailableBalance() {
        if (this.availableBalance < 0) {
            return false;
        } else {
            return true;
        }
    }
}
