import * as i0 from "@angular/core";
var DeviceDetectionService = /** @class */ (function () {
    function DeviceDetectionService() {
        this.userAgent = navigator.userAgent.toLowerCase();
    }
    DeviceDetectionService.prototype.isAndroid = function () {
        return this.userAgent.indexOf("android") > -1;
    };
    DeviceDetectionService.prototype.isIphone = function () {
        return this.userAgent.toLowerCase().indexOf("iphone") > -1;
    };
    DeviceDetectionService.prototype.isIpad = function () {
        return this.userAgent.toLowerCase().indexOf("ipad") > -1;
    };
    DeviceDetectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeviceDetectionService_Factory() { return new DeviceDetectionService(); }, token: DeviceDetectionService, providedIn: "root" });
    return DeviceDetectionService;
}());
export { DeviceDetectionService };
