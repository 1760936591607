import * as tslib_1 from "tslib";
import { USER_CONTACT_CONFIG } from "../config/api";
import { SettingsLoaderService } from "./settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ContactInfoService = /** @class */ (function (_super) {
    tslib_1.__extends(ContactInfoService, _super);
    function ContactInfoService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = USER_CONTACT_CONFIG;
        return _this;
    }
    ContactInfoService.prototype.getUserContacts = function () {
        this.showLoader();
        var post = this.http.post(this.config.info, {});
        this.hideLoader();
        return post;
    };
    ContactInfoService.prototype.saveUserContacts = function (first_name, last_name, email) {
        this.showLoader();
        var post = this.http.post(this.config.save, { first_name: first_name, last_name: last_name, email: email });
        this.hideLoader();
        return post;
    };
    ContactInfoService.prototype.createUser = function (first_name, last_name, email, promo_code) {
        this.showLoader();
        var post = this.http.post(this.config.create, { first_name: first_name, last_name: last_name, email: email, promo_code: promo_code });
        this.hideLoader();
        return post;
    };
    ContactInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactInfoService_Factory() { return new ContactInfoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ContactInfoService, providedIn: "root" });
    return ContactInfoService;
}(SettingsLoaderService));
export { ContactInfoService };
