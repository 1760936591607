import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserIdleConfig} from './user-idle/user-idle-config';
import {AuthConfig} from './_services/auth-config';
import {UserIdleComponent} from './user-idle/user-idle/user-idle.component';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {DateResponseInterceptor} from './_helpers/date-response.interceptor';
import {RolesConfig} from './permission-guard/roles-config';
import {CanAccessDirective} from './permission-guard/can-access.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    UserIdleComponent,
    CanAccessDirective,
  ],
  entryComponents: [UserIdleComponent],
  providers: [
    AuthGuard
  ],
  exports: [
    CanAccessDirective,
  ]
})
export class AuthModule {
  static forRoot(authConfig: AuthConfig, config: RolesConfig): ModuleWithProviders {

    return {
      ngModule: AuthModule,
      providers: [
        {provide: RolesConfig, useValue: config},
        {provide: AuthConfig, useValue: authConfig},
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DateResponseInterceptor,
          multi: true
        }
      ]
    };
  }
}
