import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../modules/auth/_services/authentication.service";
import * as i3 from "../shared/popup/popup.component";
var PhoneService = /** @class */ (function () {
    function PhoneService(ngZone, router, authenticationService, popupComponent) {
        this.ngZone = ngZone;
        this.router = router;
        this.authenticationService = authenticationService;
        this.popupComponent = popupComponent;
        this.postUploded = new BehaviorSubject(null);
        this.postAvatarUploded = new BehaviorSubject(null);
        this.userAvatarUploded = new BehaviorSubject(null);
    }
    PhoneService.prototype.init = function () {
        window.myPhone = window.myPhone || {};
        window.myPhone.namespace = window.myPhone.namespace || {};
        window.myPhone.namespace.publicFunc = this.publicFunc.bind(this);
    };
    PhoneService.prototype.destroy = function (blockId) {
        window.myPhone.namespace.publicFunc = null;
    };
    PhoneService.prototype.publicFunc = function (json) {
        //this.popupComponent.showPopupError({text: json});
        if (typeof json === 'string') {
            json = json.replace('SyntaxError: Unexpected end of JSON input', '');
            try {
                json = JSON.parse(json);
            }
            catch (e) {
                this.popupComponent.showPopupError({ text: json });
                //this.popupComponent.showPopupError({text: e});
            }
        }
        if (json.status == 'true' || json.status === true) {
            var command = json.command;
            if (command == 'postUpload') {
                this.postUploded.next({ id: json.id }); //Math.round(+new Date()/1000)+Math.random()
            }
            if (command == 'uploadAvatarToContent') {
                this.postAvatarUploded.next({ avatar_link: json.avatar_link });
            }
            if (command == 'uploadAvatarToUser') {
                this.userAvatarUploded.next({ avatar_link: json.avatar_link });
            }
        }
    };
    PhoneService.prototype.setFilesList = function () {
        var token = this.authenticationService.getToken();
        var json = '{"command":"filesList","token":"' + token + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.setTakePhoto = function () {
        var token = this.authenticationService.getToken();
        var json = '{"command":"takePhoto","token":"' + token + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.setTakeVideo = function () {
        var token = this.authenticationService.getToken();
        var json = '{"command":"takeVideo","token":"' + token + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.uploadAvatarToContent = function (id) {
        var token = this.authenticationService.getToken();
        var json = '{"command":"uploadAvatarToContent","token":"' + token + '","id":"' + id + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.uploadAvatarToUser = function (id) {
        var token = this.authenticationService.getToken();
        var json = '{"command":"uploadAvatarToUser","token":"' + token + '","id":"' + id + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.sendCommand = function (json) {
        if (window.webkit) {
            window.webkit.messageHandlers.phoneListener.postMessage(json);
        }
        //if(AndroidFunction) {
        AndroidFunction.getCommand(json);
        //}
    };
    PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.PopupComponent)); }, token: PhoneService, providedIn: "root" });
    return PhoneService;
}());
export { PhoneService };
