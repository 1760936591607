import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AddressesService} from "../../../../service/settings/addresses.service";
import {RouterPaths} from "../../../../config/testing/router.paths.model";
import {StylingService} from "../../../../service/styling.service";

@Component({
    selector: 'app-edit-address',
    templateUrl: './edit-address.component.html',
    styleUrls: ['./edit-address.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class EditAddressComponent implements OnInit {
    newAddressForm: FormGroup;
    address;
    addressId;
    routerPaths = RouterPaths;
    constructor(private formBuilder: FormBuilder,
                private addressesService: AddressesService,
                private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newAddressForm = this.formBuilder.group({
            address: ['', Validators.required],
        });
        this.route.params.subscribe((params: Params) => {
            this.addressId = params.id;

            this.getAccountsList(this.addressId);
        });
    }

    getAccountsList(id) {
        if (id) {
            this.addressesService.find(id).subscribe(data => {
                this.findAccountResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        } else {
            this.loaderService.hideAndShowTryAgain();
            this.goBack();
        }
    }

    findAccountResult(data) {
        if(data.status === true && data.result && data.result.address){
            this.setAddress(data.result.address);
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
            this.goBack();
        }
    }

    onSubmit() {
        const address = this.getAddress();

        if (this.addressId && address.trim()) {
            this.addressesService.editAddress(this.addressId, address).subscribe(data => {
                this.onSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitResult(data) {
        if(data.status === true){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.goBack();
        } else if (data.status === false){
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setAddress(address) {
        if (address && address.trim()) {
            this.newAddressForm.controls.address.setValue(address);
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getAddress() {
        return this.newAddressForm.controls.address.value;
    }

    goBack() {
        this.newAddressForm.controls.address.setValue('');
        this.router.navigate([this.routerPaths.addresses.main]);
    }

    onInput($event) {
        this.newAddressForm.get('address').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
