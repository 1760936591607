/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hero.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../keyboard/keyboard.component.ngfactory";
import * as i3 from "../keyboard/keyboard.component";
import * as i4 from "../../service/keyboard.service";
import * as i5 from "../../modules/auth/_services/authentication.service";
import * as i6 from "../../service/partner.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "../trading/trading.component.ngfactory";
import * as i10 from "../trading/trading.component";
import * as i11 from "../../service/coins/coinsTrade.service";
import * as i12 from "../../service/loader.service";
import * as i13 from "../../service/investments/investments.service";
import * as i14 from "../../service/kiosk.service";
import * as i15 from "../popup/popup.component";
import * as i16 from "./hero.component";
var styles_HeroComponent = [i0.styles];
var RenderType_HeroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeroComponent, data: {} });
export { RenderType_HeroComponent as RenderType_HeroComponent };
function View_HeroComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "hero__keyboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "keyboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-keyboard", [], null, null, null, i2.View_KeyboardComponent_0, i2.RenderType_KeyboardComponent)), i1.ɵdid(3, 114688, null, 0, i3.KeyboardComponent, [i4.KeyboardService, i5.AuthenticationService, i6.PartnerService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_HeroComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "hero__graph hero__graph--top"], ["src", "../../../assets/images/graph_1.png"]], null, null, null, null, null))], null, null); }
function View_HeroComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "hero__graph hero__graph--bottom"], ["src", "../../../assets/images/graph_2.png"]], null, null, null, null, null))], null, null); }
export function View_HeroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "hero"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeroComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeroComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeroComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["class", "hero__girl"], ["sizes", "(max-width: 768px) 768px,\n            (max-width: 1200px) 1200px,\n            1440px"], ["src", "../../../assets/images/girl-with-vase.png"], ["srcset", "../../../assets/images/girl-with-vase-1200w.png 1200w,\n             ../../../assets/images/girl-with-vase-1440w.png 1440w\n            ../../../assets/images/girl-with-vase"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["class", "hero__coins"], ["src", "../../../assets/images/bitcoins.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "hero__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "app-trading", [], [[8, "className", 0]], [[null, "isData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isData" === en)) {
        var pd_0 = (_co.isCoins($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TradingComponent_0, i9.RenderType_TradingComponent)), i1.ɵprd(512, null, i8.ɵNgStyleImpl, i8.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i8.NgStyle, [i8.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(14, { visibility: 0 }), i1.ɵdid(15, 245760, null, 0, i10.TradingComponent, [i11.CoinsTradeService, i12.LoaderService, i7.Router, i13.InvestmentsService, i5.AuthenticationService, i14.KioskService, i15.PopupComponent], null, { isData: "isData" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isKiosk(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.showCoins; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.showCoins; _ck(_v, 6, 0, currVal_2); var currVal_5 = _ck(_v, 14, 0, (_co.showCoins ? "visible" : "hidden")); _ck(_v, 13, 0, currVal_5); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getHeroText(); _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 15).isAppClass; _ck(_v, 11, 0, currVal_4); }); }
export function View_HeroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hero", [], null, null, null, View_HeroComponent_0, RenderType_HeroComponent)), i1.ɵdid(1, 114688, null, 0, i16.HeroComponent, [i14.KioskService, i6.PartnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeroComponentNgFactory = i1.ɵccf("app-hero", i16.HeroComponent, View_HeroComponent_Host_0, {}, {}, []);
export { HeroComponentNgFactory as HeroComponentNgFactory };
