export const PARTNERS = {
    snekapek: {
        name: 'Snekapek',
        logo: 'snekapek/snekapek-logo.svg',
        short_name: 'snekapek'
    },
    sap: {
        name: 'Snekapek',
        logo: 'snekapek/snekapek-logo.svg',
        short_name: 'sap'
    },
    riverpay: {
        name: 'RiverPay',
        short_name: 'riverpay'
    },
    mascot: {
        name: 'Mascot',
        short_name: 'mascot'
    },
    default: {
        name: 'eBox',
        logo: 'activate_yourself.png',
        short_name: 'ebox'
    }
}
