import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { SCRATCH_OFF_POPUP_TYPE, SCRATCH_OFF_RULES, SCRATCH_OFF_WELCOME_RULES } from "./scratch-off-rules.config";
var ScratchOffRulesComponent = /** @class */ (function () {
    function ScratchOffRulesComponent(scratchOffService, formBuilder, popupComponent, loaderService) {
        this.scratchOffService = scratchOffService;
        this.formBuilder = formBuilder;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.isElementReady = new EventEmitter();
        this.isPaymentForbidden = new EventEmitter();
        this.isPaymentSuccess = new EventEmitter();
        this.imageLoaded = false;
        this.POPUP_TYPE = SCRATCH_OFF_POPUP_TYPE;
        this.popupVisible = false;
        this.responseKeys = {
            backgroundImage: 'image_bg',
            gamePrice: 'price',
            paymentMethods: 'cards',
            isPaymentForbidden: 'hidePayment'
        };
    }
    Object.defineProperty(ScratchOffRulesComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            this._showPopup = value;
            if (value && !this.popupVisible) {
                this.popupVisible = true;
                this.getInfo();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScratchOffRulesComponent.prototype.ngOnInit = function () {
        this.buyForm = this.formBuilder.group({
            paymentMethod: [null, Validators.required],
        });
    };
    ScratchOffRulesComponent.prototype.ngOnDestroy = function () {
        this.popupVisible = false;
    };
    ScratchOffRulesComponent.prototype.getInfo = function () {
        var _this = this;
        this.backgroundImage = './assets/images/scratchOff/game-placeholder.jpeg';
        setTimeout(function () {
            _this.rules = _this.isWelcomePopup() ? SCRATCH_OFF_WELCOME_RULES : SCRATCH_OFF_RULES;
        });
        // this.scratchOffService.setPayment().subscribe(data => {
        //     this.setPayment(data);
        // }, () => {
        //     this.loaderService.hideAndShowTryAgain();
        //     this.isElementReady.emit(false);
        // }, () => {
        //     this.loaderService.hide();
        // });
    };
    ScratchOffRulesComponent.prototype.isWelcomePopup = function () {
        return this.popupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    };
    ScratchOffRulesComponent.prototype.isScratchOffRulesPopup = function () {
        return this.popupType === SCRATCH_OFF_POPUP_TYPE.pigRules;
    };
    ScratchOffRulesComponent.prototype.setPayment = function (response) {
        if (response.status === true) {
            if (response[this.responseKeys.isPaymentForbidden]) {
                this.isPaymentForbidden.emit();
                return;
            }
            this.isElementReady.emit(true);
            this.backgroundImage = response[this.responseKeys.backgroundImage];
            this.gamePrice = response[this.responseKeys.gamePrice];
            this.paymentMethods = response[this.responseKeys.paymentMethods];
        }
        else if (response.status === false) {
            this.isElementReady.emit(false);
            this.popupComponent.showPopupError({ text: response.message });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
            this.isElementReady.emit(false);
        }
    };
    ScratchOffRulesComponent.prototype.onActionBtnClick = function () {
        if (this.isScratchOffRulesPopup()) {
            this.buyGameRequest(1);
        }
        else if (this.isWelcomePopup()) {
            this.isPaymentSuccess.emit();
        }
        // if (this.activeMethod) {
        //     const cardId = this.getMethodId(this.activeMethod);
        //     this.popupComponent.showPopupAgreement({
        //           text: `You are purchasing ${this.gamePrice} <br><br> ${this.getPaymentName(this.activeMethod)}`},
        //       (status) => {
        //           if (status) {
        //               this.buyGameRequest(cardId);
        //           }
        //       });
        // } else {
        //     this.popupComponent.showPopupError({text: 'Please, select payment card first'});
        // }
    };
    ScratchOffRulesComponent.prototype.buyGameRequest = function (cardId) {
        this.buyGameResult({ status: true });
        // this.loaderService.show();
        // this.scratchOffService.buyGame(cardId).subscribe(data => {
        //     this.buyGameResult(data);
        // }, () => {
        //     this.loaderService.hideAndShowTryAgain();
        // }, () => {
        //     this.loaderService.hide();
        // });
    };
    ScratchOffRulesComponent.prototype.buyGameResult = function (response) {
        if (response.status === true) {
            // this.popupComponent.showPopupSuccess({text: response.message});
            this.isPaymentSuccess.emit();
        }
        else if (response.status === false) {
            this.popupComponent.showPopupError({ text: response.message });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ScratchOffRulesComponent.prototype.getPaymentMethod = function (method) {
        return method.payment_method;
    };
    ScratchOffRulesComponent.prototype.getMethodId = function (method) {
        return method.id;
    };
    ScratchOffRulesComponent.prototype.getPaymentName = function (data) {
        if (data) {
            return data.description + ' - ' + data.last_four;
        }
        return '';
    };
    ScratchOffRulesComponent.prototype.changeMethod = function (method) {
        if (method) {
            this.activeMethod = method;
        }
    };
    ScratchOffRulesComponent.prototype.onBgLoaded = function () {
        this.imageLoaded = true;
        this.isElementReady.emit(true);
    };
    return ScratchOffRulesComponent;
}());
export { ScratchOffRulesComponent };
