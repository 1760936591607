<div class="hero">
    <div class="hero__keyboard" *ngIf="isKiosk()">
        <div class="keyboard">
            <app-keyboard></app-keyboard>
        </div>
    </div>

    <ng-template [ngIf]="isKiosk() || isCryptoVersion">
        <ng-template [ngIf]="isViewDefined">
            <img *ngIf="!showCoins && !isKiosk()" class="hero__graph hero__graph--top" src="../../../assets/images/graph_1.png" alt="">
            <img *ngIf="!showCoins && !isKiosk()" class="hero__graph hero__graph--bottom" src="../../../assets/images/graph_2.png" alt="">

            <picture *ngIf="!isKiosk()" >
                <source media="(min-width:1800px)" srcset="../../../assets/images/girl-with-vase-1200w.png">
                <img class="hero__girl" src="../../../assets/images/girl-with-vase-768w.png" alt="girl-with-vase">
            </picture>

            <img *ngIf="!isKiosk()" class="hero__coins" src="../../../assets/images/bitcoins.png"  alt="">
            <img *ngIf="isKiosk()" class="hero__coins" [src]="getKioskCoinsImage()"  alt="">
            <div *ngIf="!isKiosk()" class="hero__text">{{getHeroText()}}</div>
        </ng-template>


        <app-trading [ngStyle]="{visibility: showCoins ? 'visible' : 'hidden'}"
                     (isData)="isCoins($event)"></app-trading>
    </ng-template>

    <div class="hero-slider" *ngIf="!isKiosk() && isWatchVersion">
        <div class="slide" class="slide-watch">
            <div class="slide-watch__product-subtitle slide-watch__product-text">
                Stainless Steel Mesh Band
            </div>
            <div class="slide-watch__product">
                <div class="slide-watch__product-top">

                    <div class="slide-watch__product-text slide-watch__product-text--accent">
                        WRIST WATCH
                    </div>
                </div>
                <img class="slide-watch__product-img" src="../../../assets/images/hero/slider/watch.png" alt="">
                <div class="slide-watch__bottom">
                    <div class="slide-watch__bottom-text slide-watch__bottom-text--accent">10,450 BTC</div>
                    <div class="slide-watch__bottom-text">ultrathin dial stainless steel strap quartz</div>
                    <img class="slide-watch__layover" src="../../../assets/images/hero/slider/layover-max.png" alt="">
                </div>
            </div>
            <img class="slide-watch__man" src="../../../assets/images/hero/slider/man-min-2.png" alt="">
        </div>
    </div>


</div>

