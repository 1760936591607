import * as i0 from "@angular/core";
import * as i1 from "../../shared/popup/popup.component";
var CopyToBufferService = /** @class */ (function () {
    function CopyToBufferService(popupComponent) {
        this.popupComponent = popupComponent;
    }
    CopyToBufferService.prototype.copyToBuffer = function (value, needMessage, messageText) {
        var tempInput = document.createElement('input');
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        if (needMessage) {
            var popupMessage = messageText ? messageText : 'Code has been successfully copied!';
            this.popupComponent.showPopupSuccess({ text: popupMessage });
        }
    };
    CopyToBufferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CopyToBufferService_Factory() { return new CopyToBufferService(i0.ɵɵinject(i1.PopupComponent)); }, token: CopyToBufferService, providedIn: "root" });
    return CopyToBufferService;
}());
export { CopyToBufferService };
