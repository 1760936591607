import $ from 'jquery';
import * as i0 from "@angular/core";
var FitTextService = /** @class */ (function () {
    function FitTextService() {
    }
    FitTextService.prototype.fitText = function (el) {
        var text = el.text();
        var fsize = parseInt(el.css('font-size'));
        var measured = this.measureText(text, fsize);
        if (measured.width > el.width()) {
            //console.log('reducing');
            while (true) {
                fsize = parseInt(el.css('font-size'));
                var m = this.measureText(text, fsize);
                if (m.width > el.width()) {
                    el.css('font-size', --fsize + 'px');
                }
                else {
                    break;
                }
            }
        }
        else if (measured.width < el.width()) {
            //console.log('increasing');
            while (true) {
                fsize = parseInt(el.css('font-size'));
                var rootfsize = parseInt($('html').css('font-size'));
                var maxAspectRatio = 10 / 9;
                var minAspectRatio = 10 / 11;
                var minWidth = 1280;
                var currentRatio = window.innerWidth / window.innerHeight;
                var ifSquareRatio = currentRatio < maxAspectRatio && currentRatio > minAspectRatio && minWidth < window.innerWidth;
                if (ifSquareRatio) {
                    rootfsize = rootfsize * 0.56;
                }
                if (fsize > rootfsize) {
                    break;
                }
                var m = this.measureText(text, fsize);
                if (m.width < el.width() - 4) { // not sure why -4 is needed (often)
                    el.css('font-size', ++fsize + 'px');
                }
                else {
                    break;
                }
            }
        }
    };
    FitTextService.prototype.measureText = function (pText, pFontSize, pFamily, pWeight) {
        var lDiv = document.createElement('div');
        document.body.appendChild(lDiv);
        if (pFamily != null) {
            lDiv.style.fontFamily = pFamily;
        }
        if (pWeight != null) {
            lDiv.style.fontWeight = pWeight;
        }
        lDiv.style.fontSize = "" + pFontSize + "px";
        lDiv.style.position = "absolute";
        lDiv.style.left = '-1000';
        lDiv.style.top = '-1000';
        lDiv.innerHTML = pText;
        var lResult = {
            width: lDiv.clientWidth,
            height: lDiv.clientHeight
        };
        document.body.removeChild(lDiv);
        lDiv = null;
        return lResult;
    };
    FitTextService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FitTextService_Factory() { return new FitTextService(); }, token: FitTextService, providedIn: "root" });
    return FitTextService;
}());
export { FitTextService };
