export const COMMISSION_TYPE = {
  credit: 1,
  debit: 2,
  settlement: 3,
  cardFee: 4
}

export const COMMISSION_PAYOUT_METHODS = {
  river: 'river',
  ebox: 'ebox',
  mascot: 'mascot',
  mailCheck: 'mailCheck'
}
