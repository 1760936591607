import {Injectable} from '@angular/core';

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen: () => void;
  webkitRequestFullscreen: () => void;
  webkitFullscreenElement: () => void;
}

interface FsDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitExitFullscreen: () => void;
  webkitRequestFullscreen: () => void;
}


@Injectable({
  providedIn: 'root'
})
export class FullScreenService {


  constructor() {
  }


  toggleFullScreen(): void {
    if (!this.isFullScreen()) {
      this.setFullScreen();
    } else {
      this.exitFullScreen();
    }
  }

  setFullScreen() {
    const fsDocElem = <FsDocumentElement> document.documentElement;

    if (fsDocElem.requestFullscreen)
      fsDocElem.requestFullscreen();
    else if (fsDocElem.msRequestFullscreen)
      fsDocElem.msRequestFullscreen();
    else if (fsDocElem.mozRequestFullScreen)
      fsDocElem.mozRequestFullScreen();
    else if (fsDocElem.webkitRequestFullscreen)
      fsDocElem.webkitRequestFullscreen();
  }

  exitFullScreen() {
    const fsDoc = <FsDocument> document;

    if (fsDoc.exitFullscreen)
      fsDoc.exitFullscreen();
    else if (fsDoc.msExitFullscreen)
      fsDoc.msExitFullscreen();
    else if (fsDoc.mozCancelFullScreen)
      fsDoc.mozCancelFullScreen();
    else if (fsDoc.webkitExitFullscreen)
      fsDoc.webkitExitFullscreen();
  }

  isFullScreen(): boolean {
    const fsDoc = <FsDocument> document;

    return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
  }

}
